<template>
  <div class="sofgratsx" :style="simulate ? 'background-color:#fff8ef' : ''">
    <f7-block class="containerCreatedon">
      <span class="createdOn"
        >Created on
        {{ $moment(focusedPrescription.createdAt).format("LLL") }}</span
      >
    </f7-block>
    <f7-block
      v-show="progressOpened"
      id="soysticky"
      class="text-align-center no-margin-bottom"
      :style="simulate ? 'background-color: rgb(255 248 239)' : ''"
    >
      <f7-row class="margin-bottom-half">
        <f7-col v-if="modifiable" class="simulate"
          ><f7-toggle
            @toggle:change="simulateCalculation($event)"
            :checked="simulate"
          ></f7-toggle
          ><span>
            <strong v-if="simulate"> Simulating...</strong
            ><template v-else> Patient data</template></span
          ></f7-col
        >
        <f7-col v-else></f7-col>
        <f7-col><p>Cardiovascular Health Score</p></f7-col>
        <f7-col></f7-col>
      </f7-row>
      <f7-block-title class="numeroin" medium
        >{{ progressbarValue || "0" }}%</f7-block-title
      >
      <f7-progressbar
        :color="
          progressbarValue < 50
            ? 'red'
            : progressbarValue < 75
            ? 'yellow'
            : 'green'
        "
        class="pro"
        :progress="progressbarValue"
        id="risk-progressbar"
      >
      </f7-progressbar>
    </f7-block>
    <f7-card>
      <f7-card-header v-if="modifiable" class="bold uppercaseme"
        >Health behavior<f7-button v-if="!simulate" @click="askPatient()" raised
          >Ask the patient</f7-button
        >
      </f7-card-header>
      <f7-card-content>
        <f7-card class="separatecards">
          <f7-card-header
            class="soyblock"
            :class="{
              activo: lerisk.physical.active,
              localgreen: lerisk.physical.points == 2,
              localyellow: lerisk.physical.points == 1,
              localred: lerisk.physical.points == 0,
            }"
          >
            <f7-list>
              <f7-list-item class="neurita" title="Physical Activity">
                <f7-toggle
                  v-if="modifiable"
                  slot="media"
                  :checked="lerisk.physical.active"
                  @toggle:change="cambiando($event, 'physical')"
                ></f7-toggle>
              </f7-list-item>
            </f7-list>
          </f7-card-header>

          <f7-card-content v-if="lerisk.physical.active">
            <f7-list
              v-if="
                modifiable &&
                  !simulate &&
                  hayPhysPatData &&
                  hayPhysPatData.answer
              "
              class="patreported"
            >
              <f7-list-item
                title="Patient reported data avilable"
                :footer="
                  'Reported on ' +
                    $moment(hayPhysPatData.answer.wh).format('LLL')
                "
              >
                <f7-button
                  outline
                  popover-open=".physycailselfreported"
                  slot="after"
                  >View</f7-button
                >
              </f7-list-item>
            </f7-list>
            <f7-block-title
              class="display-flex justify-content-space-between sliderinfo"
              >Moderate<span v-if="lerisk.physical.active"
                >{{ lerisk.physical.moderateValue }} min/week</span
              ></f7-block-title
            >
            <f7-block-footer class="descrColor">
              <p>
                At this intensity, your breathing will be heavy but you can
                still speak in short sentences. Moderate activities may include
                brisk walking or a bike ride.
              </p>
            </f7-block-footer>
            <f7-list
              v-if="modifiable"
              class="margin-top margin-bottom padding-bottom"
              simple-list
            >
              <f7-list-item>
                <f7-list-item-cell class="flex-shrink-3">
                  <f7-range
                    class="rangegrande"
                    @range:change="moderate($event, false)"
                    @range:changed="moderate($event, true)"
                    :min="0"
                    :max="500"
                    :scale-steps="500"
                    :step="1"
                    :value="lerisk.physical.moderateValue"
                    :scale="true"
                    :format-scale-label="
                      (value) => scaleLabels(value, 'physicalModerate')
                    "
                    :color="sliderColor('physical')"
                  ></f7-range>
                  <span class="range-scale-step unitOfMeasurement"
                    >min/week
                  </span>
                </f7-list-item-cell>
              </f7-list-item>
            </f7-list>
            <f7-block-title
              class="display-flex justify-content-space-between sliderinfo"
              >Vigorous
              <span v-if="lerisk.physical.active"
                >{{ lerisk.physical.vigorousValue }} min/week</span
              ></f7-block-title
            >
            <f7-block-footer class="descrColor">
              <p>
                At this intensity, you are breathing very hard and only able to
                speak in short phrases. Vigorous activities may include running,
                swimming fast, or playing basketball.
              </p>
            </f7-block-footer>
            <f7-list
              v-if="modifiable"
              class="margin-top margin-bottom"
              simple-list
            >
              <f7-list-item style="margin:10px auto 10px auto">
                <f7-list-item-cell class="flex-shrink-3">
                  <f7-range
                    class="rangegrande"
                    @range:change="vigorous($event, false)"
                    @range:changed="vigorous($event, true)"
                    :min="0"
                    :max="420"
                    :scale-steps="420"
                    :step="1"
                    :value="lerisk.physical.vigorousValue"
                    :scale="true"
                    :format-scale-label="
                      (value) => scaleLabels(value, 'physicalVigorous')
                    "
                    :color="sliderColor('physical')"
                  ></f7-range>
                  <span class="range-scale-step unitOfMeasurement"
                    >min/week
                  </span>
                </f7-list-item-cell>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>

        <f7-card class="separatecards">
          <f7-card-header
            class="soyblock"
            :class="{
              activo: lerisk.diet.active,
              localgreen: lerisk.diet.points == 2,
              localyellow: lerisk.diet.points == 1,
              localred: lerisk.diet.points == 0,
            }"
          >
            <f7-list>
              <f7-list-item class="neurita" title="Food Intake">
                <f7-toggle
                  v-if="modifiable"
                  slot="media"
                  :checked="lerisk.diet.active"
                  @toggle:change="cambiando($event, 'diet')"
                ></f7-toggle>
              </f7-list-item>
            </f7-list>
          </f7-card-header>

          <f7-card-content v-if="lerisk.diet.active">
            <f7-list
              v-if="
                modifiable && !simulate && patrepdata && hayPhysPatData.answer
              "
              class="patreported"
            >
              <f7-list-item
                title="Patient reported data avilable"
                :footer="
                  'Reported on ' + $moment(patrepdata.answer.wh).format('LLL')
                "
              >
                <f7-button outline popover-open=".dietselfreported" slot="after"
                  >View</f7-button
                >
              </f7-list-item>
            </f7-list>
            <f7-list media-list class="switcholor maslineas">
              <f7-list-item divider
                ><strong
                  >In an average week, how often do you (the patient)</strong
                ></f7-list-item
              >
              <f7-list-item
                class="break-spaces"
                :class="
                  lerisk.diet.dietQ1 === 'Usually/Often'
                    ? 'localgreen'
                    : 'localred'
                "
                title="Eat 2 or more servings of fruit a day?"
                ><div slot="subtitle">
                  <strong>Serving</strong> = 1⁄2 cup or 1 med. fruit or 3⁄4 cup
                  100% fruit juice.
                </div>
                <f7-segmented strong tag="p">
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Usually/Often', 'dietQ1')"
                    :active="lerisk.diet.dietQ1 === 'Usually/Often'"
                    :icon-material="
                      lerisk.diet.dietQ1 === 'Usually/Often' ? 'check' : null
                    "
                    >Usually/Often</f7-button
                  >
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Sometimes', 'dietQ1')"
                    :active="lerisk.diet.dietQ1 === 'Sometimes'"
                    :icon-material="
                      lerisk.diet.dietQ1 === 'Sometimes' ? 'check' : null
                    "
                    >Sometimes</f7-button
                  >
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Rarely/Never', 'dietQ1')"
                    :active="lerisk.diet.dietQ1 === 'Rarely/Never'"
                    :icon-material="
                      lerisk.diet.dietQ1 === 'Rarely/Never' ? 'check' : null
                    "
                    >Rarely/Never</f7-button
                  >
                </f7-segmented>
              </f7-list-item>
              <f7-list-item
                class="break-spaces"
                :class="
                  lerisk.diet.dietQ2 === 'Usually/Often'
                    ? 'localgreen'
                    : 'localred'
                "
                title="Eat 2 or more servings of vegetables a day?"
                ><div slot="subtitle">
                  <strong>Serving</strong> = 1⁄2 cup vegetables, or 1 cup leafy
                  raw vegetables.
                </div>
                <f7-segmented strong tag="p">
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Usually/Often', 'dietQ2')"
                    :active="lerisk.diet.dietQ2 === 'Usually/Often'"
                    :icon-material="
                      lerisk.diet.dietQ2 === 'Usually/Often' ? 'check' : null
                    "
                    >Usually/Often</f7-button
                  >
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Sometimes', 'dietQ2')"
                    :active="lerisk.diet.dietQ2 === 'Sometimes'"
                    :icon-material="
                      lerisk.diet.dietQ2 === 'Sometimes' ? 'check' : null
                    "
                    >Sometimes</f7-button
                  >
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Rarely/Never', 'dietQ2')"
                    :active="lerisk.diet.dietQ2 === 'Rarely/Never'"
                    :icon-material="
                      lerisk.diet.dietQ2 === 'Rarely/Never' ? 'check' : null
                    "
                    >Rarely/Never</f7-button
                  >
                </f7-segmented>
              </f7-list-item>
              <f7-list-item
                class="break-spaces"
                :class="
                  lerisk.diet.dietQ3 === 'Usually/Often'
                    ? 'localgreen'
                    : 'localred'
                "
                title="Eat 2 or more servings of whole grain products or high fiber starches a day?"
                ><div slot="subtitle">
                  <strong>Serving</strong> = 1 slice of 100% whole grain bread;
                  1 cup whole grain cereal like Shredded Wheat, Wheaties, Grape
                  Nuts, high fiber cereals, oatmeal, 3-4 whole grain crackers,
                  1⁄2 cup brown rice or whole wheat pasta, boiled or baked
                  potatoes, yuca, yams or plantain.
                </div>
                <f7-segmented strong tag="p">
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Usually/Often', 'dietQ3')"
                    :active="lerisk.diet.dietQ3 === 'Usually/Often'"
                    :icon-material="
                      lerisk.diet.dietQ3 === 'Usually/Often' ? 'check' : null
                    "
                    >Usually/Often</f7-button
                  >
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Sometimes', 'dietQ3')"
                    :active="lerisk.diet.dietQ3 === 'Sometimes'"
                    :icon-material="
                      lerisk.diet.dietQ3 === 'Sometimes' ? 'check' : null
                    "
                    >Sometimes</f7-button
                  >
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Rarely/Never', 'dietQ3')"
                    :active="lerisk.diet.dietQ3 === 'Rarely/Never'"
                    :icon-material="
                      lerisk.diet.dietQ3 === 'Rarely/Never' ? 'check' : null
                    "
                    >Rarely/Never</f7-button
                  >
                </f7-segmented>
              </f7-list-item>
              <f7-list-item
                class="break-spaces"
                :class="
                  lerisk.diet.dietQ4 === 'Rarely/Never'
                    ? 'localgreen'
                    : 'localred'
                "
                title="Drink 16 ounces or more of non-diet soda, fruit drink/punch or Kool-Aid a day?"
                ><div slot="subtitle">
                  <strong>Note:</strong> 1 can of soda = 12 ounces
                </div>
                <f7-segmented strong tag="p">
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Usually/Often', 'dietQ4')"
                    :active="lerisk.diet.dietQ4 === 'Usually/Often'"
                    :icon-material="
                      lerisk.diet.dietQ4 === 'Usually/Often' ? 'check' : null
                    "
                    >Usually/Often</f7-button
                  >
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Sometimes', 'dietQ4')"
                    :active="lerisk.diet.dietQ4 === 'Sometimes'"
                    :icon-material="
                      lerisk.diet.dietQ4 === 'Sometimes' ? 'check' : null
                    "
                    >Sometimes</f7-button
                  >
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Rarely/Never', 'dietQ4')"
                    :active="lerisk.diet.dietQ4 === 'Rarely/Never'"
                    :icon-material="
                      lerisk.diet.dietQ4 === 'Rarely/Never' ? 'check' : null
                    "
                    >Rarely/Never</f7-button
                  >
                </f7-segmented>
              </f7-list-item>
              <f7-list-item
                :class="
                  lerisk.diet.dietQ5 === 'Rarely/Never'
                    ? 'localgreen'
                    : 'localred'
                "
                title="Eat sweets like cake, cookies, pastries, donuts, muffins, chocolate and candies in between meals more than 2 times per day?"
              >
                <f7-segmented strong tag="p">
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Usually/Often', 'dietQ5')"
                    :active="lerisk.diet.dietQ5 === 'Usually/Often'"
                    :icon-material="
                      lerisk.diet.dietQ5 === 'Usually/Often' ? 'check' : null
                    "
                    >Usually/Often</f7-button
                  >
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Sometimes', 'dietQ5')"
                    :active="lerisk.diet.dietQ5 === 'Sometimes'"
                    :icon-material="
                      lerisk.diet.dietQ5 === 'Sometimes' ? 'check' : null
                    "
                    >Sometimes</f7-button
                  >
                  <f7-button
                    :disabled="!modifiable"
                    @click="ChangeDietValue('Rarely/Never', 'dietQ5')"
                    :active="lerisk.diet.dietQ5 === 'Rarely/Never'"
                    :icon-material="
                      lerisk.diet.dietQ5 === 'Rarely/Never' ? 'check' : null
                    "
                    >Rarely/Never</f7-button
                  >
                </f7-segmented>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
        <f7-card class="separatecards">
      <f7-card-header
        class="soyblock"
        :class="{
          activo: lerisk.smoking.active,
          localgreen: lerisk.smoking.points == 2,
          localyellow: lerisk.smoking.points == 1,
          localred: lerisk.smoking.points == 0,
        }"
      >
        <f7-list>
          <f7-list-item class="neurita" title="Tobacco smoking status">
            <f7-toggle
              v-if="modifiable"
              slot="media"
              :checked="lerisk.smoking.active"
              @toggle:change="cambiando($event, 'smoking')"
            ></f7-toggle>
          </f7-list-item>
        </f7-list>
      </f7-card-header>

      <f7-card-content v-if="lerisk.smoking.active">
        <f7-list
          v-if="modifiable"
          class="patreported"
          no-hairlines-md
        >
          <f7-list-input
            :value="lerisk.smoking.date ? [lerisk.smoking.date] : []"
            @blur="editDate($event.target.value, 'smoking')"
            type="datepicker"
            :calendar-params="{
              locale: 'en-US',
              dateFormat: 'mm/dd/yyyy',
              closeOnSelect: true,
              disabled: { from: new Date() },
            }"
            readonly
          >
            <div slot="media">Collected on</div>
          </f7-list-input>
        </f7-list>
        <f7-block class="margin-bottom margin-top">
          <f7-block-footer class="descrColor text-align-right">
            <p>{{ smokingDefinitions[lerisk.smoking.value] }}</p>
          </f7-block-footer>
          <f7-segmented strong tag="p">
            <f7-button
              :disabled="!modifiable"
              @click="buttonSelected('never')"
              :active="lerisk.smoking.value == 'never'"
              :icon-material="lerisk.smoking.value == 'never' ? 'check' : null"
              >Never or Quit
            </f7-button>
            <f7-button
              :disabled="!modifiable"
              @click="buttonSelected('former')"
              :active="lerisk.smoking.value == 'former'"
              :icon-material="lerisk.smoking.value == 'former' ? 'check' : null"
              >Former</f7-button
            >
            <f7-button
              :disabled="!modifiable"
              @click="buttonSelected('current')"
              :active="lerisk.smoking.value == 'current'"
              :icon-material="
                lerisk.smoking.value == 'current' ? 'check' : null
              "
              >Current</f7-button
            >
          </f7-segmented>
        </f7-block>
        <f7-block-title v-if="!modifiable" class="text-align-right"
          >Collected on {{ lerisk.smoking.date }}
        </f7-block-title>
      </f7-card-content>
    </f7-card>
      </f7-card-content>
    </f7-card>
    <f7-card>
      <f7-card-header class="bold uppercaseme"
        >Clinical data</f7-card-header>
      <f7-card-content>
    <f7-card class="separatecards">
      <f7-card-header
        class="soyblock"
        :class="{
          activo: lerisk.bmi.active,
          localgreen: lerisk.bmi.points == 2,
          localyellow: lerisk.bmi.points == 1,
          localred: lerisk.bmi.points == 0,
        }"
      >
        <f7-list>
          <f7-list-item class="neurita" title="BMI">
            <f7-toggle
              v-if="modifiable"
              slot="media"
              :checked="lerisk.bmi.active"
              @toggle:change="cambiando($event, 'bmi')"
            ></f7-toggle>
            <span slot="after" v-if="lerisk.bmi.active"
              >{{ ageAtCollectionDate(lerisk.bmi.weightDate)<240 ? lerisk.bmi.bmi : lerisk.bmi.absoluteBmi }}
              <span class="unidaddexx">
                {{ ageAtCollectionDate(lerisk.bmi.weightDate)<240 ? "th percentile" : "kg/m2" }}</span
              ></span
            >
          </f7-list-item>
        </f7-list>
      </f7-card-header>

      <f7-card-content v-if="lerisk.bmi.active">
        <f7-list  v-if="rangeInit" class=" margin-bottom margin-top padding-bottom">
          <f7-list-item>
            <f7-list-item-cell class="flex-shrink-3 margin-bottom">
              <f7-range
              
                :draggableBar="false"
                class="rangegrande pointsBar"
                :min="ageAtCollectionDate(lerisk.bmi.weightDate) < 240 ? 10 : 0"
                :max="ageAtCollectionDate(lerisk.bmi.weightDate) < 240 ? 99 : 80"
                :scale-steps="ageAtCollectionDate(lerisk.bmi.weightDate) < 240 ? 89 : 80"
                :step="1"
                :value="
                  ageAtCollectionDate(lerisk.bmi.weightDate) < 240 ? lerisk.bmi.bmi : lerisk.bmi.absoluteBmi
                "
                :scale="true"
                :format-scale-label="(value) => scaleLabels(value, 'bmi')"
                :color="sliderColor('bmi')"
              ></f7-range>
              <span class="range-scale-step unitOfMeasurement"
                >{{ ageAtCollectionDate(lerisk.bmi.weightDate)<240 ? "Percentiles" : "kg/m2" }}
              </span>
            </f7-list-item-cell>
          </f7-list-item>
        </f7-list>
        <f7-list
          v-if="modifiable "
          class="patreported"
          no-hairlines-md
        >
          <f7-list-input
            :value="lerisk.bmi.weightDate ? [lerisk.bmi.weightDate] : []"
            @blur="editDate($event.target.value, 'bmi', 'weight')"
            type="datepicker"
            :calendar-params="{
              locale: 'en-US',
              dateFormat: 'mm/dd/yyyy',
              closeOnSelect: true,
              disabled: { from: new Date() },
            }"
            readonly
          >
            <div slot="media">Collected on</div>
          </f7-list-input>
        </f7-list>
        <f7-block-title
          class="display-flex justify-content-space-between sliderinfo"
          >Weight
          <span v-if="lerisk.bmi.active"
            >{{ lerisk.bmi.weightValue }} pounds</span
          ></f7-block-title
        >
        <f7-list v-if="modifiable" class="margin-bottom" simple-list>
          <f7-list-item>
            <f7-list-item-cell class="flex-shrink-3">
              <f7-range
                class="rangegrande"
                @range:changed="weight($event, true)"
                @range:change="weight($event, false)"
                :min="50"
                :max="399"
                :step="1"
                :value="lerisk.bmi.weightValue"
                :color="sliderColor('bmi')"
              ></f7-range>
            </f7-list-item-cell>
          </f7-list-item>
        </f7-list>

        <f7-block-title v-else class="text-align-right"
          >Collected on {{ lerisk.bmi.weightDate }}
        </f7-block-title>
        <f7-list
          v-if="modifiable"
          class="patreported"
          no-hairlines-md
        >
          <f7-list-input
            :value="lerisk.bmi.heightDate ? [lerisk.bmi.heightDate] : []"
            @blur="editDate($event.target.value, 'bmi', 'height')"
            type="datepicker"
            :calendar-params="{
              locale: 'en-US',
              dateFormat: 'mm/dd/yyyy',
              closeOnSelect: true,
              disabled: { from: new Date() },
            }"
            readonly
          >
            <div slot="media">Collected on</div>
          </f7-list-input>
        </f7-list>
        <f7-block-title
          class="display-flex justify-content-space-between sliderinfo"
          >Height
          <span v-if="lerisk.bmi.active"
            >{{ Math.floor(lerisk.bmi.heightValue / 12) }}'{{
              lerisk.bmi.heightValue % 12
            }}"</span
          ></f7-block-title
        >
        <f7-list v-if="modifiable" simple-list>
          <f7-list-item>
            <f7-list-item-cell class="flex-shrink-3">
              <f7-range
                class="rangegrande"
                @range:changed="height($event, true)"
                @range:change="height($event, false)"
                :min="42"
                :max="85"
                :step="1"
                :value="lerisk.bmi.heightValue"
                :color="sliderColor('bmi')"
              ></f7-range>
            </f7-list-item-cell>
          </f7-list-item>
        </f7-list>

        <f7-block-title v-else class="text-align-right"
          >Collected on {{ lerisk.bmi.heightDate }}
        </f7-block-title>
      </f7-card-content>
    </f7-card>
   <f7-card class="separatecards">
      <f7-card-header
        class="soyblock"
        :class="{
          activo: lerisk.bloodPressure.active,
          localgreen: lerisk.bloodPressure.points == 2,
          localyellow: lerisk.bloodPressure.points == 1,
          localred: lerisk.bloodPressure.points == 0,
        }"
      >
        <f7-list>
          <f7-list-item class="neurita" title="Blood pressure">
            <f7-toggle
              v-if="modifiable"
              class="toggleBp"
              slot="media"
              :checked="lerisk.bloodPressure.active"
              @toggle:change="cambiando($event, 'bloodPressure')"
            ></f7-toggle>
            <span slot="after" v-if="lerisk.bloodPressure.active"
              >{{ lerisk.bloodPressure.bloodSystolicValue }}/{{
                lerisk.bloodPressure.bloodDiastolicValue
              }}
              <span class="unidaddexx"> mm hg</span></span
            >
          </f7-list-item>
        </f7-list>
      </f7-card-header>

      <f7-card-content v-if="lerisk.bloodPressure.active">
        <f7-list
          v-if="modifiable "
          class="patreported"
          no-hairlines-md
        >
          <f7-list-input
            :value="
              lerisk.bloodPressure.date ? [lerisk.bloodPressure.date] : []
            "
            @blur="editDate($event.target.value, 'bloodPressure')"
            type="datepicker"
            :calendar-params="{
              locale: 'en-US',
              dateFormat: 'mm/dd/yyyy',
              closeOnSelect: true,
              disabled: { from: new Date() },
            }"
            readonly
          >
            <div slot="media">Collected on</div>
          </f7-list-input>
        </f7-list>
        <template v-if="modifiable">
          <f7-list class="margin-top margin-bottom" simple-list>
            <f7-list-item>
              <f7-list-item-cell class="flex-shrink-3 margin-top">
                <f7-range
                  v-if="bloodPressureSlider"
                  class="rangegrande"
                  @range:change="
                    bloodPressure($event, 'bloodSystolicValue', false)
                  "
                  @range:changed="
                    bloodPressure($event, 'bloodSystolicValue', true)
                  "
                  :min="50"
                  :max="200"
                  :scale-steps="150"
                  :step="1"
                  :value="lerisk.bloodPressure.bloodSystolicValue"
                  :scale="true"
                  :format-scale-label="
                    (value) => scaleLabels(value, 'bloodSystolicValue')
                  "
                  :color="sliderColor('bloodPressure')"
                ></f7-range>
                <span class="range-scale-step "> Systolic</span>
                <span class="range-scale-step unitOfMeasurement"> mm hg</span>
              </f7-list-item-cell>
            </f7-list-item>
          </f7-list>
          <f7-list class="margin-top margin-bottom" simple-list>
            <f7-list-item>
              <f7-list-item-cell class="flex-shrink-3 margin-top">
                <f7-range
                  v-if="bloodPressureSlider"
                  class="rangegrande"
                  @range:change="
                    bloodPressure($event, 'bloodDiastolicValue', false)
                  "
                  @range:changed="
                    bloodPressure($event, 'bloodDiastolicValue', true)
                  "
                  :min="50"
                  :max="200"
                  :scale-steps="150"
                  :step="1"
                  :value="lerisk.bloodPressure.bloodDiastolicValue"
                  :scale="true"
                  :format-scale-label="
                    (value) => scaleLabels(value, 'bloodDiastolicValue')
                  "
                  :color="sliderColor('bloodPressure')"
                ></f7-range>
                <span class="range-scale-step "> Diastolic</span>
                <span class="range-scale-step unitOfMeasurement"> mm hg</span>
              </f7-list-item-cell>
            </f7-list-item>
          </f7-list>
        </template>
        <f7-block-title v-else class="text-align-right"
          >Collected on {{ lerisk.bloodPressure.date }}
        </f7-block-title>
      </f7-card-content>
    </f7-card>
    <f7-card class="separatecards">
      <f7-card-header
        class="soyblock"
        :class="{
          activo: lerisk.fbg.active,
          localgreen: lerisk.fbg.points == 2,
          localyellow: lerisk.fbg.points == 1,
          localred: lerisk.fbg.points == 0,
        }"
      >
        <f7-list>
          <f7-list-item class="neurita" title="Fasting Blood Glucose">
            <f7-toggle
              v-if="modifiable"
              slot="media"
              :checked="lerisk.fbg.active"
              @toggle:change="cambiando($event, 'fbg')"
            ></f7-toggle>
            <span slot="after" v-if="lerisk.fbg.active"
              >{{ lerisk.fbg.value }}
              <span class="unidaddexx"> mg/dl</span></span
            >
          </f7-list-item>
        </f7-list>
      </f7-card-header>

      <f7-card-content v-if="lerisk.fbg.active">
        <f7-list
          v-if="modifiable"
          class="patreported"
          no-hairlines-md
        >
          <f7-list-input
            :value="lerisk.fbg.date ? [lerisk.fbg.date] : []"
            @blur="editDate($event.target.value, 'fbg')"
            type="datepicker"
            :calendar-params="{
              locale: 'en-US',
              dateFormat: 'mm/dd/yyyy',
              closeOnSelect: true,
              disabled: { from: new Date() },
            }"
            readonly
          >
            <div slot="media">Collected on</div>
          </f7-list-input>
        </f7-list>
        <f7-list v-if="modifiable" class="margin-top margin-bottom" simple-list>
          <f7-list-item>
            <f7-list-item-cell class="flex-shrink-3">
              <f7-range
                class="rangegrande"
                @range:change="fbg($event, false)"
                @range:changed="fbg($event, true)"
                :min="50"
                :max="200"
                :scale-steps="150"
                :step="1"
                :value="lerisk.fbg.value"
                :scale="true"
                :format-scale-label="(value) => scaleLabels(value, 'fbg')"
                :color="sliderColor('fbg')"
              ></f7-range>
              <span class="range-scale-step unitOfMeasurement">mg/dl</span>
            </f7-list-item-cell>
          </f7-list-item>
        </f7-list>
        <f7-block-title v-else class="text-align-right"
          >Collected on {{ lerisk.fbg.date }}
        </f7-block-title>
      </f7-card-content>
    </f7-card>

    <f7-card class="separatecards">
      <f7-card-header
        class="soyblock"
        :class="{
          activo: lerisk.cholesterol.active,
          localgreen: lerisk.cholesterol.points == 2,
          localyellow: lerisk.cholesterol.points == 1,
          localred: lerisk.cholesterol.points == 0,
        }"
      >
        <f7-list>
          <f7-list-item class="neurita" title="Total Cholesterol">
            <f7-toggle
              v-if="modifiable"
              slot="media"
              :checked="lerisk.cholesterol.active"
              @toggle:change="cambiando($event, 'cholesterol')"
            ></f7-toggle>
            <span slot="after" v-if="lerisk.cholesterol.active"
              >{{ lerisk.cholesterol.value }}
              <span class="unidaddexx"> mg/dl</span></span
            >
          </f7-list-item>
        </f7-list>
      </f7-card-header>
      <f7-card-content v-if="lerisk.cholesterol.active">
        <f7-list
          v-if="modifiable"
          class="patreported"
          no-hairlines-md
        >
          <f7-list-input
            :value="lerisk.cholesterol.date ? [lerisk.cholesterol.date] : []"
            @blur="editDate($event.target.value, 'cholesterol')"
            type="datepicker"
            :calendar-params="{
              locale: 'en-US',
              dateFormat: 'mm/dd/yyyy',
              closeOnSelect: true,
              disabled: { from: new Date() },
            }"
            readonly
          >
            <div slot="media">Collected on</div>
          </f7-list-input>
        </f7-list>
        <f7-list v-if="rangeInit && modifiable" class="margin-top margin-bottom" simple-list>
          <f7-list-item >
            <f7-list-item-cell class="flex-shrink-3">
              <f7-range
                class="rangegrande"
                @range:change="cholesterol($event, false)"
                @range:changed="cholesterol($event, true)"
                :min="50"
                :max="300"
                :scale-steps="250"
                :step="1"
                :value="lerisk.cholesterol.value"
                :scale="true"
                :format-scale-label="
                  (value) => scaleLabels(value, 'cholesterol')
                "
                :color="sliderColor('cholesterol')"
              ></f7-range>
              <span class="range-scale-step unitOfMeasurement">mg/dl</span>
            </f7-list-item-cell>
          </f7-list-item>
        </f7-list>
        <f7-block-title v-else class="text-align-right"
          >Collected on {{ lerisk.cholesterol.date }}
        </f7-block-title>
      </f7-card-content>
    </f7-card>
      </f7-card-content>
    </f7-card>
    <f7-popover class="popover-menu physycailselfreported">
      <f7-list>
        <f7-list-item
          title="Days in last 7 days"
          footer="Days of vigorous activities"
          :after="patrepphys.vigdays"
        ></f7-list-item>
        <f7-list-item
          title="Time per day"
          footer="Hours of vigorous activity"
          :after="patrepphys.vighours"
        ></f7-list-item>
        <f7-list-item
          title="Days in last 7 days"
          footer="Days of moderate activities"
          :after="patrepphys.moddays"
        ></f7-list-item>
        <f7-list-item
          title="Time per day"
          footer="Hours of moderate activity"
          :after="patrepphys.modhours"
        ></f7-list-item>
      </f7-list>
      <f7-block class="margin-vertical-half"
        ><f7-button @click="loadPhysPatRep()" popover-close fill raised
          >Load to risk calculation</f7-button
        ></f7-block
      >
    </f7-popover>

    <f7-popover class="popover-menu dietselfreported">
      <f7-list v-if="patrepdata && patrepdata.answer">
        <f7-list-item
          footer="More than 2 "
          title="fruits/day"
          :after="
            patrepdata.answer.dietQ1 === true
              ? 'Usually/Often'
              : patrepdata.answer.dietQ1 === false
              ? 'Rarely/Never'
              : patrepdata.answer.dietQ1
          "
        ></f7-list-item>
        <f7-list-item
          footer="More than 2 "
          title="vegetables/day"
          :after="
            patrepdata.answer.dietQ2 === true
              ? 'Usually/Often'
              : patrepdata.answer.dietQ2 === false
              ? 'Rarely/Never'
              : patrepdata.answer.dietQ2
          "
        ></f7-list-item>
        <f7-list-item
          footer="More than 2 servings "
          title="whole grains/day"
          :after="
            patrepdata.answer.dietQ3 === true
              ? 'Usually/Often'
              : patrepdata.answer.dietQ3 === false
              ? 'Rarely/Never'
              : patrepdata.answer.dietQ3
          "
        ></f7-list-item>
        <f7-list-item
          footer="more than 16 ounces "
          title="Sweetened beverages/day"
          :after="
            patrepdata.answer.dietQ4 === true
              ? 'Rarely/Never'
              : patrepdata.answer.dietQ4 === false
              ? 'Usually/Often'
              : patrepdata.answer.dietQ4
          "
        ></f7-list-item>
        <f7-list-item
          footer="in between meals"
          title="sweet snacks"
          :after="
            patrepdata.answer.dietQ5 === true
              ? 'Usually/Often'
              : patrepdata.answer.dietQ5 === false
              ? 'Rarely/Never'
              : patrepdata.answer.dietQ5
          "
        ></f7-list-item>
      </f7-list>
      <f7-block class="margin-vertical-half"
        ><f7-button @click="loadDietPatRep()" popover-close fill raised
          >Load to risk calculation</f7-button
        ></f7-block
      >
    </f7-popover>
  </div>
</template>
<script>
import calculateGrownChart from "../js/growncalculator.js";
import {
  calculateBloodPressure,
  bloodPressureCutPoints,
} from "../js/bloodPressurecalculator.js";
import { mapState, mapGetters } from "vuex";
export default {
  props: ["ageInMonth", "patrepdata"],
  data() {
    return {
      bloodPressureSlider: true,
      physicalinterestedWidth: "",
      dietinterestedWidth: "",
      progressOpened: false,
      fromHeight: false,
      leriskSimulated: {},
      progressbarSimulated: 0,
      simulate: false,
      rangeInit:true
    };
  },
  mounted() {
    this.progressOpened = Object.values(this.lerisk).some(
      (item) => item.active
    );
    if (this.progressOpened) this.megacalculate();
    if (this.ageAtCollectionDate(this.lerisk.bloodPressure.date) < 240) this.getBloodPressureCutPoints();
    if(this.modifiable)this.simulateCalculation(true)
  },
  computed: {
    ...mapState([
      "focusedPrescription",
      "user",
      "actualPatient",
      "focusedAccount",
    ]),
    ...mapGetters(["onClinic"]),
    isAdolescent(){
       return this.ageInMonth<240
    },
    lerisk() {
      let res = this.simulate
        ? Object.assign({}, this.leriskSimulated)
        : Object.assign({}, this.focusedPrescription.risk);
      return res;
    },
    progressbarValue() {
      return this.simulate
        ? this.progressbarSimulated
        : this.focusedPrescription.globalProgressBar;
    },
    modifiable() {
      // returns true if the the first prescription has not been sent yet and if the user is the clinician that created the prescription. used to enable the sliders and other parameters in the prescription
      return (
        !this.focusedPrescription.sent &&
        this.focusedAccount.privx.clinician &&
        this.focusedPrescription.clinicid == this.focusedAccount.clinicid
      );
    },
    hayPhysPatData() {
      // returns  the answers collected from the health survey (object)
      let datsm = null;
      if (this.modifiable && this.patrepdata) {
        datsm = this.patrepdata;
      }
      return datsm;
    },
    patrepphys() {
      // returns object hours and minutos that the patient dedicates to moderate and vigorous physical activity. if one of them is 0, that item is filled with 'no'
      let patrepphys = {
        modhours: "0",
        moddays: "0",
        vighours: "0",
        vigdays: "0",
      };
      if (this.hayPhysPatData && this.hayPhysPatData.answer) {
        let answer = this.hayPhysPatData.answer || {};
        if (answer.noVigorous) {
          patrepphys.vigdays = "no";
          patrepphys.vighours = "no";
        } else {
          patrepphys.vigdays = answer.times.vigorous.days;
          patrepphys.vighours =
            answer.times.vigorous.hours +
            ":" +
            (answer.times.vigorous.mins < 10
              ? "0" + answer.times.vigorous.mins
              : answer.times.vigorous.mins);
        }
        if (answer.noModerate) {
          patrepphys.modhours = "no";
          patrepphys.moddays = "no";
        } else {
          patrepphys.moddays = answer.times.moderate.days;
          patrepphys.modhours =
            answer.times.moderate.hours +
            ":" +
            (answer.times.moderate.mins < 10
              ? "0" + answer.times.moderate.mins
              : answer.times.moderate.mins);
        }
      }
      return patrepphys;
    },
    smokingDefinitions() {
      return {
        never: "Never, or quit more than 12 months ago",
        former: "Quit smoking less than 12 months ago",
        current: "Current smoker",
      };
    },
    riskCutPoints() {
      return {
        bloodSystolicValue: {
          intermediate: 120,
          poor: 140,
        },
        bloodDiastolicValue: {
          intermediate: 80,
          poor: 90,
        },
        bmi: {
          intermediate: this.ageAtCollectionDate(this.lerisk.bmi.weightDate)<240 ? 85 : 25,
          poor: this.ageAtCollectionDate(this.lerisk.bmi.weightDate)<240 ? 96 : 30,
        },
        fbg: {
          intermediate: 100,
          poor: 126,
        },
        cholesterol: {
          intermediate: this.ageAtCollectionDate(this.lerisk.cholesterol.date) < 240 ? 170 : 200,
          poor:this.ageAtCollectionDate(this.lerisk.cholesterol.date) < 240 ? 200 : 240,
        },
        physicalModerate: {
          ideal: this.ageInMonth < 240 ? 420 : 150,
          intermediate: 1,
        },
        physicalVigorous: {
          ideal: this.ageInMonth < 240 ? 210 : 75,
          intermediate: 1,
        },
      };
    },
  },
  methods: {
    askPatient() {
      // opens the health survey
      this.$f7.dialog.preloader("Opening health behavior survey...");
      let newWindow=window.open()
      let sendinforequest = this.$firebase
        .functions()
        .httpsCallable("sendinforequest");
      sendinforequest({
        patientid: this.actualPatient.id,
        accountid: this.focusedAccount.id,
        clinicid: this.onClinic,
        fromRisk: true,
      })
        .then((ladata) => {
          this.$f7.dialog.close();
          if (ladata.data.fail) {
            throw new Error(ladata.data.datum);
          } else {
          newWindow.location=ladata.data.datum
            this.$f7.dialog.close();
          }
        })
        .catch((error) => {
          this.$f7.dialog.close();
          console.log(error);
          this.$f7.dialog.alert(error.message, error.code || error);
        });
    },
    editDate(date, where, bmiParam) {
      this.rangeInit=false
      // sets the date when the item info was collected
      let datename = "date";
      if (bmiParam) {
        datename = bmiParam + "Date";
      }
      if (date != this.lerisk[where][datename]) {
        let exists = this.lerisk[where][datename];
        this.$firebase
          .database()
          .ref(
            "prescriptions_by_clinic/" +
              this.onClinic +
              "/" +
              this.actualPatient.id +
              "/" +
              this.focusedPrescription.id +
              "/risk/" +
              where +
              "/" +
              datename
          )
          .set(date)
          .then(() => {
            this.lerisk[where][datename] = date;
            if (exists) {
              this.$f7.toast.show({
                text: "Date updated",
                position: "center",
                closeTimeout: 1000,
                destroyOnClose: true,
              });
            }
            this.rangeInit=true
          })
          .catch((error) => {
            console.log(error);
            this.$f7.dialog.alert(error.message, error.code);
          });
      }
    },
    loadDietPatRep() {
      // sets the diet togles according to the health survey answers
      let answers = this.hayPhysPatData.answer || {};

      this.ChangeDietValue(
        answers.dietQ1,
        "dietQ1"
      );
      this.ChangeDietValue(
        answers.dietQ2,
        "dietQ2"
      );
      this.ChangeDietValue(
        answers.dietQ3,
        "dietQ3"
      );
      this.ChangeDietValue(
        answers.dietQ4,
        "dietQ4"
      );
      this.ChangeDietValue(
        answers.dietQ5,
        "dietQ5"
      );
      this.setWills(
        answers.willingDiet ? answers.willingDiet : null,
        "willingDiet",
        "diet"
      );
      this.setWills(
        answers.willingPhysical ? answers.willingPhysical : null,
        "willingPhysical",
        "physical"
      );
    },
    loadPhysPatRep() {
      // sets the physical sliders according to the health survey answers
      let answers = this.hayPhysPatData.answer || {};
      let minutps =
        parseInt(answers.times.vigorous.hours) * 60 +
        parseInt(answers.times.vigorous.mins);
      let dias = parseInt(answers.times.vigorous.days);
      let vigorousminweek = minutps * dias;
      this.vigorous(vigorousminweek, true);
      let minutpsmod =
        parseInt(answers.times.moderate.hours) * 60 +
        parseInt(answers.times.moderate.mins);
      let diasmod = parseInt(answers.times.moderate.days);
      let moderatedminweek = minutpsmod * diasmod;
      this.moderate(moderatedminweek, true);
      this.setWills(
        answers.willingPhysical ? answers.willingPhysical : null,
        "willingPhysical",
        "physical"
      );
      this.setWills(
        answers.willingDiet ? answers.willingDiet : null,
        "willingDiet",
        "diet"
      );
    },
    updateFireBaseData(target, tostore) {
      // updates in database the new values for the risk items
      this.$firebase
        .database()
        .ref(
          "prescriptions_by_clinic/" +
            this.onClinic +
            "/" +
            this.focusedPrescription.patientid +
            "/" +
            this.focusedPrescription.id +
            "/risk/" +
            target
        )
        .set(tostore)
        .then(() => {
          if (target == "bloodPressure" && this.fromHeight) {
            this.fromHeight = false;
          } else {
            this.$f7.toast.show({
              text: target + " updated",
              position: "center",
              closeTimeout: 2000,
              destroyOnClose: true,
            });
          }
        })
        .catch((error) => {
          this.$f7.dialog.alert(error.message, error.code);
          console.log(error);
        });
    },
    cambiando(neovalue, cual, clon = null) {
      // sets the points for the risk item
      this.progressOpened = true;
      let clone = null;
      if (cual == "bmi") {
        clone = clon || Object.assign({}, this.lerisk.bmi);
        clone.active = neovalue;
        if (neovalue) {
          let heightMeters = 0.0254 * this.lerisk.bmi.heightValue;
          let weightKilos = (this.lerisk.bmi.weightValue / 2.2).toFixed(2);
          let bmi = (weightKilos / (heightMeters * heightMeters)).toFixed(1);
          bmi = parseFloat(bmi);
          clone.absoluteBmi = bmi;

          if (this.ageAtCollectionDate(clone.weightDate) < 240) {
            let percentil = calculateGrownChart(
              bmi,
              this.ageInMonth,
              this.actualPatient.sex
            );
            clone.bmi = Math.floor(percentil);
            clone.points = this.rulers(clone.bmi, 85, 96);
          } else {
            clone.points = this.rulers(clone.absoluteBmi, 25, 30);
          }
        }
      } else if (cual == "fbg") {
        clone = clon || Object.assign({}, this.lerisk.fbg);
        clone.active = neovalue;
        if (neovalue) {
          clone.points = this.rulers(clone.value, 100, 126);
        }
      } else if (cual == "cholesterol") {
        clone = clon || Object.assign({}, this.lerisk.cholesterol);
        clone.active = neovalue;
        if (neovalue) {
          if (this.ageAtCollectionDate(clone.date) < 240) {
            clone.points = this.rulers(clone.value, 170, 200);
          } else {
            clone.points = this.rulers(clone.value, 200, 240);
          }
        }
      } else if (cual == "bloodPressure") {
        clone = clon || Object.assign({}, this.lerisk.bloodPressure);
        clone.active = neovalue;
        if (neovalue) {
          if (this.ageAtCollectionDate(clone.date) < 240) {
            if (!this.lerisk.bmi.active) {
              let esteToggle = this.$f7.toggle.get(".toggleBp");
              return this.$f7.dialog.alert(
                "Height info is needed to calculate BP percentiles in patients under 19 years old",
                () => {
                  return esteToggle.toggle();
                }
              );
            }
            let results = calculateBloodPressure(
              this.actualPatient.sex,
              this.ageInMonth,
              this.lerisk.bmi.heightValue,
              clone.bloodSystolicValue,
              clone.bloodDiastolicValue
            );
            let systolic = results.systolic;
            let diastolic = results.diastolic;

            if (
              (systolic >= 90 || diastolic >= 90)
               && (systolic < 95 && diastolic < 95) 
            ) {
              clone.points = 1;
            } else if (systolic < 90 && diastolic < 90) {
              clone.points = 2;
            } else {
              clone.points = 0;
            }
          } else {
            if (
              clone.bloodDiastolicValue < 80 &&
              clone.bloodSystolicValue < 120
            ) {
              clone.points = 2;
            } else if (
              clone.bloodDiastolicValue >= 90 ||
              clone.bloodSystolicValue >= 140
            ) {
              clone.points = 0;
            } else {
              clone.points = 1;
            }
          }
        }
      } else if (cual == "smoking") {
        clone = clon || Object.assign({}, this.lerisk.smoking);
        clone.active = neovalue;
        if (neovalue) {
          clone.points =
            clone.value == "never" ? 2 : clone.value == "former" ? 1 : 0;
        }
      } else if (cual == "physical") {
        clone = clon || Object.assign({}, this.lerisk.physical);
        clone.active = neovalue;
        if (neovalue) {
          if (this.ageInMonth < 240) {
            if (clone.moderateValue == 0 && clone.vigorousValue == 0) {
              clone.points = 0;
            } else if (
              (clone.moderateValue + clone.vigorousValue * 2) / 7 <
              60
            ) {
              clone.points = 1;
            } else {
              clone.points = 2;
            }
          } else {
            if (clone.moderateValue == 0 && clone.vigorousValue == 0) {
              clone.points = 0;
            } else if (clone.moderateValue + clone.vigorousValue * 2 < 150) {
              clone.points = 1;
            } else {
              clone.points = 2;
            }
          }
        }
      } else if (cual == "diet") {
        clone = clon || Object.assign({}, this.lerisk.diet);
        clone.active = neovalue;
        if (neovalue) {
          let pts = 0;
          let options = ["dietQ1", "dietQ2", "dietQ3", "dietQ4", , "dietQ5"];
          options.forEach((one) => {
            if (one=="dietQ4" ||one=="dietQ5" &&( clone[one] === true || clone[one] === "Rarely/Never")) {
              pts++;
            }
            else if((one=="dietQ1" ||one=="dietQ2"||one=="dietQ3" )&&(clone[one] === true || clone[one] === "Usually/Often")){
              pts++;
            }
          });
          if (pts < 2) {
            clone.points = 0;
          } else if (pts > 3) {
            clone.points = 2;
          } else {
            clone.points = 1;
          }
        }
      }
      //save in store
      if (this.simulate) {
        this.leriskSimulated[cual] = clone;
      } else {
        this.$store.commit("setRisk", { riskid: cual, value: clone });
        this.updateFireBaseData(cual, clone);
      }
      this.megacalculate();
    },
    rulers(value, cutBottom, cutTop) {
      // returns the point (number) based on the cut points for the item
      let points = 0;
      if (value < cutBottom) {
        points = 2;
      } else if (value >= cutBottom && value < cutTop) {
        points = 1;
      }
      return points;
    },
   async megacalculate() {
      // recalculates the risk score and sets the progress bar
      let local_points = 0;
      let local_totalItems = 0;
      Object.keys(this.lerisk).forEach((unriskid) => {
        if (this.lerisk[unriskid].active) {
          local_points += Number(this.lerisk[unriskid].points);
          local_totalItems++;
        }
      });
      let value = Math.round((local_points / (local_totalItems * 2)) * 100);
      if (isNaN(value)) {
        value = 0;
      }
      if (local_totalItems == 0) {
        this.progressOpened = false;
      }
      this.$f7.progressbar.set("#risk-progressbar", value);
      if(this.simulate) return this.progressbarSimulated = value
      try{
     await this.$firebase.database().ref("prescriptions_by_clinic/" +this.onClinic +"/" +
            this.focusedPrescription.patientid +'/'+this.focusedPrescription.id+"/globalProgressBar").set(value)
            this.$store.commit("updateObjectPropValue", {
            what: "focusedPrescription",
            prop: "globalProgressBar",
            neovalue: value,
          });
          }catch(error){
            this.$f7.dialog.alert(error.message)
            console.log(error)
          }
    },
    height(value, calculate) {
      // if calculate is true calls the functions to calculate the total score, else just change this item value but doesn't calculate (is used in case the slider is moving but hasn't stoped yet)
      // payload: value:this item new value, calculate:boolean
      let BMIclone = Object.assign({}, this.lerisk.bmi);
      BMIclone.heightValue = value;
      if (calculate) {
        this.fromHeight = true;
        this.cambiando(true, "bmi", BMIclone);
        //ver si disparar bloodpressuretb
        if (this.lerisk.bloodPressure.active) {
          if (this.ageAtCollectionDate(this.lerisk.bloodPressure.date) < 240) {
            this.bloodPressureSlider = false;
            this.getBloodPressureCutPoints();
            setTimeout(() => {
              this.bloodPressureSlider = true;
            }, 30);
          }
          this.cambiando(true, "bloodPressure");
        }
      } else {
        this.simulate
          ? (this.leriskSimulated.bmi = Object.assign(BMIclone))
          : this.$store.commit("setRisk", { riskid: "bmi", value: BMIclone });
      }
    },
    weight(value, calculate) {
      // if calculate is true calls the functions to calculate the total score, else just change this item value but doesn't calculate (is used in case the slider is moving but hasn't stoped yet)
      // payload: value:this item new value, calculate:boolean
      let BMIclone = Object.assign({}, this.lerisk.bmi);
      BMIclone.weightValue = value;
      if (calculate) {
        this.cambiando(true, "bmi", BMIclone);
      } else {
        this.simulate
          ? (this.leriskSimulated.bmi = Object.assign(BMIclone))
          : this.$store.commit("setRisk", { riskid: "bmi", value: BMIclone });
      }
    },
    fbg(value, calculate) {
      // if calculate is true calls the functions to calculate the total score, else just change this item value but doesn't calculate (is used in case the slider is moving but hasn't stoped yet)
      // payload: value:this item new value, calculate:boolean
      let fbgclone = Object.assign(
        {},
        this.simulate ? this.leriskSimulated.fbg : this.lerisk.fbg
      );
      fbgclone.value = value;
      if (calculate) {
        this.cambiando(true, "fbg", fbgclone);
      } else {
        this.simulate
          ? (this.leriskSimulated.fbg = Object.assign(fbgclone))
          : this.$store.commit("setRisk", { riskid: "fbg", value: fbgclone });
      }
    },
    cholesterol(value, calculate) {
      // if calculate is true calls the functions to calculate the total score, else just change this item value but doesn't calculate (is used in case the slider is moving but hasn't stoped yet)
      // payload: value:this item new value, calculate:boolean
      let fixed = value.toFixed(0);
      let cholesterolclone = Object.assign(
        {},
        this.simulate
          ? this.leriskSimulated.cholesterol
          : this.lerisk.cholesterol
      );
      cholesterolclone.value = Number(fixed);
      if (calculate) {
        this.cambiando(true, "cholesterol", cholesterolclone);
      } else {
        this.simulate
          ? (this.leriskSimulated.cholesterol = Object.assign(cholesterolclone))
          : this.$store.commit("setRisk", {
              riskid: "cholesterol",
              value: cholesterolclone,
            });
      }
    },
    bloodPressure(value, wich, calculate) {
      // if calculate is true calls the functions to calculate the total score, else just change this item value but doesn't calculate (is used in case the slider is moving but hasn't stoped yet)
      // payload: value:this item new value, calculate:boolean
      let bloodPressureclone = Object.assign(
        {},
        this.simulate
          ? this.leriskSimulated.bloodPressure
          : this.lerisk.bloodPressure
      );
      bloodPressureclone[wich] = value;
      if (calculate) {
        this.cambiando(true, "bloodPressure", bloodPressureclone);
      } else {
        this.simulate
          ? (this.leriskSimulated.bloodPressure = Object.assign(
              bloodPressureclone
            ))
          : this.$store.commit("setRisk", {
              riskid: "bloodPressure",
              value: bloodPressureclone,
            });
      }
    },
    buttonSelected(selected) {
      // calls the functions to recalculate the score with this new value
      // payload: selected: can be ('never','former','current')
      let smokingclone = Object.assign(
        {},
        this.simulate ? this.leriskSimulated.smoking : this.lerisk.smoking
      );
      smokingclone.value = selected;
      this.cambiando(true, "smoking", smokingclone);
    },
    moderate(value, calculate) {
      // if calculate is true calls the functions to calculate the total score, else just change this item value but doesn't calculate (is used in case the slider is moving but hasn't stoped yet)
      // payload: value:this item new value, calculate:boolean
      let physicalclone = Object.assign(
        {},
        this.simulate ? this.leriskSimulated.physical : this.lerisk.physical
      );
      physicalclone.moderateValue = value;
      if (calculate) {
        this.cambiando(true, "physical", physicalclone);
      } else {
        this.simulate
          ? (this.leriskSimulated.physical = Object.assign(physicalclone))
          : this.$store.commit("setRisk", {
              riskid: "physical",
              value: physicalclone,
            });
      }
    },
    vigorous(value, calculate) {
      // if calculate is true calls the functions to calculate the total score, else just change this item value but doesn't calculate (is used in case the slider is moving but hasn't stoped yet)
      // payload: value:this item new value, calculate:boolean
      let physicalclone = Object.assign(
        {},
        this.simulate ? this.leriskSimulated.physical : this.lerisk.physical
      );
      physicalclone.vigorousValue = value;
      if (calculate) {
        this.cambiando(true, "physical", physicalclone);
      } else {
        this.simulate
          ? (this.leriskSimulated.physical = Object.assign(physicalclone))
          : this.$store.commit("setRisk", {
              riskid: "physical",
              value: physicalclone,
            });
      }
    },

    setWills(value, q, where) {
      let clone = Object.assign({}, this.lerisk[where]);
      clone[q]=value
      this.cambiando(true, where, clone);
    },
    ChangeDietValue(value, q) {
      // calls the functions to recalculate the score with this new diet value
      // payload: value:this item new value, q: the diet question that called this function
      let dietclone = Object.assign({}, this.lerisk.diet);
      dietclone[q] = value;
      this.cambiando(true, "diet", dietclone);
    },
    simulateCalculation(checked) {
        this.leriskSimulated = Object.assign({}, this.focusedPrescription.risk);
      
     if(checked) this.simulate = true
     else  this.simulate = false
      this.megacalculate();
    },
    scaleLabels(value, cual) {
      let poor = this.riskCutPoints[cual].poor;
      let intermediate = this.riskCutPoints[cual].intermediate;
      let ideal = this.riskCutPoints[cual].ideal;
      if ((cual == "bloodDiastolicValue" || cual == "bloodSystolicValue") && value == poor) {
          return `<span class="  aboveScale red">Poor<br/><span class="bold bigger" >${value}</span><br/>|</span>`;
         } else {
        if (value == poor)
          return `<span class=" cutPoints red">|<br/><span class="bold bigger">${value}</span><br/>Poor</span>`;
        if (value == intermediate)
          return `<span class=" cutPoints yellow">|<br/><span class="bold bigger">${value}</span><br/>Interm</span>`;
        if (value == ideal)
          return `<span class=" cutPoints green">|<br/><span class="bold bigger">${value}</span><br/>Ideal</span>`;
      }
      return "";
    },
    sliderColor(cual) {
      return this.lerisk[cual].points == 2
        ? "green"
        : this.lerisk[cual].points == 1
        ? "yellow"
        : "red";
    },
    getBloodPressureCutPoints() {
      let cutPoints = bloodPressureCutPoints(
        this.actualPatient.sex,
        this.ageInMonth,
        this.lerisk.bmi.heightValue
      );
      this.riskCutPoints.bloodSystolicValue.poor =
        cutPoints.systolic_cut_points.poor;
      this.riskCutPoints.bloodSystolicValue.intermediate =
        cutPoints.systolic_cut_points.intermediate;

      this.riskCutPoints.bloodDiastolicValue.poor =
        cutPoints.diastolic_cut_points.poor;
      this.riskCutPoints.bloodDiastolicValue.intermediate =
        cutPoints.diastolic_cut_points.intermediate;
    },
          ageAtCollectionDate(collectionDate){ 
            if(!collectionDate) return this.ageInMonth
        return this.$moment(collectionDate,"MM/DD/YYYY").diff(this.$moment(this.actualPatient.birthday, "MM-DD-YYYY"),'months');
      },
  }
};
</script>
<style>
.createdOn {
  margin-top: 2px;
  background-color: transparent !important;
}
.containerCreatedon {
  text-align: right;
  background-color: white !important;
  padding: 0px;
  margin: 4px;
}
.range-knob-label {
  font-size: 18px;
  font-weight: bold;
  width: 46px !important;
  height: 28px !important;
  margin-left: -23px !important;
}
.pro {
  --f7-progressbar-height: 35px;
  border-radius: 10px;
}
.block-title {
  margin-top: 0;
}
.numeroin {
  left: 45%;
  position: absolute;
  z-index: 80;
}
.greyBackground {
  background-color: #efefef;
}
.simulate {
  align-self: center;
  text-align: initial;
}
#soysticky {
  position: sticky;
  top: 0;
  z-index: 999998;
  background: #fff;
  margin-top: 0px !important;
  padding-top: 5px;
  padding-bottom: 17px;
  border-bottom: 1px dotted gray;
}
.soyblock {
  display: block !important;
}
.soyblock.activo {
  background: #e8f3ff;
  padding-right: 0px !important;
}
.neurita .item-title {
  font-weight: bold;
  font-size: 20px;
}
.neurita .item-after {
  font-size: 22px;
  color: #202020;
}
.rangegrande .range-bar {
  height: 8px;
}
.rangegrande .range-knob-wrap {
  height: 28px !important;
  width: 28px !important;
  margin-top: -14px !important;
  margin-left: -14px !important;
}
.margenabajo {
  margin-bottom: 12px !important;
}
.sliderinfo {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}
.sofgratsx {
  background: #efefef;
}
.separatecards {
  margin-bottom: 30px !important;
}
.unidaddexx {
  font-size: 15px;
}
.list.maslineas .item-title {
  white-space: unset !important;
}
.activo.localgreen {
  border-right: 20px solid #4caf50;
}
.activo.localyellow {
  border-right: 20px solid #ffc107;
}
.activo.localred {
  border-right: 20px solid #f44336;
}
.list.switcholor .localgreen .item-content {
  border-right: 8px solid #4caf50;
}
.list.switcholor .localred .item-content {
  border-right: 8px solid #f44336;
}
.maslineas li {
  margin-top: 4px;
}
.patreported {
  margin-bottom: 13px !important;
  background: #ddf4ff;
}
.physycailselfreported .item-after {
  font-weight: bold;
}
.descrColor {
  color: #505050;
}
.break-spaces .item-subtitle {
  white-space: break-spaces !important;
}
.optionFont {
  margin-left: 5px;
  font-size: larger;
  font-weight: 500 !important;
}
.range-scale-step::before {
  display: none;
}
.aboveScale {
  text-align: center;
  top: -46px;
  position: relative;
}
.pointsBar .range-knob-wrap {
  display: none;
}
.unitOfMeasurement {
  position: relative;
  float: right;
  top: -28px;
}
.red {
  color: red;
}
.yellow {
  color: #ffc107;
}
.green {
  color: green;
}
.cutPoints {
  text-align: center;
  position: relative;
  top: -2px;
}
.bigger {
  font-size: 120%;
}
</style>