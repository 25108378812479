<template>
  <f7-page class="bakcer" name="patientReported" @page:beforein="loadPrevious()" >
    <f7-navbar :title="actualPatient.name+' '+actualPatient.lastName" :subtitle="actualPatient.email" back-link="Back">

       <f7-nav-right>
      
       </f7-nav-right>
    </f7-navbar>
<f7-block>
  <f7-row>
    <f7-col>
      <f7-button popover-open=".openSurveysPopover" outline raised >Answer survey now
        <f7-icon class="chevron" material="expand_more" />
        </f7-button>
        </f7-col>
    <f7-col><f7-button popover-open=".sendSurveysPopover" fill raised >Send survey by e-mail
      <f7-icon class="chevron" material="expand_more" />
      </f7-button>
      </f7-col>
  </f7-row>
  <f7-popover class="openSurveysPopover">
    <f7-list>
      <f7-list-item @click="answerSurveyNow('health')" link="#" popover-close title="Show only Health survey"></f7-list-item>
      <f7-list-item @click="answerSurveyNow('research')" link="#" popover-close title="Show only Reseach survey"></f7-list-item>
      <f7-list-item @click="answerSurveyNow()" link="#" popover-close title="Show both surveys"></f7-list-item>
    </f7-list>
  </f7-popover>
  <f7-popover class="sendSurveysPopover">
    <f7-list>
      <f7-list-item @click="sendInformationRequest('health')" link="#" popover-close title="Send only Health survey"></f7-list-item>
      <f7-list-item @click="sendInformationRequest('research')" link="#" popover-close title="Send only Reseach survey"></f7-list-item>
      <f7-list-item @click="sendInformationRequest()" link="#" popover-close title="Send both surveys"></f7-list-item>
    </f7-list>
  </f7-popover>
  </f7-block>
  <div v-for="onepreivous in sortedRequests" :key="onepreivous.id">
    <f7-card v-if="onepreivous.researchSurvey">
    <f7-card-header ><f7-icon material="check"></f7-icon><strong>Research Survey</strong> Answered on {{$moment(onepreivous.createdAt*1000).format('LLL')}}</f7-card-header>
     <f7-list accordion-list>
    <f7-list-item accordion-item title="Answers">
      <f7-accordion-content>
        <f7-list media-list>
         <f7-list-item
          v-for="q in researchSurveysQuestions(onepreivous.researchSurvey)" :key="q.id"
           :title="q.text">
          <div v-if="onepreivous.answers&&onepreivous.answers[q.id]&&onepreivous.answers[q.id].multiple" slot="text">
           {{researchSurveyAnswer(q,onepreivous.answers[q.id])}}
          </div>
          <div v-else-if="onepreivous.answers&&onepreivous.answers[q.id]" slot="after">
            {{researchSurveyAnswer(q,onepreivous.answers[q.id])}}
          </div>
          <div v-else slot="after">
           N/A
          </div>
          </f7-list-item>
        </f7-list>
      </f7-accordion-content>
    </f7-list-item>
     </f7-list>
    </f7-card>
<f7-card v-else >
  <f7-card-header v-if="!onepreivous.answer">Pending answer...</f7-card-header>
   <f7-card-header v-else><f7-icon material="check"></f7-icon><strong>Health Survey</strong> Answered on {{$moment(onepreivous.answer.wh).format('LLL')}}</f7-card-header>
    <f7-card-content v-if="onepreivous.answer">
      <f7-list>
        <f7-list-item footer="Physical activity" title="Days of vigorous activities last 7 days" :after="!onepreivous.answer.noVigorous?onepreivous.answer.times.vigorous.days:'0'"></f7-list-item>
        <f7-list-item footer="Physical activity" title="Time per day of vigorous activity" :after="!onepreivous.answer.noVigorous?onepreivous.answer.times.vigorous.hours+' hrs '+':'+onepreivous.answer.times.vigorous.mins+' mins':'0'"></f7-list-item>
        <f7-list-item footer="Physical activity" title="Days of moderate activities last 7 days" :after="!onepreivous.answer.noModerate?onepreivous.answer.times.moderate.days:'0'"></f7-list-item>
        <f7-list-item footer="Physical activity" title="Time per day of moderate activity" :after="!onepreivous.answer.noModerate?onepreivous.answer.times.moderate.hours+' hrs '+onepreivous.answer.times.moderate.mins+' mins':'0'"></f7-list-item>
        <f7-list-item footer="Diet" title="less than 2 fruits/day" :after="onepreivous.answer.dietQ1===true?'Rarely/Never':onepreivous.answer.dietQ1===false?'Usually/Often':onepreivous.answer.dietQ1"></f7-list-item>
        <f7-list-item footer="Diet" title="less than 2 vegetables/day" :after="onepreivous.answer.dietQ2===true?'Rarely/Never':onepreivous.answer.dietQ2===false?'Usually/Often':onepreivous.answer.dietQ2"></f7-list-item>
        <f7-list-item footer="Diet" title="less than 2 servings of whole grains/day" :after="onepreivous.answer.dietQ3===true?'Rarely/Never':onepreivous.answer.dietQ3===false?'Usually/Often':onepreivous.answer.dietQ3"></f7-list-item>
        <f7-list-item footer="Diet" title="more than 16 ounces of sweetened beverages/day" :after="onepreivous.answer.dietQ4===true?'Rarely/Never':onepreivous.answer.dietQ4===false?'Usually/Often':onepreivous.answer.dietQ4"></f7-list-item>
        <f7-list-item footer="Diet" title="sweet snacks in between meals" :after="onepreivous.answer.dietQ5===true?'Usually/Often':onepreivous.answer.dietQ5===false?'Rarely/Never':onepreivous.answer.dietQ5"></f7-list-item>
        <f7-list-item title="Physical community resources preferences" :footer="onepreivous.answer.physicalResourcesPreferences?onepreivous.answer.physicalResourcesPreferences.join(', '):'N/A'"></f7-list-item>
        <f7-list-item title="Food intake community resources preferences" :footer="onepreivous.answer.foodResourcesPreferences?onepreivous.answer.foodResourcesPreferences.join(', '):'N/A'"></f7-list-item>
        <f7-list-item title="Physical activity preferences" :footer="onepreivous.answer.activityPreferences?onepreivous.answer.activityPreferences.join(', '):'N/A'"></f7-list-item>
      </f7-list>
    </f7-card-content>
      <f7-card-footer>Request sent to {{onepreivous.to}} by {{onepreivous.sender}} on {{$moment(onepreivous.when).format('LLL')}}</f7-card-footer>
</f7-card>
  
  </div>
<f7-block v-if="sortedRequests.length==0">No surveys have been sent to {{actualPatient.name}}</f7-block>


  </f7-page>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
  export default {
    data: ()=> {
      return {
        previous:{},
        researchSurveys:null
      }
    },
    computed:{
      ...mapState(['actualPatient','focusedAccount']),
      ...mapGetters(['onClinic']),
      sortedRequests(){ // orders the heath survey requests by its creation moment
        let requests=Object.values(this.previous);
        requests.sort((a,b)=>{
            var x = a.when?a.when:this.$moment(a.createdAt*1000);
            var y = b.when?b.when:this.$moment(b.createdAt*1000);
            if (x < y) {return 1;}
            if (x > y) {return -1;}
            return 0;
        });
        return requests;
      }
    },
    methods:{
      loadPrevious(){ // gets from database the health survey requests of this patient on this clinic
         this.$f7.dialog.preloader('Loading surveys...');
           let promises=[
             this.$firebase.database().ref('requests_by_clinic/'+this.onClinic+'/'+this.actualPatient.id).once('value'),
             this.$firebase.functions().httpsCallable('getResearchSurveyAnswers')({
               clinicid:this.onClinic,
               patientid: this.actualPatient.id,
                accountid:this.focusedAccount.id
               })
           ]
           return Promise.all(promises)
           .then(multi=>{
             let answers={}
             
           let healthSurveys=multi[0].val()||{}
            Object.values(healthSurveys).forEach(res=>{
              answers[res.id]=res
              })
            
           let research=multi[1]
         if(research.data.error) throw new Error(research.data.error)
           
         this.researchSurveys=research.data.researchSurveys
         let researchSurveysAnswers=research.data.researchSurveysAnswers
           Object.values(researchSurveysAnswers).forEach(res=>{
              Object.values(res).forEach(sur=>{
                answers[sur.id]=sur
                })
            })
             this.previous=answers
             this.$f7.dialog.close();
        }).catch(error=>{
           this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message);
          console.log(error)
        });
      },
      researchSurveyAnswer(q,answer){
        if(answer.multiple || answer.freeText){
          let allAnswers=[]
          if(answer.multiple)allAnswers.push(answer.multiple.join(', '))
          if(answer.freeText)allAnswers.push(answer.freeText)
          return allAnswers.join(', ')
        }else{
        return q.options[answer-1].text
        }
      },
      researchSurveysQuestions(sur){
       let sections= Object.values(this.researchSurveys[sur].sections)
       sections=sections.sort((a,b)=>{
         if (a.order > b.order) {return 1;}
         if (a.order < b.order) {return -1;}
         return 0
       })
       let allQuestions=[]
       sections.forEach(sec=>{
          let orderedQuestions=Object.values(sec.questions).sort((a,b)=>{
         if (a.order > b.order) {return 1;}
         if (a.order < b.order) {return -1;}
         return 0
       })
         orderedQuestions.forEach(q=>{
           allQuestions.push(q)
         })
       })
       return allQuestions
      },
      sendInformationRequest(survey){ // calls a backend functions to send the health survey to the patient
      this.$f7.dialog.preloader(`Sending ${survey?survey:''} survey...`);
         let sendinforequest = this.$firebase.functions().httpsCallable('sendinforequest');
        sendinforequest({
          patientid: this.actualPatient.id,
          accountid:this.focusedAccount.id,
          clinicid:this.onClinic,
          only:survey
          }).then((ladata)=> {
          this.$f7.dialog.close();
          if(ladata.data.fail){
            throw new Error(ladata.data.datum);
          }
          else{
            let cual=ladata.data.datum;
            if(cual!='research'){
            let neo={};
            neo[cual.id]=cual;
            this.previous=Object.assign({},this.previous,neo);
            }
          }
         this.$f7.toast.show({
            text: 'Survey sent succesfully',
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
        }).catch((error)=>{
          this.$f7.dialog.close();
          console.log(error)
          this.$f7.dialog.alert(error.message,error.code|| error);
        });
      },
      answerSurveyNow(survey){ // opens the health survey 
      this.$f7.dialog.preloader(`Opening ${survey} survey...`);
         let sendinforequest = this.$firebase.functions().httpsCallable('sendinforequest');
          
        sendinforequest({
          patientid: this.actualPatient.id,
          accountid:this.focusedAccount.id,
          clinicid:this.onClinic,
          answerNow:true,
          only:survey
          }).then((ladata)=> {
          this.$f7.dialog.close();
          if(ladata.data.fail){
            throw new Error(ladata.data.datum);
          }
          else{
           window.location.replace(ladata.data.datum)
            
            this.$f7.dialog.close();
          }
         
        }).catch((error)=>{
          this.$f7.dialog.close();
          console.log(error)
          this.$f7.dialog.alert(error.message,error.code|| error);
        });
    },
    },
    watch:{
      onClinic(){ // obsers if the getter onClinic changes. if its true navigates back. used to get out of the edit pages if the user selects another clinic on the left pannel
        if(this.$f7.views.main.router.url==='/patientReported'){
         if(!this.focusedAccount.privx.clinician){
           this.$f7.views.main.router.navigate('/settings')
         }else{
         this.$f7.views.main.router.navigate('/patients')
       }
        }
      }
    }
  };
</script>
<style>
.bakcer .page-content{
  background: #e2e2e2!important;
}

</style>