<template>
  <f7-page name="pdf">
      <f7-navbar title="PDF viewer" :subtitle="'Page '+page+'/'+numPages" class="nohideback" back-link="Volver">
        <f7-nav-right>
          <f7-link @click="prevPage()" icon-material="keyboard_arrow_left"></f7-link>
           <f7-link @click="nextpage()" icon-material="keyboard_arrow_right"></f7-link>
          <f7-link @click="rotate += 90" icon-material="screen_rotation"></f7-link></f7-nav-right>
           <f7-link @click="$refs.myPdfComponent.print()" icon-material="printer"></f7-link>
          
      </f7-navbar>

 
     <pdf :src="focusedPDF" ref="myPdfComponent" class="all100pdf" :page="page" @error="pdferror" @num-pages="numPages = $event" @progress="actualizarLoadedRatio($event)" :rotate="rotate"></pdf>

  </f7-page>
</template>
<script>
 import {mapState} from 'vuex';
 import pdf from 'vue-pdf';

export default {
  components: {
    pdf
  },
data() {

      return {
       numPages: 1,
       rotate: 0,
       page: 1,
       loadedRatio: 0,
       progressdialog: null
      }

},
computed:{
  ...mapState(['focusedPDF']),
},
  mounted() {
    this.progressdialog = this.$f7.dialog.progress('Cargando archivo', 0);
    this.progressdialog.setText('0%');
    },
methods:{
  printmeplease(){

  },
  nextpage(){
    if(this.page<this.numPages){
      this.page++;
    }
  },
  prevPage(){
    if(this.page>1){
      this.page--;
    }
  },
  actualizarLoadedRatio(cuanto){
    let percet=Math.floor(cuanto * 100);
    this.progressdialog.setProgress(percet);
    this.progressdialog.setText(percet + '%');
    if(cuanto==1){
       this.progressdialog.close();
    }
  },
  pdferror(err) {

            console.log(err);
        }
}
}
</script>
<style >
.all100pdf{
  width:100%;
}
.view-master-detail .page-master-detail .navbar.nohideback .link.back {
  display:flex!important;
}
</style>



