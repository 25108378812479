<template>
  <f7-page class="bggris" >
    <f7-navbar :title="focusedclinic?focusedclinic.name:'Create New Clinic'" :subtitle="focusedclinic?focusedclinic.formatted_address:null"  back-link="Back">
    </f7-navbar>

<f7-list 
v-show="!focusedclinic || editAddress"
 no-hairlines-md>
  <f7-list-item divider title="Step 1: Find adress/place"></f7-list-item>
  <f7-list-input
    label="Address/Place"
    type="text"
    input-id="autocomplete_inst"
    placeholder="Search address or place name"
    clear-button
  >
  </f7-list-input>
</f7-list>
<f7-block>
  <f7-row>
    <f7-col width="100" medium="50">
  <template v-if="clinic.place_id">
  <f7-list class="no-margin-top">
  <f7-list-input
    label="Name"
    type="text"
    :value="clinic.name"
    @input  ="clinic.name=$event.target.value"
  >
  </f7-list-input>
 
  <f7-list-item
    header="Address"
    :title="clinic.formatted_address"
  >
  <f7-link slot="after"
  @click="editAddress=true"
  >
    <f7-icon material="edit"></f7-icon>
  </f7-link>
  </f7-list-item>
</f7-list>
<f7-row>
  <f7-col><f7-button outline @click="legoback()" color="red">Cancel</f7-button></f7-col>
   <f7-col><f7-button @click="saveclinic()" fill raised color="green">Save</f7-button></f7-col>
</f7-row>
  </template>
  <p v-else>First search a place or adress on the input above</p>
</f7-col>
    <f7-col width="100" medium="50">
        <div class="mapclinic"></div>
          <f7-block v-show="lephotos.length>0">
            <f7-row>
 <f7-col v-for="(onephoto,idx) in lephotos" :key="idx" width="33" @click="$refs.darkphotoviewer.open(idx)" class="fotosalto" 
      :style="'background: url('+onephoto+'); background-repeat:no-repeat; background-size:cover; background-position:center;'"></f7-col>

  </f7-row>
  </f7-block>
    </f7-col>
  </f7-row>
</f7-block>

 <f7-photo-browser
            :photos="lephotos"
            theme="dark"
            ref="darkphotoviewer"
          ></f7-photo-browser>

  </f7-page>
</template>

<script>
import {mapState} from 'vuex';
import loadGoogleMapsApi from 'load-google-maps-api';

export default {
  data(){
    
    return{
      editAddress:false,
    googleMaps: null,
    lemapa: null,
    placemarker:null,
    lephotos:[],
    clinic:{
       id:'',
      formatted_address:'',
      lat:null,
      lng:null,
      name:'',
      place_id:'',
    }

    }
  },
  created(){
    
    if(this.focusedclinic){
      this.clinic=JSON.parse(JSON.stringify(this.focusedclinic))
    }else{
        this.resetInfo();
    }
  },
  mounted(){

   if(this.focusedclinic){
      
      this.dibujarMpa();
      this.loadMapWithPin();
   }
   else{
      this.dibujarMpa();
   }
  },
  computed:{
    ...mapState(['focusedclinic','clinics','user','accountsAll','myAccounts'])
  },
  methods:{
        requestPhotos(){ // calls google map api to get the pictures of that place
          let request2 = {
            placeId: this.focusedclinic.place_id,
            fields: ['photo',]
          };
          let service = new this.googleMaps.places.PlacesService(this.lemapa);
          service.getDetails(request2,(place, status)=> {
             if(status!=='OK'){
               return this.$f7.dialog.alert(status);
            }
            else{
               let locphots=place.photos || [];
               this.lephotos= locphots.map(x => x.getUrl());
            }
          });
        },
    legoback(){ // navigate back
        this.$f7.views.main.router.back();
      
    },
    resetInfo(){ // set to default all the clinic object
      this.clinic=Object.assign({},{
        id:this.clinic.id,
      formatted_address:'',
      lat:null,
      lng:null,
      name:'',
      place_id:'',
      });
      if(this.placemarker){
        this.placemarker.setMap(null);
        this.placemarker=null;
      }
    },
     loadMapWithPin(){ //it is called when there is a clinic to edit, not to create. it loads the map on the screen and sets the clinic on the center
      loadGoogleMapsApi({key:this.$store.state.gmapsAPIkey,libraries:['places'],language:'en'}).then((googleMaps)=> {
           this.googleMaps=googleMaps;
          this.lemapa= new this.googleMaps.Map(document.querySelector('.mapclinic'), {
             center: {lat: this.focusedclinic.lat, lng: this.focusedclinic.lng},
              zoom: 15,
              streetViewControl: false,
              mapTypeControl: false
            });
              //crear marker
               this.placemarker=new this.googleMaps.Marker({
                position: {lat: this.focusedclinic.lat, lng: this.focusedclinic.lng},
                 map: this.lemapa
              });
              this.requestPhotos();
          }).catch((error)=> {
            this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
          })
     },
       dibujarMpa(){ // opens the google map on the screen and creates the google maps autocomplete input
         loadGoogleMapsApi({key:this.$store.state.gmapsAPIkey,libraries:['places'],language:'en'}).then((googleMaps)=> {
           this.googleMaps=googleMaps;
           let defaultBounds = new this.googleMaps.LatLngBounds(
              new this.googleMaps.LatLng(38.989752, -90.928138),
              new this.googleMaps.LatLng(38.248369, -89.452410)
            );

          this.lemapa= new this.googleMaps.Map(document.querySelector('.mapclinic'), {
             center: {lat: 38.6425597, lng:-90.3107618},
              zoom: 9,
              streetViewControl: false,
              mapTypeControl: false
            });
            let countryRestrict = {'country': 'us'};
            let autocomplete = new this.googleMaps.places.Autocomplete(
            /** @type {!HTMLInputElement} */ (
                document.getElementById('autocomplete_inst')), {
              componentRestrictions: countryRestrict,
              bounds: defaultBounds,
            });
            autocomplete.addListener('place_changed', ()=> {
            let place = autocomplete.getPlace();
            if (place.geometry) {
              this.lemapa.panTo(place.geometry.location);
              this.lemapa.setZoom(15);
              let prothopos=place.photos || [];
               this.lephotos=prothopos.map(unaphoto => {
                return unaphoto.getUrl();
              });
              let tostore={
                id:this.clinic.id,
                formatted_address: place.formatted_address || '',
                lat: place.geometry.location.lat() || null,
                lng: place.geometry.location.lng() || null,
                name:place.name || '',
                place_id:place.place_id
              };

              this.clinic=Object.assign({},this.clinic,tostore);
              //crear marker
               this.placemarker=new this.googleMaps.Marker({
                position: place.geometry.location,
                 map: this.lemapa
              });
            } else {
              document.getElementById('autocomplete_inst').placeholder = 'Enter a valid place';
            }
          });

          }).catch((error)=> {
           this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
          });
      },
      saveclinic(){ // calls a backend fucntion to store the clinic
        if(this.clinic.name=='')return this.$f7.dialog.alert('A name is required!');
        if(this.clinic.formatted_address=='') return this.$f7.dialog.alert('An adress is required!');  
        let editing=null
        if(!this.focusedclinic){
 this.clinic.id= this.$firebase.database().ref('clinics/').push().key;
 this.$f7.dialog.preloader('Creating new clinic...'); 
}else{
  editing=true
  this.$f7.dialog.preloader('Editing clinic...');
}
         let call = this.$firebase.functions().httpsCallable('saveClinic')
         call({clinic:this.clinic,editing,}).then(ledata=>{
           if(ledata.data.error){
             throw new Error(ledata.data.error)
           }
           let local_clinics=Object.assign({},this.clinics);
            local_clinics[this.clinic.id]=this.clinic;
          this.$store.commit('setObjectTo',{what:'clinics',to:local_clinics});
            this.$f7.dialog.close()
            if(!editing){
              let data=ledata.data
            let local_accounts={}
          local_accounts[data.account.id]=data.account;
          this.$store.commit('setObjectTo',{what:'accountsAll',to:local_accounts});
          let local_myAccounts=Object.assign({},this.myAccounts);
          local_myAccounts[data.account.id]=data.account;
         this.$store.commit('setObjectTo',{what:'myAccounts',to:local_myAccounts});
         this.$store.commit('setObjectTo',{what:'focusedAccount',to:data.account});
                }
        let toastCenter = this.$f7.toast.show({
        text: this.clinic.name+' saved',
        position: 'center',
        closeTimeout: 1500,
        destroyOnClose: true
    });
        this.$f7.dialog.close();
        this.$f7.views.main.router.back();
         }).catch(error=>{
           this.$f7.dialog.close()
           console.log(error)
           this.$f7.dialog.alert((error.message,error.code)||error)
           

         })
      },
  }
}
</script>
<style >
.pic{
  margin: 10px;
  max-width:250px;
  max-height:250px;
  filter: drop-shadow(5px 5px 5px blue);
}
.deleteItem{
background-color:red;
margin:auto 10px
}
.demo-swiper .swiper-slide {
  font-size: 25px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #000;
}
.demo-swiper .swiper-slide {
  box-sizing: border-box;
  border: 1px solid #ddd;
  background: #fff;
  max-width: 50%;
  width: auto;
}
.demo-swiper {
  margin: 0px 0 35px;
  font-size: 18px;
  height: 250px;
}
.aximwith{
  width:100%
}
.mapclinic{
  height:300px;
}
.bggris{
  background:#e6e6e6;
}
.fotosalto{
  height: 100px;
  background-color: #cecece;
  /* -webkit-background-size:cover; -moz-background-size:cover; -o-background-size:cover; */
margin-bottom: 16px;
}

.popup.photo-browser-popup.photo-browser-standalone{
  z-index: 99999!important;
}
</style>