import { mapState  } from 'vuex';

export const photomixin = {
    data(){
        return {
            notifLoading: null,
            progressdialog: null,
            task: null,
            profile_avatarObserver: null,
            profile_preAvatarLoading:'../static/ripple.gif',
            profile_oldAvatar: null
        }
    },
    computed: {
        ...mapState(['user']),
        profile_myavatar(){
            return this.user.photoURL
        }
    },
    created (){
      this.profile_oldAvatar = this.profile_myavatar;
        this.notifLoading=this.$f7.notification.create({
            title:'Upload Ok',
            titleRightText:'100%',
            subtitle: 'Processing image',
            text:'This might take a couple of seconds...',
            closeTimeout: 90000,
            cssClass: "procesImageUpload",
            icon: '<i class="material-icons">cloud_upload</i>'
        });
    },
    methods: {
      encenderObservableAvatar () {
        this.profile_avatarObserver = this.$firebase.database().ref('users/'+this.$firebase.auth().currentUser.uid+'/photoURL');
        this.profile_avatarObserver.on('value', snapshot => {
            let myavatar = snapshot.val() || null;
            if(myavatar && (this.profile_oldAvatar!=myavatar)){
              this.$store.commit('updateAvatar', myavatar);
              this.profile_oldAvatar = myavatar;
              this.notifLoading.close();
            }
        });
        },
        alsnapshot (snapshot) {
            let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            let nentero = Math.round(percentage);
            if (!isNaN(nentero)) {
                this.progressdialog.setProgress(nentero);
                this.progressdialog.setText(nentero + '%');
            }
        },
        alerror(err) {
            console.log(err);
            this.progressdialog.close();
            this.$f7.dialog.alert(err.code,'upload error');
        },
        altermino() {
            this.progressdialog.close();
            this.notifLoading.open();
            this.profile_oldAvatar = this.profile_myavatar;
          //  this.$store.commit('updateAvatar', this.profile_preAvatarLoading);
        },
        subirfoto (filereceive) {
          let filename = filereceive.name
            this.encenderObservableAvatar();
            let extenx = filename.split('.').pop();
            this.progressdialog = this.$f7.dialog.progress('Uploading avatar...', 0);
            this.progressdialog.setText('0%');
             this.task = this.$firebase.storage().ref('usergen/' + this.$firebase.auth().currentUser.uid + '/avatar.' + extenx).put(filereceive);
             this.task.on('state_changed',
                this.alsnapshot,
                this.alerror,
                this.altermino
            );
        },
        tomarFoto () {
            this.$$('#myimg-input').click();
        },
        filesChange (e) {
            let file = e.target.files[0];
            if (file) {
                this.subirfoto(file)
            }
        }
    }
}