<template>
  <f7-page @page:beforein="loadData" style="background-color:#f7f7f8">
    <f7-navbar back-link="Back" title="Select Digital Resources">
      <f7-nav-right>
          <f7-link class="searchbar-enable" data-searchbar=".searchbar-digitalResources" icon-material="search"></f7-link>
        <f7-block>
      <f7-button raised popover-open=".popover-digitalResourcesFilter" >Filter
          <f7-badge class="badgeTop" :color="filterCounter>0?'red':''">{{filterCounter}}</f7-badge>
      </f7-button>
</f7-block>
      </f7-nav-right>
      <f7-searchbar
      class="searchbar-digitalResources"
      expandable
      :disable-button="true"
      custom-search
      @searchbar:search="searchDigitalResource" 
      ref="searchForDigitalResource"
    ></f7-searchbar>
    </f7-navbar>
     
    
<f7-popover  class="popover-digitalResourcesFilter inerliimtheight">
    <f7-list >
      
      <f7-list-item divider>Types</f7-list-item>
      <f7-list-item
       v-for="(type,index) in sortedTagList('types')" 
      :key="index" 
      popover-close 
      :title="type"
      checkbox 
      :value="index"
      @change="checkActiveFilters($event,'types')"
      >
      </f7-list-item>
      <f7-list-item divider>Platforms</f7-list-item>
      <f7-list-item
       v-for="(type,index) in sortedTagList('platforms')" 
      :key="index" 
      popover-close 
      :title="type"
      checkbox 
      :value="index"
      @change="checkActiveFilters($event,'platforms')"
      >
      </f7-list-item>
      <f7-list-item divider>Features</f7-list-item>
      <f7-list-item
       v-for="(type,index) in sortedTagList('features')" 
      :key="index" 
      popover-close 
      :title="type"
      checkbox 
      :value="index"
      @change="checkActiveFilters($event,'features')"
      >
      </f7-list-item>
    </f7-list>
  </f7-popover>
  <f7-block v-if="filteredDigitalResources.length==0">
    <f7-block-title>No results</f7-block-title>
    </f7-block>
    
    <f7-card v-for="resource in filteredDigitalResources" :key="resource.id"
    class="margin-bottom"
    >
      <f7-card-header>
          <f7-row><img class="userPhoto margin-right"
            :src="resource.logo || '../static/icons/no-pictures.png'"
          />
          <img v-if="Object.keys(selectedDigitalResources).includes(resource.id)" 
        class="selectedLogo" src="../static/selected.png" />
          <div class="align-self-center">{{ resource.name||resource.link }}</div>
        
          </f7-row>
          <f7-button 
           @click="resourceDetails(resource.id)"
           :color="openedDetails[resource.id]?'red':'blue'"
           >{{!openedDetails[resource.id]?'Details':'Close details'}}
           </f7-button>
      </f7-card-header>
      <f7-card-content v-if="openedDetails[resource.id]">
        <f7-list class="margin-bottom">
          <template v-if="!resource.patientid">
          <f7-list-item 
          class="description"
          title="Description" 
          
          >
          <template #after>
            <p>{{resource.description}}</p>
          </template>
          
          </f7-list-item>
          <f7-list-item
           v-for="(category, index) in digitalResourcesTags"
            :key="index"
          >
            <div slot="title">
                Resource {{ index }}  
            </div>
            <div slot="after">
                <div v-if="Object.values(resource[index]).length == 0">
                  No tags selected
                </div>
                <f7-chip
                  v-for="item in resource[index]"
                  :key="item"
                  :text="category[item]"
                >
                </f7-chip>
              </div>
          </f7-list-item>
          </template>
           
          <f7-list-item v-if="!resource.patientid"
          title="Equipment needed"
          :after="resource.equipment"
          ></f7-list-item>
        </f7-list>

      </f7-card-content>
      <f7-card-footer>
        <div>
        <f7-list>
          <f7-list-item checkbox no-chevron 
          :checked="Object.keys(selectedDigitalResources).includes(resource.id)"
          @change="selectDigitalResource($event.target.checked,resource)"
          >
            <div slot="title" :style="{color:selectedDigitalResources[resource.id]?'#48a9f5':'gray'}">
              {{selectedDigitalResources[resource.id]?'Selected':'Select'}}
              </div>
            </f7-list-item
            ></f7-list>
        </div>
        <f7-button target="_blank" external :href="resource.link">Link to Resource</f7-button>
      </f7-card-footer>
    </f7-card>
    <f7-popup class="newResourcePopup" ref="addNewDigitalResourcePopup">
      <f7-page>
        <f7-navbar title="Adding new resource">
          <f7-nav-right><f7-link popup-close>Close</f7-link></f7-nav-right>
        </f7-navbar>
        <f7-list class="no-margin-top">
          <f7-list-input
          label="Link to resource"
            :value="newResource.link"
            @input="newResource.link=$event.target.value.trim()"
          ></f7-list-input>
          <f7-list-input
          label="Name (optional)"
          :value="newResource.name"
            @input="newResource.name=$event.target.value.trim()"

          ></f7-list-input>
          <f7-list-input
          type="textarea"
          label="Description (optional)"
          :value="newResource.description"
            @input="newResource.description=$event.target.value.trim()"

          ></f7-list-input>
        </f7-list>
        <f7-block>
          <f7-row>
            <f7-col><f7-button popup-close>Cancel</f7-button></f7-col>
            <f7-col><f7-button @click="saveNewDigitalResource" fill>save</f7-button></f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-popup>
    <f7-fab @click="addNewDigitalResource()" position="right-bottom" slot="fixed" color="light-blue">
    <f7-icon material="add"></f7-icon>
  </f7-fab>
  </f7-page>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
        filteredTags:{
          types:{},
          platforms:{},
          features:{}
        },
        localResources:{},
        newResource:{
          id:'',
          link:'',
          name:'',
          description:'',
          patientid:''
        },
        openedDetails:{},
        searchBarValue:''
    };
  },
  computed: {
    ...mapState(["actualPatient","digitalResources", "digitalResourcesTags","focusedPrescription","user","pendingDigitalResources"]),
    ...mapGetters(['onClinic']),
    filterCounter(){
      let counter=0
      Object.values(this.filteredTags).forEach(category=>{
        counter+=Object.keys(category).length
      })
      return counter
    },
    filteredDigitalResources() {
      this.localResources=Object.assign({},this.localResources,this.selectedDigitalResources,this.digitalResources)
      let array = Object.values(this.localResources).filter((res) => {
        return !res.inactive 
      })
      if(this.searchBarValue){
        array=array.filter(res=>{
          return res.name.includes(this.searchBarValue)||res.link.includes(this.searchBarValue)
        })
      }
        if(Object.keys(this.filteredTags.types).length>0){
      array=array.filter((res) => {
        return Object.keys(res.types).some(tag=>this.filteredTags.types[tag])});
          }
        if(Object.keys(this.filteredTags.platforms).length>0){
      array=array.filter((res) => {
        return Object.keys(res.platforms).some(tag=>this.filteredTags.platforms[tag])});
          }
          if(Object.keys(this.filteredTags.features).length>0){
      array=array.filter((res) => {
        return Object.keys(res.features).some(tag=>this.filteredTags.features[tag])});
          }
      return array.sort((a, b) => {
        var x = a.name.toLowerCase();
        var y = b.name.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    },
    selectedDigitalResources(){
     if(!this.focusedPrescription.selectedDigitalResources){ // for reactivity porpuses
       this.$store.commit('setChildOnFocusedPrescription',{wich:'selectedDigitalResources',neovalue:{}})
     }
      return this.focusedPrescription.selectedDigitalResources||{}
    }
  },
  methods: {
    addNewDigitalResource(){
      this.newResource.name=''
      this.newResource.link=''
      this.newResource.description=''
      this.$refs.addNewDigitalResourcePopup.open()
    },
    checkActiveFilters(e,category){
      let checked=e.target.checked
      let value=e.target.value
      let local=Object.assign({},this.filteredTags)
      if(checked) local[category][value]=value
      else delete local[category][value]
      this.filteredTags=Object.assign({},local)
    },
    async selectDigitalResource(checked,resource){ // adds or deletes a selected digital resource from the prescription
        let toStore={
          id: resource.id,
          where:'selectedDigitalResources',
          value:checked?resource:null
          };
          try{
       await this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/selectedDigitalResources/'+resource.id).set(checked?resource:null)
                  this.$store.commit('setSelection',toStore);
           this.$f7.toast.show({
            text: `${resource.name}${checked?' added to selection':' removed from selection'}`,
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
        }catch(error){
          console.log(error)
          this.$f7.dialog.alert(error.message,error.code)
        }
      
    },
    async loadData() {
      if (Object.values(this.digitalResources).length == 0) {
        try {
          this.$f7.dialog.preloader("Loading...");
          let res = await this.$firebase
            .database()
            .ref("digitalResources")
            .once("value");
          this.$store.commit("setObjectTo", {
            what: "digitalResources",
            to: res.val() || {},
          });
          this.$f7.dialog.close();
        } catch (error) {
          this.$f7.dialog.close();
          console.log(error);
          this.$f7.dialog.alert(error.message);
        }
      }
       this.localResources=Object.assign({},this.selectedDigitalResources,this.digitalResources)
    },
    resourceDetails(id) {
        if(this.openedDetails[id])delete this.openedDetails[id]
        else this.openedDetails[id]=true
        this.openedDetails=Object.assign({},this.openedDetails)
    },
    categoryTags(category, selected) {
      let selectedTagsName = Object.values(selected).map((sel) => {
        return this.digitalResourcesTags[category][sel];
      });
      return selectedTagsName.join(", ");
    },
   async saveNewDigitalResource(){
     if(!this.newResource.link) return this.$f7.dialog.alert('A link is required')
     let linkInUse=Object.values(this.localResources).some(res=>{
       return res.link==this.newResource.link 
     })
     if(linkInUse) return this.$f7.dialog.alert('This link is already in use by another resource')

      this.newResource.id=this.$firebase.database().ref('pendingDigitalResources').push().key
       this.newResource.patientid=this.actualPatient.id
       this.newResource.prescriptionid=this.focusedPrescription.id
       this.newResource.clinicid=this.onClinic
    try{
    await this.$firebase.database().ref('pendingDigitalResources/'+this.newResource.id).set(this.newResource)
    if(this.user.god||(this.user.globalPrivx&&this.user.globalPrivx.createDigitalResources)){
      let local=Object.assign({},this.pendingDigitalResources)
      local[this.newResource.id]=this.newResource
      this.$store.commit('setObjectTo',{what:'pendingDigitalResources',to:local})
    }
    await this.selectDigitalResource(true,Object.assign({},this.newResource))
    this.$refs.addNewDigitalResourcePopup.close()
    this.$refs.searchForDigitalResource.disable()
    
    }catch(error){
      console.log(error)
      this.$f7.dialog.alert(error.message)
    }

    },
    searchDigitalResource(saerchbar,query){
      this.searchBarValue=query.toLowerCase().trim()
      
    },
    sortedTagList(category){
      let tagList= this.digitalResourcesTags[category] ||{}
      let array=  Object.values(tagList).sort((a,b)=>{
            var x = a;
            var y = b;
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
        let objToReturn={}
        array.forEach(tagValue=>{
          let key = Object.keys(tagList).find(key=>tagList[key]==tagValue)
               objToReturn[key]=tagValue
        })
        return objToReturn
    }
  }
};
</script>

<style>
.resourceLogo {
  max-height: 80px;
  max-width: 80px;
}
.digitalResourceHeader {
  left: 30px;
  position: relative;
}
.selectedLogo{
  position: absolute;
   left: 40px;
}
.addResourcesButton{
  width: fit-content;
  margin: auto;

}
.newResourcePopup{
  height: 430px  !important;
}

.description .item-content .item-inner{
  display:grid !important;
}
</style>
