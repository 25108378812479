<template>
<f7-page bg-color="white" >
  <f7-navbar>
  <f7-nav-left>
    <f7-link panel-open="left" icon-material="menu"></f7-link>
  </f7-nav-left>
    <f7-nav-title
    title="Clinics"
    ></f7-nav-title>
    <f7-nav-right>

        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchclinics"
          icon-material="search"
        ></f7-link>
        
      </f7-nav-right>
        <f7-searchbar
        class="searchclinics"
        expandable
        search-container=".search-list"
        search-in=".item-title, .item-subtitle, .item-text"
        :disable-button="!$theme.aurora"
      ></f7-searchbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found"></f7-list-item>
    </f7-list>
<f7-list  media-list class="search-list searchbar-found no-margin-top fixiconresoruce">
    <f7-list-item 
      v-for="clinic in myClinics" :key="clinic.id"
       :title="clinics[clinic].name"
       link="#"
       @click="editclinic(clinic)"
       :subtitle="clinics[clinic].formatted_address"
        >
       <f7-icon material="create"  slot="after"></f7-icon>
    </f7-list-item>
        <f7-list-item v-if="myClinics.length==0" title="There are no clinics created"></f7-list-item>
</f7-list>
<f7-fab @click="createclinic()" position="right-bottom" slot="fixed" color="light-blue">
    <f7-icon material="add"></f7-icon>

  </f7-fab>

</f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
    data(){
        return{
        }
    },
    computed:{
      ...mapState(['clinics']),
      ...mapGetters(['myClinics']),
     
    },
    methods:{
        createclinic(){ // sets to null the focusedclinic on the state, and navigates to createclinic
           this.$store.commit('setObjectTo',{what:'focusedclinic',to:null})
            this.$f7.views.main.router.navigate("/createclinic/");
        },
        editclinic(clinic){ // sets the focusedclinic on the state, and navigates to createclinic
          this.$store.commit('setObjectTo',{what:'focusedclinic',to:this.clinics[clinic]})
           this.$f7.views.main.router.navigate("/createclinic/");
        },
    }
}
</script>
<style>
.fixiconresoruce .item-media .icon{
  margin: 0 auto;
}
</style>