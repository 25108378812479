<template>
<div>
<f7-card>
        <f7-card-header class="bold uppercaseme">Scheduling</f7-card-header>
  <f7-card-content :padding="false" class="fixblock inpublanck">
<f7-list  inset no-hairlines-md>
   <f7-list-input
  v-if="modifiable"
    label="Date for week 4 follow-up email"
    type="datepicker"
    placeholder="Date for week 4 follow-up email"
    :value="focusedPrescription && focusedPrescription.fups && focusedPrescription.fups.followupfour.date?[focusedPrescription.fups.followupfour.date]:[]"
    @change="saveDatetoPrescrption($event.target.value,'f4')"
    :calendar-params="{locale:'en-US',dateFormat: 'mm/dd/yyyy', closeOnSelect:true, disabled:{to: new Date()}}"
    readonly
  >
  </f7-list-input>
  <f7-list-item v-else-if="focusedPrescription.fups.followupfour.sent || focusedPrescription.clinician!==this.user.id"
    header="Week 4 follow-up email"
    placeholder="Date for week 4 follow-up email"
    :title="focusedPrescription && focusedPrescription.fups && focusedPrescription.fups.followupfour.date?focusedPrescription.fups.followupfour.date:''"
  >
   <div style="margin: auto 20px" slot="content-end" >
    <div class="item-after" v-if="focusedPrescription.fups.followupfour.answers" style="color:green" >Answered {{$moment(focusedPrescription.fups.followupfour.answers.wh).format('MMMM Do YYYY')}}</div>
    <div class="item-after" fill v-else-if="focusedPrescription.fups.followupfour.sent" >Sent, wating to be answered</div>
   <div class="item-after" v-else >Scheduled</div>
   </div> 
  </f7-list-item>
  <f7-list-input
  v-else
    label="Week 4 follow-up email"
    type="datepicker"
    placeholder="Date for week 4 follow-up email"
    :value="focusedPrescription && focusedPrescription.fups && focusedPrescription.fups.followupfour.date?[focusedPrescription.fups.followupfour.date]:[]"
    @change="saveDatetoPrescrption($event.target.value,'f4')"
    :calendar-params="{locale:'en-US',dateFormat: 'mm/dd/yyyy', closeOnSelect:true, disabled:{to: new Date()}}"
    readonly
  >
   <div style="margin: auto 20px" slot="content-end" >
    <f7-button fill v-if="!focusedPrescription.fups.followupfour.sent && focusedPrescription.clinician===this.user.id" @click="sendNow('f4')" >Send now</f7-button> 
    <div class="item-after" v-else-if="focusedPrescription.fups.followupfour.answers" style="color:green" >Answered {{$moment(focusedPrescription.fups.followupfour.answers.wh).format('MMMM Do YYYY')}}</div>
    <div class="item-after" fill v-else-if="focusedPrescription.fups.followupfour.sent" >Sent, wating to be answered</div>
   <div class="item-after" v-else >Scheduled</div>
   </div>
  </f7-list-input>
  <f7-list-input
  v-if="modifiable"
    label="Date for week 8 follow-up email"
    type="datepicker"
    placeholder="Date for week 8 follow-up email"
    :value="focusedPrescription && focusedPrescription.fups && focusedPrescription.fups.followupeight.date?[focusedPrescription.fups.followupeight.date]:[]"
    @change="saveDatetoPrescrption($event.target.value,'f8')"
    :calendar-params="{locale:'en-US',dateFormat: 'mm/dd/yyyy', closeOnSelect:true, disabled:{to: focusedPrescription.fups.followupfour.date? new Date(focusedPrescription.fups.followupfour.date):new Date()}}"
    readonly
  >
  </f7-list-input>
  <f7-list-item v-else-if="focusedPrescription.fups.followupeight.sent || focusedPrescription.clinician!==this.user.id"
    header="Week 8 follow-up email"
    placeholder="Date for week 8 follow-up email"
    :title="focusedPrescription && focusedPrescription.fups && focusedPrescription.fups.followupeight.date?focusedPrescription.fups.followupeight.date:''"
  >
   <div style="margin: auto 20px" slot="content-end" >
    <div class="item-after" v-if="focusedPrescription.fups.followupeight.answers" style="color:green" >Answered {{$moment(focusedPrescription.fups.followupeight.answers.wh).format('MMMM Do YYYY')}}</div>
    <div class="item-after" fill v-else-if="focusedPrescription.fups.followupeight.sent" >Sent, wating to be answered</div>
   <div class="item-after" v-else >Scheduled</div>
   </div> 
  </f7-list-item>
  <f7-list-input
  v-else
    label="Week 8 follow-up email"
    type="datepicker"
    placeholder="Date for week 8 follow-up email"
    :value="focusedPrescription && focusedPrescription.fups && focusedPrescription.fups.followupeight.date?[focusedPrescription.fups.followupeight.date]:[]"
    @change="saveDatetoPrescrption($event.target.value,'f8')"
    :calendar-params="{locale:'en-US',dateFormat: 'mm/dd/yyyy', closeOnSelect:true, disabled:{to: focusedPrescription.fups.followupfour.date? new Date(focusedPrescription.fups.followupfour.date):new Date()}}"
    readonly
  >
   <div style="margin: auto 20px" slot="content-end" >
    <f7-button fill v-if="focusedPrescription.fups.followupfour.sent && !focusedPrescription.fups.followupeight.sent && focusedPrescription.clinician===this.user.id" @click="sendNow('f8')" >Send now</f7-button> 
    <div class="item-after" v-else-if="focusedPrescription.fups.followupeight.answers" style="color:green" >Answered on {{$moment(focusedPrescription.fups.followupeight.answers.wh).format('MMMM Do YYYY')}}</div>
    <div class="item-after" fill v-else-if="focusedPrescription.fups.followupeight.sent" >Sent, wating to be answered</div>
    <div class="item-after" v-else >Scheduled</div>
   </div>
  </f7-list-input>
  <f7-list-input
  v-if="modifiable"
    label="Date for week 12 follow-up email"
    type="datepicker"
    placeholder="Date for week 12 follow-up email"
    :value="focusedPrescription && focusedPrescription.fups && focusedPrescription.fups.followuptwelve.date?[focusedPrescription.fups.followuptwelve.date]:[]"
    @change="saveDatetoPrescrption($event.target.value,'f12')"
    :calendar-params="{locale:'en-US',dateFormat: 'mm/dd/yyyy', closeOnSelect:true, disabled:{to: focusedPrescription.fups.followupeight.date? new Date(focusedPrescription.fups.followupeight.date):new Date()}}"
    readonly
  >
  </f7-list-input>
  <f7-list-item v-else-if="focusedPrescription.fups.followuptwelve.sent || focusedPrescription.clinician!==this.user.id"
    header="Week 12 follow-up email"
    placeholder="Date for week 12 follow-up email"
    :title="focusedPrescription && focusedPrescription.fups && focusedPrescription.fups.followuptwelve.date?focusedPrescription.fups.followuptwelve.date:''"
  >
   <div style="margin: auto 20px" slot="content-end" >
    <div class="item-after" v-if="focusedPrescription.fups.followuptwelve.answers" style="color:green" >Answered {{$moment(focusedPrescription.fups.followuptwelve.answers.wh).format('MMMM Do YYYY')}}</div>
    <div class="item-after" fill v-else-if="focusedPrescription.fups.followuptwelve.sent" >Sent, wating to be answered</div>
   <div class="item-after" v-else >Scheduled</div>
   </div> 
  </f7-list-item>
  <f7-list-input
  v-else
    label="Week 12 follow-up email"
    type="datepicker"
    placeholder="Date for week 12 follow-up email"
    :value="focusedPrescription && focusedPrescription.fups && focusedPrescription.fups.followuptwelve.date?[focusedPrescription.fups.followuptwelve.date]:[]"
    @change="saveDatetoPrescrption($event.target.value,'f12')"
    :calendar-params="{locale:'en-US',dateFormat: 'mm/dd/yyyy', closeOnSelect:true, disabled:{to: focusedPrescription.fups.followupeight.date? new Date(focusedPrescription.fups.followupeight.date):new Date()}}"
    readonly
  >
   <div style="margin: auto 20px" slot="content-end" >
    <f7-button fill v-if="focusedPrescription.fups.followupfour.sent && focusedPrescription.fups.followupeight.sent && !focusedPrescription.fups.followuptwelve.sent && focusedPrescription.clinician===this.user.id" @click="sendNow('f12')" >Send now</f7-button> 
    <div class="item-after" v-else-if="focusedPrescription.fups.followuptwelve.answers" style="color:green" >Answered on {{$moment(focusedPrescription.fups.followuptwelve.answers.wh).format('MMMM Do YYYY')}}</div>
    <div class="item-after" fill v-else-if="focusedPrescription.fups.followuptwelve.sent" >Sent, wating to be answered</div>
    <div class="item-after" v-else >Scheduled</div>
   </div>
  </f7-list-input>
  <!-- removed option
  <f7-list-input
  v-if="modifiable"

    label="Date for next appointment"
    type="text"
    input-id="calendar-date-time"
    placeholder="Next appointment"
    :value="focusedPrescription.nextAppointment"
    @change="saveNextAppointmentToPrescrption($event.target.value)"
    readonly
  ></f7-list-input>
   <f7-list-item v-else title="Next appointment" :after="focusedPrescription.nextAppointment"></f7-list-item>
    -->

</f7-list>
  </f7-card-content>
  </f7-card>
  <f7-card>
        <f7-card-header class="bold uppercaseme">Follow up answers</f7-card-header>
        <f7-card-content v-if="noAnswers">      
        <f7-block class="fixblock inpublanck" >The survey answers will be here</f7-block>
        </f7-card-content>
  <f7-card-content v-for="week in followUpData.weeks" :key="week.week" :padding="false" class="fixblock inpublanck">
    <f7-block-title v-if="focusedPrescription.fups[week.week].answers"><strong>{{week.title}} (answered {{$moment(focusedPrescription.fups[week.week].answers.wh).format('MMMM Do YYYY')}})</strong></f7-block-title>
    <div class="data-table">
  <table style="background:white"  v-if="focusedPrescription.fups[week.week].answers">
    <thead>
      <tr>
        <th v-for="option in followUpData.options" :key="option.header" class="label-cell"><strong>{{option.header}}</strong></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td v-for="option in followUpData.options" :key="option.answer"  class="label-cell">{{focusedPrescription.fups[week.week].answers[option.answer] || '-'}}</td>
      </tr>
    </tbody>
  </table>
</div>
  </f7-card-content>
  
</f7-card>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  data(){
    return{
       followUpData:{
        weeks:[{title:'Week 4',week:'followupfour'},{title:'Week 8',week:'followupeight'},{title:'Week 12',week:'followuptwelve'}],
        options:[
        {header:'Physical',answer:'physical'},
        {header:'Fruits',answer:'dietQ1'},
        {header:'Vegetables',answer:'dietQ2'},
        {header:'Grains',answer:'dietQ3'},
        {header:'Beverages',answer:'dietQ4'},
        {header:'Between meals',answer:'dietQ5'},
        ],
      },
    }
  },
computed:{
    ...mapState(['focusedPrescription','focusedAccount','user','actualPatient']),
    ...mapGetters(['onClinic']),
  noAnswers(){ // returns true if there are no follow up answers yet
           return !Object.values(this.focusedPrescription.fups).some(fup=>fup.answers)
    },
    modifiable(){// returns true if the the first prescription has not been sent yet and if the user is the clinician that created the prescription. used to enable the sliders and other parameters in the prescription
      return !this.focusedPrescription.sent && this.focusedAccount.privx.clinician && this.focusedPrescription.clinicid==this.focusedAccount.clinicid;
    },
},
methods:{
      saveDatetoPrescrption(date,propertyx){ // sets the date of the follow up survey that is going to be sent by email and sms
      let wheretosave={
            f4:'followupfour',
            f8:'followupeight',
            f12:'followuptwelve',
          };
      if(this.focusedPrescription.fups[wheretosave[propertyx]].date!=date && date){
        let sudoindx=this.focusedPrescription.patientid+'_'+this.focusedPrescription.id+'_'+propertyx

        this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/fups/'+wheretosave[propertyx]+'/date').set(date).then(()=>{

        return this.$firebase.database().ref('cronfx').orderByChild('sudoindx').equalTo(sudoindx).once('value')
       }) .then(snspns=>{
    if(snspns.exists()){
      snspns.forEach(childsnap => {
        let id=childsnap.key;
         this.$firebase.database().ref('cronfx/'+id+'/date').set(date).then(()=>{
           this.$store.commit('setFupsDates',{date,wich:wheretosave[propertyx]});
        let toastCenter = this.$f7.toast.show({
            text: 'Follow up set on '+date,
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
         })
          })
              }else{
               this.$store.commit('setFupsDates',{date,wich:wheretosave[propertyx]});
        let toastCenter = this.$f7.toast.show({
            text: 'Follow up set on '+date,
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
              }
             }).catch(error=>{
               this.$f7.dialog.alert(error.message,error.code)
               console.log(error)
             })
      }
    },
    sendNow(cid){ // calls a functions that sends the follow up survey and erase the scheduled date.
                    // payload: cid: is the week of the follow up ('f4','f8' or 'f12') (string)
      this.$f7.dialog.preloader('Sending email and SMS to patient...')
      let wheretosave={
          f4:'followupfour',
          f8:'followupeight',
          f12:'followuptwelve',
        };
        let send = this.$firebase.functions().httpsCallable('sendNow');
        let data= {
          patientid: this.actualPatient.id,
          prescripid: this.focusedPrescription.id,
          cid:cid,
          clinicid:this.onClinic
        }
          send(data).then((ladata)=> {
            if(ladata.data.error) {
              throw new Error(ladata.data.error)
            }
          let date=ladata.data
          this.$store.commit('setFupsDates',{date,wich:wheretosave[cid]});
          this.$store.commit('setFupsSent',{wich:wheretosave[cid]});
          this.$f7.dialog.close()
          }).catch((error)=>{
            this.$f7.dialog.close();
            console.log(error)
            this.$f7.dialog.alert((error.message,error.code)|| error);
          
          });

    },
}
}
</script>