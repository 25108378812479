<template>
<f7-page bg-color="white"  @page:beforein="getTypeOfMyResources(false)" >
  <f7-navbar>
  <f7-nav-left>
    <f7-link panel-open="left" icon-material="menu"></f7-link>
  </f7-nav-left>
    <f7-nav-title
    title="Type of Resources"
    subtitle="Are avialable in all the clinics"
    ></f7-nav-title>
    <f7-nav-right>
             <f7-link @click="getTypeOfMyResources(true)" icon-material="refresh"></f7-link>

        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchtypeofResource"
          icon-material="search"
        ></f7-link>
        
      </f7-nav-right>
        <f7-searchbar
        class="searchtypeofResource"
        expandable
        search-container=".search-list"
        search-in=".item-title, .item-subtitle, .item-text"
        :disable-button="!$theme.aurora"
      ></f7-searchbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found"></f7-list-item>
    </f7-list>

  

<f7-list  media-list class="search-list searchbar-found no-margin-top fixiconresoruce">
  <f7-list-item divider title="Food Resources"></f7-list-item>
    <f7-list-item 
      v-for="type in resDiet" :key="type.id"
       :title="type.name"
      
      
       :text="type.yelpCategories?type.yelpCategories.join(', '):''"
        ><f7-icon :style="{backgroundColor:type.color,borderRadius:'20%',padding:'5px'}"
         :icon="'fa fa-2x fa-'+type.ico.ico" slot="media"></f7-icon>
      <!-- <f7-icon material="create"  slot="after"></f7-icon> -->
    </f7-list-item>
        <f7-list-item v-if="resDiet.length==0" title="There are no Diet resources created"></f7-list-item>

     <f7-list-item divider title="Physicial Activity Resources"></f7-list-item>
    <f7-list-item 
    v-for="type in resPhysical" :key="type.id"
       :title="type.name"
        :text="type.yelpCategories?type.yelpCategories.join(', '):''"
        ><f7-icon
        :style="{backgroundColor:type.color,borderRadius:'20%',padding:'5px'}"
         :icon="'fa fa-2x fa-'+type.ico.ico" slot="media"></f7-icon>
        <!-- <f7-icon material="create"  slot="after"></f7-icon> -->  
    </f7-list-item>
         <f7-list-item v-if="resPhysical.length==0" title="There are no Physical Activities resources created"></f7-list-item>

</f7-list>
<!-- modifiable
<f7-fab @click="createType()" position="right-bottom" slot="fixed" color="light-blue">
    <f7-icon material="add"></f7-icon>

  </f7-fab>
-->
</f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
    data(){
        return{
        }
    },
    computed:{
      ...mapState(['typeOfResources','resourceTypeEnFoco','activeTab','focusedAccount','clinics']),
      ...mapGetters(['onClinic']),
      resDiet(){ // returns array with the diet resources on this clinic 
        return this.$store.getters.resourcesByType('diet');
      },
      resPhysical(){ // returns array with the physical resources on this clinic 
        return this.$store.getters.resourcesByType('physical');
      }
    },
    methods:{
        createType(){ // to create a type of resource . not in use yet
           this.$store.commit('setObjectTo',{what:'resourceTypeEnFoco',to:null})
            this.$f7.views.main.router.navigate("/createTypeOfResource/");
        },
        editType(type){ // to edit a type of resource . not in use yet
          this.$store.commit('setObjectTo',{what:'resourceTypeEnFoco',to:type})
           this.$f7.views.main.router.navigate("/createTypeOfResource/");
        },
         getTypeOfMyResources(force=false){ // gets from database the type of resources
        if(Object.keys(this.typeOfResources).length==0 || force){
          this.$f7.dialog.preloader('Loading types of resources...');
       let call= this.$firebase.functions().httpsCallable('getTypeOfResources')
       call({clinicid:this.onClinic,accountid:this.focusedAccount.id}).then(ledata=>{
         if(ledata.data.error){
           throw new Error(ledata.data.error)
         }
          this.$store.commit('setObjectTo',{what:'typeOfResources',to:ledata.data.types});
           this.$f7.dialog.close();
        }).catch(error=>{
           this.$f7.dialog.close();
           console.log(error)
         this.$f7.dialog.alert((error.message,error.code)||error);
          
        });
        }
      }
    },
}
</script>
<style>
.fixiconresoruce .item-media .icon{
  margin: 0 auto;
}
</style>