
export const paxhelpersmixin = {

		data(){
			return {
				risk:{
				fbg:{
						active: false,
						value:99,
						points: 2,
						date:this.$moment().format('MM/DD/YYYY'),
				},
				cholesterol:{
						active: false,
						value:50,
						points: 2,
						date:this.$moment().format('MM/DD/YYYY'),
				},
				smoking:{
						active: false,
						value: 'never',
						points: 2,
						date:this.$moment().format('MM/DD/YYYY'),
				},

				bmi:{
						 active: false,
							weightValue:66,
							heightValue:42,
							bmi:null,
							absoluteBmi:null,
							points: 0,
							heightDate:this.$moment().format('MM/DD/YYYY'),
							weightDate:this.$moment().format('MM/DD/YYYY'),
					},
				

					bloodPressure:{
							active: false,
							bloodSystolicValue:65,
							bloodDiastolicValue:55,
							points: 0,
							date:this.$moment().format('MM/DD/YYYY'),
					},
	
					physical:{
							active: false,
							vigorousValue:0,
							moderateValue:0,
							points: 0,
							willingPhysical:null
					},
					diet:{
							active: false,
							dietQ1:'Rarely/Never',
							dietQ2:'Rarely/Never',
							dietQ3:'Rarely/Never',
							dietQ4:'Rarely/Never',
							dietQ5:'Rarely/Never',
							points: 0,
							willingDiet:null
					}
			},
			}
		},
    methods: {
		checkEmailUnique(patientid,email,clinicid){ // checks if the email is used by an other patient on this clinic. returns an array with the patients that have the same email
			return new Promise((resolve, reject)=>{
				this.$firebase.database().ref('patients_by_clinic/'+clinicid).orderByChild('email').equalTo(email).once('value').then(snosn=>{
					let othersArray=[];
					let previos=snosn.val() || null;
					if(previos){
						let personsids=Object.values(previos) || [];
						othersArray = personsids.filter(unpatientid=>{
							return (unpatientid.email.length>0 && unpatientid.id!=patientid)
						});
					}
					resolve(othersArray);
				}).catch(error=>{
					return reject(new Error(error.message));
				})
			});
		},
		suage(date){ // returns how old is the patient on years, if the patient is younger than 2 years ir returns the age on month, if the patient is younger than 2 month it returns the age in days
						// payload: date: patient's birthday
			let momento=this.$moment(date, "MM-DD-YYYY");
			let years= this.$moment().diff(momento, 'years');
			if(years>=2){
				return years+' y';
			}
			else{
					let months= this.$moment().diff(momento, 'months');
					if(months>=2){
						return months+ 'm';
					}
					else{
						return this.$moment().diff(momento, 'days')+ 'd';
					}
			}
		},
		lastNamesAndNames(patient){ // returns the joined name and last name of the patient
		 if(patient.lastName)return patient.lastName+', '+patient.name
		 return patient.name
		},
			loadDefaultRisk(riskFromDataBase){ // returns the risk object of the prescription. if the risk misses an item, it if added with default values.
				let riskIds=Object.keys(this.risk);
				riskIds.forEach(onerisj => {
					if(!riskFromDataBase[onerisj]){
						riskFromDataBase[onerisj]=this.risk[onerisj];
					}else{
						if(onerisj=='diet'){
								riskFromDataBase.diet.dietQ1=riskFromDataBase.diet.dietQ1===true?'Rarely/Never':riskFromDataBase.diet.dietQ1===false?'Usually/Often':riskFromDataBase.diet.dietQ1,
								riskFromDataBase.diet.dietQ2=riskFromDataBase.diet.dietQ2===true?'Rarely/Never':riskFromDataBase.diet.dietQ2===false?'Usually/Often':riskFromDataBase.diet.dietQ2,
								riskFromDataBase.diet.dietQ3=riskFromDataBase.diet.dietQ3===true?'Rarely/Never':riskFromDataBase.diet.dietQ3===false?'Usually/Often':riskFromDataBase.diet.dietQ3,
								riskFromDataBase.diet.dietQ4=riskFromDataBase.diet.dietQ4===true?'Rarely/Never':riskFromDataBase.diet.dietQ4===false?'Usually/Often':riskFromDataBase.diet.dietQ4,
								riskFromDataBase.diet.dietQ5=riskFromDataBase.diet.dietQ5===false?'Rarely/Never':riskFromDataBase.diet.dietQ5===true?'Usually/Often':riskFromDataBase.diet.dietQ5
								}
					}
				});
				return riskFromDataBase;
			}
    }
}