<template>
  <f7-page name="patienthist" class="svgris"  @page:beforein="getPrescriptions(false)" @page:beforeremove="turnOffObservable()">
    <f7-navbar :title="lastNamesAndNames(actualPatient)" subtitle="History of PREVENT prescriptions" back-link="Back">
      
       <f7-nav-right>
           <f7-block v-if="gettingPrescriptions"><f7-preloader></f7-preloader></f7-block>
      <f7-link @click="getPrescriptions(true)" icon-material="refresh"></f7-link>
    
       </f7-nav-right>
    </f7-navbar>
    <f7-list class="onepax" inset media-list>
      <f7-list-item 
      :header="actualPatient.gender||actualPatient.sex+ ' | '+suage(actualPatient.birthday)"
      :title="lastNamesAndNames(actualPatient)"
      :subtitle="actualPatient.geo.address"
      :text="actualPatient.email"
      link="/editPatient"
      >
        <img :src="'../static/'+(actualPatient.sex=='female'?'feme.jpg':'masc.jpg')" alt="gender avatar" slot="media" />
       <f7-icon slot="after" material="edit"></f7-icon></f7-list-item>
    </f7-list>

    <f7-block v-if="sortedPrescriptions.length==0">Patient withouth previous PREVENT Tool prescriptions.</f7-block>

     <f7-list media-list class="no-margin-top mormarginbotton">
       <f7-list-item v-for="prescrip in sortedPrescriptions" 
       link="#" 
       @click="goToPrescription(prescrip)" 
       :key="prescrip.id"
       :header="!prescrip.sent?'Preparing...':'Sent '+$moment(prescrip.lastSent).fromNow()"
       :title="'On '+$moment(prescrip.createdAt).format('LLL')" 
       :subtitle="'By '+prescrip.clinicianemail"
       >
       <span v-if="prescrip.sent" slot="after" class="text-color-primary"><f7-icon material="check"></f7-icon></span>
       <f7-icon v-if="!prescrip.sent" material="rate_review" slot="after"></f7-icon>
       </f7-list-item>
     </f7-list>


    <f7-fab v-if="focusedAccount.privx.clinician"  position="right-bottom" slot="fixed" color="light-blue">
    <f7-icon material="add"></f7-icon>
      <f7-icon material="close"></f7-icon>
    <f7-fab-buttons position="top">
      <f7-fab-button  fab-close  href="/patientReported"  label="Patient reported data"><f7-icon material="contact_mail"></f7-icon></f7-fab-button>
      <f7-fab-button  fab-close @click="createNewPrescription()" label="Add new prescription"><f7-icon material="note_add"></f7-icon></f7-fab-button>
    </f7-fab-buttons>
  </f7-fab>

  </f7-page>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
import {paxhelpersmixin} from '../mixins/paxhelpersmixin';

  export default {
    mixins:[paxhelpersmixin],

    data: ()=> {
      return {
        gettingPrescriptions: false,
        obserablePatientInfo:null
      }
    },
    mounted(){
      this.obserablePatientInfo=this.$firebase.database().ref('patients_by_clinic/'+this.onClinic+'/'+this.actualPatient.id)
      this.obserablePatientInfo.on('value', (data)=> {
        if(!data.val()) return
        let local = Object.assign({},this.actualPatient,data.val())
        this.$store.commit('setObjectTo',{what:'actualPatient',to:local});
        this.$store.commit('updateObjectPropValue',{what:'patients',prop: this.actualPatient.id,neovalue:local});
        
      });
    },
    computed:{
      ...mapState(['actualPatient','user','focusedAccount','typeOfResources','resources','activities']),
      ...mapGetters(['onClinic']),
      sortedPrescriptions(){ // returs the prescription ordered by its creation moment. it creates for each sent prescription a node called 'lastSent', it is used to show when was that prescription sent the last time
        let allprestips=Object.values(this.actualPatient.prescriptions||{}) || [];
         allprestips.forEach(prescrip=>{
         if(prescrip.sent) {
          prescrip['lastSent']=Object.values(prescrip.sent)[Object.values(prescrip.sent).length - 1].when
        }
        })
         return Object.values(allprestips).sort((a,b)=>{
            var x = a.createdAt;
            var y = b.createdAt;
            if (x > y) {return -1;}
            if (x < y) {return 1;}
            return 0;
        });
      }
    },
    methods:{
      getPrescriptions(force=false){ // gets all the prescriptions for this patient on this clinic
  if(this.sortedPrescriptions.length==0 || force){
         this.gettingPrescriptions=true;
        this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.actualPatient.id).once('value').then(snap=>{
          let focusedGuy=Object.assign({},this.actualPatient);
          focusedGuy.prescriptions=snap.val() || {};
          this.$store.commit('setObjectTo',{what:'actualPatient',to:focusedGuy});
          this.$store.commit('updateObjectPropValue',{what:'patients',prop: this.actualPatient.id,neovalue:focusedGuy});
             this.gettingPrescriptions=false;
        }).catch(error=>{
            this.gettingPrescriptions=false;
          this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
        });
        }
      },
      async goToPrescription(prescription,force=false){ // navigates to 'patient' if is a new prescription, else calls the function 'navigateToPrescription'. also gets the type of resources, the resources and activities if they have not been called before
                                                        // payload: prescription:the prescription (object), force: boolean (used to force the call)
        if(prescription){
          this.$f7.dialog.preloader('Loading...')
        }
        try{
          let promises=[]
          if(Object.keys(this.typeOfResources).length==0){
           let call= this.$firebase.functions().httpsCallable('getTypeOfResources')
           promises.push(call({clinicid:this.onClinic,accountid:this.focusedAccount.id}))
          }
          if(Object.keys(this.resources).length==0 || force){
          promises.push(this.$firebase.database().ref('resources_by_clinic/'+this.onClinic).once('value'))
         }
         if(Object.keys(this.activities).length==0){
           let call= this.$firebase.functions().httpsCallable('getActivities')
           promises.push(call({clinicid:this.onClinic,accountid:this.focusedAccount.id}))
          }
          let responses=await Promise.all(promises)
          responses.forEach(resp=>{
            if(resp.data){
              if(resp.data.error){
                throw new Error(resp.data.error)
              }
              if (resp.data.types){
                this.$store.commit('setObjectTo',{what:'typeOfResources',to:resp.data.types})
              } else if(resp.data.activities){
                this.$store.commit('setObjectTo',{what:'activities',to:resp.data.activities})
              }
            }else{
              this.$store.commit('setObjectTo',{what:'resources',to:resp.val() || {}})
            }
          })

          if(!prescription){
           this.$f7.views.main.router.navigate('/patient');
           this.$f7.dialog.close()
          return false
          }
           this.navigateToPrescription(prescription);
            this.$f7.dialog.close();
         }catch(error){
             this.$f7.dialog.close();
             console.log(error)
              this.$f7.dialog.alert((error.message,error.code)|| error)
              
         }
        },
      navigateToPrescription(prescription){ // sets 'focusedPrescription' to the selected prescription. if some of its parameters dont exist its gives then a default value

       let previousrisk=prescription.risk || {};
        if(!prescription.selectedResources){
          prescription.selectedResources={};
        }
        if(!prescription.selectedActivities){
          prescription.selectedActivities={};
        }
        let checkfornulls=['nextAppointment','followupfour','followupeight','followuptwelve','sent'];
        checkfornulls.forEach(oncheck => {
          if(!prescription[oncheck]){
            prescription[oncheck]=null;
          }
        });
       prescription.risk=this.loadDefaultRisk(previousrisk);
         this.$store.commit('setObjectTo',{what:'focusedPrescription',to:prescription});
         this.$f7.dialog.close();
          this.$f7.views.main.router.navigate('/patient');
      },
      createNewPrescription(){ // creates a new prescription with default values and stores it in database
          let neokey=this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.actualPatient.id).push().key;
          let ahora=this.$moment();
          let prescription={
            id: neokey,
            clinicid:this.onClinic,
            clinician: this.user.id,
            clinicianemail: this.user.email,
            clinicianName: this.user.displayName || this.focusedAccount.name || '',
            patientid: this.actualPatient.id,
            createdAt: Date.now(),
            globalProgressBar:0,
            selectedResources:{},
            selectedActivities:{},
            sent: null,
            nextAppointment:null,
            fups:{
              followupfour:{},
            followupeight:{},
            followuptwelve:{},
            }
            
          };
          prescription.fups.followupfour.date=ahora.add(4, 'w').format('MM/DD/YYYY');
          prescription.fups.followupeight.date=ahora.add(4, 'w').format('MM/DD/YYYY');
          prescription.fups.followuptwelve.date=ahora.add(4, 'w').format('MM/DD/YYYY');
          prescription.risk=this.loadDefaultRisk({});

          this.$f7.dialog.preloader('Creating new prescription...');
          this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.actualPatient.id+'/'+neokey).set(prescription).then(()=>{
            
               let focusedGuy=Object.assign({},this.actualPatient);
               if(!focusedGuy.prescriptions){
                 focusedGuy.prescriptions={};
               }
               focusedGuy.prescriptions[neokey]=prescription;
              this.$store.commit('setObjectTo',{what:'actualPatient',to:focusedGuy});
               this.$store.commit('updateObjectPropValue',{what:'patients',prop: this.actualPatient.id,neovalue:focusedGuy});

              this.$store.commit('setObjectTo',{what:'focusedPrescription',to:prescription});
              return this.goToPrescription()
             
          }).catch(error=>{
            this.$f7.dialog.close();
            this.$f7.dialog.alert(error.message,error.code);
            console.log(error)
          });


      },
      turnOffObservable(){
         this.obserablePatientInfo.off();
      }
    },
    watch:{ 
    onClinic(){ // obsers if the getter onClinic changes. if its true navigates back. used to get out of the edit pages if the user selects another clinic on the left pannel
        if(this.$f7.views.main.router.url=='/patienthist'){
            if(!this.focusedAccount.privx.clinician){
           this.$f7.views.main.router.navigate('/settings')
         }else{
         this.$f7.views.main.router.navigate('/patients')
       }
        } 
    }
  },
  };
</script>
<style>
.mormarginbotton{
margin-bottom: 60px!important;  
}
.onepax img{
  width: 55px;
}
</style>