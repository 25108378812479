<template>
  <f7-app :params="f7params">
     <f7-panel  left reveal theme-dark   :visible-breakpoint="960">
    <f7-view>
      <f7-page>
              
              <div class="userprofile">
            <img class="thumb" :src="user.photoURL ||'../static/icons/person.png'" alt="No photo" slot="inner" />
            <f7-block-title v-if="focusedAccount && (user.displayName ||focusedAccount.name)">{{user.displayName||focusedAccount.name}}</f7-block-title>
            <f7-block-title>{{user.email}}</f7-block-title>
              <f7-list class="menulatizq"  media-list  style="margin-bottom: 10px;">
                <f7-list-item
                 panel-close view=".view-main" 
                 :class="activeTab=='/settings'?'tabActive':''"
                @click="navigateTo('/settings')"
                 link="#"  
                 chevron-center
                  title="Settings"
                 footer="Accounts and preferences" >
            <f7-icon slot="media" material="settings" ></f7-icon>
            
          </f7-list-item>
              </f7-list>
              <template  v-if="myClinics.length>0 && Object.keys(clinics).length>0">
              <f7-block-title class="text-align-left">You are working on clinic...</f7-block-title>
              
              <f7-list  v-if="myClinics.length==1" >
                <f7-list-item class="white-space-normal ">
                <div  class="onClinicTitle" slot="title">{{onClinic?clinics[onClinic].name:'No Clinic'}}</div>
                </f7-list-item>
              </f7-list>
                <f7-list 
                v-else
                style="border-bottom: solid ;margin-bottom: 0px;">
                  <f7-list-item class="white-space-normal "
                  id="accordionClinics"
                   accordion-item
                   @accordionOpen="accordionToggle(true)"
                   @accordionClose="accordionToggle(false)"
                   >
                   <div class="onClinicTitle" slot="title">{{onClinic?clinics[onClinic].name:'Select a Clinic'}}</div>
                    <f7-accordion-content  class="nolink">
                      <f7-list 
                      media-list
                      class=" menuclinics " > 
                        <f7-list-item
                        class="onClinicTitle"
                         @click="selectedClinic(clinic)"
                     v-for="clinic in myClinics" :key="clinic"
                         link="#"  :title="clinics[clinic].name">
                         <f7-icon v-if="clinic==onClinic" slot="after" material="check"></f7-icon>
                         </f7-list-item>
                      </f7-list>
                    </f7-accordion-content>
                 </f7-list-item>
                </f7-list>
    </template>
    <f7-block v-else-if="Object.keys(clinics).length>0">Redeem your code on Settings</f7-block>
    <f7-block v-else>There are no clinics created to redeem a code</f7-block>
              </div>
              <f7-block v-if="Object.keys(user).length>0 && Object.keys(focusedAccount).length>0 && focusedAccount.inactive">This account was inactivated</f7-block>
        <f7-list 
        v-else-if="Object.keys(user).length>0 && Object.keys(focusedAccount).length>0"
        class="menulatizq no-margin-top ">

          <f7-list-item v-if="focusedAccount.privx.clinician"
          panel-close view=".view-main" :class="activeTab=='/patients'?'tabActive':''"
          @click="navigateTo('/patients')" link="#" title="Patients" footer="Patients on this clinic" >
            <f7-icon slot="media" material="face" ></f7-icon>
            
          </f7-list-item>

          <f7-list-item 
          v-if="focusedAccount.privx.createResources"
           :class="activeTab=='/resources'?'tabActive':''"
          @click="navigateTo('/resources')"
            panel-close
            view=".view-main"
            link="#"
            title="Community Resources"
            footer="Clinic specific community resources"
           >
            <f7-icon slot="media" material="map" ></f7-icon>
          </f7-list-item>
          <f7-list-item v-if="focusedAccount.privx.createAccounts"

           :class="activeTab=='/accounts'?'tabActive':''"
          @click="navigateTo('/accounts')"
            panel-close
            view=".view-main"
            link=""
            title="Accounts"
            footer="Manage this clinic's accounts"
            >
            <f7-icon slot="media" material="people_alt" ></f7-icon>
          </f7-list-item>
          
          <f7-list-item v-if="focusedAccount.privx.exportReports"

           :class="activeTab=='/exportReports'?'tabActive':''"
          @click="navigateTo('/exportReports')"
            panel-close
            view=".view-main"
            link=""
            title="Export Reports"
            footer="Download Excel with surveys answers"
            >
            <f7-icon slot="media" material="file_download" ></f7-icon>
          </f7-list-item> 
        </f7-list>
        <template v-if="user.globalPrivx|| user.god">
          <f7-block-title>App Global Items</f7-block-title>
        <f7-list>

          <f7-list-item 
          v-if=" user.god||user.globalPrivx.createDigitalResources"
           :class="activeTab=='/digitalResources'?'tabActive':''"
          @click="navigateTo('/digitalResources')"
            panel-close
            view=".view-main"
            link="#"
            title="Digital Resources"
            footer="available Digital resources"
           >
            <f7-icon slot="media" material="language" ></f7-icon>
            <div  v-if="Object.keys(pendingDigitalResources).length>0"
             slot="after">
               <f7-badge class="badgeTop" 
                color="red">{{Object.keys(pendingDigitalResources).length}}</f7-badge>
            </div>
          </f7-list-item>

           <f7-list-item v-if="user.god||user.globalPrivx.createActivities"
          :class="activeTab=='/activities'?'tabActive':''"
          @click="navigateTo('/activities')"
            panel-close
            view=".view-main"
            link="#"
            title="Activities"
            footer=" Activities for physical recommendations"
            >
            <f7-icon slot="media" material="pool" ></f7-icon>
          </f7-list-item>
          <f7-list-item  v-if="user.god||user.globalPrivx.createClinics "
           :class="activeTab=='/clinics'?'tabActive':''"
          @click="navigateTo('/clinics')"
            panel-close
            view=".view-main"
            link=""
            title="Clinics"
            footer="Manage prevent tool's clinics"
            >
            <f7-icon slot="media" material="business" ></f7-icon>
          </f7-list-item>
          
          <f7-list-item v-if="user.god" 
          :class="activeTab=='/typeOfResources'?'tabActive':''"
          @click="navigateTo('/typeOfResources')"
            panel-close
            view=".view-main"
            link="#"
            title="Type of Community Rscs"
            footer="To categorize the community resources"
            >
            <f7-icon slot="media" material="add_location" ></f7-icon>
          </f7-list-item>
          <f7-list-item  v-if="user.god "
           :class="activeTab=='/globalPrivx'?'tabActive':''"
          @click="navigateTo('/globalPrivx')"
            panel-close
            view=".view-main"
            link=""
            title="Global Privileges"
            footer="Manage users global privileges"
            >
            <f7-icon slot="media" material="vpn_key" ></f7-icon>
          </f7-list-item>
        </f7-list>
        </template>
        <f7-block-title class="versionFont margin-bottom">Version {{version}}</f7-block-title>
        <f7-button outline  @click="logomeout()" class="margin" >
            Log  Out
          </f7-button>
      </f7-page>
    </f7-view>
  </f7-panel>
    <f7-view name="landingview" main url="/landing/"></f7-view>
    <f7-login-screen :opened="loginScreen" id="my-login-screen">
      <f7-view>
        <f7-page login-screen>
          <f7-login-screen-title class="titulo no-margin-bottom">PREVENT</f7-login-screen-title>
          <f7-block class="text-align-center versionnum no-margin-top">V {{version}}</f7-block>
          <f7-list media-list class="logininputs">
            <f7-list-input v-if="loginStep===1" label="Enter your email" :value="email"  @keyup.native.enter="avanzaLogin()" type="email" @input="email=$event.target.value"></f7-list-input>
             <template v-else-if="loginStep===2">
              <f7-list-item class="titlebold" :title="email" header="Logging in as:">
                <f7-link class="lefarr" @click="loginStep=1" icon-material="arrow_back" slot="media"></f7-link>
              </f7-list-item>
              <f7-list-input label="Enter your PREVENT Tool password"  @keyup.native.enter="ingresarConemailpass()" type="password" @input="password=$event.target.value">
                <div slot="media"></div>
              </f7-list-input>
            </template>
            <template v-else-if="loginStep===3">
              <f7-list-item class="titlebold" :title="email" header="Creating account with:">
                <f7-link class="lefarr" @click="loginStep=1" icon-material="arrow_back" slot="media"></f7-link>
              </f7-list-item>
              <f7-list-input label="Create a password for PREVENT"  @keyup.native.enter="creteAccount()" type="password" @input="password=$event.target.value">
                <div slot="media"></div>
              </f7-list-input>
            </template>
          </f7-list>

           <f7-block>
             <f7-row>
               <f7-col><f7-link @click="forgotMyPass()" v-if="loginStep===2">Forgot your password?</f7-link></f7-col>
              <f7-col>
                <f7-button v-if="loginStep===1" class="botbackwhite" @click="avanzaLogin()" fill raised>Next step</f7-button>
                <f7-button v-else-if="loginStep===2" class="botbackwhite" @click="ingresarConemailpass()" fill raised>Login</f7-button>
                <f7-button v-else-if="loginStep===3" class="botbackwhite" @click="creteAccount()" fill raised>Create Account</f7-button>
                </f7-col>
             </f7-row>
            </f7-block>
       <f7-block class="text-align-center">
         <f7-block-title>Prevent Tool</f7-block-title>
         <p>Washington University in St. Louis</p>
         <f7-link target="_blank" external href="https://www.hicapps.cl"><p class="hicapps">Powered by HICAPPS</p></f7-link>
       </f7-block>
        </f7-page>
      </f7-view>
    </f7-login-screen>
  </f7-app>
</template>
<script>
import routes from "../js/routes.js";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      accordionOpened:false,
      privileges:{},
      loginStep: 1,
      create: false,
      autorizedId:'',
      createResource:false,
      privilegesObservable:null,
      // Framework7 Parameters
      f7params: {
        id: "cl.hicapps.preventtool", // App bundle ID
        name: "prevent tool", // App name
        theme: "md", // Automatic theme detection

        // App routes
        routes: routes,

        // Input settings
        input: {
          scrollIntoViewOnFocus: this.$device.cordova && !this.$device.electron,
          scrollIntoViewCentered: this.$device.cordova && !this.$device.electron
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false
        }
      },
      name: "",
      LastName: "",
      email: "",
      password: ""
    };
  },

  computed: {
    ...mapState([
      "activeTab",
      "loginScreen",
       "version",
       "user",
       "myAccounts",
       "clinics",
       "focusedAccount",
       "loginout",
       "pendingDigitalResources",
       "AYAVersion"
    ]),
    ...mapGetters(['myClinics','onClinic'])
   
  },
  methods: {
     emailIsValid (email) { // checks if the email format is correct
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      },
     avanzaLogin(){ 
        this.email=this.email.trim();
        if(!this.email){
          return this.$f7.dialog.alert('You must provide an email','email missing');
        }
        if(!this.emailIsValid(this.email)){
          return this.$f7.dialog.alert('You must provide an email that looks like USER@DOMAIN.XYZ','email format invalid');
        }
        this.$f7.dialog.preloader('Verifying...');
        this.versiExisto();
      },
    versiExisto(){ // checks if the email exists on the app
        let doiexist = this.$firebase.functions().httpsCallable('doiexist');
        doiexist({email:this.email}).then((ladata)=> {
          this.$f7.dialog.close();
          if(ladata.data){
            //user alrready exist, ask for password
            this.loginStep=2;
          }
          else{
            //user alrready exist, ask to create a password
            this.loginStep=3;
          }
        }).catch((error)=>{
          console.log('error en fx',error);
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
        });
      },
        accordionToggle(opened){ // open and close the accordian id="accordionClinics"
         this.accordionOpened=opened
        },
      closeAccordion(){ // close accordion id="accordionClinics"
        if(this.accordionOpened){
         this.$f7.accordion.close('#accordionClinics')
         this.accordionOpened=false
         }
      },
    creteAccount(){ // creates a user on the firebase app with email and password
     
          if(!this.email || !this.password){
          return this.$f7.dialog.alert('You must provide a password');
        }
        this.$f7.dialog.preloader('Creating...');
        this.$firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
        .catch((error)=> {
          this.$f7.dialog.close();
          if(error.code=='auth/email-already-in-use'){
            this.ingresarConemailpass();
          }
          else{
           
            this.$f7.dialog.alert(error.message,error.code);
          }
        });
    },
      forgotMyPass(){ // forgot my password functions from firebase. sends and recovery email
        this.$f7.dialog.confirm('You will receive a password-reset link at '+this.email,  ()=> {
          this.$f7.dialog.preloader('Sending e-mail with link...');
              this.$firebase.auth().sendPasswordResetEmail(this.email).then(()=> {
                this.$f7.dialog.close();
             
                this.$f7.dialog.alert('Check your inbox at '+this.email, 'email sent');
              }).catch((error)=> {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
              });
            });
      },
        ingresarConemailpass(){ // sign in with password on firebase 
        if(!this.email || !this.password){
          return this.$f7.dialog.alert('You must provide a password');
        }
        this.$f7.dialog.preloader('Logging in...');
        return this.$firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(()=>{
          this.password=null;
        }).catch((error)=> {
                this.$f7.dialog.close();
                 this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
            });
      },

      visteme(userinfopr,fromObs){ 
        return new Promise((resolve,reject)=>{
                let userinfo=this.user;
                 let promesAccounts=[];
                   userinfo =Object.assign({},userinfo,userinfopr || {});
                this.$store.commit("setObjectTo", {what: "user",to: userinfo}); 
                  let myClinicsObjects= this.$store.getters.myClinicsProfiles || [];
                   myClinicsObjects.forEach(unaclininfo => {
                    promesAccounts.push(this.$firebase.database().ref('accounts_by_clinic/'+unaclininfo.id+'/'+unaclininfo.account_id).orderByChild('inactive').equalTo(null).once('value'));
                  });
                return  Promise.all(promesAccounts).then(multinsaps=>{
                  let myAccounts={}
                  multinsaps.forEach(unsnap => {
                     let local_account=unsnap.val();
                     if(local_account.privx && !local_account.inactive){
                     myAccounts[local_account.id]=local_account;
                     }
                  });
                 this.$store.commit("setObjectTo", { what: "myAccounts", to: myAccounts });
                 if(Object.keys(this.focusedAccount).length===0 || !this.onClinic){
                 this.$store.commit('setObjectTo',{what:'focusedAccount',to: myAccounts[Object.keys(myAccounts)[0]] || {privx:{}} })
                 }
                 if(fromObs){
                   this.$store.commit('setObjectTo',{what:'focusedAccount',to: myAccounts[this.focusedAccount.id]|| {privx:{}}})
                 }
                resolve(myAccounts)
        }).catch(error=>{
          reject(error)
        });

        });
      },
     iniciaLogin() { // turns on and obserbable on the user. if its created and verified can enter the app
      this.$f7.dialog.preloader("Loading...");
      this.$store.state.firstPreloader=true
      this.$firebase.auth().onAuthStateChanged(user => {
        if (user) {
          if(!user.emailVerified){
            return user.sendEmailVerification().then(()=> {
               this.$firebase.auth().signOut();
                 this.loginStep=1;
              this.$f7.dialog.alert('Check your inbox at '+this.email+' to validate your account before being able to login.','Validate');
            }).catch((error)=> {
               this.$f7.dialog.close();
               console.log(error)
               this.loginStep=1;
              this.$f7.dialog.alert(error.message,error.code);
          
            });
          }
          else{
            if(!this.AYAVersion){
            const list=['simon@hicapps.cl','mohrmann@wustl.edu','henryj@wustl.edu','Katie.gettinger@wustl.edu','sauerburgerk@wustl.edu','leslieglover@wustl.edu','Hayashi_r@wustl.edu','sandheinrich@wustl.edu']
              if(list.includes(user.email)){
               return this.logomeout(user.email)
               
               } 
               }
                this.$store.commit("setObjectTo", {what: "user",to: {
                  id:user.uid,
                  email:user.email,
                  displayName:user.displayName,
                  photoURL:user.photoURL
                  }});
              let promesax=[
                this.$firebase.database().ref('users/' + user.uid).once('value'),
                 this.$firebase.database().ref('info/').once('value'),
                 this.$firebase.database().ref('clinics/').once('value')
               ];
            return Promise.all(promesax).then(multisnaps=>{
               let local_info = multisnaps[1].val() || {};
                  this.$store.commit("setObjectTo", { what: "info", to: local_info });
                  let local_clinics = multisnaps[2].val() || {};
                  this.$store.commit("setObjectTo", { what: "clinics", to: local_clinics });
                  let thisUser=multisnaps[0].val() || {}
                  if(thisUser.god||(thisUser.globalPrivx&&thisUser.globalPrivx.createDigitalResource)){
                  this.getPendingDigitalResources()
                    }
              return this.visteme(thisUser)
            }).then(myAccounts => {
               let hasActive=false
               Object.values(myAccounts).forEach(account=>{!account.inactive?hasActive=true:null})
                  if(hasActive && Object.keys(myAccounts).length>0 ) {
                    if (this.focusedAccount.privx.clinician) {
                    this.$f7.views.main.router.navigate('/patients')
                    this.$store.commit('setWhatTo',{what:'activeTab',to:'/patients'})
                  }else if (this.focusedAccount.privx.createResources) {
                     this.$store.commit('setWhatTo',{what:'activeTab',to:'/resources'})
                   this.$f7.views.main.router.navigate('/resources')
            }else if (this.focusedAccount.privx.createAccounts) {
              this.$store.commit('setWhatTo',{what:'activeTab',to:'/accounts'})
            this.$f7.views.main.router.navigate('/accounts')
            }
                }else{
                  this.$f7.views.main.router.navigate('/settings')
                }
                this.$store.commit("setWhatTo", {
                  what: "loginScreen",
                  to: false
                });
                 
                this.encenderObervablePrivilgios(user);
              }).then()
              .catch(error => {
                console.log(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
              });
          }
        } else {
          this.password=null;
          this.loginStep=1;
          this.$store.commit("setWhatTo", { what: "loginScreen", to: true });
          if(!this.loginout){
            this.$f7.dialog.close();
          }
        }
      });
    },
    encenderObervablePrivilgios(user){ // turns on an observable to the node from database users/"+user.uid
     this.privilegesObservable=this.$firebase.database().ref("users/"+user.uid);
     this.privilegesObservable.on('value',snap=>{
            return this.visteme(snap.val() || {},'fromObs')
      });
    },
    async getPendingDigitalResources(){
      try{
      let prePending=await this.$firebase.database().ref('pendingDigitalResources').once('value')
        this.$store.commit("setObjectTo", { what: "pendingDigitalResources", to: prePending.val() || {}});
    }catch(error){
      console.log(error)
      this.$f7.dialog.alert(error.message)
    }
    },
     logomeout(account){ // logs out
       this.$f7.dialog.preloader('Login out...')
       this.$store.state.loginout=true
       if(this.privilegesObservable){
         this.privilegesObservable.off();
       }
       this.password=null;
       
        this.$firebase.auth().signOut().then(()=> {
          if(account) {
            this.$f7.dialog.close()
            this.$f7.dialog.close()
            this.$f7.dialog.confirm(`Now your account ${account} should use aya.preventtool.com, Do you want to navigate there?`,()=>{
               window.location.replace('https://aya.preventtool.com');
            })
           
          } 
          else window.location.reload()
        }).catch((error)=> {
          this.$f7.dialog.alert(error.message,error.code)
          console.log(error);
        });
      },
      navigateTo(link){ // navigates into the selected left pannel item
        this.closeAccordion()
        if(this.$f7.views.main.router.url!=link){
           this.$store.commit('setWhatTo',{what:'activeTab',to:link})
          this.$f7.views.main.router.navigate(link);
        }
      },
      selectedClinic(clinic){ // swich clinics to working on
        if(this.onClinic!=clinic){
        this.$store.commit('setObjectTo',{what:'resources',to:{}})
        this.$store.commit('setObjectTo',{what:'patients',to:{}})
        this.$store.commit('setObjectTo',{what:'accountsAll',to:{}})
        this.$store.commit('setObjectTo',{what:'focusedAccount',to:this.myAccounts[this.user.privx_by_clinic[clinic].account_id]})
         this.closeAccordion()
      
      }

      }
  },
  mounted() {
    this.$f7ready(f7 => {
      // Init cordova APIs (see cordova-app.js)
  
      // Call F7 APIs here
      //AAUI
      this.iniciaLogin();
    });
  }
};
</script>

<style>
.logininputs input{
  padding-left: 8px!important;
    border-radius: 9px;
    border-color: #1684fe;
    border: 2px solid #0a7cff;
}
#my-login-screen .login-screen-content{
  background: none!important;
  margin-top: 30px;
}
#my-login-screen .page.page-current{
     background: url('../static/back.jpg');
  background-repeat:no-repeat;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-position:center;
}
.titulo{
   color:#0a036d;
   font-size:40px;
}

.versionnum{
  margin-top: 0px;
    color: #0a016d;
}


.hicapps{
  color: #8e8e8e;
    font-size: 13px;
    font-style: italic;
}
.lefarr{
  margin:0 auto!important;
}

.lefarr .icon{
  font-size:40px;
}
.titlebold .item-title{
  font-weight: bold!important;
}
.menulatizq .icon{
  margin: 0 auto!important;
}
.tabActive{
  background-color: white;
  color:black
}
.logininputs .item-input-wrap input{
  background:#FFF;
}
.svgris {
  background: #dadada;
}

.thumb{
    width: 100px;
    height: 100px;
    border-radius: 50px;
    
}
.userprofile{
  text-align: center;
  margin-top:20px
}
.userprofile .list .item-link .item-inner:before{
  content: none;
}
.nolink .media-list .item-link .item-title-row:before, li.media-item .item-link .item-title-row:before{
  content: none;
}
.menuclinics  ul {
background:black !important
}
.white-space-normal .onClinicTitle{
  white-space:normal;
  font-size: 90% !important;
  text-align: left !important;
}
.item-footer{
  text-align: left;
  color:unset !important
}
.media-list .item-media, li.media-item .item-media{
  align-self:center !important
}
.versionFont{
  font-weight: normal;
    font-size: small;
    text-align:center;

}
</style>
