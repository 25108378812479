<template>
<f7-page bg-color="white"  @page:beforein="loadData()" >
<f7-navbar>
  <f7-nav-left>
    <f7-link panel-open="left" icon-material="menu"></f7-link>
  </f7-nav-left>
    <f7-nav-title
    title="Accounts"
    :subtitle="clinics[onClinic]?'On '+clinics[onClinic].name:''"
    ></f7-nav-title>
    <f7-nav-right>
      <f7-link @click="loadData(true)" icon-material="refresh"></f7-link>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchAccount"
          icon-material="search"
        ></f7-link>
      </f7-nav-right>
        <f7-searchbar
        class="searchAccount"
        expandable
        search-container=".search-list"
        search-in=".item-title, .item-subtitle, .item-text"
        :disable-button="!$theme.aurora"
      ></f7-searchbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found"></f7-list-item>
    </f7-list>

<f7-list  media-list class="search-list searchbar-found no-margin-top fixiconresorlist">
    <f7-list-item
    v-for="account in sortedAccounts" :key="account.id"
    :class="account.inactive?'inactivated':''"
    link="#"
    @click="editAccount(account)"
    v-show="(user.god )||(account.god && user.id==account.user)|| (!user.god  && !account.god)"
       :title="account.name"
       :subtitle="account.god||account.user==user.id?'Your Account':account.code?account.email?account.email:'':account.email?'Redeemed by '+account.email:''"
        >
        <div slot="header"
        >
        <span v-if="!account.privx">No privileges</span>
        <span v-else
        >
        {{Object.keys(account.privx).join(', ')}}
        </span>
        </div>
        <div v-if="!account.god" slot="text" >
          <span v-if="account.inactive">Inactive account</span>
        <f7-link v-else-if="!account.user" @click="copyCode($event,account.code)" ><span>Code: {{account.code}}</span>
       </f7-link>
       </div><f7-icon slot="after" material="edit" ></f7-icon>
    </f7-list-item>
</f7-list>
<f7-fab @click="createAccount()" position="right-bottom" slot="fixed" color="light-blue">
    <f7-icon material="add"></f7-icon>

  </f7-fab>

</f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
    data(){
        return{
          diction:{
            physical:'Physical Activity Resources',
            diet:'Food Resources',
          }
        }
    },
    // qui estoy   si navego a inactive accout q al cambiar de clinica q  navegue de vuelta
  watch:{
    onClinic(){
       if(this.focusedAccount.inactive){
          return this.$f7.views.main.router.navigate('/inactiveAccount')
        }
        if(this.activeTab=='/accounts'){
          if(!this.focusedAccount.privx.createAccounts){
            this.$f7.toast.show({
            text: 'Account without privileges on accounts',
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
          return this.$f7.views.main.router.navigate('/settings')  
        }
        this.$f7.dialog.preloader('Loading accounts...')
              this.$firebase.database().ref('accounts_by_clinic/'+this.onClinic).once('value').then(snap=>{
               let local_accounts=snap.val() || {}
               this.$store.commit('setObjectTo',{what:'accountsAll',to:local_accounts})
               this.$f7.dialog.close();
              }).catch(error=>{
                 this.$f7.dialog.close();
               this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
              })
    }
    }
  },
    computed:{
      ...mapState(['typeOfResources','clinics','accountsAll','activeTab','user','focusedAccount','firstPreloader']),
      ...mapGetters(['sortedAccounts','onClinic']),
      getPrivxs(privxs){
        return Object.keys(privxs)
      },
    },
    methods:{
      copyCode(event,code){ // copies the created code to the clipboard 
  let el = document.createElement('textarea');
  el.value = code;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  let toastCenter = this.$f7.toast.show({
          text:'Code copied!',
          position: 'center',
          closeTimeout: 2000,
          destroyOnClose: true
        });
        event.stopPropagation()
      },
        createAccount(){ // sets to null the accountToEdit on the state, and navigates to createAccount
           this.$store.commit('setObjectTo',{what:'accountToEdit',to:null})
            this.$f7.views.main.router.navigate("/createAccount/");
        },
        editAccount(account){ // sets the accountToEdit on the state and navigates to createAccount
          this.$store.commit('setObjectTo',{what:'accountToEdit',to:account})
           this.$f7.views.main.router.navigate("/createAccount/");
           
        },
        loadData(force=false){ // gets from database all the accounts for this clinic
          if(this.focusedAccount.inactive){
          return this.$f7.views.main.router.navigate('/inactiveAccount')
        }
          let potencialCalls=[];
          if(Object.keys(this.accountsAll).length==0 || this.accountsAll[Object.keys(this.accountsAll)[0]].clinicid!=this.onClinic || force ){
             if(!this.firstPreloader){
          this.$f7.dialog.preloader('Loading Accounts...');
          }
          this.$store.state.firstPreloader=false
              this.$firebase.database().ref('accounts_by_clinic/'+this.onClinic).once('value').then(snap=>{
               let local_accounts=snap.val() ||{}
               this.$store.commit('setObjectTo',{what:'accountsAll',to:local_accounts})
               this.$f7.dialog.close();
              }).catch(error=>{
                 this.$f7.dialog.close();
                 console.log(error)
                this.$f7.dialog.alert(error.message,error.code);
              })
         }
        }
    }
}
</script>
<style>
.fixiconresorlist .item-media .icon{
  margin: 0 auto;
}
.inactivated{
  color: #b1b1b1;
}
</style>