<template>
  <f7-page class="bggris"  name="createnewactivity">
    <f7-navbar :title="focusedActivity?focusedActivity.name:'Create New Activity'" :subtitle="focusedActivity?Object.keys(activity.actLevels).join(', '):null"  back-link="Back">
      <f7-nav-right v-if="focusedActivity">
               <f7-link @click="attemptInactivate()" icon-material="delete"></f7-link>
      </f7-nav-right>
    </f7-navbar>

<f7-list class="margin-bottom"  no-hairlines-md>
    <f7-list-input 
    @input  ="activity.name=$event.target.value"
    :value="activity.name"
    label="Name"
    type="text"
    placeholder="Activity`s name"
    clear-button
    @change="checkNameExists($event.target.value)"
  >
  </f7-list-input>

  <f7-list-item divider title="Select activity level categories"></f7-list-item>
  <f7-list-item 
    title="strength"
    >
    <f7-toggle
     :checked="activity.actLevels.strength"
    @toggle:change="selectedLevel('strength',$event)"
    ></f7-toggle>
    </f7-list-item>
    <f7-list-item 
    title="light"
    >
    <f7-toggle
     :checked="activity.actLevels.light"
    @toggle:change="selectedLevel('light',$event)"
    ></f7-toggle>
    </f7-list-item>
    <f7-list-item 
    title="moderate"
    >
    <f7-toggle
     :checked="activity.actLevels.moderate"
    @toggle:change="selectedLevel('moderate',$event)"
    ></f7-toggle>
    </f7-list-item>
    <f7-list-item 
    title="vigorous"
    >
    <f7-toggle
     :checked="activity.actLevels.vigorous"
    @toggle:change="selectedLevel('vigorous',$event)"
    ></f7-toggle>
    </f7-list-item>
   



  <f7-list-item divider title="Select activity target group(s)"></f7-list-item>
  <f7-list-item 
    class="seudotitle clp_green"
    title="Green"
    >
    <f7-toggle
     :checked="activity.groups.green"
    @toggle:change="selectedGroup('green',$event)"
    ></f7-toggle>
    </f7-list-item>
    <f7-list-item 
     class="seudotitle clp_yellow"
    title="Yellow"
    >
    <f7-toggle
     :checked="activity.groups.yellow"
    @toggle:change="selectedGroup('yellow',$event)"
    ></f7-toggle>
    </f7-list-item>
    <f7-list-item 
     class="seudotitle clp_red"
    title="Red"
    >
    <f7-toggle
     :checked="activity.groups.red"
    @toggle:change="selectedGroup('red',$event)"
    ></f7-toggle>
    </f7-list-item>
</f7-list>
<f7-block>
  <f7-row>
    <f7-col><f7-button @click="$f7.views.main.router.back()" outline color="red"><f7-icon material="chevron_left"></f7-icon> Cancel</f7-button></f7-col>
    <f7-col><f7-button @click="saveActivity()" fill raised color="green"><f7-icon material="save"></f7-icon> Save</f7-button></f7-col>
  </f7-row>
</f7-block>





  </f7-page>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
export default {
  data(){
    return{
    activity:{
       id:'',
      actLevels:{
        strength: null,
        light: null,
        moderate: null,
        vigorous: null
      },
      groups:{
          green:null,
          yellow:null,
          red:null
      },
      name:''
    }
    }
  },
  created(){
    if(this.focusedActivity){
       this.activity=Object.assign({},this.activity,this.focusedActivity);
    }
  },
  computed:{
    ...mapState(['activities','focusedActivity']),
    ...mapGetters(['onClinic'])
  },
  methods:{
    checkNameExists(name){ // checks if this activity name already exist active or inactive. if it doesn't it creates de new activity, else let the user know the duplication and do not save the new activity
    if(this.activity.name){
      let exist=Object.values(this.activities).find(activity=>activity.name.toLowerCase()===this.activity.name.toLowerCase())
             if(exist){ 
               if(exist.inactive){
               return this.$f7.dialog.confirm('Already exists an activity with that name but it is disactivated, do you want to activate it?','Activate activity',(()=>{
                this.$firebase.database().ref('activities/'+exist.id+'/inactive').set(null).then(()=>{
                
                this.$store.commit('setObjectTo',{what:'focusedActivity',to:this.activities[exist.id]})
                this.$store.commit('updateObjectPropValue',{what:'focusedActivity',prop: 'inactive',neovalue:null});
                this.activity=Object.assign({},this.activity,this.focusedActivity);
                let local_activities=Object.assign({},this.activities);
              local_activities[this.focusedActivity.id]=this.focusedActivity;
              this.$store.commit('setObjectTo',{what:'activities',to:local_activities});
               }).catch(error=>{
                 this.$f7.dialog.alert(error.message,error.code)
                 console.log(error)
               })
              }),(()=>{
                this.activity.name=''
              })
               )
           }else{
        this.activity.name=''
            return this.$f7.dialog.alert('This activity already exists')
            
           }
             }
             }
    },
    attemptInactivate(){ // opens a framework7 prompt, if the answer is yes it calls the functions to inactivate the activity
      this.$f7.dialog.confirm('Are you sure you want to inactivate this activity?',()=> {
        this.inactivateActivity();
      });
    },
    inactivateActivity(){ // Inactivates the activity by setting to true the node 'inactive' on the activity
         this.$f7.dialog.preloader('Inactivating activity...');
          this.$firebase.database().ref('activities/'+this.focusedActivity.id+'/inactive').set(true).then(()=>{
            let local_activity=Object.assign({},this.focusedActivity)
            local_activity.inactive=true
            this.$store.commit('updateObjectPropValue',{what:'activities',prop: this.focusedActivity.id,neovalue:local_activity});
            this.$f7.dialog.close();
            this.$f7.views.main.router.back();
          }).catch(error=>{
            console.log(error);
            this.$f7.dialog.close();
            this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
          });
    },
      saveActivity(){ // calls a backend functions to store the new or edited activity
          if(this.activity.name=='') return this.$f7.dialog.alert('A name is required!');
          
           if ( !this.activity.actLevels.strength && !this.activity.actLevels.light && !this.activity.actLevels.moderate && !this.activity.actLevels.vigorous )return this.$f7.dialog.alert('At least one activity level is required!');
           if ( !this.activity.groups.green && !this.activity.groups.yellow && !this.activity.groups.red )return this.$f7.dialog.alert('At least one group is required!');
              this.$f7.dialog.preloader(this.focusedActivity?'Editing activity...':'Creating new activity...');

             let call=this.$firebase.functions().httpsCallable('setActivity')
      call({activity:this.activity}).then((ledata)=>{
        if(ledata.data.error){
          throw new Error(ledata.data.error)
        }
        if(!this.activity.id) this.activity.id=ledata.data.id
        let local_activities=Object.assign({},this.activities);
        local_activities[ledata.data.id]=this.activity;
        this.$store.commit('setObjectTo',{what:'activities',to:local_activities});
        let toastCenter = this.$f7.toast.create({
          text: this.activity.name+' saved',
          position: 'center',
          closeTimeout: 1500,
          destroyOnClose:true
        });
        toastCenter.open();
        this.$f7.dialog.close();
        this.$f7.views.main.router.back();
      }).catch((error)=>{
        this.$f7.dialog.close();
        console.log(error)
        this.$f7.dialog.alert((error.message,error.code)|| error);
      })
    
      },
      selectedGroup(group,checked){ // if checked it sets to true or null the selected group on the activity
                                    //payload: group: the risk group to edit on the activity (string), checked:true or null
          if(checked){
            this.activity.groups[group]=true;
          }else{
             this.activity.groups[group]=null;
          }
      },
      selectedLevel(level,checked){// if checked it sets to true or null the selected intensity level on the activity
                                    //payload: level: the intensity level to edit on the activity (string), checked:true or null
          if(checked){
            this.activity.actLevels[level]=true;
          }else{
             this.activity.actLevels[level]=null;
          }
      }
     
  },
  watch:{
    onClinic(){ // obsers if the getter onClinic changes. if its true navigates back. used to get out of the edit pages if the user selects another clinic on the left pannel
        if(this.$f7.views.main.router.url=='/createActivity/'){
            this.$f7.views.main.router.navigate('/activities/')
        } 
    }
  },
}
</script>
<style >
.bggris{
  background:#e6e6e6;
}
.seudotitle .item-title{
  padding: 3px 7px;
  font-weight: bold;
  margin-right: 3px;
  border-radius: 15px;
}
.seudotitle.clp_green .item-title{
  color: green;
  background: #dfffe8;
}
.seudotitle.clp_yellow .item-title{
  color: #736600;
  background: #fff8be;
}
.seudotitle.clp_red .item-title{
  color: #ff0a0a;
  background: #ffdfdf;
}
</style> 