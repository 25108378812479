import patienthist from '../pages/patienthist.vue';
import Landing from '../pages/landing.vue';
import Patient from '../pages/patient.vue';

import NotFoundPage from '../pages/404.vue';

import patients from '../pages/patients.vue';
import map from '../pages/map.vue';

import resources from '../pages/resources.vue';
import createResource from '../pages/createResource.vue';
import typeOfResources from '../pages/typeOfResources.vue';
import createTypeOfResource from '../pages/createTypeOfResource.vue';
import editPatient from '../pages/editPatient.vue';
import patientReported from '../pages/patientReported.vue';
import activities from '../pages/activities.vue';
import createActivity from '../pages/createActivity.vue';
import selectActivities from '../pages/selectActivities.vue';
import clinics from '../pages/clinics.vue'; 
import createclinic from '../pages/createclinic.vue';
import accounts from '../pages/accounts.vue';
import createAccount from '../pages/createAccount.vue';
import settings from '../pages/settings.vue';
import selectClinic from '../pages/selectClinic.vue';
import inactiveAccount from '../pages/inactiveAccount.vue';
import pdf from '../pages/pdf.vue';
import globalPrivx from '../pages/globalPrivx.vue';
import editGlobalPrivx from '../pages/editGlobalPrivx.vue';
import digitalResources from '../pages/digitalResources.vue';
import createDigitalResource from '../pages/createDigitalResource.vue';
import selectDigitalResources from '../pages/selectDigitalResources.vue';
import pendingDigitalResources from '../pages/pendingDigitalResources.vue';
import exportReports from '../pages/exportReports.vue';

var routes = [
  {
    path: '/exportReports',
    component: exportReports,
  },
  {
    path: '/pendingDigitalResources',
    component: pendingDigitalResources,
  },
  {
    path: '/selectDigitalResources/',
    component: selectDigitalResources,
  },
  {
    path: '/createDigitalResource/:id',
    component: createDigitalResource,
  },
  {
    path: '/digitalResources',
    component: digitalResources,
  },
  {
    path: '/editGlobalPrivx/:uid',
    component: editGlobalPrivx,
  },
  {
    path: '/globalPrivx',
    component: globalPrivx,
  },
  {
    path: '/pdf',
    component: pdf,
  },
  {
    path: '/inactiveAccount',
    component: inactiveAccount,
  },
  {
    path: '/selectClinic',
    component: selectClinic,
  },
  {
    path: '/settings',
    component: settings,
  },
  {
    path: '/createAccount',
    component: createAccount,
  },
  {
    path: '/accounts',
    component: accounts,
  },
  {
    path: '/createclinic',
    component: createclinic,
  },
  {
    path: '/clinics',
    component: clinics,
  },
  {
    path: '/activities',
    component: activities,
  },
  {
    path: '/selectactivities',
    component: selectActivities,
  },
  {
    path: '/createActivity',
    component: createActivity,
  },
  {
    path: '/patientReported',
    component: patientReported,
  },
  {
    path: '/editPatient',
    component: editPatient,
  },
  {
    path: '/createTypeOfResource',
    component: createTypeOfResource,
  },
  {
    path: '/typeOfResources',
    component: typeOfResources,
  },
  {
    path: '/createResource',
    component: createResource,
  },
  {
    path: '/resources',
    component: resources,
  },
  {
    path: '/map',
    component: map,
  },
 
  {
    path: '/landing',
    component: Landing,
  },
  {
    path: '/patients',
    component: patients,
  },
  {
    path: '/patient',
    component: Patient,
  },
  {
    path: '/patienthist',
    component: patienthist,
  },

  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
