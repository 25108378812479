<template>
<div>
  <f7-block class="containerCreatedon">
  <span class="createdOn" >Created on {{$moment(focusedPrescription.createdAt).format('LLL')}}</span>
  </f7-block>
      <f7-card class="no-margin-bottom borderradiustop">
        <f7-card-header class="bold uppercaseme">
          <f7-list>
      <f7-list-item class="neurita" title="Physical Activity Recommendations"> 
        <f7-toggle v-if="modifiable" slot="media" :checked="!focusedPrescription.physicalRecomInact" 
        @toggle:change="inactivateRecommendation($event,'physicalRecomInact')"></f7-toggle>
      </f7-list-item>
    </f7-list>
    </f7-card-header>
        <f7-card-content v-if="!focusedPrescription.physicalRecomInact"
        :padding="false" class="fixblock ">
        <template v-if="focusedPrescription.physicalRecomByClinician">
        <f7-block  class="recomInfo text-align-center" >
            <div>
               <f7-icon color="gray" material="info" class="margin-right" />
               These recommendations were provided by the clinician, they won't change automatically
            </div>
              <div>
                 <f7-link @click="setPhysicalRecomAuto()">
                   <f7-icon class="margin-right" material="restore"/>Reset to automatic values</f7-link>
              </div>
               </f7-block>
               <f7-block
                 class="margin-top padding no-margin-bottom-force">
            <span><strong>{{physicatActivitiesRecommnedation.week1_4.title}}</strong>
            {{focusedPrescription.physicalRecomByClinician.week1_4}}
            </span>
         <f7-link @click="openEditPhysicalRecom()"  class="float-right">
            <f7-icon material="edit"/></f7-link>
          </f7-block>
          </template>
          <f7-block class="padding-bottom" v-else-if="!lerisk.bmi.active">
           <f7-icon class="text-color-yellow" material="warning"></f7-icon> There is no BMI information on your profile, or it is disabled.
          </f7-block>
                <f7-block v-else-if="lerisk.bmi.active "
                 class="no-margin-bottom-force padding-bottom-half">
            <span><strong>{{physicatActivitiesRecommnedation.week1_4.title}}</strong>
            {{physicatActivitiesRecommnedation.week1_4.str}}
            </span>
         <f7-link @click="openEditPhysicalRecom()"  class="float-right">
            <f7-icon material="edit"/></f7-link>
          </f7-block>
        </f7-card-content>
      </f7-card>
      <f7-popup  :opened="physicalRecompopup" 
      @popup:closed="physicalRecompopup = false">
        <f7-page>
          <f7-navbar title="Editing physical recommendations" 
          class="navSubtitle"
          :subtitle="focusedPrescription.physicalRecomByClinician?'Clinician recommendations':'Automatic recommendations'">
            <f7-nav-right><f7-link popup-close>Cancel</f7-link></f7-nav-right>
          </f7-navbar>
           <f7-block class="no-margin">
             <f7-card  v-for="(one,i) in physicatActivitiesRecommnedation" :key="i">
               <f7-card-header class="margin"><strong>{{one.title}}:</strong></f7-card-header>
               <f7-card-content class="no-padding">
                 <f7-list class="no-margin">
                <f7-list-input
                type="textarea"
                :value="localPhysicalRecommendation[i]"
                @input="localPhysicalRecommendation[i]=$event.target.value.trim()"
                clear-button
                >
                </f7-list-input>
              </f7-list>
               </f7-card-content>
               <f7-card-footer >
                 <div></div>
                 <f7-button @click="setLocalPhysicalRecom(i)" >reset</f7-button>
               </f7-card-footer>
             </f7-card>
              
          </f7-block>
          <f7-row class="margin text-align-center">
            <f7-col></f7-col>
            <f7-col><f7-button  @click="savePhysicalRecom()" fill>Save changes</f7-button></f7-col>
          </f7-row>
        </f7-page>
      </f7-popup>
      <f7-card v-if="lerisk.bmi.active && !focusedPrescription.physicalRecomInact" class="no-margin-top no-margin-bottom borderradiustop">
        <f7-card-header class="bold uppercaseme">Motivation to change</f7-card-header>
      <f7-card-content >
        <f7-block class="margin-top">How interested are you  in making changes to your <strong>physical activity</strong> in order to be healthier?</f7-block>
        <f7-list class="margin" simple-list>
          <f7-list-item class="noWrap">
            <f7-list-item-cell class="flex-shrink-3">
              <f7-range
                class="rangegrande"
                @range:changed="changeWilling($event,'willingPhysical','physical')"
                :min="1"
                :max="5"
                :scale-steps="4"
                :step="1"
                :value="lerisk.physical.willingPhysical"
                :scale="true"
                :format-scale-label="(value) => willingScaleLabels(value)"
                :color="willingBarColor(lerisk.physical.willingPhysical?lerisk.physical.willingPhysical:3)"
                :disabled="!modifiable"
              ></f7-range>
            </f7-list-item-cell>
          </f7-list-item>
        </f7-list>
      </f7-card-content>
    </f7-card>
<f7-card v-if="lerisk.bmi.active && !focusedPrescription.physicalRecomInact" class="no-margin-top borderradiusbottom">
        <f7-card-header class="bold uppercaseme">Activities <f7-button v-if="modifiable" href="/selectActivities" raised>Add activities</f7-button></f7-card-header>
        <f7-card-content   class="fixblock chiperholder">
          <p v-if="selectedActivities.length==0">No activities selected</p>
              <f7-chip v-for="activity in selectedActivities" :key="activity.id" color="blue" :text="activity.name" :deleteable="modifiable" @click="modifiable?deleteActivyChip(activity):null"></f7-chip>
        </f7-card-content>
        <f7-card-footer v-if=" selectedActivities.length==0 && modifiable" class="footergris ">
          <span></span><span>Add activities here <f7-icon material="arrow_upward"></f7-icon></span>
        </f7-card-footer>
 </f7-card>
 <f7-card class="no-margin-bottom borderradiustop">
        <f7-card-header class="bold uppercaseme"><f7-list>
      <f7-list-item class="neurita" title="Food intake Recommendations"> 
        <f7-toggle v-if="modifiable" slot="media" 
        :checked="!alldietRecomInact" 
        :disabled="allDiettoggleDisabled"
        @toggle:change="inactivateRecommendation($event,'allDiet')"></f7-toggle>
      </f7-list-item>
    </f7-list></f7-card-header>
    <template v-if="!alldietRecomInact">
        <f7-card-content  class="fixblock padding-bottom"
        v-if="lerisk.diet.active && ((lerisk.diet.dietQ1=='Usually/Often' && lerisk.diet.dietQ2=='Usually/Often' && lerisk.diet.dietQ3=='Usually/Often' && lerisk.diet.dietQ4=='Rarely/Never' && lerisk.diet.dietQ5=='Rarely/Never'))"
        >
        <f7-block class="padding-bottom" >
            <f7-icon class="text-color-green" material="thumb_up"></f7-icon> Good job. There are no behaviour change recommendations for you.
          </f7-block>
        </f7-card-content>
        <f7-card-content :padding="false" class="fixblock padding-bottom">
          <div class="list accordion-list itemlinkuno" >
          <ul>
            <li class="accordion-item" v-for="(q,index) in obesityRecomendationsProperties"  :key="index"
            :class="!focusedPrescription[`${q}RecomInact`]?'accordion-item-closed':''"
            >
              <div class="item-content">
                    <f7-toggle 
                    v-if="modifiable"
                     class="item-media" :checked="!focusedPrescription[`${q}RecomInact`]" 
                    @toggle:change="inactivateRecommendation($event,`${q}RecomInact`)"></f7-toggle>
                <a v-if="!focusedPrescription[`${q}RecomInact`]"
                 class=" item-inner item-link">
                  <div  class="list-title " >
                    <f7-row >
                    <span>{{dietDiction[q]}}</span>
                    <span><f7-icon  material="keyboard_arrow_down" color="gray" /></span>
                    </f7-row>
                    </div>
                </a>  
                <div v-else class="item-inner display-block disabled">
                  <div class="list-title ">
                    <f7-row>
                      <span>{{dietDiction[q]}}</span>
                    </f7-row>
                    
                    </div>
                </div>
              </div>
                  <f7-accordion-content v-if="!focusedPrescription[`${q}RecomInact`]">
              <f7-block>
                <p>
                  {{info.diet[q].goal}}
                </p>
              </f7-block>
              <f7-block>
                  <f7-card
             v-for="box in sortame(info.diet[q].boxes)"
            :key="box.header"
            class="indicafood  "
            :class="{bverde:(box.id=='Anytime'),bamarillo:(box.id=='Sometimes')}"
          >
            <f7-card-content  >
              <div  >
                <f7-card-header class="display-block">
                  <img :src="'../static/traffic_'+box.id+'.png'" class="traffic" alt="light signal" />
                <strong>{{box.id}}</strong> 
                  <br />
                  <div :style="{padding:'0px',fontSize:'14px'}">{{box.header}}</div>
                </f7-card-header>
                <br />
                <f7-block :style="{padding:'0px',fontSize:'14px'}" class="display-block">
                  <f7-row>
                    <f7-col
                      v-for="item in box.list"
                      :key="item"
                      :width="item.length>30?'50':'50'"
                      style="padding-bottom:4px"
                    >{{item}}</f7-col>
                  </f7-row>
                </f7-block>
              </div>
            </f7-card-content>
          </f7-card>
              </f7-block>
            </f7-accordion-content>
          </li>
          </ul>
          </div>
        </f7-card-content>
        </template>
 </f7-card>
  <f7-card v-if="!alldietRecomInact" class="no-margin-top no-margin-bottom borderradiustop">
        <f7-card-header class="bold uppercaseme">Motivation to change</f7-card-header>
      <f7-card-content >
        <f7-block class="margin-top">How interested are you  in making changes to your <strong>eating habits</strong> in order to be healthier?</f7-block>
        <f7-list class="margin" simple-list>
          <f7-list-item class="noWrap">
            <f7-list-item-cell class="flex-shrink-3">
              <f7-range
                class="rangegrande"
                @range:changed="changeWilling($event,'willingDiet', 'diet')"
                :min="1"
                :max="5"
                :scale-steps="4"
                :step="1"
                :value="lerisk.diet.willingDiet"
                :scale="true"
                :format-scale-label="(value) => willingScaleLabels(value)"
                :color="willingBarColor(lerisk.diet.willingDiet?lerisk.diet.willingDiet:3)"
                :disabled="!modifiable"
              ></f7-range>
            </f7-list-item-cell>
          </f7-list-item>
        </f7-list>
      </f7-card-content>
    </f7-card>
<f7-card class="selectedResources">
        <f7-card-header class="bold uppercaseme ">Community Resources <f7-button v-if="modifiable" href="/map/" raised>Add Community Resources</f7-button></f7-card-header>
        <f7-card-content :padding="false" class="fixblock ">
              <f7-list media-list v-if=" Object.keys(selectedResources).length>0">
                <f7-list-item
                media-item
                v-for="oneresource in selectedResources" :key="oneresource.id"
                :footer="typeOfResources[oneresource.type].name"
                :title="oneresource.ginfo.name"
                :subtitle="oneresource.ginfo.formatted_address"
                >
                <div class="iconBackground" :style="{backgroundColor:typeOfResources[oneresource.type].color}" slot="media">
  <f7-icon class="iconRx" >{{typeOfResources[oneresource.type].ico.uni}}</f7-icon>
    </div>
                 <f7-link text-color="red" v-if="modifiable" @click="deleteResource('selectedResources',oneresource)" slot="after">Delete</f7-link>
                </f7-list-item>
              </f7-list>
              <f7-block class="padding" v-else>No resources selected</f7-block>
        </f7-card-content>
        <f7-card-footer v-if="  Object.keys(selectedResources).length==0 && modifiable" class="footergris ">
          <span></span><span>Add resources here <f7-icon material="arrow_upward"></f7-icon></span>
        </f7-card-footer>
 </f7-card>
  
 <f7-card class="selectedDigitalResources">
        <f7-card-header class="bold uppercaseme">Digital Resources <f7-button v-if="modifiable" href="/selectDigitalResources/" raised>Add Digital Resources</f7-button></f7-card-header>
        <f7-card-content :padding="false" class="fixblock">
              <f7-list media-list v-if=" Object.keys(selectedDigitalResources).length>0">
                <f7-list-item
                media-item
                v-for="oneresource in selectedDigitalResources" :key="oneresource.id"
                :footer="oneresource.types?Object.values(oneresource.types).join(', '):''"
                :title="oneresource.name||oneresource.link"
                :subtitle="oneresource.platforms?Object.values(oneresource.platforms).join(', '):''"
                >
                <div  slot="media">
              <img class="logoInRx" :src="oneresource.logo||'../static/icons/no-pictures.png'" />
                    </div>
                 <f7-link text-color="red" v-if="modifiable" @click="deleteResource('selectedDigitalResources',oneresource)" slot="after">Delete</f7-link>
                </f7-list-item>
              </f7-list>
              <f7-block class="padding" v-else>No digital resources selected</f7-block>
        </f7-card-content>
        <f7-card-footer v-if="  Object.keys(selectedDigitalResources).length==0 && modifiable" class="footergris ">
          <span></span><span>Add digital resources here <f7-icon material="arrow_upward"></f7-icon></span>
        </f7-card-footer>
 </f7-card>

</div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  props:['patrepdata'],
  data() {
    return {
      followUpData:{
        weeks:[{title:'Week 4',week:'followupfour'},{title:'Week 8',week:'followupeight'},{title:'Week 12',week:'followuptwelve'}],
        options:[
        {header:'Physical',answer:'physical'},
        {header:'Fruits',answer:'dietQ1'},
        {header:'Vegetables',answer:'dietQ2'},
        {header:'Grains',answer:'dietQ3'},
        {header:'Beverages',answer:'dietQ4'},
        {header:'Between meals',answer:'dietQ5'},
        ],
      },
      interestedOptions: {
        1: { value: 1, name: "very interested" },
        2: { value: 2, name: "interested" },
        3: { value: 3, name: "fairly interested" },
        4: { value: 4, name: "slightly interested" },
        5: { value: 5, name: "not at all interested" },
      },
      allDiettoggleDisabled:false,
      physicalRecompopup:false,
      localPhysicalRecommendation:{
        week1_4:'',
        week5_8:'',
        week9_12:'',
        week12plus:''
      },
    }
  },
mounted(){
  this.timePicker()
  if(this.modifiable){
  if(!this.lerisk.diet.willingDiet)this.changeWilling(3,'willingDiet', 'diet')
  if(!this.lerisk.physical.willingPhysical)this.changeWilling(3,'willingPhysical', 'physical')
  }
},
  computed: {
    ...mapState([
      'focusedPrescription',
      'focusedAccount',
        'user',
      'actualPatient',
      'dietDiction',
      'typeOfResources',
      'resources',
      'activities',
      'AYAVersion',
      'preResourcesFilter',
      'digitalResources'
      
    ]),
    ...mapGetters(['obesityStatus','obesityRecomendationsProperties','physicatActivitiesRecommnedation','onClinic','info','resourcesByType']),

    lerisk(){ // returns the risk object from the prescription
      return this.focusedPrescription.risk;
    },
    noAnswers(){ // returns true if there are no follow up answers yet
           return !Object.values(this.focusedPrescription.fups).some(fup=>fup.answers)
    },
    selectedResources(){ // return the object of the selected resources for this prescription
        return this.focusedPrescription.selectedResources || {};
       
    },
    selectedDigitalResources(){ // return the object of the selected digital resources for this prescription
        return this.focusedPrescription.selectedDigitalResources || {};
       
    },
    selectedActivities(){ // returns array with the selected activities for this prescription. including the activities that were writen by the clinician on the selectActivities page 
      if(Object.keys(this.activities).length>0){
        let preactivities = this.focusedPrescription.selectedActivities || {};
        let arrayofactivities=Object.keys(preactivities) || [];
        return arrayofactivities.map(activityid=>{
          if(!this.activities[activityid]){
            let activity={
              id:activityid,
              name:activityid
            }
            return activity
          }else{
         return this.activities[activityid]
           }
          });
         
      }
      else{
        return [];
      }
      
    },
     modifiable(){// returns true if the the first prescription has not been sent yet and if the user is the clinician that created the prescription. used to enable the sliders and other parameters in the prescription
      return !this.focusedPrescription.sent && this.focusedAccount.privx.clinician && this.focusedPrescription.clinicid==this.focusedAccount.clinicid;
    },
    alldietRecomInact(){
      if(this.focusedPrescription.dietQ1RecomInact&&this.focusedPrescription.dietQ2RecomInact&&
      this.focusedPrescription.dietQ3RecomInact&&this.focusedPrescription.dietQ4RecomInact&&
      this.focusedPrescription.dietQ5RecomInact) return true
      else return false
    }
  },

  methods: {
    changeWilling(value,what,where){
      let clone= Object.assign({},this.lerisk[where])
      clone[what]=value
      this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/risk/'+[where]+'/'+what).set(value).then(()=>{
        this.$store.commit("setRisk", { riskid: where, value: clone });
      }).catch(error=>{
              this.$f7.dialog.alert(error.message,error.code)
               console.log(error)
      })

    },
    inactivateRecommendation(value,prop){
      let neovalue=null
      if(!value)neovalue=true
      let updates={}
      if(prop=='allDiet'){
        this.allDiettoggleDisabled=true
        for (let i=1;i<=5;i++){
                 updates[`dietQ${i}RecomInact`]=neovalue 
        }
      }else{
         if(this.focusedPrescription[prop]==neovalue)return;
        updates[prop]=neovalue
      }
     
        this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/').update(updates).then(()=>{
        Object.keys(updates).forEach(one=>{
          this.$store.commit('setChildOnFocusedPrescription',{wich:one,neovalue:neovalue})
          })
       this.allDiettoggleDisabled=false
        }).catch(error=>{
        console.log(error)
        this.$f7.dialog.alert(error.messsage)
        })
    },
    openEditPhysicalRecom(){
      if(this.focusedPrescription.physicalRecomByClinician){
        Object.keys(this.focusedPrescription.physicalRecomByClinician).forEach(week=>{
          this.localPhysicalRecommendation[week]=this.focusedPrescription.physicalRecomByClinician[week]
        })
         }else{
        this.setLocalPhysicalRecom()
        }
      this.physicalRecompopup=true
    },
    setLocalPhysicalRecom(wich=null){
      if(wich){
        this.localPhysicalRecommendation[wich]=this.physicatActivitiesRecommnedation[wich].str
      }else{
        Object.keys(this.physicatActivitiesRecommnedation).forEach(week=>{
          this.localPhysicalRecommendation[week]=this.physicatActivitiesRecommnedation[week].str
        })
      }
    },
    setPhysicalRecomAuto(){
       this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/physicalRecomByClinician').set(null).then(()=>{
      this.$store.commit('setPhysicalRecomByClinician',{neovalue:null})
      this.setLocalPhysicalRecom()
      this.physicalRecompopup=false
      }).catch(error=>{
        console.log(error)
        this.$f7.dialog.alert(error.message)
      })
    },
    savePhysicalRecom(){
      if(Object.values(this.localPhysicalRecommendation).some(one=>!one)){
      return this.$f7.dialog.alert('The recommendations can not be empty')
      }
      this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/physicalRecomByClinician').set(this.localPhysicalRecommendation).then(()=>{
      this.$store.commit('setPhysicalRecomByClinician',{neovalue:this.localPhysicalRecommendation})
        this.physicalRecompopup=false
      }).catch(error=>{
        console.log(error)
        this.$f7.dialog.alert(error.message)
      })
    },
    saveNextAppointmentToPrescrption(date){ // sets the date of the next appointment with the clinician
      if(this.focusedPrescription['nextAppointment']!=date && date){
        this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/nextAppointment').set(date).then(()=>{
          this.$store.commit('setNextApppointment',{date});
         this.$f7.toast.show({
            text: 'Next appointment recommended for '+date,
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
        }).catch(error=>{
         this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
        })
        
      }
    },
    sortame(osjeto ={}){ // returns an array alphabetically ordered
      let valores=Object.values(osjeto) || [];
      valores.sort((a,b)=>{
            var x = a.order;
            var y = b.order;
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
        return valores;
    },
    timePicker(){ // instances a framework7 calendar id:calendar-date-time
    let picker = this.$f7.calendar.create({
  inputEl: '#calendar-date-time',
  timePicker: true,
  locale:'en',
  dateFormat: {month: 'numeric', day: 'numeric', year: 'numeric',hour: 'numeric', minute: 'numeric'},
  //closeOnSelect:true,
   disabled:{to: new Date()}
});
},
    deleteResource(target,resource){ // deletes a selected resource from the prescription
      this.$f7.dialog.confirm('Are you sure you want to delete this resource from the list?',  ()=> {

        this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/'+target+'/'+resource.id).set(null).then(()=>{
           this.$store.commit('setSelection',{where:target,id:resource.id,value:null});
           this.$f7.toast.show({
            text: `${target=='selectedResources'?resource.ginfo.name:resource.name} removed from selection`,
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
        }).catch(error=>{
          console.log(error)
          this.$f7.dialog.alert(error.message,error.code)
        })
      
    });
    },
    deleteActivyChip(activity){ // deletes a selected activity from the prescription
      if(this.modifiable){
        this.$f7.dialog.confirm('Are you sure you want to delete '+activity.name+' from the list?',  ()=> {
        let toStore={
          id: activity.id,
          where:'selectedActivities',
          value:null
          };
        this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/selectedActivities/'+activity.id).set(null).then(()=>{
          this.$store.commit('setSelection',toStore);
          this.$f7.toast.show({
            text: activity.name+' removed from selection',
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
        }).catch(error=>{
          this.$f7.dialog.alert(error.message,error.code)
          console.log(error)
        })
       
    });
      }

    },
    willingBarColor(value){
      if(!value) return;
      if(value<3)return 'green'
      if(value==3)return 'yellow'
      else return 'red' 
    },
    willingScaleLabels(value){
      let color=value<3?'green':value==3?'yellow':'red'
      return `<span class="cutPoints ${color}">|<br/><span class=" bigger" >${this.interestedOptions[value].name}</span></span>`;
    },
    setResourcesFromSurvey(){
      
     let foodResourcesPreferences = this.patrepdata.answer.foodResourcesPreferences || []
     let digitalFoodResources = false
     if(foodResourcesPreferences.includes('Digital resources (e.g., cooking videos, educational websites)')){
      digitalFoodResources=true
       foodResourcesPreferences.splice(foodResourcesPreferences.indexOf('Digital resources (e.g., cooking videos, educational websites)'),1)
     }
     let physicalResourcesPreferences = this.patrepdata.answer.physicalResourcesPreferences || []
     let digitalPhysicalResources = false
     if(physicalResourcesPreferences.includes('Digital resources (e.g., fitness videos, trackers, apps)')){
      digitalPhysicalResources=true
      physicalResourcesPreferences.splice(physicalResourcesPreferences.indexOf('Digital resources (e.g., fitness videos, trackers, apps)'),1)
     }
     let allSelectedResources = physicalResourcesPreferences.concat(foodResourcesPreferences)
     let dietResources = this.resourcesByType('diet') ||[]
     let physicalResources = this.resourcesByType('physical')||[]
     let allResouces = dietResources.concat(physicalResources)
    let matchingResources = []
    allSelectedResources.forEach(selectedResource=>{
       let name = selectedResource.toLowerCase()
      let selected =  allResouces.find(localResource=>{
        return name.includes(localResource.name.toLowerCase())
      })
      if(selected && !matchingResources.includes(selected)) matchingResources.push(selected)
    })
    this.$store.commit('setWhatTo',{what:'preResourcesFilter',to:matchingResources||[]})
    if(!this.focusedPrescription.selectedResources|| Object.values(this.focusedPrescription.selectedResources).length==0){
        this.getTailoredYelpResources()
    }
    if(!this.focusedPrescription.selectedDigitalResources|| Object.values(this.focusedPrescription.selectedDigitalResources).length==0){
        this.getTailoredDigitalResources({digitalFoodResources,digitalPhysicalResources})
    }
    },
   async getTailoredYelpResources(){
     try{
       this.$f7.preloader.showIn('.selectedResources');
      let call = this.$firebase.functions().httpsCallable('getTailoredYelpResources')
      let response = await call({
        center:{lat:this.actualPatient.geo.coord.lat,lng:this.actualPatient.geo.coord.lng},
        types:this.preResourcesFilter,
        clinicid:this.onClinic
        })
        let toUpdate=[]
       response.data.fiveCloserResources.forEach(res=>{ 
        toUpdate.push(this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/selectedResources/'+res.id).set(res)) 
      })
      await Promise.all(toUpdate)
      
      response.data.fiveCloserResources.forEach(res=>{
      let toStore={id: res.id, where:'selectedResources',value:res};
      this.$store.commit('setSelection',toStore)
        })
        this.$store.commit('setObjectTo',{what:'focusedPrescription',to:this.focusedPrescription})
       this.$f7.preloader.hideIn('.selectedResources');
      if(response.data.error)throw new Error(response.data.error)

     }catch(error){
       this.$f7.preloader.hideIn('.selectedResources');
       console.log(error)
       this.$f7.dialog.alert(error.message)
     }
   },
   async getTailoredDigitalResources({digitalFoodResources,digitalPhysicalResources}){
    if(Object.values(this.digitalResources).length==0 ){
            try{
               this.$f7.preloader.showIn('.selectedDigitalResources');
           let preResources= await this.$firebase.database().ref('digitalResources').once('value')
            let localDigitalResources=preResources.val()||{}
            this.$store.commit('setObjectTo',{what:'digitalResources',to:localDigitalResources})
              let foodTypes=[]
              let physicalTypes=[]
              Object.values(this.digitalResources).forEach(res=>{
               if(res.types.FoodNutrition) foodTypes.push(res)
               else if(res.types.PhysicalActivity) physicalTypes.push(res)
              })
              let randomlySelectedDigitalResources = []
              if(digitalFoodResources){
              if(foodTypes.length){
                let firstFoodSelected = foodTypes[Math.floor(Math.random()*foodTypes.length)];
                randomlySelectedDigitalResources.push(firstFoodSelected)
                foodTypes.splice(foodTypes.indexOf(firstFoodSelected),1)
              }
              if(foodTypes.length){
                let secondFoodSelected = foodTypes[Math.floor(Math.random()*foodTypes.length)];
                randomlySelectedDigitalResources.push(secondFoodSelected)
                foodTypes.splice(foodTypes.indexOf(secondFoodSelected),1)
              }
            }
            if(digitalPhysicalResources){
               if(physicalTypes.length){
                let firstFhysicalSelected = physicalTypes[Math.floor(Math.random()*physicalTypes.length)];
                randomlySelectedDigitalResources.push(firstFhysicalSelected)
                physicalTypes.splice(physicalTypes.indexOf(firstFhysicalSelected),1)
              }
              if(physicalTypes.length){
                let secondFhysicalSelected = physicalTypes[Math.floor(Math.random()*physicalTypes.length)];
                randomlySelectedDigitalResources.push(secondFhysicalSelected)
                physicalTypes.splice(physicalTypes.indexOf(secondFhysicalSelected),1)
              }
            }
            if(randomlySelectedDigitalResources.length){
               let toUpdate=[]
       randomlySelectedDigitalResources.forEach(res=>{
        toUpdate.push(this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/selectedDigitalResources/'+res.id).set(res)) 
      })
      await Promise.all(toUpdate)
      randomlySelectedDigitalResources.forEach(res=>{
      let toStore={id: res.id, where:'selectedDigitalResources',value:res};
      this.$store.commit('setSelection',toStore)
        })
        this.$store.commit('setObjectTo',{what:'focusedPrescription',to:this.focusedPrescription})
            }
            this.$f7.preloader.hideIn('.selectedDigitalResources');
            }catch(error){
              this.$f7.preloader.hideIn('.selectedDigitalResources');
            console.log(error)
            this.$f7.dialog.alert(error.message)
            
          }
          }
    
   }
  },
  watch:{
    patrepdata(value){
      if(value.answer && (value.answer.foodResourcesPreferences || value.answer.physicalResourcesPreferences) 
      && !this.focusedPrescription.sent) 
      this.setResourcesFromSurvey()
    }
  }
};
</script>
<style >
.bold{
  font-weight: bold;
}

.fixblock {
    padding-top: 7px;
}
.footergris, .fixblock{
 background: #f3f3f3;
}
.fixblock .block{
 margin: 5px 3px!important;
}

.indicafood{
  border-left: 4px solid #ff1b32;
}

.bverde{
  border-color:#00c531;
}


.bamarillo{
  border-color:#ffba00;
}


.tituloneuro .item-title{
  font-weight: bold;
}
.uppercaseme{
  text-transform: uppercase;
  color: #464646;
}
.unsetflex{
  display: block;
}
.chiperholder .chip{
  margin-right: 5px;
}
.chiperholder .chip-label{
  font-weight: bold;
}
.itemlinkuno .item-link .item-content{
  background: #FFF;
}


 .fixblock  .no-margin-bottom-force{
  margin-bottom: 0px!important;
}
.borderradiustop{
  border-radius: 5px 5px 0px 0px!important;
}
.borderradiusbottom{
  border-radius: 0px 0px 5px 5px!important;
}
.traffic{
  width: 50px;
  float: right;
}

.inpublanck .item-input-wrap input{
  background: #fff;
    padding-left: 5px;
    border-radius: 11px;
}
.iconRx{
  font-family:Fontawesome;
  left:5px
}
.displayBlock{
  display: block !important;
}
.navSubtitle .subtitle{
  top: 3px;
    position: relative;
}
.recomInfo{
  margin:0px ;
  padding: 10px;
    background: aliceblue;
}
.noWrap{
  white-space: unset !important;
}
.logoInRx{
  height: 30px;
  border-radius: 50%;
}
    
</style>