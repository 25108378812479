<template>
  <f7-page >
     <f7-navbar :title="(resourceTypeEnFoco?(resourceTypeEnFoco.name):('Create new'))" subtitle="Type of Resource"  back-link="Back">
     <f7-nav-right>
       <f7-link @click="saveType()" icon-material="save"></f7-link>
     </f7-nav-right>
    </f7-navbar>
<f7-list class="coniconmed margin-bottom"  no-hairlines-md>
  <f7-list-input 
   @input  ="type.class=$event.target.value"
    :value="type.class"
    label="Class"
    type="select"
    placeholder="Please choose..."
  >
    <option :value="null">Select classification</option>
    <option
     value="diet"
     >Food resource</option>
     <option
     value="physical"
     >Physical Activity resource</option>
  </f7-list-input>
 <f7-list-input 
    @input  ="type.name=$event.target.value"
    :value="type.name"
    label="Name"
    type="text"
    placeholder="Type`s name"
    clear-button
  >
  </f7-list-input>
  <f7-list-item divider title="Choose Map Marker Icon"></f7-list-item>
  <f7-list-item 
 v-if="type.ico.ico"
  radio 
   :value="type.ico.ico" 
  checked
  @change="type.ico = type.ico"
  > <f7-icon :icon="'fa fa-2x fa-'+type.ico.ico" slot="media"></f7-icon>
  <f7-button
  v-show="!otherIcons"
  @click="otherIcons=!otherIcons"
   small round slot="after">
    <f7-icon material="edit"></f7-icon>
    Change Icon</f7-button>
  </f7-list-item>
  <f7-list-item 
 v-if="resourceTypeEnFoco && otherIcons && type.ico.ico!=resourceTypeEnFoco.ico.ico"
  radio 
   :value="resourceTypeEnFoco.ico.ico" 
  :checked="type.ico.ico === resourceTypeEnFoco.ico.ico"
  @change="type.ico = resourceTypeEnFoco.ico,otherIcons=false"
  name="resourceicon" 
  > <f7-icon :icon="'fa fa-2x fa-'+resourceTypeEnFoco.ico.ico" slot="media"></f7-icon>
  
  <f7-button
  v-show="!otherIcons"
  @click="otherIcons=!otherIcons"
   small round slot="after">
    <f7-icon material="edit"></f7-icon>
    Change Icon</f7-button>
  </f7-list-item>
  <f7-list-item 
 v-for="unico in availableIcons" :key="unico.ico" 
  v-show="otherIcons && unico.ico!=type.ico.ico "
  radio 
 :value="unico.ico" 
  :checked="type.ico.ico === unico.ico"
  @change="(type.ico = unico,otherIcons=false)"
  name="resourceicon" 
  ><f7-icon :icon="'fa fa-2x fa-'+unico.ico" slot="media"></f7-icon></f7-list-item>
</f7-list>
<f7-block>
  <f7-row>
    <f7-col><f7-button @click="$f7.views.main.router.back()" outline color="red"><f7-icon material="chevron_left"></f7-icon> Cancel</f7-button></f7-col>
    <f7-col><f7-button @click="saveType()" fill raised color="green"><f7-icon material="save"></f7-icon> Save</f7-button></f7-col>
  </f7-row>
</f7-block>
  </f7-page>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import categories from '../js/yelpCategories'
export default {
  data(){
    
    return{
      otherIcons:true,
      type:{
        id:'',
        name:'',
        ico:{ico:'',uni:''},
        class:'',
        yelpCategories:[]
      }

    }
  }, 
  mounted(){
   if(this.resourceTypeEnFoco){
     this.type=JSON.parse(JSON.stringify(this.resourceTypeEnFoco))
     this.otherIcons=false
   }
  },
  computed:{
    ...mapState(['resourceTypeEnFoco','typeOfResources','iconsUrl','focusedAccount']),
    ...mapGetters(['availableIcons','onClinic'])
  },
  methods:{
      saveType(){
          if(this.type.name=="")return this.$f7.dialog.alert('A name is required!');
         if(this.type.class=='') return this.$f7.dialog.alert('A class is required!');
           if ( this.type.ico.ico=="" )return this.$f7.dialog.alert('An icon is required!');
      this.$f7.dialog.preloader(this.resourceTypeEnFoco?'Saving changes':'Creating new Resource type...');
      let call=this.$firebase.functions().httpsCallable('setTypeOfResources')
      call({type:this.type}).then((ledata)=>{
        if(ledata.data.error){
          throw new Error (ledata.data.error)
        }
        if(!this.type.id) this.type.id=ledata.data.id
        let local_typeOfResources=Object.assign({},this.typeOfResources);
        local_typeOfResources[ledata.data.id]=this.type;
        this.$store.commit('setObjectTo',{what:'typeOfResources',to:local_typeOfResources});
        this.$f7.toast.show({
          text: this.type.name+' saved',
          position: 'center',
          closeTimeout: 1500,
          destroyOnClose:true
        });
        this.$f7.dialog.close();
        this.$f7.views.main.router.back();
      }).catch((error)=>{
        this.$f7.dialog.close();
        console.log(error)
        this.$f7.dialog.alert((error.message,error.code)||error);
      })
      },
  },
  watch:{
    onClinic(){ // obsers if the getter onClinic changes. if its true navigates back. used to get out of the edit pages if the user selects another clinic on the left pannel
        if(this.$f7.views.main.router.url=='/createTypeOfResources/'){
            this.$f7.views.main.router.navigate('/typeOfResources/')
        } 
    }
  },
}
</script>
<style >
.coniconmed .item-media .icon{
  margin: 0 auto;
}
</style>

//a.	Sports-specific centers ['gyms','martialarts','brazilianjiujitsu','chinesemartialarts','karate','kickboxing','muaythai','taekwondo','swimminglessons']
//Studio Fitness ['cyclingclasses','barreclasses','cardioclasses','dancestudios','pilates','meditationcenters','yoga']
//2.	Parks and Playground ['parks','playgrounds']
//3.	Community/recreation ['swimmingpools','amateursportsteams','baseballfields','basketballcourts','bicyclepaths','discgolf','climbing','hiking','lakes','recreation','rock_climbing','tennis','youth_club','communitycenters']
//4.	Grocery Stores ['ethicgrocery','intlgrocery','organic_stores']
// 5.	Farmer’s Markets ['farmersmarket']
//6.	CSA’s ['csa']
// 7.	WIC Clinics []
// 8.	Food Pantries ['foodbanks']
// 9.	Food Organizations []
// 10.	Community Gardens ['gardens']
//11.	Weight Management Program ['dietitians','preventivemedicine','nutritionists','weightlosscenters']