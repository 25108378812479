<template>
<f7-page bg-color="white" >
<f7-navbar>
  <f7-nav-left >
    <f7-link panel-open="left" icon-material="menu"></f7-link>
  </f7-nav-left>
    <f7-nav-title
    title="Export Reports"
    :subtitle="clinics[onClinic]?'On '+clinics[onClinic].name+' Clinic':'No clinic selected'"
    ></f7-nav-title>
    </f7-navbar>
    <f7-block class="text-align-center">
      <f7-card>
        <f7-card-content>
    Download an updated report with the last prescription information and folllow up survey answers 
    for all the patient of <strong>{{clinics[onClinic].name}} Clinic</strong>
    </f7-card-content>
      </f7-card>
      <f7-button class="margin" @click="download" fill >
      <f7-icon material="file_download" /> Download</f7-button>
      </f7-block>
    </f7-page>
</template>
      
      <script>
import { mapGetters, mapState } from 'vuex'
    import axios from 'axios';
      export default {
          computed:{
          ...mapState(['clinics','focusedAccount','focusedAccount','activeTab']),
          ...mapGetters(['onClinic'])
          },methods:{
             async download(){
               this.$f7.dialog.preloader('Creating and downloading Report...')
             try{
               let idToken = await this.$firebase.auth().currentUser.getIdToken();
             let endpoint = 'https://us-central1-prevent-tool-pro.cloudfunctions.net'; //'http://localhost:5001/prevent-tool-pro/us-central1' 'https://us-central1-prevent-tool-pro.cloudfunctions.net
        let response = await axios.get(`${endpoint}/exporter/exportReports/${this.onClinic}`, {
                    headers: {'Authorization': 'Bearer ' + idToken},
                     responseType: 'blob', // important
                    });
        this.$f7.dialog.close();
        if(response.data.type=='application/json'){
          var textPromise = await response.data.text();
          console.log('error',textPromise);
          return this.$f7.dialog.alert(textPromise,'Error');
        }
     const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
          let fileLink = document.createElement('a');
          document.body.appendChild(fileLink);
          fileLink.href = fileURL;
          fileLink.setAttribute('download',`report-${this.$moment().format('MM-DD-YYYY')}.xlsx`);
          fileLink.classList.add("external");
          fileLink.click();
          window.URL.revokeObjectURL(fileURL);
        //  this.avisaOk('Descarga OK');
        } catch(error){
          this.$f7.dialog.close();
          console.log(error)
          this.$f7.dialog.alert(error.message)
        }
              }
          },
          watch:{
    onClinic(){
       if(this.focusedAccount.inactive){
          return this.$f7.views.main.router.navigate('/inactiveAccount')
        }
        if(this.activeTab=='/exportReports'){
          if(!this.focusedAccount.privx.exportReports){
            this.$f7.toast.show({
            text: 'Account without privileges on exporting Reports',
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
          return this.$f7.views.main.router.navigate('/settings')  
        }
    }
    }
  },
      
      }
      </script>
