<template>
  <f7-page >
    <f7-navbar  :title="patientClone.name+' '+patientClone.lastName" :subtitle="patientClone.email" back-link="Back">
    </f7-navbar>
     <f7-list media-list class="no-margin listagris">
        <f7-list-item divider title="Demographic information" ></f7-list-item>
        <f7-list-input
          label="Name"
          type="text"
          :value="patientClone.name"
           @input="patientClone.name = $event.target.value"
          placeholder="Patient's name"
          info="Required"
        >
        </f7-list-input>
         <f7-list-input
          label="Last Name"
          type="text"
          :value="patientClone.lastName"
            @input="patientClone.lastName = $event.target.value"
          placeholder="Patient's last name"
        >
        </f7-list-input>
      <f7-list-input
          label="Patient's birthday"
          type="datepicker"
          placeholder="Select date"
          info="Required"
           :value="[patientClone.birthday]"
          readonly
         :calendar-params="{locale:'en-US', dateFormat: 'mm/dd/yyyy',closeOnSelect:true, disabled:{
          from: new Date()
          }}"
          @change="patientClone.birthday=$event.target.value"
        ></f7-list-input>
       <f7-list-item
       header="Sex (assigned at birth)"
       >
         <f7-segmented strong tag="p">
          <f7-button :active="patientClone.sex=='female'" @click="patientClone.sex='female'" :icon-material="patientClone.sex=='female'?'check':null">Female</f7-button>
          <f7-button :active="patientClone.sex=='male'" @click="patientClone.sex='male'" :icon-material="patientClone.sex=='male'?'check':null">Male</f7-button>
        </f7-segmented>
        <div slot="footer">Required</div>
       </f7-list-item>
       <f7-list-item
       header="Gender"
       >
       <div slot="title">
         <f7-checkbox
         :checked="genderSameAsAbove"
         @change="genderSameAsAbove=$event.target.checked"
         ></f7-checkbox><span class="margin-left">Same as above</span>
       </div>
       </f7-list-item>
     <f7-list-input v-if="!genderSameAsAbove"
  @input  ="patientClone.gender=$event.target.value.trim()"
    :value="patientClone.gender"
    label="Patient self-describe"
    placeholder="Patient self-describe"
    clear-button
  >
  </f7-list-input>
        <f7-list-item divider title="Patient contact information" ></f7-list-item>
           <f7-list-input
          label="email"
          type="email"
          :value="patientClone.email"
            @input="patientClone.email = $event.target.value"
          placeholder="Patient's email"
          validate
          @change="emailIsValid($event.target.value,'patient')"
        >
         <div slot="info"><f7-checkbox :checked="patientClone.sendToArray && patientClone.sendToArray.includes('email')" @change="setSendToArray($event.target.checked,'email')"></f7-checkbox><span class="margin-left">Send prescription to this contact</span></div>
        </f7-list-input>
        <f7-list-input
          label="Phone number"
          type="tel"
          maxlength="14"
          :value="formatPhoneNumber(patientClone.phone)"
          @input="patientClone.phone = $event.target.value.trim()"
          placeholder="(555)555-5555"
          @change="numberIsValid(patientClone.phone,'patient')"
        >
         <div slot="info"><f7-checkbox  :checked="patientClone.sendToArray && patientClone.sendToArray.includes('phone')" @change="setSendToArray($event.target.checked,'phone')"></f7-checkbox><span class="margin-left">Send prescription to this contact</span></div>
        </f7-list-input>
        <f7-list-item v-if="!editAdress && patientClone.geo"
       header="Address"
       
       link="#"
       @click="turnOnMapAutoComplete()"
       :title="patientClone.geo.address"
       >
        <f7-icon slot="after" material="edit"></f7-icon>
       </f7-list-item>
       <f7-list-input v-else
                  label="Address"
                  type="text"
                  placeholder="Patient`s adress"
                  input-id="autocomplete_patedit"
                  info="Required"
                  clear-button
                >
                </f7-list-input>
        <f7-list-item divider title="Caregiver's contact information" ></f7-list-item>
        <f7-list-input
          label="Name"
          type="text"
          :value="patientClone.caregiverName"
           @input="patientClone.caregiverName = $event.target.value"
          placeholder="Caregiver's name"
        >
        </f7-list-input>
        <f7-list-input
          label="Caregiver's email"
          type="email"
          :value="patientClone.caregiverEmail"
            @input="patientClone.caregiverEmail = $event.target.value"
          placeholder="Caregiver's email"
          @change="emailIsValid($event.target.value,'caregiver')"
        >
          <div slot="info"><f7-checkbox :checked="patientClone.sendToArray && patientClone.sendToArray.includes('caregiverEmail')" @change="setSendToArray($event.target.checked,'caregiverEmail')"></f7-checkbox><span class="margin-left">Send prescription to this contact</span></div>
        </f7-list-input>
        <f7-list-input
          label="Caregiver's phone number"
          type="tel"
          :value="formatPhoneNumber(patientClone.caregiverPhone)"
            @input="patientClone.caregiverPhone = $event.target.value.trim()"
            maxlength="14"
          placeholder="(555)555-5555"
          @change="numberIsValid(patientClone.caregiverPhone,'caregiver')"
        >
        <div slot="info"><f7-checkbox :checked="patientClone.sendToArray && patientClone.sendToArray.includes('caregiverPhone')" @change="setSendToArray($event.target.checked,'caregiverPhone')"></f7-checkbox><span class="margin-left">Send prescription to this contact</span></div>
        </f7-list-input>
     </f7-list>
<f7-block>
  <f7-button fill @click="saveandback()" raised>Save changes</f7-button>
</f7-block>
  </f7-page>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
import {paxhelpersmixin} from '../mixins/paxhelpersmixin';

import loadGoogleMapsApi from 'load-google-maps-api';
  export default {
      	mixins:[paxhelpersmixin],

    data: ()=> {
      return {
          editAdress:false,
          googleMaps: null,
          patientClone:{},
          genderSameAsAbove:false
      }
    },
    computed:{
      ...mapState(['actualPatient','focusedAccount']),
      ...mapGetters(['onClinic'])
    },
    mounted(){
      this.patientClone= JSON.parse(JSON.stringify(this.actualPatient));
      if(this.patientClone.gender && this.patientClone.gender==this.patientClone.sex){
        this.genderSameAsAbove=true
      }
      if(!this.patientClone.sendToArray)this.patientClone.sendToArray=[]
    },
    methods:{
      emailIsValid (email,where) { // checks if the email format is correct
        if(email){
      let valid= /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      if(!valid)  {
       this.$f7.dialog.alert('For the '+where+' you must provide an email that looks like USER@DOMAIN.XYZ','email format invalid')
      return true
      }
      }
      },
      numberIsValid(number,where){ // checks if the phone number format is correct
        if(this.creatPatOpened ){
          number = number.replaceAll('(', '');
                number = number.replaceAll(')', '');
                number = number.replaceAll('-', '');
                number = number.replaceAll(' ', '');
                if(number && !number.startsWith('+1')) number = this.patientClone[where=='patient'?'phone':'caregiverPhone'] = '+1'+number
                let valid = /^[0-9\-\+]{12,15}$/.test(number);
                if (!number || !valid){
                    this.$f7.dialog.alert('You must provide a valid phone number for the '+where,'Phone number format invalid')
      return true
                }
                
        }
      },
      async saveandback(){ // save the changes on database and navigates back
      if(!this.patientClone.id || this.patientClone.name=="" || Object.keys(this.patientClone.geo).length==0 || this.patientClone.sex=="" ||this.patientClone.birthday==""){
        return this.$f7.dialog.alert("missing required information");
      }
      if (!this.patientClone.email && !this.patientClone.caregiverEmail && !this.patientClone.phone && !this.patientClone.caregiverPhone ){
        return this.$f7.dialog.alert("At least one contact information is required")
      }
      
      let invalid=this.emailIsValid(this.patientClone.email,"patient")
      if(invalid) return false
      let invalidCaregiver=this.emailIsValid(this.patientClone.caregiverEmail,"caregiver")
      if(invalidCaregiver) return false
      if(this.patientClone.email && this.patientClone.caregiverEmail && this.patientClone.email==this.patientClone.caregiverEmail) return this.$f7.dialog.alert("Both emails can not be the same")
      let invalidNumber=this.patientClone.phone &&this.numberIsValid(this.patientClone.phone,'patient')
      if(invalidNumber) return false
      let invalidNumberCaregiver=this.patientClone.caregiverPhone && this.numberIsValid(this.patientClone.caregiverPhone,'caregiver')
      if(invalidNumberCaregiver) return false
        try{
      let arrayofprevious = await this.checkEmailUnique(this.patientClone.id,this.patientClone.email,this.onClinic)
        if(arrayofprevious.length>0){
          throw new Error('Email '+this.patientClone.email+' already in use by '+this.lastNamesAndNames(arrayofprevious[0])+', if this is the email of the caregiver add it on the caregiver info at the bottom of this form');
        }
          
        if(this.patientClone.sendToArray.length==0){
          return this.$f7.dialog.alert("Select least one contact to send the prescription")
        } 
        if (this.patientClone.sendToArray.some(contact=>{
         return !this.patientClone[contact] 
      }) ) return this.$f7.dialog.alert("A contact to send the prescription is not filled")
        else{
          if(this.genderSameAsAbove){
            this.patientClone.gender=this.patientClone.sex
          }
          if(!this.patientClone.gender) delete this.patientClone.gender
          let hyperClonado=JSON.parse(JSON.stringify(this.patientClone));
          delete hyperClonado.prescriptions;
          this.$f7.dialog.preloader('Saving changes...');
            await this.$firebase.database().ref('patients_by_clinic/'+this.onClinic+'/'+this.patientClone.id).set(hyperClonado);
        }
        this.creatPatOpened=false;
        this.$store.commit('setObjectTo',{what:'actualPatient',to:this.patientClone});
        this.$store.commit('updateObjectPropValue',{what:'patients',prop: this.patientClone.id,neovalue:this.patientClone});
          this.$f7.dialog.close();
          let toastCenter = this.$f7.toast.show({
                text:'Changes saved ok',
                position: 'center',
                closeTimeout: 1500,
                destroyOnClose: true
              });
           this.$f7.views.main.router.back();
      }catch(error){
        this.$f7.dialog.close();
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code)
      }
    },
        turnOnMapAutoComplete(){ // calls the google map api to set the autocomplete input
            this.editAdress=true;
       loadGoogleMapsApi({key:this.$store.state.gmapsAPIkey,libraries:['places'],language:'en'}).then((googleMaps)=> {
           this.googleMaps=googleMaps;
           let autocomplete = new this.googleMaps.places.Autocomplete(
            /** @type {!HTMLInputElement} */ (
                document.getElementById('autocomplete_patedit'))
              );
                   autocomplete.addListener('place_changed', ()=> {
            var place = autocomplete.getPlace();
            if (place.geometry) {
               let geo={
                address:place.formatted_address,
                place_id:place.place_id,
                coord:{
                  lat:place.geometry.location.lat(),
                  lng:place.geometry.location.lng()
                  }
              };
              this.patientClone.geo=Object.assign({},geo);
              this.editAdress=false;
            } else {
              document.getElementById('autocomplete_patx').value = 'Enter a valid address';
            }
          });

       }).catch((error)=> {
            this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
          });
    },
    formatPhoneNumber(phoneNumberString) {
            if (phoneNumberString) {
                let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
                let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                    let intlCode = match[1] ? '+1 ' : '';
                    return [
                        intlCode,
                        '(',
                        match[2],
                        ') ',
                        match[3],
                        '-',
                        match[4],
                    ].join('');
                }
                return phoneNumberString;
            }
        },
        setSendToArray(checked,wich){
          if(checked)this.patientClone.sendToArray.push(wich)
          else this.patientClone.sendToArray.splice(this.patientClone.sendToArray.indexOf(wich),1)
        }
        
    },
    watch:{
    onClinic(){ // obsers if the getter onClinic changes. if its true navigates back. used to get out of the edit pages if the user selects another clinic on the left pannel
        if(this.$f7.views.main.router.url=='/editPatient'){
            if(!this.focusedAccount.privx.clinician){
           this.$f7.views.main.router.navigate('/settings')
         }else{
         this.$f7.views.main.router.navigate('/patients')
       }
        } 
    }
  },
  };
</script>
<style>
.listagris li{
  background:#efefef!important;
 }

.listagris li input{
   background: #fff!important;
    padding-left: 8px!important;
    border-radius: 10px;
 }
  </style>