let BMI=0
let Age_Months=0  


let n = new Array;
    let L = 0;
    let M = 0;
    let S = 0;
    
    n[0] = 50;
    n[1] = 50.399;
    n[2] = 50.798;
    n[3] = 51.197;
    n[4] = 51.595;
    n[5] = 51.994;
    n[6] = 52.392;
    n[7] = 52.79;
    n[8] = 53.188;
    n[9] = 53.586;
    n[10] = 53.983;
    n[11] = 54.38;
    n[12] = 54.776;
    n[13] = 55.172;
    n[14] = 55.567;
    n[15] = 55.962;
    n[16] = 56.356;
    n[17] = 56.749;
    n[18] = 57.142;
    n[19] = 57.535;
    n[20] = 57.926;
    n[21] = 58.317;
    n[22] = 58.706;
    n[23] = 59.095;
    n[24] = 59.483;
    n[25] = 59.871;
    n[26] = 60.257;
    n[27] = 60.642;
    n[28] = 61.026;
    n[29] = 61.409;
    n[30] = 61.791;
    n[31] = 62.172;
    n[32] = 62.552;
    n[33] = 62.93;
    n[34] = 63.307;
    n[35] = 63.683;
    n[36] = 64.058;
    n[37] = 64.431;
    n[38] = 64.803;
    n[39] = 65.173;
    n[40] = 65.542;
    n[41] = 65.91;
    n[42] = 66.276;
    n[43] = 66.64;
    n[44] = 67.003;
    n[45] = 67.364;
    n[46] = 67.724;
    n[47] = 68.082;
    n[48] = 68.439;
    n[49] = 68.793;
    n[50] = 69.146;
    n[51] = 69.497;
    n[52] = 69.847;
    n[53] = 70.194;
    n[54] = 70.54;
    n[55] = 70.884;
    n[56] = 71.226;
    n[57] = 71.566;
    n[58] = 71.904;
    n[59] = 72.24;
    n[60] = 72.575;
    n[61] = 72.907;
    n[62] = 73.237;
    n[63] = 73.565;
    n[64] = 73.891;
    n[65] = 74.215;
    n[66] = 74.537;
    n[67] = 74.857;
    n[68] = 75.175;
    n[69] = 75.49;
    n[70] = 75.804;
    n[71] = 76.115;
    n[72] = 76.424;
    n[73] = 76.73;
    n[74] = 77.035;
    n[75] = 77.337;
    n[76] = 77.637;
    n[77] = 77.935;
    n[78] = 78.23;
    n[79] = 78.524;
    n[80] = 78.814;
    n[81] = 79.103;
    n[82] = 79.389;
    n[83] = 79.673;
    n[84] = 79.955;
    n[85] = 80.234;
    n[86] = 80.511;
    n[87] = 80.785;
    n[88] = 81.057;
    n[89] = 81.327;
    n[90] = 81.594;
    n[91] = 81.859;
    n[92] = 82.121;
    n[93] = 82.381;
    n[94] = 82.639;
    n[95] = 82.894;
    n[96] = 83.147;
    n[97] = 83.398;
    n[98] = 83.646;
    n[99] = 83.891;
    n[100] = 84.134;
    n[101] = 84.375;
    n[102] = 84.614;
    n[103] = 84.849;
    n[104] = 85.083;
    n[105] = 85.314;
    n[106] = 85.543;
    n[107] = 85.769;
    n[108] = 85.993;
    n[109] = 86.214;
    n[110] = 86.433;
    n[111] = 86.65;
    n[112] = 86.864;
    n[113] = 87.076;
    n[114] = 87.286;
    n[115] = 87.493;
    n[116] = 87.698;
    n[117] = 87.9;
    n[118] = 88.1;
    n[119] = 88.298;
    n[120] = 88.493;
    n[121] = 88.686;
    n[122] = 88.877;
    n[123] = 89.065;
    n[124] = 89.251;
    n[125] = 89.435;
    n[126] = 89.617;
    n[127] = 89.796;
    n[128] = 89.973;
    n[129] = 90.147;
    n[130] = 90.32;
    n[131] = 90.49;
    n[132] = 90.658;
    n[133] = 90.824;
    n[134] = 90.988;
    n[135] = 91.149;
    n[136] = 91.308;
    n[137] = 91.466;
    n[138] = 91.621;
    n[139] = 91.774;
    n[140] = 91.924;
    n[141] = 92.073;
    n[142] = 92.22;
    n[143] = 92.364;
    n[144] = 92.507;
    n[145] = 92.647;
    n[146] = 92.785;
    n[147] = 92.922;
    n[148] = 93.056;
    n[149] = 93.189;
    n[150] = 93.319;
    n[151] = 93.448;
    n[152] = 93.574;
    n[153] = 93.699;
    n[154] = 93.822;
    n[155] = 93.943;
    n[156] = 94.062;
    n[157] = 94.179;
    n[158] = 94.295;
    n[159] = 94.408;
    n[160] = 94.52;
    n[161] = 94.63;
    n[162] = 94.738;
    n[163] = 94.845;
    n[164] = 94.95;
    n[165] = 95.053;
    n[166] = 95.154;
    n[167] = 95.254;
    n[168] = 95.352;
    n[169] = 95.449;
    n[170] = 95.543;
    n[171] = 95.637;
    n[172] = 95.728;
    n[173] = 95.818;
    n[174] = 95.907;
    n[175] = 95.994;
    n[176] = 96.08;
    n[177] = 96.164;
    n[178] = 96.246;
    n[179] = 96.327;
    n[180] = 96.407;
    n[181] = 96.485;
    n[182] = 96.562;
    n[183] = 96.638;
    n[184] = 96.712;
    n[185] = 96.784;
    n[186] = 96.856;
    n[187] = 96.926;
    n[188] = 96.995;
    n[189] = 97.062;
    n[190] = 97.128;
    n[191] = 97.193;
    n[192] = 97.257;
    n[193] = 97.32;
    n[194] = 97.381;
    n[195] = 97.441;
    n[196] = 97.5;
    n[197] = 97.558;
    n[198] = 97.615;
    n[199] = 97.67;
    n[200] = 97.725;
    n[201] = 97.778;
    n[202] = 97.831;
    n[203] = 97.882;
    n[204] = 97.932;
    n[205] = 97.982;
    n[206] = 98.03;
    n[207] = 98.077;
    n[208] = 98.124;
    n[209] = 98.169;
    n[210] = 98.214;
    n[211] = 98.257;
    n[212] = 98.3;
    n[213] = 98.341;
    n[214] = 98.382;
    n[215] = 98.422;
    n[216] = 98.461;
    n[217] = 98.5;
    n[218] = 98.537;
    n[219] = 98.574;
    n[220] = 98.61;
    n[221] = 98.645;
    n[222] = 98.679;
    n[223] = 98.713;
    n[224] = 98.745;
    n[225] = 98.778;
    n[226] = 98.809;
    n[227] = 98.84;
    n[228] = 98.87;
    n[229] = 98.899;
    n[230] = 98.928;
    n[231] = 98.956;
    n[232] = 98.983;
    n[233] = 99.01;
    n[234] = 99.036;
    n[235] = 99.061;
    n[236] = 99.086;
    n[237] = 99.111;
    n[238] = 99.134;
    n[239] = 99.158;
    n[240] = 99.18;
    n[241] = 99.202;
    n[242] = 99.224;
    n[243] = 99.245;
    n[244] = 99.266;
    n[245] = 99.286;
    n[246] = 99.305;
    n[247] = 99.324;
    n[248] = 99.343;
    n[249] = 99.361;
    n[250] = 99.379;
    n[251] = 99.396;
    n[252] = 99.413;
    n[253] = 99.43;
    n[254] = 99.446;
    n[255] = 99.461;
    n[256] = 99.477;
    n[257] = 99.492;
    n[258] = 99.506;
    n[259] = 99.52;
    n[260] = 99.534;
    n[261] = 99.547;
    n[262] = 99.56;
    n[263] = 99.573;
    n[264] = 99.585;
    n[265] = 99.598;
    n[266] = 99.609;
    n[267] = 99.621;
    n[268] = 99.632;
    n[269] = 99.643;
    n[270] = 99.653;
    n[271] = 99.664;
    n[272] = 99.674;
    n[273] = 99.683;
    n[274] = 99.693;
    n[275] = 99.702;
    n[276] = 99.711;
    n[277] = 99.72;
    n[278] = 99.728;
    n[279] = 99.736;
    n[280] = 99.744;
    n[281] = 99.752;
    n[282] = 99.76;
    n[283] = 99.767;
    n[284] = 99.774;
    n[285] = 99.781;
    n[286] = 99.788;
    n[287] = 99.795;
    n[288] = 99.801;
    n[289] = 99.807;
    n[290] = 99.813;
    n[291] = 99.819;
    n[292] = 99.825;
    n[293] = 99.831;
    n[294] = 99.836;
    n[295] = 99.841;
    n[296] = 99.846;
    n[297] = 99.851;
    n[298] = 99.856;
    n[299] = 99.861;
    n[300] = 99.865;
    n[301] = 99.869;
    n[302] = 99.874;
    n[303] = 99.878;
    n[304] = 99.882;
    n[305] = 99.886;
    n[306] = 99.889;
    n[307] = 99.893;
    n[308] = 99.896;
    n[309] = 99.9;
    
   


  
export default function calculateGrownChart(BMI,Age_Months,sex){
    
    let L = 0; let M = 0; let S = 0; 
if (sex=='female'){
if(Age_Months < 25){L = -1.024496827; M = 16.38804056; S = 0.085025838;}
else if(Age_Months < 26){L = -1.102698353; M = 16.3189719; S = 0.084214052;}
else if(Age_Months < 27){L = -1.18396635; M = 16.25207985; S = 0.083455124;}
else if(Age_Months < 28){L = -1.268071036; M = 16.18734669; S = 0.082748284;}
else if(Age_Months < 29){L = -1.354751525; M = 16.12475448; S = 0.082092737;}
else if(Age_Months < 30){L = -1.443689692; M = 16.06428762; S = 0.081487717;}
else if(Age_Months < 31){L = -1.53454192; M = 16.00593001; S = 0.080932448;}
else if(Age_Months < 32){L = -1.626928093; M = 15.94966631; S = 0.080426175;}
else if(Age_Months < 33){L = -1.720434829; M = 15.89548197; S = 0.079968176;}
else if(Age_Months < 34){L = -1.814635262; M = 15.84336179; S = 0.079557735;}
else if(Age_Months < 35){L = -1.909076262; M = 15.79329146; S = 0.079194187;}
else if(Age_Months < 36){L = -2.003296102; M = 15.7452564; S = 0.078876895;}
else if(Age_Months < 37){L = -2.096828937; M = 15.69924188; S = 0.078605255;}
else if(Age_Months < 38){L = -2.189211877; M = 15.65523282; S = 0.078378696;}
else if(Age_Months < 39){L = -2.279991982; M = 15.61321371; S = 0.078196674;}
else if(Age_Months < 40){L = -2.368732949; M = 15.57316843; S = 0.078058667;}
else if(Age_Months < 41){L = -2.455021314; M = 15.53508019; S = 0.077964169;}
else if(Age_Months < 42){L = -2.538471972; M = 15.49893145; S = 0.077912684;}
else if(Age_Months < 43){L = -2.618732901; M = 15.46470384; S = 0.077903716;}
else if(Age_Months < 44){L = -2.695488973; M = 15.43237817; S = 0.077936763;}
else if(Age_Months < 45){L = -2.768464816; M = 15.40193436; S = 0.078011309;}
else if(Age_Months < 46){L = -2.837426693; M = 15.37335154; S = 0.078126817;}
else if(Age_Months < 47){L = -2.902178205; M = 15.34660842; S = 0.078282739;}
else if(Age_Months < 48){L = -2.962580386; M = 15.32168181; S = 0.078478449;}
else if(Age_Months < 49){L = -3.018521987; M = 15.29854897; S = 0.078713325;}
else if(Age_Months < 50){L = -3.069936555; M = 15.27718618; S = 0.078986694;}
else if(Age_Months < 51){L = -3.116795864; M = 15.2575692; S = 0.079297841;}
else if(Age_Months < 52){L = -3.159107331; M = 15.23967338; S = 0.079646006;}
else if(Age_Months < 53){L = -3.196911083; M = 15.22347371; S = 0.080030389;}
else if(Age_Months < 54){L = -3.230276759; M = 15.20894491; S = 0.080450145;}
else if(Age_Months < 55){L = -3.259300182; M = 15.19606152; S = 0.080904391;}
else if(Age_Months < 56){L = -3.284099963; M = 15.18479799; S = 0.081392203;}
else if(Age_Months < 57){L = -3.30481415; M = 15.17512871; S = 0.081912623;}
else if(Age_Months < 58){L = -3.321596954; M = 15.16702811; S = 0.082464661;}
else if(Age_Months < 59){L = -3.334615646; M = 15.16047068; S = 0.083047295;}
else if(Age_Months < 60){L = -3.344047622; M = 15.15543107; S = 0.083659478;}
else if(Age_Months < 61){L = -3.35007771; M = 15.15188405; S = 0.084300139;}
else if(Age_Months < 62){L = -3.352893805; M = 15.14980479; S = 0.0849682;}
else if(Age_Months < 63){L = -3.352691376; M = 15.14916825; S = 0.085662539;}
else if(Age_Months < 64){L = -3.34966438; M = 15.14994984; S = 0.086382035;}
else if(Age_Months < 65){L = -3.343998803; M = 15.15212585; S = 0.087125591;}
else if(Age_Months < 66){L = -3.335889574; M = 15.15567186; S = 0.087892047;}
else if(Age_Months < 67){L = -3.325522491; M = 15.16056419; S = 0.088680264;}
else if(Age_Months < 68){L = -3.31307846; M = 15.16677947; S = 0.089489106;}
else if(Age_Months < 69){L = -3.298732648; M = 15.17429464; S = 0.090317434;}
else if(Age_Months < 70){L = -3.282653831; M = 15.18308694; S = 0.091164117;}
else if(Age_Months < 71){L = -3.265003896; M = 15.1931339; S = 0.092028028;}
else if(Age_Months < 72){L = -3.245937506; M = 15.20441335; S = 0.092908048;}
else if(Age_Months < 73){L = -3.225606516; M = 15.21690296; S = 0.093803033;}
else if(Age_Months < 74){L = -3.204146115; M = 15.2305815; S = 0.094711916;}
else if(Age_Months < 75){L = -3.181690237; M = 15.24542745; S = 0.095633595;}
else if(Age_Months < 76){L = -3.158363475; M = 15.26141966; S = 0.096566992;}
else if(Age_Months < 77){L = -3.134282833; M = 15.27853728; S = 0.097511046;}
else if(Age_Months < 78){L = -3.109557879; M = 15.29675967; S = 0.09846471;}
else if(Age_Months < 79){L = -3.084290931; M = 15.31606644; S = 0.099426955;}
else if(Age_Months < 80){L = -3.058577292; M = 15.33643745; S = 0.100396769;}
else if(Age_Months < 81){L = -3.032505499; M = 15.35785274; S = 0.101373159;}
else if(Age_Months < 82){L = -3.0061576; M = 15.38029261; S = 0.10235515;}
else if(Age_Months < 83){L = -2.979609448; M = 15.40373754; S = 0.103341788;}
else if(Age_Months < 84){L = -2.952930993; M = 15.42816819; S = 0.104332139;}
else if(Age_Months < 85){L = -2.926186592; M = 15.45356545; S = 0.105325289;}
else if(Age_Months < 86){L = -2.899435307; M = 15.47991037; S = 0.106320346;}
else if(Age_Months < 87){L = -2.872731211; M = 15.50718419; S = 0.10731644;}
else if(Age_Months < 88){L = -2.846123683; M = 15.53536829; S = 0.108312721;}
else if(Age_Months < 89){L = -2.819657704; M = 15.56444426; S = 0.109308364;}
else if(Age_Months < 90){L = -2.793374145; M = 15.5943938; S = 0.110302563;}
else if(Age_Months < 91){L = -2.767310047; M = 15.6251988; S = 0.111294537;}
else if(Age_Months < 92){L = -2.741498897; M = 15.65684126; S = 0.112283526;}
else if(Age_Months < 93){L = -2.715970894; M = 15.68930333; S = 0.113268793;}
else if(Age_Months < 94){L = -2.690753197; M = 15.7225673; S = 0.114249622;}
else if(Age_Months < 95){L = -2.665870146; M = 15.75661555; S = 0.115225321;}
else if(Age_Months < 96){L = -2.641343436; M = 15.79143062; S = 0.116195218;}
else if(Age_Months < 97){L = -2.617192204; M = 15.82699517; S = 0.117158667;}
else if(Age_Months < 98){L = -2.593430614; M = 15.86329241; S = 0.118115073;}
else if(Age_Months < 99){L = -2.570076037; M = 15.90030484; S = 0.119063807;}
else if(Age_Months < 100){L = -2.547141473; M = 15.93801545; S = 0.12000429;}
else if(Age_Months < 101){L = -2.524635245; M = 15.97640787; S = 0.120935994;}
else if(Age_Months < 102){L = -2.502569666; M = 16.01546483; S = 0.121858355;}
else if(Age_Months < 103){L = -2.48095189; M = 16.05516984; S = 0.12277087;}
else if(Age_Months < 104){L = -2.459785573; M = 16.09550688; S = 0.123673085;}
else if(Age_Months < 105){L = -2.439080117; M = 16.13645881; S = 0.124564484;}
else if(Age_Months < 106){L = -2.418838304; M = 16.17800955; S = 0.125444639;}
else if(Age_Months < 107){L = -2.399063683; M = 16.22014281; S = 0.126313121;}
else if(Age_Months < 108){L = -2.379756861; M = 16.26284277; S = 0.127169545;}
else if(Age_Months < 109){L = -2.360920527; M = 16.30609316; S = 0.128013515;}
else if(Age_Months < 110){L = -2.342557728; M = 16.34987759; S = 0.128844639;}
else if(Age_Months < 111){L = -2.324663326; M = 16.39418118; S = 0.129662637;}
else if(Age_Months < 112){L = -2.307240716; M = 16.43898741; S = 0.130467138;}
else if(Age_Months < 113){L = -2.290287663; M = 16.48428082; S = 0.131257852;}
else if(Age_Months < 114){L = -2.273803847; M = 16.53004554; S = 0.132034479;}
else if(Age_Months < 115){L = -2.257782149; M = 16.57626713; S = 0.132796819;}
else if(Age_Months < 116){L = -2.242227723; M = 16.62292864; S = 0.133544525;}
else if(Age_Months < 117){L = -2.227132805; M = 16.67001572; S = 0.134277436;}
else if(Age_Months < 118){L = -2.212495585; M = 16.71751288; S = 0.134995324;}
else if(Age_Months < 119){L = -2.19831275; M = 16.76540496; S = 0.135697996;}
else if(Age_Months < 120){L = -2.184580762; M = 16.81367689; S = 0.136385276;}
else if(Age_Months < 121){L = -2.171295888; M = 16.86231366; S = 0.137057004;}
else if(Age_Months < 122){L = -2.158454232; M = 16.91130036; S = 0.137713039;}
else if(Age_Months < 123){L = -2.146051754; M = 16.96062216; S = 0.138353254;}
else if(Age_Months < 124){L = -2.134084303; M = 17.0102643; S = 0.138977537;}
else if(Age_Months < 125){L = -2.122547629; M = 17.06021213; S = 0.139585795;}
else if(Age_Months < 126){L = -2.111437411; M = 17.11045106; S = 0.140177947;}
else if(Age_Months < 127){L = -2.100749266; M = 17.16096656; S = 0.140753927;}
else if(Age_Months < 128){L = -2.090478774; M = 17.21174424; S = 0.141313686;}
else if(Age_Months < 129){L = -2.080621484; M = 17.26276973; S = 0.141857186;}
else if(Age_Months < 130){L = -2.071172932; M = 17.31402878; S = 0.142384404;}
else if(Age_Months < 131){L = -2.062128649; M = 17.3655072; S = 0.142895332;}
else if(Age_Months < 132){L = -2.053484173; M = 17.4171909; S = 0.143389972;}
else if(Age_Months < 133){L = -2.045235058; M = 17.46906585; S = 0.143868341;}
else if(Age_Months < 134){L = -2.03737688; M = 17.52111811; S = 0.144330469;}
else if(Age_Months < 135){L = -2.029906684; M = 17.57333347; S = 0.144776372;}
else if(Age_Months < 136){L = -2.022817914; M = 17.62569869; S = 0.145206138;}
else if(Age_Months < 137){L = -2.016107084; M = 17.67819987; S = 0.145619819;}
else if(Age_Months < 138){L = -2.009769905; M = 17.7308234; S = 0.146017491;}
else if(Age_Months < 139){L = -2.003802134; M = 17.78355575; S = 0.146399239;}
else if(Age_Months < 140){L = -1.998199572; M = 17.83638347; S = 0.146765161;}
else if(Age_Months < 141){L = -1.992958064; M = 17.88929321; S = 0.147115364;}
else if(Age_Months < 142){L = -1.988073505; M = 17.94227168; S = 0.147449967;}
else if(Age_Months < 143){L = -1.983541835; M = 17.9953057; S = 0.147769097;}
else if(Age_Months < 144){L = -1.979359041; M = 18.04838216; S = 0.148072891;}
else if(Age_Months < 145){L = -1.975521156; M = 18.10148804; S = 0.148361495;}
else if(Age_Months < 146){L = -1.972024258; M = 18.15461039; S = 0.148635067;}
else if(Age_Months < 147){L = -1.968864465; M = 18.20773639; S = 0.148893769;}
else if(Age_Months < 148){L = -1.966037938; M = 18.26085325; S = 0.149137776;}
else if(Age_Months < 149){L = -1.963540872; M = 18.31394832; S = 0.14936727;}
else if(Age_Months < 150){L = -1.961369499; M = 18.36700902; S = 0.149582439;}
else if(Age_Months < 151){L = -1.959520079; M = 18.42002284; S = 0.149783482;}
else if(Age_Months < 152){L = -1.9579889; M = 18.47297739; S = 0.149970604;}
else if(Age_Months < 153){L = -1.956772271; M = 18.52586035; S = 0.15014402;}
else if(Age_Months < 154){L = -1.95586652; M = 18.57865951; S = 0.15030395;}
else if(Age_Months < 155){L = -1.955267984; M = 18.63136275; S = 0.150450621;}
else if(Age_Months < 156){L = -1.954973011; M = 18.68395801; S = 0.15058427;}
else if(Age_Months < 157){L = -1.954977947; M = 18.73643338; S = 0.150705138;}
else if(Age_Months < 158){L = -1.955279136; M = 18.788777; S = 0.150813475;}
else if(Age_Months < 159){L = -1.955872909; M = 18.84097713; S = 0.150909535;}
else if(Age_Months < 160){L = -1.956755579; M = 18.89302212; S = 0.150993582;}
else if(Age_Months < 161){L = -1.957923436; M = 18.94490041; S = 0.151065883;}
else if(Age_Months < 162){L = -1.959372737; M = 18.99660055; S = 0.151126714;}
else if(Age_Months < 163){L = -1.9610997; M = 19.04811118; S = 0.151176355;}
else if(Age_Months < 164){L = -1.963100496; M = 19.09942105; S = 0.151215094;}
else if(Age_Months < 165){L = -1.96537124; M = 19.15051899; S = 0.151243223;}
else if(Age_Months < 166){L = -1.967907983; M = 19.20139397; S = 0.151261042;}
else if(Age_Months < 167){L = -1.970706706; M = 19.25203503; S = 0.151268855;}
else if(Age_Months < 168){L = -1.973763307; M = 19.30243131; S = 0.151266974;}
else if(Age_Months < 169){L = -1.977073595; M = 19.35257209; S = 0.151255713;}
else if(Age_Months < 170){L = -1.980633277; M = 19.40244671; S = 0.151235395;}
else if(Age_Months < 171){L = -1.984437954; M = 19.45204465; S = 0.151206347;}
else if(Age_Months < 172){L = -1.988483106; M = 19.50135548; S = 0.151168902;}
else if(Age_Months < 173){L = -1.992764085; M = 19.55036888; S = 0.151123398;}
else if(Age_Months < 174){L = -1.997276103; M = 19.59907464; S = 0.15107018;}
else if(Age_Months < 175){L = -2.002014224; M = 19.64746266; S = 0.151009595;}
else if(Age_Months < 176){L = -2.00697335; M = 19.69552294; S = 0.150942;}
else if(Age_Months < 177){L = -2.012148213; M = 19.7432456; S = 0.150867753;}
else if(Age_Months < 178){L = -2.017533363; M = 19.79062086; S = 0.150787221;}
else if(Age_Months < 179){L = -2.023123159; M = 19.83763907; S = 0.150700774;}
else if(Age_Months < 180){L = -2.028911755; M = 19.88429066; S = 0.150608788;}
else if(Age_Months < 181){L = -2.034893091; M = 19.9305662; S = 0.150511645;}
else if(Age_Months < 182){L = -2.041060881; M = 19.97645636; S = 0.150409731;}
else if(Age_Months < 183){L = -2.047408604; M = 20.02195192; S = 0.15030344;}
else if(Age_Months < 184){L = -2.05392949; M = 20.06704377; S = 0.150193169;}
else if(Age_Months < 185){L = -2.060616513; M = 20.11172291; S = 0.150079322;}
else if(Age_Months < 186){L = -2.067462375; M = 20.15598047; S = 0.149962308;}
else if(Age_Months < 187){L = -2.074459502; M = 20.19980767; S = 0.14984254;}
else if(Age_Months < 188){L = -2.081600029; M = 20.24319586; S = 0.149720441;}
else if(Age_Months < 189){L = -2.088875793; M = 20.28613648; S = 0.149596434;}
else if(Age_Months < 190){L = -2.096278323; M = 20.32862109; S = 0.149470953;}
else if(Age_Months < 191){L = -2.103798828; M = 20.37064138; S = 0.149344433;}
else if(Age_Months < 192){L = -2.111428194; M = 20.41218911; S = 0.149217319;}
else if(Age_Months < 193){L = -2.119156972; M = 20.45325617; S = 0.14909006;}
else if(Age_Months < 194){L = -2.126975375; M = 20.49383457; S = 0.14896311;}
else if(Age_Months < 195){L = -2.134873266; M = 20.5339164; S = 0.148836931;}
else if(Age_Months < 196){L = -2.142840157; M = 20.57349387; S = 0.148711989;}
else if(Age_Months < 197){L = -2.150865204; M = 20.61255929; S = 0.148588757;}
else if(Age_Months < 198){L = -2.158937201; M = 20.65110506; S = 0.148467715;}
else if(Age_Months < 199){L = -2.167044578; M = 20.6891237; S = 0.148349348;}
else if(Age_Months < 200){L = -2.175176987; M = 20.72660728; S = 0.14823412;}
else if(Age_Months < 201){L = -2.183317362; M = 20.76355011; S = 0.148122614;}
else if(Age_Months < 202){L = -2.191457792; M = 20.79994337; S = 0.148015249;}
else if(Age_Months < 203){L = -2.199583649; M = 20.83578051; S = 0.147912564;}
else if(Age_Months < 204){L = -2.207681525; M = 20.87105449; S = 0.147815078;}
else if(Age_Months < 205){L = -2.215737645; M = 20.90575839; S = 0.147723315;}
else if(Age_Months < 206){L = -2.223739902; M = 20.93988477; S = 0.147637768;}
else if(Age_Months < 207){L = -2.231667995; M = 20.97342858; S = 0.147559083;}
else if(Age_Months < 208){L = -2.239511942; M = 21.00638171; S = 0.147487716;}
else if(Age_Months < 209){L = -2.247257081; M = 21.0387374; S = 0.14742421;}
else if(Age_Months < 210){L = -2.254885145; M = 21.07048996; S = 0.147369174;}
else if(Age_Months < 211){L = -2.26238209; M = 21.10163241; S = 0.147323144;}
else if(Age_Months < 212){L = -2.269731517; M = 21.13215845; S = 0.147286698;}
else if(Age_Months < 213){L = -2.276917229; M = 21.16206171; S = 0.147260415;}
else if(Age_Months < 214){L = -2.283925442; M = 21.1913351; S = 0.147244828;}
else if(Age_Months < 215){L = -2.290731442; M = 21.21997472; S = 0.147240683;}
else if(Age_Months < 216){L = -2.29732427; M = 21.24797262; S = 0.147248467;}
else if(Age_Months < 217){L = -2.303687802; M = 21.27532239; S = 0.14726877;}
else if(Age_Months < 218){L = -2.309799971; M = 21.30201933; S = 0.147302299;}
else if(Age_Months < 219){L = -2.315651874; M = 21.32805489; S = 0.147349514;}
else if(Age_Months < 220){L = -2.32121731; M = 21.35342563; S = 0.147411215;}
else if(Age_Months < 221){L = -2.326481911; M = 21.37812462; S = 0.147487979;}
else if(Age_Months < 222){L = -2.331428139; M = 21.40214589; S = 0.147580453;}
else if(Age_Months < 223){L = -2.336038473; M = 21.42548351; S = 0.147689289;}
else if(Age_Months < 224){L = -2.34029545; M = 21.44813156; S = 0.14781515;}
else if(Age_Months < 225){L = -2.344181703; M = 21.47008412; S = 0.147958706;}
else if(Age_Months < 226){L = -2.34768; M = 21.49133529; S = 0.148120633;}
else if(Age_Months < 227){L = -2.350773286; M = 21.51187918; S = 0.148301619;}
else if(Age_Months < 228){L = -2.353444725; M = 21.53170989; S = 0.148502355;}
else if(Age_Months < 229){L = -2.355677743; M = 21.55082155; S = 0.148723546;}
else if(Age_Months < 230){L = -2.35745607; M = 21.56920824; S = 0.148965902;}
else if(Age_Months < 231){L = -2.358763788; M = 21.58686406; S = 0.149230142;}
else if(Age_Months < 232){L = -2.359585369; M = 21.60378309; S = 0.149516994;}
else if(Age_Months < 233){L = -2.359905726; M = 21.61995939; S = 0.149827195;}
else if(Age_Months < 234){L = -2.359710258; M = 21.635387; S = 0.150161492;}
else if(Age_Months < 235){L = -2.358980464; M = 21.65006126; S = 0.150520734;}
else if(Age_Months < 236){L = -2.357714508; M = 21.6639727; S = 0.150905439;}
else if(Age_Months < 237){L = -2.355892424; M = 21.67711736; S = 0.151316531;}
else if(Age_Months < 238){L = -2.353501353; M = 21.68948935; S = 0.151754808;}
else if(Age_Months < 239){L = -2.350528726; M = 21.70108288; S = 0.152221086;}
else if(Age_Months < 240){L = -2.346962247; M = 21.71189225; S = 0.152716206;}
else if(Age_Months < 241){L = -2.342796948; M = 21.72190973; S = 0.153240872;}
else{
    L = -2.342796948; M = 21.72190973; S = 0.153240872;
}
} else if(sex=='male'){
    if(Age_Months < 25){L = -1.982373595; M = 16.54777487; S = 0.080127429;}
    else if(Age_Months < 26){L = -1.924100169; M = 16.49442763; S = 0.079233994;}
    else if(Age_Months < 27){L = -1.86549793; M = 16.44259552; S = 0.078389356;}
    else if(Age_Months < 28){L = -1.807261899; M = 16.3922434; S = 0.077593501;}
    else if(Age_Months < 29){L = -1.750118905; M = 16.34333654; S = 0.076846462;}
    else if(Age_Months < 30){L = -1.69481584; M = 16.29584097; S = 0.076148308;}
    else if(Age_Months < 31){L = -1.642106779; M = 16.24972371; S = 0.075499126;}
    else if(Age_Months < 32){L = -1.592744414; M = 16.20495268; S = 0.074898994;}
    else if(Age_Months < 33){L = -1.547442391; M = 16.16149871; S = 0.074347997;}
    else if(Age_Months < 34){L = -1.506902601; M = 16.11933258; S = 0.073846139;}
    else if(Age_Months < 35){L = -1.471770047; M = 16.07842758; S = 0.07339337;}
    else if(Age_Months < 36){L = -1.442628957; M = 16.03875896; S = 0.072989551;}
    else if(Age_Months < 37){L = -1.419991255; M = 16.00030401; S = 0.072634432;}
    else if(Age_Months < 38){L = -1.404277619; M = 15.96304277; S = 0.072327649;}
    else if(Age_Months < 39){L = -1.39586317; M = 15.92695418; S = 0.07206864;}
    else if(Age_Months < 40){L = -1.394935252; M = 15.89202582; S = 0.071856805;}
    else if(Age_Months < 41){L = -1.401671596; M = 15.85824093; S = 0.071691278;}
    else if(Age_Months < 42){L = -1.416100312; M = 15.82558822; S = 0.071571093;}
    else if(Age_Months < 43){L = -1.438164899; M = 15.79405728; S = 0.071495113;}
    else if(Age_Months < 44){L = -1.467669032; M = 15.76364255; S = 0.071462106;}
    else if(Age_Months < 45){L = -1.504376347; M = 15.73433668; S = 0.071470646;}
    else if(Age_Months < 46){L = -1.547942838; M = 15.70613566; S = 0.071519218;}
    else if(Age_Months < 47){L = -1.597896397; M = 15.67904062; S = 0.071606277;}
    else if(Age_Months < 48){L = -1.653732283; M = 15.65305192; S = 0.071730167;}
    else if(Age_Months < 49){L = -1.714869347; M = 15.62817269; S = 0.071889214;}
    else if(Age_Months < 50){L = -1.780673181; M = 15.604408; S = 0.072081737;}
    else if(Age_Months < 51){L = -1.850468473; M = 15.58176458; S = 0.072306081;}
    else if(Age_Months < 52){L = -1.923551865; M = 15.56025067; S = 0.072560637;}
    else if(Age_Months < 53){L = -1.999220429; M = 15.5398746; S = 0.07284384;}
    else if(Age_Months < 54){L = -2.076707178; M = 15.52064993; S = 0.073154324;}
    else if(Age_Months < 55){L = -2.155348017; M = 15.50258427; S = 0.073490667;}
    else if(Age_Months < 56){L = -2.234438552; M = 15.48568973; S = 0.073851672;}
    else if(Age_Months < 57){L = -2.313321723; M = 15.46997718; S = 0.074236235;}
    else if(Age_Months < 58){L = -2.391381273; M = 15.45545692; S = 0.074643374;}
    else if(Age_Months < 59){L = -2.468032491; M = 15.44213961; S = 0.075072264;}
    else if(Age_Months < 60){L = -2.542781541; M = 15.43003207; S = 0.075522104;}
    else if(Age_Months < 61){L = -2.61516595; M = 15.41914163; S = 0.07599225;}
    else if(Age_Months < 62){L = -2.684789516; M = 15.40947356; S = 0.076482128;}
    else if(Age_Months < 63){L = -2.751316949; M = 15.40103139; S = 0.076991232;}
    else if(Age_Months < 64){L = -2.81445945; M = 15.39381785; S = 0.077519149;}
    else if(Age_Months < 65){L = -2.87402476; M = 15.38783094; S = 0.07806539;}
    else if(Age_Months < 66){L = -2.92984048; M = 15.38306945; S = 0.078629592;}
    else if(Age_Months < 67){L = -2.981796828; M = 15.37952958; S = 0.079211369;}
    else if(Age_Months < 68){L = -3.029831343; M = 15.37720582; S = 0.079810334;}
    else if(Age_Months < 69){L = -3.073924224; M = 15.37609107; S = 0.080426086;}
    else if(Age_Months < 70){L = -3.114093476; M = 15.37617677; S = 0.081058206;}
    else if(Age_Months < 71){L = -3.15039004; M = 15.37745304; S = 0.081706249;}
    else if(Age_Months < 72){L = -3.182893018; M = 15.37990886; S = 0.082369741;}
    else if(Age_Months < 73){L = -3.21170511; M = 15.38353217; S = 0.083048178;}
    else if(Age_Months < 74){L = -3.23694834; M = 15.38831005; S = 0.083741021;}
    else if(Age_Months < 75){L = -3.25876011; M = 15.39422883; S = 0.0844477;}
    else if(Age_Months < 76){L = -3.277281546; M = 15.40127496; S = 0.085167651;}
    else if(Age_Months < 77){L = -3.292683774; M = 15.40943252; S = 0.085900184;}
    else if(Age_Months < 78){L = -3.305124073; M = 15.41868691; S = 0.086644667;}
    else if(Age_Months < 79){L = -3.314768951; M = 15.42902273; S = 0.087400421;}
    else if(Age_Months < 80){L = -3.321785992; M = 15.44042439; S = 0.088166744;}
    else if(Age_Months < 81){L = -3.326345795; M = 15.45287581; S = 0.088942897;}
    else if(Age_Months < 82){L = -3.328602731; M = 15.46636218; S = 0.089728202;}
    else if(Age_Months < 83){L = -3.328725277; M = 15.48086704; S = 0.090521875;}
    else if(Age_Months < 84){L = -3.32687018; M = 15.49637465; S = 0.091323162;}
    else if(Age_Months < 85){L = -3.323188896; M = 15.51286936; S = 0.092131305;}
    else if(Age_Months < 86){L = -3.317827016; M = 15.53033563; S = 0.092945544;}
    else if(Age_Months < 87){L = -3.310923871; M = 15.54875807; S = 0.093765118;}
    else if(Age_Months < 88){L = -3.302612272; M = 15.56812143; S = 0.09458927;}
    else if(Age_Months < 89){L = -3.293018361; M = 15.58841065; S = 0.095417247;}
    else if(Age_Months < 90){L = -3.282260813; M = 15.60961101; S = 0.096248301;}
    else if(Age_Months < 91){L = -3.270454609; M = 15.63170735; S = 0.097081694;}
    else if(Age_Months < 92){L = -3.257703616; M = 15.65468563; S = 0.097916698;}
    else if(Age_Months < 93){L = -3.244108214; M = 15.67853139; S = 0.098752593;}
    else if(Age_Months < 94){L = -3.229761713; M = 15.70323052; S = 0.099588675;}
    else if(Age_Months < 95){L = -3.214751287; M = 15.72876911; S = 0.100424251;}
    else if(Age_Months < 96){L = -3.199158184; M = 15.75513347; S = 0.101258643;}
    else if(Age_Months < 97){L = -3.18305795; M = 15.78231007; S = 0.102091189;}
    else if(Age_Months < 98){L = -3.166520664; M = 15.8102856; S = 0.102921245;}
    else if(Age_Months < 99){L = -3.1496103; M = 15.83904708; S = 0.103748189;}
    else if(Age_Months < 100){L = -3.132389637; M = 15.86858123; S = 0.104571386;}
    else if(Age_Months < 101){L = -3.114911153; M = 15.89887562; S = 0.105390269;}
    else if(Age_Months < 102){L = -3.097226399; M = 15.92991765; S = 0.106204258;}
    else if(Age_Months < 103){L = -3.079383079; M = 15.96169481; S = 0.107012788;}
    else if(Age_Months < 104){L = -3.061423765; M = 15.99419489; S = 0.107815327;}
    else if(Age_Months < 105){L = -3.043386071; M = 16.02740607; S = 0.108611374;}
    else if(Age_Months < 106){L = -3.025310003; M = 16.0613159; S = 0.109400388;}
    else if(Age_Months < 107){L = -3.007225737; M = 16.09591292; S = 0.110181915;}
    else if(Age_Months < 108){L = -2.989164598; M = 16.13118532; S = 0.110955478;}
    else if(Age_Months < 109){L = -2.971148225; M = 16.16712234; S = 0.111720691;}
    else if(Age_Months < 110){L = -2.953208047; M = 16.20371168; S = 0.112477059;}
    else if(Age_Months < 111){L = -2.935363951; M = 16.24094239; S = 0.1132242;}
    else if(Age_Months < 112){L = -2.917635157; M = 16.27880346; S = 0.113961734;}
    else if(Age_Months < 113){L = -2.900039803; M = 16.31728385; S = 0.114689291;}
    else if(Age_Months < 114){L = -2.882593796; M = 16.35637267; S = 0.115406523;}
    else if(Age_Months < 115){L = -2.865311266; M = 16.39605916; S = 0.116113097;}
    else if(Age_Months < 116){L = -2.848204697; M = 16.43633265; S = 0.116808702;}
    else if(Age_Months < 117){L = -2.831285052; M = 16.47718256; S = 0.117493042;}
    else if(Age_Months < 118){L = -2.81456189; M = 16.51859843; S = 0.11816584;}
    else if(Age_Months < 119){L = -2.79804347; M = 16.56056987; S = 0.118826835;}
    else if(Age_Months < 120){L = -2.781736856; M = 16.60308661; S = 0.119475785;}
    else if(Age_Months < 121){L = -2.765648008; M = 16.64613844; S = 0.120112464;}
    else if(Age_Months < 122){L = -2.749782197; M = 16.68971518; S = 0.120736656;}
    else if(Age_Months < 123){L = -2.734142443; M = 16.73380695; S = 0.121348181;}
    else if(Age_Months < 124){L = -2.718732873; M = 16.77840363; S = 0.121946849;}
    else if(Age_Months < 125){L = -2.703555506; M = 16.82349538; S = 0.122532501;}
    else if(Age_Months < 126){L = -2.688611957; M = 16.86907238; S = 0.123104991;}
    else if(Age_Months < 127){L = -2.673903164; M = 16.91512487; S = 0.123664186;}
    else if(Age_Months < 128){L = -2.659429443; M = 16.96164317; S = 0.124209969;}
    else if(Age_Months < 129){L = -2.645190534; M = 17.00861766; S = 0.124742239;}
    else if(Age_Months < 130){L = -2.631185649; M = 17.05603879; S = 0.125260905;}
    else if(Age_Months < 131){L = -2.617413511; M = 17.10389705; S = 0.125765895;}
    else if(Age_Months < 132){L = -2.603872392; M = 17.15218302; S = 0.126257147;}
    else if(Age_Months < 133){L = -2.590560148; M = 17.20088732; S = 0.126734613;}
    else if(Age_Months < 134){L = -2.577474253; M = 17.25000062; S = 0.12719826;}
    else if(Age_Months < 135){L = -2.564611831; M = 17.29951367; S = 0.127648067;}
    else if(Age_Months < 136){L = -2.551969684; M = 17.34941726; S = 0.128084023;}
    else if(Age_Months < 137){L = -2.539539972; M = 17.39970308; S = 0.128506192;}
    else if(Age_Months < 138){L = -2.527325681; M = 17.45036072; S = 0.128914497;}
    else if(Age_Months < 139){L = -2.515320235; M = 17.50138161; S = 0.129309001;}
    else if(Age_Months < 140){L = -2.503519447; M = 17.55275674; S = 0.129689741;}
    else if(Age_Months < 141){L = -2.491918934; M = 17.60447714; S = 0.130056765;}
    else if(Age_Months < 142){L = -2.480514136; M = 17.6565339; S = 0.130410133;}
    else if(Age_Months < 143){L = -2.469300331; M = 17.70891811; S = 0.130749913;}
    else if(Age_Months < 144){L = -2.458272656; M = 17.76162094; S = 0.131076187;}
    else if(Age_Months < 145){L = -2.447426113; M = 17.81463359; S = 0.131389042;}
    else if(Age_Months < 146){L = -2.436755595; M = 17.86794729; S = 0.131688579;}
    else if(Age_Months < 147){L = -2.426255887; M = 17.92155332; S = 0.131974905;}
    else if(Age_Months < 148){L = -2.415921689; M = 17.97544299; S = 0.132248138;}
    else if(Age_Months < 149){L = -2.405747619; M = 18.02960765; S = 0.132508403;}
    else if(Age_Months < 150){L = -2.395728233; M = 18.08403868; S = 0.132755834;}
    else if(Age_Months < 151){L = -2.385858029; M = 18.1387275; S = 0.132990575;}
    else if(Age_Months < 152){L = -2.376131459; M = 18.19366555; S = 0.133212776;}
    else if(Age_Months < 153){L = -2.366542942; M = 18.24884431; S = 0.133422595;}
    else if(Age_Months < 154){L = -2.357086871; M = 18.3042553; S = 0.133620197;}
    else if(Age_Months < 155){L = -2.347757625; M = 18.35989003; S = 0.133805756;}
    else if(Age_Months < 156){L = -2.338549576; M = 18.41574009; S = 0.133979452;}
    else if(Age_Months < 157){L = -2.3294571; M = 18.47179706; S = 0.13414147;}
    else if(Age_Months < 158){L = -2.320474586; M = 18.52805255; S = 0.134292005;}
    else if(Age_Months < 159){L = -2.311596446; M = 18.5844982; S = 0.134431256;}
    else if(Age_Months < 160){L = -2.302817124; M = 18.64112567; S = 0.134559427;}
    else if(Age_Months < 161){L = -2.294131107; M = 18.69792663; S = 0.134676731;}
    else if(Age_Months < 162){L = -2.285532933; M = 18.75489278; S = 0.134783385;}
    else if(Age_Months < 163){L = -2.277017201; M = 18.81201584; S = 0.134879611;}
    else if(Age_Months < 164){L = -2.268578584; M = 18.86928753; S = 0.134965637;}
    else if(Age_Months < 165){L = -2.260211837; M = 18.92669959; S = 0.135041695;}
    else if(Age_Months < 166){L = -2.251911809; M = 18.98424378; S = 0.135108024;}
    else if(Age_Months < 167){L = -2.243673453; M = 19.04191185; S = 0.135164867;}
    else if(Age_Months < 168){L = -2.235491842; M = 19.09969557; S = 0.135212469;}
    else if(Age_Months < 169){L = -2.227362173; M = 19.15758672; S = 0.135251083;}
    else if(Age_Months < 170){L = -2.21927979; M = 19.21557707; S = 0.135280963;}
    else if(Age_Months < 171){L = -2.211240187; M = 19.27365839; S = 0.135302371;}
    else if(Age_Months < 172){L = -2.203239029; M = 19.33182247; S = 0.135315568;}
    else if(Age_Months < 173){L = -2.195272161; M = 19.39006106; S = 0.135320824;}
    else if(Age_Months < 174){L = -2.187335625; M = 19.44836594; S = 0.135318407;}
    else if(Age_Months < 175){L = -2.179425674; M = 19.50672885; S = 0.135308594;}
    else if(Age_Months < 176){L = -2.171538789; M = 19.56514153; S = 0.135291662;}
    else if(Age_Months < 177){L = -2.163671689; M = 19.62359571; S = 0.135267891;}
    else if(Age_Months < 178){L = -2.155821357; M = 19.6820831; S = 0.135237567;}
    else if(Age_Months < 179){L = -2.147985046; M = 19.74059538; S = 0.135200976;}
    else if(Age_Months < 180){L = -2.140160305; M = 19.7991242; S = 0.135158409;}
    else if(Age_Months < 181){L = -2.132344989; M = 19.85766121; S = 0.135110159;}
    else if(Age_Months < 182){L = -2.124537282; M = 19.916198; S = 0.135056522;}
    else if(Age_Months < 183){L = -2.116735712; M = 19.97472615; S = 0.134997797;}
    else if(Age_Months < 184){L = -2.108939167; M = 20.03323719; S = 0.134934285;}
    else if(Age_Months < 185){L = -2.10114692; M = 20.09172262; S = 0.134866291;}
    else if(Age_Months < 186){L = -2.093358637; M = 20.15017387; S = 0.134794121;}
    else if(Age_Months < 187){L = -2.085574403; M = 20.20858236; S = 0.134718085;}
    else if(Age_Months < 188){L = -2.077794735; M = 20.26693944; S = 0.134638494;}
    else if(Age_Months < 189){L = -2.070020599; M = 20.32523642; S = 0.134555663;}
    else if(Age_Months < 190){L = -2.062253431; M = 20.38346455; S = 0.13446991;}
    else if(Age_Months < 191){L = -2.054495145; M = 20.44161501; S = 0.134381553;}
    else if(Age_Months < 192){L = -2.046748156; M = 20.49967894; S = 0.134290916;}
    else if(Age_Months < 193){L = -2.039015385; M = 20.5576474; S = 0.134198323;}
    else if(Age_Months < 194){L = -2.031300282; M = 20.6155114; S = 0.134104101;}
    else if(Age_Months < 195){L = -2.023606828; M = 20.67326189; S = 0.134008581;}
    else if(Age_Months < 196){L = -2.015942013; M = 20.73088905; S = 0.133912066;}
    else if(Age_Months < 197){L = -2.008305745; M = 20.7883851; S = 0.133814954;}
    else if(Age_Months < 198){L = -2.000706389; M = 20.84574003; S = 0.133717552;}
    else if(Age_Months < 199){L = -1.993150137; M = 20.90294449; S = 0.1336202;}
    else if(Age_Months < 200){L = -1.985643741; M = 20.95998909; S = 0.133523244;}
    else if(Age_Months < 201){L = -1.97819451; M = 21.01686433; S = 0.133427032;}
    else if(Age_Months < 202){L = -1.970810308; M = 21.07356067; S = 0.133331914;}
    else if(Age_Months < 203){L = -1.96349954; M = 21.1300685; S = 0.133238245;}
    else if(Age_Months < 204){L = -1.956271141; M = 21.18637813; S = 0.133146383;}
    else if(Age_Months < 205){L = -1.949134561; M = 21.24247982; S = 0.13305669;}
    else if(Age_Months < 206){L = -1.942099744; M = 21.29836376; S = 0.132969531;}
    else if(Age_Months < 207){L = -1.935177101; M = 21.35402009; S = 0.132885274;}
    else if(Age_Months < 208){L = -1.92837748; M = 21.40943891; S = 0.132804292;}
    else if(Age_Months < 209){L = -1.921712136; M = 21.46461026; S = 0.132726962;}
    else if(Age_Months < 210){L = -1.915192685; M = 21.51952414; S = 0.132653664;}
    else if(Age_Months < 211){L = -1.908831065; M = 21.57417053; S = 0.132584784;}
    else if(Age_Months < 212){L = -1.902639482; M = 21.62853937; S = 0.132520711;}
    else if(Age_Months < 213){L = -1.896630358; M = 21.68262062; S = 0.132461838;}
    else if(Age_Months < 214){L = -1.890816268; M = 21.73640419; S = 0.132408563;}
    else if(Age_Months < 215){L = -1.885209876; M = 21.78988003; S = 0.132361289;}
    else if(Age_Months < 216){L = -1.879823505; M = 21.84303819; S = 0.132320427;}
    else if(Age_Months < 217){L = -1.874670324; M = 21.8958685; S = 0.132286382;}
    else if(Age_Months < 218){L = -1.869760299; M = 21.94836168; S = 0.1322596;}
    else if(Age_Months < 219){L = -1.865113245; M = 22.00050569; S = 0.132240418;}
    else if(Age_Months < 220){L = -1.860734944; M = 22.05229242; S = 0.13222933;}
    else if(Age_Months < 221){L = -1.85663384; M = 22.10371305; S = 0.132226801;}
    else if(Age_Months < 222){L = -1.852827186; M = 22.15475603; S = 0.132233201;}
    else if(Age_Months < 223){L = -1.849323204; M = 22.20541249; S = 0.132248993;}
    else if(Age_Months < 224){L = -1.846131607; M = 22.255673; S = 0.132274625;}
    else if(Age_Months < 225){L = -1.843261294; M = 22.30552831; S = 0.132310549;}
    else if(Age_Months < 226){L = -1.840720248; M = 22.3549693; S = 0.132357221;}
    else if(Age_Months < 227){L = -1.83851544; M = 22.40398706; S = 0.132415103;}
    else if(Age_Months < 228){L = -1.83665586; M = 22.45257182; S = 0.132484631;}
    else if(Age_Months < 229){L = -1.835138046; M = 22.50071778; S = 0.132566359;}
    else if(Age_Months < 230){L = -1.833972004; M = 22.54841437; S = 0.132660699;}
    else if(Age_Months < 231){L = -1.833157751; M = 22.59565422; S = 0.132768153;}
    else if(Age_Months < 232){L = -1.83269562; M = 22.64242956; S = 0.132889211;}
    else if(Age_Months < 233){L = -1.832584342; M = 22.68873292; S = 0.133024368;}
    else if(Age_Months < 234){L = -1.832820974; M = 22.73455713; S = 0.133174129;}
    else if(Age_Months < 235){L = -1.833400825; M = 22.7798953; S = 0.133338999;}
    else if(Age_Months < 236){L = -1.834317405; M = 22.82474087; S = 0.133519496;}
    else if(Age_Months < 237){L = -1.83555752; M = 22.86908912; S = 0.133716192;}
    else if(Age_Months < 238){L = -1.837119466; M = 22.91293151; S = 0.133929525;}
    else if(Age_Months < 239){L = -1.838987063; M = 22.95626373; S = 0.134160073;}
    else if(Age_Months < 240){L = -1.841146139; M = 22.99908062; S = 0.134408381;}
    else if(Age_Months < 241){L = -1.843580575; M = 23.04137734; S = 0.134675001;}
    else{
        L = -1.843580575; M = 23.04137734; S = 0.134675001;
    }
}



let Z_Score =  (power((BMI / M), L) - 1) / (L * S);

let Percentile =  ZtoPercentile(Z_Score);

 
 return Percentile
}

function power(x,y){
    return Math.pow(x,y);
    }

    function ZtoPercentile(z){
        
        let az = Math.abs(z);
        if (z < -3.09) return 0.1;
        if (z > 3.09) return 99.9;
        let thispercentile = n[Math.round(az * 100)];
        if (z >= 0) { return thispercentile }
        else { return 100 - thispercentile }
        }
    





    
   

   
