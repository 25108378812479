<template>
  <f7-page  name="selectactivities">
  <f7-navbar  title="Select or add activities to the prescription" :subtitle="Object.keys(selectedActivities).length +' activit'+(Object.keys(selectedActivities).length==1?'y':'ies')+' selected'" back-link="Back">
   <f7-subnavbar :inner="false">
      <f7-searchbar
        search-container=".myactivitiestargeted"
        search-in=".item-title"
        :disable-button="true"
        :backdrop="false"
      ></f7-searchbar>
    </f7-subnavbar>
    </f7-navbar>

<f7-list class="searchbar-not-found">
    <f7-list-item title="Nothing found"></f7-list-item>
  </f7-list>
  <f7-card>
  <f7-row>
    <f7-col >
  <f7-list inset class="no-margin-bottom">
    <f7-list-input
  label="Add a new activity"
  outline
  clear-button
  :value="newActivity"
  @input="newActivity=$event.target.value"
  > 
  </f7-list-input>
  </f7-list>
  </f7-col>
  <f7-col class="align-self-center">
    <f7-block class="no-margin-bottom smaller">
    <f7-button @click="addNew" icon-material="add" round small raised  fill>Add it</f7-button>
    </f7-block>
  </f7-col>
  </f7-row>
  </f7-card>
<f7-list class="myactivitiestargeted search-list no-margin-top">
  <f7-list-group v-if="Object.keys(newActivities).length>0" >
    <f7-list-item title="Created by you" group-title></f7-list-item>
    <f7-list-item 
    v-for="oneacti in newActivities"
    :key="oneacti.id"
    :title="oneacti.name"
    checkbox
     :value="oneacti.id"
     :checked="!!selectedActivities[oneacti.id]"
      @change="checkActivity(oneacti,$event.target.checked)"
    ></f7-list-item>
  </f7-list-group>
  <f7-list-group v-for="activitytype in activityTypes" :key="activitytype">
    <f7-list-item :title="activitytype" group-title></f7-list-item>
    <f7-list-item 
    v-for="oneacti in recommendedActivites[activitytype]" 
    v-show="!oneacti.inactive"
    :key="oneacti.id"
    :title="oneacti.name"
    checkbox
     :value="oneacti.id"
     :checked="!!selectedActivities[oneacti.id]"
      @change="checkActivity(oneacti,$event.target.checked)"
    ></f7-list-item>
    <f7-list-item v-if="!recommendedActivites[activitytype]" :title="'No '+activitytype+' activities for this health status'" ></f7-list-item>
    </f7-list-group>
</f7-list>

  </f7-page>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
export default {
  data(){
    return{
      newActivity:'',
      newActivities:{}
    }
  }, 
  mounted(){
        let preactivities = this.focusedPrescription.selectedActivities || {};
        let arrayofactivities=Object.keys(preactivities) || [];
         let newOnes={}
         arrayofactivities.forEach(activityid=>{
          if(!this.activities[activityid]){
            let activity={
              id:activityid,
              name:activityid
            }
             newOnes[activity.id]=activity
          }
          });
          this.newActivities=Object.assign({},newOnes)
      
  },
  computed:{
    ...mapState(['actualPatient','focusedPrescription','activityTypes','activities','focusedAccount']),
    ...mapGetters(['recommendedActivites','onClinic']),

       selectedActivities(){ //  returns the selected activities of the prescription
      return this.focusedPrescription.selectedActivities || {};
    },
  },
  methods:{
    addNew(){ // creates and adds a new activity for the prescription
      if(this.newActivity.length>0){
        let exists=false
        exists=Object.values(this.newActivities).some(act=>{
         return act.name===this.newActivity
        })
        if(exists)return this.$f7.dialog.alert('This activiy already exists')
        exists=Object.values(this.activities).some(act=>{
         return act.name===this.newActivity
        })
        if(exists)return this.$f7.dialog.alert('This activiy already exists')
        let activity={
          id:this.newActivity,
          name:this.newActivity,

        }
        this.newActivities[this.newActivity]=activity
        this.checkActivity(activity,true)
      this.newActivity=''
      
      }
    },
     checkActivity(activity,checked){ // calls the fuction to add or remove the activity from the prescription depending on the checked payload
                                      // payload: activity: activity to add or remove (object), checked: add (true) or remove (false)
      this.addOrRemoveActivity(activity.id,checked);
    },
    addOrRemoveActivity(activityid,add){ // adds or remove the activy from selected activities of the prescription
      let activity=null
      if(!this.activities[activityid]){
        activity=this.newActivities[activityid]
      }else{
      activity=this.activities[activityid];
      }
      let toStore={id:activity.id,value: activity, where:'selectedActivities'};
      let strng='addedd to';
      let tofirebase=activity.id;
      if (!add){
        strng='removed from';
        toStore.value=null;
        tofirebase=null;
      }
    this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/selectedActivities/'+activity.id).set(tofirebase).then(()=>{
      this.$store.commit('setSelection',toStore);
       this.$f7.toast.show({
        text: activity.name+' '+strng+' selection',
        position: 'center',
        closeTimeout: 2000,
        destroyOnClose: true
      });
    }).catch(error=>{
     this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
    })
    }
  },
  watch:{
    onClinic(){ // obsers if the getter onClinic changes. if its true navigates back. used to get out of the edit pages if the user selects another clinic on the left pannel
      if(this.$f7.views.main.router.url==='/selectActivities'){
        if(!this.focusedAccount.privx.clinician){
           this.$f7.views.main.router.navigate('/settings')
         }else{
         this.$f7.views.main.router.navigate('/patients')
       }
      }
    }
    
  }
}
</script>
<style >
.smaller{
  width: 120px !important;
}
</style>