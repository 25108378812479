<template>
<f7-page bg-color="white"  @page:beforein="getPatients()" >
<f7-navbar>
  <f7-nav-left >
    <f7-link panel-open="left" icon-material="menu"></f7-link>
  </f7-nav-left>
    <f7-nav-title
    title="Patients"
    :subtitle="clinics[onClinic]?'On '+clinics[onClinic].name:'No clinic selected'"
    ></f7-nav-title>
    <f7-nav-right>
        <f7-link v-if="focusedAccount.privx.clinician"
          class="searchbar-enable"
          data-searchbar=".searchPatient"
          icon-material="search"
        ></f7-link>
      </f7-nav-right>
        <f7-searchbar
        class="searchPatient"
        expandable
        search-container=".search-list"
        search-in=".item-title, .item-subtitle, .item-text"
        :disable-button="!$theme.aurora"
      ></f7-searchbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
        <f7-list-item  title="Patient not found."></f7-list-item>
    </f7-list>
<template v-if="focusedAccount.privx.clinician && !this.focusedAccount.inactive">
<f7-list
  media-list class="search-list searchbar-found no-margin-top mypaxs">
    <f7-list-item v-for="patient in sortedPatients" :key="patient.id"
      link="#" chevron-center
      @click="gotoPatientHistory(patient)"
    :title="lastNamesAndNames(patient)"
    :after="suage(patient.birthday)"
    :text="patient.sex"
  >
  <img :src="'../static/'+(patient.sex=='female'?'feme.jpg':'masc.jpg')" alt="gender avatar" slot="media" />
     </f7-list-item>
</f7-list>
<f7-fab @click="createPatient()" position="right-bottom" slot="fixed" color="light-blue">
    <f7-icon material="person_add"></f7-icon>
  </f7-fab>
 </template>
<f7-block class="alertabox" v-else>Your account has not been authorized as a clinician in PREVENT-TOOL. Contact kepperm@wustl.edu to request access.</f7-block>


  <f7-popup class="newpatient-popup" :opened="creatPatOpened" @popup:closed="creatPatOpened = false">
      <f7-page>
        <f7-navbar title="Create new patient">
          <f7-nav-right>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-right>
        </f7-navbar>
       <f7-list media-list class="no-margin" no-hairlines-md>
         <f7-list-item divider title="Demographic information"></f7-list-item>
  <f7-list-input
    @input  ="patient.name=$event.target.value.trim()"
    :value="patient.name"
    label="Name"
    type="text"
    placeholder="Patient's name"
    info="Required"
    clear-button
  >
  </f7-list-input>
  <f7-list-input
   @input  ="patient.lastName=$event.target.value.trim()"
    :value="patient.lastName"
    label="Last name"
    type="text"
    placeholder="Patient's last name"
    clear-button
  >
  </f7-list-input>
    <f7-list-input
    ref="caltocreatepatbirth"
    :calendar-params="{locale:'en-US', dateFormat: 'mm/dd/yyyy',closeOnSelect:true, disabled:{
        from: new Date()
    }}"
    @change="patient.birthday=$event.target.value"
    placeholder="Patient's Birthday"
    label="Birthday"
    type="datepicker"
    info="Required"
    readonly
    clear-button
  ></f7-list-input>
  <li  class="item-content item-input">
      <div class="item-inner">
        <div class="item-title item-label">Address</div>
        <div class="item-input-wrap">
          <input 
          :value="patient.geo.address"
            id="autocomplete_patx" type="text" name="patadrx" placeholder="Patient's adress">
          <span class="input-clear-button"></span>
          <div class="item-input-info">Required</div>
        </div>
        
      </div>
    </li>
 <f7-block-title class="margin-top">Sex (assigned at birth)</f7-block-title>
 <f7-block>
   <f7-segmented strong tag="p">
    <f7-button :active="patient.sex=='female'" @click="patient.sex='female'" :icon-material="patient.sex=='female'?'check':null">Female</f7-button>
    <f7-button :active="patient.sex=='male'" @click="patient.sex='male'" :icon-material="patient.sex=='male'?'check':null">Male</f7-button>
  </f7-segmented>
  <div class="item-input-info margin-left">Required</div>
 </f7-block>
 <f7-block-title class="margin-top">Gender</f7-block-title>
 <f7-block>
   <div :class="!patient.sex?'disabled':''"><f7-checkbox @change="genderSameAsAbove=$event.target.checked"  ></f7-checkbox><span class="margin-left">Same as above</span></div>
   <f7-list  v-if="!genderSameAsAbove" class="no-margin-top">
     <f7-list-input
  @input  ="patient.gender=$event.target.value.trim()"
    :value="patient.gender"
  
    label="Patient self-describe"
    placeholder="Patient self-describe"
    clear-button
  >
  </f7-list-input>
   </f7-list>
 </f7-block>
</f7-list>
<f7-list class="no-margin"> 
  <f7-list-item divider title="Patient contact information">
  </f7-list-item>
  <f7-list-input  
  @input  ="patient.email=$event.target.value.trim()"
    :value="patient.email"
  
    label="E-mail"
    type="email"
    placeholder="Patient's e-mail"
    clear-button
    validate
    @change="emailIsValid ($event.target.value,'patient')"

  >
  <div slot="info"><f7-checkbox :checked="patient.sendToArray && patient.sendToArray.includes('email')" @change="setSendToArray($event.target.checked,'email')"></f7-checkbox><span class="margin-left">Send prescription to this contact</span></div>
  </f7-list-input>
  <f7-list-input  
  :value="formatPhoneNumber(patient.phone)"
          @input="patient.phone = $event.target.value.trim()"
    type="tel"
    label="Phone number"
    maxlength="14"
    placeholder="(555)555-5555"
    clear-button
    validate
    @change="numberIsValid(patient.phone,'patient')"
  >
  <div slot="info"><f7-checkbox :checked="patient.sendToArray && patient.sendToArray.includes('phone')" @change="setSendToArray($event.target.checked,'phone')"></f7-checkbox><span class="margin-left">Send prescription to this contact</span></div>
  </f7-list-input>
  
</f7-list>
<f7-list class="no-margin">
  <f7-list-item divider title="Caregiver's contact information">
  </f7-list-item>
  <f7-list-input
    @input  ="patient.caregiverName=$event.target.value.trim()"
    :value="patient.caregiverName"
    label="Name"
    type="text"
    placeholder="Caregiver's name"
    clear-button
  >
  </f7-list-input>
  <f7-list-input  
  @input  ="patient.caregiverEmail=$event.target.value.trim()"
    :value="patient.caregiverEmail"
  
    label="E-mail"
    type="email"
    placeholder="Caregiver's e-mail"
    clear-button
    validate
    @change="emailIsValid($event.target.value,'caregiver')"
  >
  <div slot="info"><f7-checkbox :checked="patient.sendToArray && patient.sendToArray.includes('caregiverEmail')" @change="setSendToArray($event.target.checked,'caregiverEmail')"></f7-checkbox><span class="margin-left">Send prescription to this contact</span></div>
  </f7-list-input>
  <f7-list-input  
  :value="formatPhoneNumber(patient.caregiverPhone)"
  @input="patient.caregiverPhone = $event.target.value.trim()"
    type="tel"
    label="Phone number"
    maxlength="14"
    placeholder="(555)555-5555"
    clear-button
    validate
    @change="numberIsValid(patient.caregiverPhone,'caregiver')"
  >
   <div slot="info"><f7-checkbox :checked="patient.sendToArray && patient.sendToArray.includes('caregiverPhone')" @change="setSendToArray($event.target.checked,'caregiverPhone')"></f7-checkbox><span class="margin-left">Send prescription to this contact</span></div>
       
  </f7-list-input>
  
</f7-list>
 
 <f7-block>
   <f7-row>
      <f7-col><f7-button  @click="creatPatOpened = false"  popup-close outline color="red">Cancel</f7-button></f7-col>
     <f7-col><f7-button @click="savePatient()" fill raised color="green">Create patient</f7-button></f7-col>
   </f7-row>
 </f7-block>
      </f7-page>
    </f7-popup>



</f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import loadGoogleMapsApi from 'load-google-maps-api';
import {paxhelpersmixin} from '../mixins/paxhelpersmixin';

export default {
  	mixins:[paxhelpersmixin],
    data(){
        return{
          creatPatOpened:false,
           autocomplete:null,
          patient:{
            id:'',
            name:'',
            lastName:'',
            birthday:'',
            createdAt:'',
            email:'',
            phone:'',
            sex:'',
            gender:'',
            caregiverName:'',
            caregiverEmail:'',
            caregiverPhone:'',
            geo:{},
            sendToArray:[]
          },
          genderSameAsAbove:false
        }
    },
    computed:{
      ...mapState(['actualPatient','focusedAccount','user','activeTab','firstPreloader','clinics']),
      ...mapGetters(['sortedPatients','onClinic'])
    },
    methods:{
      changeBirthday(event){ // sets the patient age, can be older that 20 years
        let years=this.$moment().diff(event.target.value, 'years');
          if(years>20) {
            this.$f7.dialog.alert('The patient cannot be more than 20 years old')
            event.target.value=''
          } else{
        this.patient.birthday=event.target.value
          }
      },
      emailIsValid (email,where) { // checks if the email format is correct
        if(this.creatPatOpened){
          if(email){
      let valid= /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      if(!valid)  {
      this.$f7.dialog.alert('For the '+where+' you must provide an email that looks like USER@DOMAIN.XYZ','email format invalid')
      return true
      }
      }
        }
      },
      numberIsValid(number,where){ // checks if the phone number format is correct
        if(this.creatPatOpened ){
          number = number.replaceAll('(', '');
                number = number.replaceAll(')', '');
                number = number.replaceAll('-', '');
                number = number.replaceAll(' ', '');
                if(number && !number.startsWith('+1')) number = this.patient[where=='patient'?'phone':'caregiverPhone'] = '+1'+number
                let valid = /^[0-9\-\+]{12,15}$/.test(number);
                if (!number || !valid){
                    this.$f7.dialog.alert('You must provide a valid phone number for the '+where,'Phone number format invalid')
      return true
                }
                
        }
      },
      resetPatient(){ //  sets the form to default values. used to clean up the creation form
        let elcal=this.$refs.caltocreatepatbirth;
        elcal.f7Calendar.setValue([]);
        //reset calendar value
        this.patient=Object.assign({},{
            id:'',
            name:'',
            lastName:'',
            birthday:"",
            email:'',
            phone:'',
            sex:'',
            caregiverName:'',
            caregiverEmail:'',
            caregiverPhone:'',
            geo:{},
            sendToArray:[]
          });
      },
        turnOnMapAutoComplete(){ // creates the google maps autocomplete input
       loadGoogleMapsApi({key:this.$store.state.gmapsAPIkey,libraries:['places'],language:'en'}).then((googleMaps)=> {
           this.googleMaps=googleMaps;
           let autocomplete = new this.googleMaps.places.Autocomplete(
            /** @type {!HTMLInputElement} */ (
                document.getElementById('autocomplete_patx'))
              );
                   autocomplete.addListener('place_changed', ()=> {
            let place = autocomplete.getPlace();
            if (place.geometry) {
               let geo={
                address:place.formatted_address,
                place_id:place.place_id,
                coord:{
                  lat:place.geometry.location.lat(),
                  lng:place.geometry.location.lng()
                  }
              };
              this.patient.geo=Object.assign({},geo);
            } else {
              document.getElementById('autocomplete_patx').placeholder = 'Enter a valid address';
            }
          });

       }).catch((error)=> {
           this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
          });
    },
    savePatient(){ // stores the new patient in database
      if(this.patient.name=="" || Object.keys(this.patient.geo).length==0  || this.patient.sex=="" ||this.patient.birthday=="" ){
        return this.$f7.dialog.alert("missing required information");
      }
    
      if (this.patient.email=="" && this.patient.caregiverEmail=="" && this.patient.phone=="" && this.patient.caregiverPhone=="" ){
        return this.$f7.dialog.alert("At least one contact information is required")
      }
      
      let invalid=this.emailIsValid(this.patient.email,'patient')
      if(invalid) return false
      let invalidCaregiver=this.emailIsValid(this.patient.caregiverEmail,'caregiver')
      if(invalidCaregiver) return false
      if(this.patient.email && this.patient.caregiverEmail && this.patient.email==this.patient.caregiverEmail) return this.$f7.dialog.alert("Both emails can not be the same")
      let invalidNumber=this.patient.phone && this.numberIsValid(this.patient.phone,'patient')
      if(invalidNumber) return false
      let invalidNumberCaregiver=this.patient.caregiverPhone && this.numberIsValid(this.patient.caregiverPhone,'caregiver')
      if(invalidNumberCaregiver) return false
      if(!this.patient.sendToArray.length) return this.$f7.dialog.alert("The prescription has to be sent to least one contact")
      if (this.patient.sendToArray.some(contact=>{
         return !this.patient[contact] 
      }) ) return this.$f7.dialog.alert("A contact to send the prescription is not filled")
      this.patient.id=this.$firebase.database().ref('patients_by_clinic/'+this.onClinic).push().key;
      this.patient.createdAt=Date.now();
      this.patient.clinicid=this.onClinic
      this.$f7.dialog.preloader('Creating new patient...');
      return this.checkEmailUnique(this.patient.id,this.patient.email,this.onClinic).then(arrayofprevious=>{
        if(arrayofprevious.length>0){
          throw new Error('Email '+this.patient.email+' already in use by '+this.lastNamesAndNames(arrayofprevious[0])+', if this is the email of the caregiver add it on the caregiver info at the bottom of this form');
        }
        else{
          if(this.genderSameAsAbove){
            this.patient.gender=this.patient.sex
          }
          if(!this.patient.gender) delete this.patient.gender
            return this.$firebase.database().ref('patients_by_clinic/'+this.onClinic+'/'+this.patient.id).set(this.patient);
        }
      }).then(()=>{
        this.creatPatOpened=false;
        this.$store.commit('setObjectTo',{what:'actualPatient',to:this.patient});
        this.$store.commit('updateObjectPropValue',{what:'actualPatient',prop: 'prescriptions',neovalue:null});
        this.$store.commit('updateObjectPropValue',{what:'patients',prop: this.patient.id,neovalue:this.patient});
          this.$f7.dialog.close();
           this.$f7.views.main.router.navigate('/patienthist');
      }).catch((error)=>{
        this.$f7.dialog.close();
        this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
      })
    },
      getPatients(){ // called before mounting the template. gets all the patients from this clinic
        if(this.focusedAccount.inactive){
          return this.$f7.views.main.router.navigate('/inactiveAccount')
        }
        if(this.focusedAccount.privx.clinician  && Object.keys(this.sortedPatients).length==0 ){
          if(!this.firstPreloader){
          this.$f7.dialog.preloader('Loading patients...');
          }
          this.$store.state.firstPreloader=false
        this.$firebase.database().ref('patients_by_clinic/'+this.onClinic).once('value').then(snap=>{
          this.$store.commit('setObjectTo',{what:'patients',to:(snap.val() || {})});
           this.$f7.dialog.close();
        }).catch(error=>{
           this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
        });
        }
      },
        createPatient(){ // opens the  patient creation pop-up. activates the google maps autocomplete input
          if(!this.autocomplete){
            this.turnOnMapAutoComplete();
          }
            this.resetPatient();
            this.creatPatOpened=true;
        },
        gotoPatientHistory(patient){ // sets actualPatient on the state and navigates to patienthist
           this.$store.commit('setObjectTo',{what:'actualPatient',to:patient})
           this.$f7.views.main.router.navigate('/patienthist');
        },
        setSendToArray(checked,wich){
          if(checked)this.patient.sendToArray.push(wich)
          else this.patient.sendToArray.splice(this.patient.sendToArray.indexOf(wich),1)
        },
         formatPhoneNumber(phoneNumberString) {
            if (phoneNumberString) {
                let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
                let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                    let intlCode = match[1] ? '+1 ' : '';
                    return [
                        intlCode,
                        '(',
                        match[2],
                        ') ',
                        match[3],
                        '-',
                        match[4],
                    ].join('');
                }
                return phoneNumberString;
            }
        },
    },
    watch:{
    onClinic(){ // obsers if the getter onClinic changes. if its true navigates back. used to get out of the edit pages if the user selects another clinic on the left pannel
       if(this.focusedAccount.inactive){
          return this.$f7.views.main.router.navigate('/inactiveAccount')
        }
        if(this.activeTab=='/patients'){
          if(!this.focusedAccount.privx.clinician){
            this.$f7.toast.show({
            text: 'Account without privileges on patients',
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
          return this.$f7.views.main.router.navigate('/settings')
        }
        this.$f7.dialog.preloader('Loading patients...')
              this.$firebase.database().ref('patients_by_clinic/'+this.onClinic).once('value').then(snap=>{
               let local_patients=snap.val() || {}
               this.$store.commit('setObjectTo',{what:'patients',to:local_patients})
               this.$f7.dialog.close();
              }).catch(error=>{
                 this.$f7.dialog.close();
               this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
              })
    }
    }
  },
    
}
</script>
<style >
.alertabox{
  background: #fde0e0;
    color: #3e0000;
    margin: 20px;
    padding: 20px!important;
    text-align: center;
}
.mypaxs .item-media img{
width: 40px;
}
</style>