<template>
  <f7-page>
    <f7-navbar >
  <f7-nav-left >
    <f7-link panel-open="left" icon-material="menu"></f7-link>
  </f7-nav-left>
  <f7-nav-title title="Inactive Account"></f7-nav-title>
    </f7-navbar>
      <f7-block class="alertabox">This account was inactivated</f7-block>
  </f7-page>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed:{
    ...mapGetters(['onClinic'])
  },
watch:{
    onClinic(){ // obsers if the getter onClinic changes. if its true navigates back.
        if(this.$f7.views.main.router.url=='/inactiveAccount'){
          this.$f7.views.main.router.back()
        }
    }
  },
}
</script>

<style>

</style>