<template>
  <f7-page @page:beforein="loadResource" bgColor="white" class="bggris">
    <f7-navbar :title="digitalResources[id]?digitalResources[id].name:'Creating digital resource'" 
    back-link="Back">
      <f7-nav-right v-if="digitalResources[id]">
               <f7-link @click="attemptInactivate()" icon-material="delete"></f7-link>
      </f7-nav-right>
    </f7-navbar>
<f7-card >
<f7-list  no-hairlines-md class="no-margin-top">
    <f7-list-input
    label="Name"
    :value="digitalResource.name"
    @input="digitalResource.name=$event.target.value"
    type="text"
    floating-label
    clear-button
    />
    <f7-list-input
    label="Link"
    :value="digitalResource.link"
    @input="digitalResource.link=$event.target.value"
    type="text"
    floating-label
    clear-button
    />
    <f7-list-item title="Edit Logo" @click="tomarFoto()" link="#" >
        <img ref="logoPreview" :src="digitalResource.logo||'./static/icons/no-pictures.png'" class="resourceLogo" slot="media" />
        <f7-icon material="edit" slot="after"></f7-icon>
      </f7-list-item>
    <f7-list-input
    label="Description"
    :value="digitalResource.description"
    @input="digitalResource.description=$event.target.value"
    type="textarea"
    floating-label
    clear-button
    />
    <f7-list-input
    label="Equipment needed"
    :value="digitalResource.equipment"
    @input="digitalResource.equipment=$event.target.value"
    type="text"
    floating-label
    clear-button
    />
<f7-list-item divider>Tags</f7-list-item>
<f7-list-item v-for="(category,index) in digitalResourcesTags" :key="index">
<div slot="title">Resource {{index}}
    <div slot="subtitle">
    <div v-if="Object.values([index]).length==0">No tags selected</div>
 <f7-chip v-for="item in digitalResource[index]" :key="item"  :text="category[item]" > </f7-chip>
    </div>
</div>
<f7-link @click="openTagsPopUp(index)"
slot="after"><f7-icon material="edit"></f7-icon></f7-link>
</f7-list-item>
</f7-list>
</f7-card>
<f7-block class="padding-top">
<f7-row class="margin">
    <f7-col><f7-button @click="$f7.views.main.router.back()"  color="red">Cancel</f7-button></f7-col>
<f7-col ><f7-button @click="saveResource" fill >Save</f7-button></f7-col>
</f7-row>
</f7-block>

<f7-popup  :opened="tagsPopUp" @popup:closed="tagsPopUp=false">
    <f7-page>
        <f7-navbar title="Selecting Tags">
            <f7-nav-right><f7-link popup-close>Close</f7-link></f7-nav-right>
        </f7-navbar>
        <f7-list class="no-margin-top">
            <f7-list-item v-for="(tag,itemId) in sortedTagList" :key="itemId"
            checkbox
            :title="tag"
            name="tag-checkbox"
            :value="itemId"
            @change="selectTag($event.target.checked,tagCategory,itemId)"
            :checked="digitalResource[tagCategory][itemId]?true:false"
          ></f7-list-item>
        </f7-list>
    </f7-page>
</f7-popup>
 <input type="file" id="logo" class="display-none" @change="filesChange($event)" accept="image/*" />
  </f7-page>
</template>

<script>
import {mapState} from 'vuex';
import { photomixin } from '../mixins/photomixin'

export default {
   mixins: [photomixin],
    props:{id:String},
  data(){
    
    return{
     digitalResource:{
         name:'',
         id:'',
         types:{},
         platforms:{},
         features:{},
         logo:'',
         link:'',
         description:'',
         equipment:''
     },
     logoToUpload:null,
     tagCategory:'',
     tagsPopUp:false,
     tagList:{}

    }
  },
  computed:{
    ...mapState(['digitalResources','digitalResourcesTags','pendingDigitalResources']),
    sortedTagList(){
      let array=  Object.values(this.tagList).sort((a,b)=>{
            var x = a;
            var y = b;
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
        let objToReturn={}
        array.forEach(tagValue=>{
          let key = Object.keys(this.tagList).find(key=>this.tagList[key]==tagValue)
               objToReturn[key]=tagValue
        })
        return objToReturn
    }
  },
  methods:{
      attemptInactivate(){
        this.$f7.dialog.confirm('Are you sure you want to inactivate this digital resource?',async()=>{
          this.digitalResource.inactive=true
           try{
          this.$f7.dialog.preloader('Saving...')
        await this.$firebase.database().ref(`digitalResources/${this.digitalResource.id}`).set(this.digitalResource)
        
         this.$store.commit('updateObjectPropValue',
        {what:'digitalResources',prop:this.digitalResource.id,neovalue:this.digitalResource}
        )
        this.$f7.views.main.router.back()
        this.$f7.dialog.close()
           }catch(error){
              this.$f7.dialog.close()
          console.log(error)
          this.$f7.dialog.alert(error.message)
           }
        })
      },
      checkLinkExists(){
        let linkExists =Object.values(this.digitalResources).find(resource=>{
         return resource.link.toLowerCase()==this.digitalResource.link.toLowerCase() && resource.id!=this.digitalResource.id})
        if(linkExists){
          if(linkExists.inactive){
            this.$f7.dialog.confirm('There is already a resource with this link but it is inactive, do you want to activate it?',()=>{
              
              let local_resource=Object.assign(this.digitalResources[linkExists.id])
            delete local_resource.inactive
            this.$store.commit('updateObjectPropValue',{what:'digitalResources',prop: local_resource.id,neovalue:local_resource});
              
              this.digitalResource=Object.assign({},this.digitalResources[local_resource.id])
              
           
            })
          }else{
            this.$f7.dialog.alert('This link is already in use by other digital resource')
          }
           return true
        }
      },
      loadResource(){
     if(this.digitalResources[this.id]||this.pendingDigitalResources[this.id]) 
     this.digitalResource=Object.assign({},this.digitalResource,this.digitalResources[this.id]
     ||this.pendingDigitalResources[this.id])
    },
      openTagsPopUp(tagCategory){
        this.tagCategory=tagCategory
          this.tagList=Object.assign({},this.digitalResourcesTags[tagCategory]||{})
          this.tagsPopUp=true 
      },
     async saveResource(){
        const res=this.digitalResource
        const alert =this.$f7.dialog.alert
        if(!res.name.trim())return alert('A name is required')
        if(!res.link.trim())return alert('A link is required')
        if(!this.digitalResource.id){
          let exists=this.checkLinkExists()
        if(exists)return; 
          this.digitalResource.id=this.$firebase.database().ref('digitalResources').push().key 
        } 
        if(Object.values(res.types).length==0 
        ||Object.values(res.platforms).length==0
        ||Object.values(res.features).length==0 )
        return alert('At least one tag for each category is required')

        try{
          this.$f7.dialog.preloader('Saving...')
          if(this.logoToUpload)await this.subirfoto(this.logoToUpload)
          if(this.digitalResource.patientid){
            let call= this.$firebase.functions().httpsCallable('saveResourceFromPendings')
            let res=await call({resource:this.digitalResource})
            if(res.data.error)throw new Error(res.data.error.message)
            delete this.digitalResource.patientid
            delete this.digitalResource.prescriptionid
            delete this.digitalResource.clinicid
            this.$store.commit('updateObjectPropValue',
        {what:'pendingDigitalResources',prop:this.digitalResource.id,neovalue:null})
          }else{
        await this.$firebase.database().ref(`digitalResources/${this.digitalResource.id}`).set(this.digitalResource)
        }
        this.$store.commit('updateObjectPropValue',
        {what:'digitalResources',prop:this.digitalResource.id,neovalue:this.digitalResource}
        )
        this.$f7.dialog.close()
        this.$f7.toast.show({
          text:'Resource saved',
            position: "center",
                closeTimeout: 2000,
                destroyOnClose: true,
        })
        this.$f7.views.main.router.back()
        }catch(error){
          this.$f7.dialog.close()
          console.log(error)
          this.$f7.dialog.alert(error.message)
        }
      },
      selectTag(checked,category,itemId){
        if(checked) this.digitalResource[category][itemId]=itemId
        else   delete this.digitalResource[category][itemId]
      },
      async subirfoto (filereceive) {
          let filename = filereceive.name
            let extenx = filename.split('.').pop();
            try{
           let snapshot =await this.$firebase.storage().ref('digitalResources/logos/' + this.digitalResource.id+ '.' + extenx).put(filereceive);
            let logoURL= await snapshot.ref.getDownloadURL()
            this.digitalResource.logo=logoURL
              }catch(error){
                this.$f7.dialog.close()
                console.log(error)
                this.$f7.dialog.alert(error.message)
              }
        },
        tomarFoto () {
            this.$$('#logo').click();
        },
        filesChange (e) {
            let file = e.target.files[0];
            if (file) {
              let img=this.$refs.logoPreview
              img.file=file
              const reader = new FileReader();
             reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; }; })(img);
              reader.readAsDataURL(file);
               this.logoToUpload=file
               
            }
        }

  }
}
</script>