<template>
  <f7-page class="bggris">
    <f7-navbar :title="focusedResource?focusedResource.ginfo.name:'Create New Resource'" :subtitle="focusedResource?resource.ginfo.formatted_address:'On '+clinics[onClinic].name"  back-link="Back">
      <f7-nav-right v-if="focusedResource">
               <f7-link @click="attemptInactivate()" icon-material="delete"></f7-link>
      </f7-nav-right>
    </f7-navbar>

<f7-list  no-hairlines-md>
  <f7-list-item divider title="Step 1: Find adress/place"></f7-list-item>
<f7-list-item v-if="Object.keys(typeOfResources).length==1"
    :title="typeOfResources[resource.type].name"
    label="Type of resource"
  >
  </f7-list-item>
   <f7-list-input v-else
    @input  ="resource.type=$event.target.value"
    :value="resource.type"
    label="Type of resource"
    type="select"
    placeholder="Please choose..."
  >
   <option :value="null">Select Type of Resource</option>
    <option
    v-for="type in typeOfResources" :key="type.id"
     :value="type.id"
     >{{type.name}}</option>
  </f7-list-input>
  <f7-list-input
  v-show="(!focusedResource && resource.type )|| editResource"
    label="Address/Place"
    type="text"
    input-id="autocomplete_clp"
    placeholder="Search address or place name"
    :value="resource.ginfo.formatted_address?resource.ginfo.formatted_address:null"
    clear-button
  >
  </f7-list-input>
</f7-list>
<f7-block>
  <f7-row>
    <f7-col width="100" medium="50">
  <template v-if="resource.ginfo.place_id">
  <f7-list class="no-margin-top">
  <f7-list-item divider :title="'Set Details'"></f7-list-item>
  <f7-list-input
    label="Name"
    type="text"
    :value="resource.ginfo.name"
    @input  ="resource.ginfo.name=$event.target.value"
  >
  </f7-list-input>
 
  <f7-list-item
    header="Address"
    :title="resource.ginfo.formatted_address"
  >
  <f7-link 
  v-if="!editResource && focusedResource"
  slot="after"
   @click="dibujarMpa(),editResource=true"
  >
    <f7-icon material="edit"></f7-icon>
  </f7-link>
  </f7-list-item>
    <f7-list-input
    label="Phone Number"
    type="text"
    :value="resource.ginfo.international_phone_number"
    @input  ="resource.ginfo.international_phone_number=$event.target.value"
  >
  </f7-list-input>

   <f7-list-input
    label="Website"
    type="text"
    :value="resource.ginfo.website"
    @input  ="resource.ginfo.website=$event.target.value"
  >
  </f7-list-input>

 
  <f7-list-input 
    @input  ="resource.amenities=$event.target.value"
    :value="resource.amenities"
    label="Amenities"
    type="textarea"
    resizable
    placeholder="Resource`s Amenities"
    clear-button
  >
  </f7-list-input>
</f7-list>
<f7-row>
  <f7-col><f7-button outline @click="legoback()" color="red">Cancel</f7-button></f7-col>
   <f7-col><f7-button @click="saveResource()" fill raised color="green">Save</f7-button></f7-col>
</f7-row>
<f7-list>
  <f7-list-item divider title="Opening hours"></f7-list-item>
  <f7-list-item v-for="(oneday,dayidx) in opening_hours" :key="dayidx" :title="oneday" ></f7-list-item>
  <f7-list-item v-if="opening_hours.length==0" title="No info on opening hours"></f7-list-item>
</f7-list>
  </template>
  <p v-else>First search a place or adress on the input above</p>
</f7-col>
    <f7-col width="100" medium="50">
        <div class="mapresource"></div>
          <f7-block v-show="lephotos.length>0">
            <f7-row>
 <f7-col v-for="(onephoto,idx) in lephotos" :key="idx" width="33" @click="$refs.darkphotoviewer.open(idx)" class="fotosalto" 
      :style="'background: url('+onephoto+'); background-repeat:no-repeat; background-size:cover; background-position:center;'"></f7-col>

  </f7-row>
  </f7-block>
    </f7-col>
  </f7-row>
</f7-block>
 <f7-photo-browser
            :photos="lephotos"
            theme="dark"
            ref="darkphotoviewer"
          ></f7-photo-browser>

  </f7-page>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import loadGoogleMapsApi from 'load-google-maps-api';

export default {
  data(){
    
    return{
      editResource:false,
    googleMaps: null,
    lemapa: null,
    placemarker:null,
    lephotos:[],
    opening_hours:[],
    resource:{
       id:'',
      ginfo:{},
      type:'',
      amenities:''
    }

    }
  },
  created(){
    this.resetInfo();
    if(this.focusedResource){
       this.focusedResource.ginfo=Object.assign({},this.resource.ginfo,this.focusedResource.ginfo);
        this.resource=JSON.parse(JSON.stringify(this.focusedResource))
    }
  },
  mounted(){

   if(this.focusedResource){
      this.loadMapWithPin();
   }
   else{
      this.dibujarMpa();
   }
   if(Object.keys(this.typeOfResources).length==1){
     this.resource.type=Object.keys(this.typeOfResources)[0]
   }
  },
  computed:{
    ...mapState(['typeOfResources','resources','focusedResource','clinics']),
    ...mapGetters(['onClinic'])
  },
  methods:{
     requestPhotos(){ // calls the google map api to get the pictures from the ubication of the resource
          let request2 = {
            placeId: this.resource.ginfo.place_id,
            fields: ['photo','opening_hours']
          };
          let service = new this.googleMaps.places.PlacesService(this.lemapa);
          service.getDetails(request2,(place, status)=> {
             if(status!=='OK'){
               return this.$f7.dialog.alert(status);
            }
            else{
               let locphots=place.photos || [];
               this.lephotos= locphots.map(x => x.getUrl());
               this.opening_hours = (place.opening_hours && place.opening_hours.weekday_text)?place.opening_hours.weekday_text : [];
                
            }
          });
        },
    attemptInactivate(){ // opens a framework7 prompt. if the answer is yes it will call the function to inactivate the resource
      this.$f7.dialog.confirm('Are you sure you want to inactivate this resource?',()=> {
        this.inactivateResource();
      });
    },
    inactivateResource(){ // Inactivates the activity by setting to true the node 'inactive' on the resource
         this.$f7.dialog.preloader('Inactivating resource...');
          this.$firebase.database().ref('resources_by_clinic/'+this.onClinic+'/'+this.focusedResource.id+'/inactive').set(true).then(()=>{
            let local_resource=Object.assign({},this.focusedResource)
            local_resource.inactive=true
           this.$store.commit('updateObjectPropValue',{what:'resources',prop: this.focusedResource.id,neovalue:local_resource});
            this.$f7.dialog.close();
            this.$f7.views.main.router.back();
          }).catch(error=>{
            console.log(error);
            this.$f7.dialog.close();
            this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
          });
    },
    legoback(){ // navigates back
        this.$f7.views.main.router.back();
         },
    resetInfo(){ // sets to default  the value nodes from the object resource
      this.resource.ginfo=Object.assign({},{
        formatted_address:'',
        international_phone_number:'',
        icon:'',
        name:'',
        lat: null,
        lng: null,
        place_id:null,
        rating:'',
        website:'',
        url:''
      });
      if(this.placemarker){
        this.placemarker.setMap(null);
        this.placemarker=null;
      }
    },
     loadMapWithPin(){ //it is called when there is a resource to edit, not to create. it loads the map on the screen and sets the resource on the center
      loadGoogleMapsApi({key:this.$store.state.gmapsAPIkey,libraries:['places'],language:'en'}).then((googleMaps)=> {
           this.googleMaps=googleMaps;
          this.lemapa= new this.googleMaps.Map(document.querySelector('.mapresource'), {
             center: {lat: this.focusedResource.ginfo.lat, lng: this.focusedResource.ginfo.lng},
              zoom: 15,
              streetViewControl: false,
              mapTypeControl: false
            });

              let unicode=this.typeOfResources[this.resource.type].ico.uni;
               this.placemarker=new this.googleMaps.Marker({
                position: {lat: this.focusedResource.ginfo.lat, lng: this.focusedResource.ginfo.lng},
                 map: this.lemapa,
                label: {
                    fontFamily: 'Fontawesome',
                    text: unicode,
                    color: '#FFF',
                    fontSize: '18px'
                  },
              });
              return true
          }).then(snap=>{
            if(!this.focusedResource.ginfo.place_id){
               let geocoder=new this.googleMaps.Geocoder
               let response= ''
                geocoder.geocode({
                 address:this.resource.ginfo.name+' '+this.resource.ginfo.formatted_address,
                 location: {lat: Number(this.resource.ginfo.lat), lng: Number(this.resource.ginfo.lng)}
              },response=>{
                this.$firebase.database().ref('resources_by_clinic/'+this.onClinic+'/'+this.resource.id+'/ginfo/place_id').set(response[0].place_id).then(()=>{
                  this.resource.ginfo.place_id=response[0].place_id
                 this.requestPhotos();
                }).catch(error=>{
                  this.$f7.dialog.alert(error.message,error.code)
                  console.log(error)
                })
            
          })
          }else{
            this.requestPhotos();
          }
          }).catch((error)=> {
           this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
          })
     },
       dibujarMpa(){ // opens the google map on the screen and creates the google maps autocomplete input
         loadGoogleMapsApi({key:this.$store.state.gmapsAPIkey,libraries:['places'],language:'en'}).then((googleMaps)=> {
           this.googleMaps=googleMaps;
           var defaultBounds = new this.googleMaps.LatLngBounds(
              new this.googleMaps.LatLng(38.989752, -90.928138),
              new this.googleMaps.LatLng(38.248369, -89.452410)
            );

          this.lemapa= new this.googleMaps.Map(document.querySelector('.mapresource'), {
             center: {lat: 38.6425597, lng:-90.3107618},
              zoom: 9,
              streetViewControl: false,
              mapTypeControl: false
            });
            var countryRestrict = {'country': 'us'};
           // var hostnameRegexp = new RegExp('^https?://.+?/');
            let autocomplete = new this.googleMaps.places.Autocomplete(
            /** @type {!HTMLInputElement} */ (
                document.getElementById('autocomplete_clp')), {
              componentRestrictions: countryRestrict,
              bounds: defaultBounds,
            });
            autocomplete.addListener('place_changed', ()=> {
              
            var place = autocomplete.getPlace();
            if (place.geometry) {
              let resourcesKeys=Object.keys(this.resources)
         let alReadyExists= resourcesKeys.find(oneResource=>
           (this.resources[oneResource].ginfo.lat==place.geometry.location.lat() 
           &&this.resources[oneResource].ginfo.lng==place.geometry.location.lng())
           ||this.resources[oneResource].ginfo.formatted_address==place.formatted_address 
           ||this.resources[oneResource].ginfo.place_id?this.resources[oneResource].ginfo.place_id==place.place_id:false
           )
            if(alReadyExists){
              let exist=false
              if(this.resources[alReadyExists].inactive){
                exist=true
              return this.$f7.dialog.confirm('Already exists a resource created on this location but it is disactivated, do you want to activate it?','Activate activity',()=>{
                this.$firebase.database().ref('resources_by_clinic/'+this.onClinic+'/'+alReadyExists+'/inactive').set(null).then(()=>{
                
                this.$store.commit('setObjectTo',{what:'focusedResource',to:this.resources[alReadyExists]})
                this.$store.commit('updateObjectPropValue',{what:'focusedResource',prop: 'inactive',neovalue:null});
                this.resource=Object.assign({},this.resource,this.focusedResource);
                let local_resources=Object.assign({},this.resources);
              local_resources[this.focusedResource.id]=this.focusedResource;
              this.$store.commit('setObjectTo',{what:'resources',to:local_resources});
              return true
              })
              })
             
              }else{
              let name=this.resources[alReadyExists].ginfo.name
                return this.$f7.dialog.alert('Already exists a resource created on this location called '+name)
              }
            }else{
              this.lemapa.panTo(place.geometry.location);
              this.lemapa.setZoom(15);
              let prothopos=place.photos || [];
               this.lephotos=prothopos.map(unaphoto => {
                return unaphoto.getUrl();
              });
              let tostore={
                formatted_address: place.formatted_address || '',
                international_phone_number: place.international_phone_number || '',
                icon: place.icon || '',
                lat: place.geometry.location.lat() || null,
                lng: place.geometry.location.lng() || null,
                name:place.name || '',
                place_id:place.place_id ,
                rating:place.rating || '',
                website:place.website || '',
                url:place.url || ''
              };
              this.resource.ginfo=Object.assign({},this.resource.ginfo,tostore);
              //crear marker
              let unicode=this.typeOfResources[this.resource.type].ico.uni;
              if(this.placemarker)this.placemarker.setMap(null)
               this.placemarker=new this.googleMaps.Marker({
                position: place.geometry.location,
                 map: this.lemapa,
                label: {
                    fontFamily: 'Fontawesome',
                     text: unicode,
                    color: '#FFF',
                    fontSize: '18px'
                  },
              });
              }
            } else {
              document.getElementById('autocomplete_clp').placeholder = 'Enter a valid place';
            }
          });

          }).catch(error=> {
            this.$f7.dialog.alert(error.message,error.code)
            console.error(error);
          });
      },
      saveResource(){ // stores the resource on database and state
          if(this.resource.ginfo.name=='')return this.$f7.dialog.alert('A name is required!');
           if(this.resource.ginfo.formatted_address=='') return this.$f7.dialog.alert('An adress is required!');
           if ( this.resource.type=='' )return this.$f7.dialog.alert('A type is required!');
            if(!this.focusedResource){
              this.resource.id= this.$firebase.database().ref('resources_by_clinic/'+this.onClinic).push().key
            }
             this.$f7.dialog.preloader('Creating new Resource...');
            this.$firebase.database().ref('resources_by_clinic/'+this.onClinic+'/'+this.resource.id).set(this.resource).then(()=>{
              let local_resources=Object.assign({},this.resources);
              local_resources[this.resource.id]=this.resource;
              this.$store.commit('setObjectTo',{what:'resources',to:local_resources});
              this.$f7.toast.show({
                text: this.resource.ginfo.name+' saved',
                position: 'center',
                closeTimeout: 1500,
                destroyOnClose: true
              });
              this.$f7.dialog.close();
              this.$f7.views.main.router.back();
            }).catch((error)=>{
              this.$f7.dialog.close();
              console.log(error)
              this.$f7.dialog.alert(error.message,error.code);
            })
      }
  },
    watch:{
    onClinic(){ // obsers if the getter onClinic changes. if its true navigates back. used to get out of the edit pages if the user selects another clinic on the left pannel
        if(this.$f7.views.main.router.url=='/createResource/'){
            this.$f7.views.main.router.navigate('/resources/')
        } 
    }
  },

}
</script>
<style >
.pic{
  margin: 10px;
  max-width:250px;
  max-height:250px;
  filter: drop-shadow(5px 5px 5px blue);
}
.deleteItem{
background-color:red;
margin:auto 10px
}
.demo-swiper .swiper-slide {
  font-size: 25px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #000;
}
.demo-swiper .swiper-slide {
  box-sizing: border-box;
  border: 1px solid #ddd;
  background: #fff;
  max-width: 50%;
  width: auto;
}
.demo-swiper {
  margin: 0px 0 35px;
  font-size: 18px;
  height: 250px;
}
.aximwith{
  width:100%
}
.mapresource{
  height:300px;
}
.bggris{
  background:#e6e6e6;
}
.fotosalto{
  height: 100px;
  background-color: #cecece;
  /* -webkit-background-size:cover; -moz-background-size:cover; -o-background-size:cover; */
margin-bottom: 16px;
}

.popup.photo-browser-popup.photo-browser-standalone{
  z-index: 99999!important;
}
</style>