<template>
  <f7-page bg-color="white" @page:beforein="loadData()">
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left" icon-material="menu"></f7-link>
      </f7-nav-left>
      <f7-nav-title title="Resources"
      :subtitle="clinics[onClinic]?'On '+clinics[onClinic].name:'No clinic selected'"
      ></f7-nav-title>
      <f7-nav-right>
        <f7-link @click="loadData(true)" icon-material="refresh"></f7-link>
        <f7-link class="searchbar-enable" data-searchbar=".searchResource" icon-material="search"></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchResource"
        expandable
        search-container=".search-list"
        search-in=".item-title, .item-subtitle, .item-text"
        :disable-button="!$theme.aurora"
      ></f7-searchbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found"></f7-list-item>
    </f7-list>

    <f7-list
      accordion-list
      media-list
      class="search-list searchbar-found no-margin-top fixiconresorlist"
    >
    <f7-list-item
    v-if="Object.keys(typeOfResources).length==0"
    >
    There are no type of resources created yet
    </f7-list-item>
    <f7-list-item divider title="Food Resources"></f7-list-item>
      <f7-list-item  class="typeHeader"
        accordion-item
        v-for="type in resDiet"
        :key="type.id"
        :title="type.name"
        :badge="sortedResourcesByType(type.id).length" :badge-color="sortedResourcesByType(type.id).length>0?'blue':''"
      >
        <f7-icon 
        :style="{backgroundColor:type.color,borderRadius:'20%',padding:'5px'}"
        :icon="'fa fa-2x fa-'+typeOfResources[type.id].ico.ico" slot="media"></f7-icon>
        <f7-accordion-content >
          <f7-list  media-list class="search-list searchbar-found no-margin-top fixiconresorlist">
          <f7-list-item
          v-if="sortedResourcesByType(type.id).length==0"
          title="No resources"
          > </f7-list-item>
            <f7-list-item class="resourcesListItems"
              v-for="resource in sortedResourcesByType(type.id)"
              :key="resource.id"
              :title="resource.ginfo.name"
              :header="diction[typeOfResources[resource.type].class] || 'Resource Type Not Defined'"
              :subtitle="resource.ginfo.formatted_address"
              link="#"
              @click="editResource(resource)"
              :text="typeOfResources[resource.type].name"
            >
              <f7-icon
              :style="{backgroundColor:type.color,borderRadius:'20%',padding:'5px'}"
               :icon="'fa fa-2x fa-'+typeOfResources[resource.type].ico.ico" slot="media"></f7-icon>
              <f7-icon material="edit" slot="after"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item divider title="Physicial Activity Resources"></f7-list-item>
      <f7-list-item  class="typeHeader"
        accordion-item
        v-for="type in resPhysical"
        :key="type.id"
        :title="type.name"
        :badge="sortedResourcesByType(type.id).length" :badge-color="sortedResourcesByType(type.id).length>0?'blue':''"
      >
        <f7-icon 
        :style="{backgroundColor:type.color,borderRadius:'20%',padding:'5px'}"
        :icon="'fa fa-2x fa-'+typeOfResources[type.id].ico.ico" slot="media"></f7-icon>
        <f7-accordion-content >
          <f7-list  media-list class="search-list searchbar-found no-margin-top fixiconresorlist">
          <f7-list-item
          v-if="sortedResourcesByType(type.id).length==0"
          title="No resources"
          > </f7-list-item>
            <f7-list-item class="resourcesListItems"
              v-for="resource in sortedResourcesByType(type.id)"
              :key="resource.id"
              :title="resource.ginfo.name"
              :header="diction[typeOfResources[resource.type].class] || 'Resource Type Not Defined'"
              :subtitle="resource.ginfo.formatted_address"
              link="#"
              @click="editResource(resource)"
              :text="typeOfResources[resource.type].name"
            >
              <f7-icon 
              :style="{backgroundColor:type.color,borderRadius:'20%',padding:'5px'}"
              :icon="'fa fa-2x fa-'+typeOfResources[resource.type].ico.ico" slot="media"></f7-icon>
              <f7-icon material="edit" slot="after"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
    <f7-fab 
    v-if="Object.keys(typeOfResources).length>0"
    @click="createResource()"
    position="right-bottom" slot="fixed" color="light-blue">
      <f7-icon material="add"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import loadGoogleMapsApi from "load-google-maps-api";
export default {
  data() {
    return {
      googleMaps: null,

      diction: {
        physical: "Physical Activity Resources",
        diet: "Food Resources"
      }
    };
  },
  computed: {
    ...mapState(['typeOfResources','activeTab','focusedAccount','resources','firstPreloader','clinics']),
    ...mapGetters(['sortedResources','sortedResourcesTypes','onClinic']),

    resDiet(){ // returns array with the diet resources on this clinic 
        return this.$store.getters.resourcesByType('diet');
      },
      resPhysical(){ // returns array with the physical resources on this clinic
        return this.$store.getters.resourcesByType('physical');
      }
    
  },
  methods: {
    sortedResourcesByType(type) { //  returns array with the resources for the type passed as payload
                                // payload: type: type id
      let resources=[]
      this.sortedResources.forEach(resource => {
        if(resource.type==type){
          if(!resource.inactive){
          resources.push(resource)
          }
        }
      });
      return resources
      
    },
    createResource() { // claer the state node focusedResource and navigates to createResource
      this.$store.commit("setObjectTo", { what: "focusedResource", to: null });
      this.$f7.views.main.router.navigate("/createResource/");
    },
        editResource(resource){ // sets the state node focusedResource to the passed resource as payload, and navigates to createResource
                                  // payload: resource: the resource to edit (object)
          this.$store.commit('setObjectTo',{what:'focusedResource',to:resource})
           this.$f7.views.main.router.navigate("/createResource/");
        },
     
        async loadData(force=false){ // if the resources or type of resources have been called yet it gets them from database
         
          try{
             if(!this.firstPreloader){
          this.$f7.dialog.preloader('Loading Resources...');
          }
          this.$store.state.firstPreloader=false
          if(Object.keys(this.typeOfResources).length==0){
           let call= this.$firebase.functions().httpsCallable('getTypeOfResources')
           let ledata= await call({clinicid:this.onClinic,accountid:this.focusedAccount.id})
            if(ledata.data.error){
              throw new Error(ledata.data.error)
         }
          this.$store.commit('setObjectTo',{what:'typeOfResources',to:ledata.data.types});
          }
          if(Object.keys(this.sortedResources).length==0 || force){
          let preresources= await this.$firebase.database().ref('resources_by_clinic/'+this.onClinic).once('value');
          this.$store.commit('setObjectTo',{what:'resources',to:preresources.val() || {}});
          
         }
         this.$f7.dialog.close();
         }catch(error){
             this.$f7.dialog.close();
             console.log(error)
              this.$f7.dialog.alert((error.message,error.code)||error)
         }
        }
  },
  watch: {
    onClinic() { // obsers if the getter onClinic changes. used to get out of the page if the user selects another clinic on the left pannel and doesn't have the createResources privilege
      
         if(this.focusedAccount.inactive){
          return this.$f7.views.main.router.navigate('/inactiveAccount')
        }
        if (this.activeTab == "/resources" ) {
        if(!this.focusedAccount.privx.createResources){
          this.$f7.toast.show({
            text: 'Account without privileges on resources',
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
          return this.$f7.views.main.router.navigate('/settings')
          
        }
        this.loadData().then(()=>{
          this.$f7.dialog.close();
        })
          .catch(error => {
            this.$f7.dialog.close();
            this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
          });
      }
    }
  }
};
</script>
<style>
.fixiconresorlist .item-media .icon {
  margin: 0 auto;
}
.resourcesListItems {
  background-color: white;
}
.typeHeader{
  background-color:#f7f7f8;
}
</style>