
function log(i){
return Math.log(i) * Math.LOG10E;   
}

function ln(i){
return Math.log(i);
}

function sq(i){
return i * i;
}

function sqr(i){
return Math.sqrt(i);
}


function power(x,y){
return Math.pow(x,y);
}

function eTo(x){
return Math.exp(x);
}


let n = new Array;
let L = 0;
let M = 0;
let S = 0;

n[0] = 50;
n[1] = 50.399;
n[2] = 50.798;
n[3] = 51.197;
n[4] = 51.595;
n[5] = 51.994;
n[6] = 52.392;
n[7] = 52.79;
n[8] = 53.188;
n[9] = 53.586;
n[10] = 53.983;
n[11] = 54.38;
n[12] = 54.776;
n[13] = 55.172;
n[14] = 55.567;
n[15] = 55.962;
n[16] = 56.356;
n[17] = 56.749;
n[18] = 57.142;
n[19] = 57.535;
n[20] = 57.926;
n[21] = 58.317;
n[22] = 58.706;
n[23] = 59.095;
n[24] = 59.483;
n[25] = 59.871;
n[26] = 60.257;
n[27] = 60.642;
n[28] = 61.026;
n[29] = 61.409;
n[30] = 61.791;
n[31] = 62.172;
n[32] = 62.552;
n[33] = 62.93;
n[34] = 63.307;
n[35] = 63.683;
n[36] = 64.058;
n[37] = 64.431;
n[38] = 64.803;
n[39] = 65.173;
n[40] = 65.542;
n[41] = 65.91;
n[42] = 66.276;
n[43] = 66.64;
n[44] = 67.003;
n[45] = 67.364;
n[46] = 67.724;
n[47] = 68.082;
n[48] = 68.439;
n[49] = 68.793;
n[50] = 69.146;
n[51] = 69.497;
n[52] = 69.847;
n[53] = 70.194;
n[54] = 70.54;
n[55] = 70.884;
n[56] = 71.226;
n[57] = 71.566;
n[58] = 71.904;
n[59] = 72.24;
n[60] = 72.575;
n[61] = 72.907;
n[62] = 73.237;
n[63] = 73.565;
n[64] = 73.891;
n[65] = 74.215;
n[66] = 74.537;
n[67] = 74.857;
n[68] = 75.175;
n[69] = 75.49;
n[70] = 75.804;
n[71] = 76.115;
n[72] = 76.424;
n[73] = 76.73;
n[74] = 77.035;
n[75] = 77.337;
n[76] = 77.637;
n[77] = 77.935;
n[78] = 78.23;
n[79] = 78.524;
n[80] = 78.814;
n[81] = 79.103;
n[82] = 79.389;
n[83] = 79.673;
n[84] = 79.955;
n[85] = 80.234;
n[86] = 80.511;
n[87] = 80.785;
n[88] = 81.057;
n[89] = 81.327;
n[90] = 81.594;
n[91] = 81.859;
n[92] = 82.121;
n[93] = 82.381;
n[94] = 82.639;
n[95] = 82.894;
n[96] = 83.147;
n[97] = 83.398;
n[98] = 83.646;
n[99] = 83.891;
n[100] = 84.134;
n[101] = 84.375;
n[102] = 84.614;
n[103] = 84.849;
n[104] = 85.083;
n[105] = 85.314;
n[106] = 85.543;
n[107] = 85.769;
n[108] = 85.993;
n[109] = 86.214;
n[110] = 86.433;
n[111] = 86.65;
n[112] = 86.864;
n[113] = 87.076;
n[114] = 87.286;
n[115] = 87.493;
n[116] = 87.698;
n[117] = 87.9;
n[118] = 88.1;
n[119] = 88.298;
n[120] = 88.493;
n[121] = 88.686;
n[122] = 88.877;
n[123] = 89.065;
n[124] = 89.251;
n[125] = 89.435;
n[126] = 89.617;
n[127] = 89.796;
n[128] = 89.973;
n[129] = 90.147;
n[130] = 90.32;
n[131] = 90.49;
n[132] = 90.658;
n[133] = 90.824;
n[134] = 90.988;
n[135] = 91.149;
n[136] = 91.308;
n[137] = 91.466;
n[138] = 91.621;
n[139] = 91.774;
n[140] = 91.924;
n[141] = 92.073;
n[142] = 92.22;
n[143] = 92.364;
n[144] = 92.507;
n[145] = 92.647;
n[146] = 92.785;
n[147] = 92.922;
n[148] = 93.056;
n[149] = 93.189;
n[150] = 93.319;
n[151] = 93.448;
n[152] = 93.574;
n[153] = 93.699;
n[154] = 93.822;
n[155] = 93.943;
n[156] = 94.062;
n[157] = 94.179;
n[158] = 94.295;
n[159] = 94.408;
n[160] = 94.52;
n[161] = 94.63;
n[162] = 94.738;
n[163] = 94.845;
n[164] = 94.95;
n[165] = 95.053;
n[166] = 95.154;
n[167] = 95.254;
n[168] = 95.352;
n[169] = 95.449;
n[170] = 95.543;
n[171] = 95.637;
n[172] = 95.728;
n[173] = 95.818;
n[174] = 95.907;
n[175] = 95.994;
n[176] = 96.08;
n[177] = 96.164;
n[178] = 96.246;
n[179] = 96.327;
n[180] = 96.407;
n[181] = 96.485;
n[182] = 96.562;
n[183] = 96.638;
n[184] = 96.712;
n[185] = 96.784;
n[186] = 96.856;
n[187] = 96.926;
n[188] = 96.995;
n[189] = 97.062;
n[190] = 97.128;
n[191] = 97.193;
n[192] = 97.257;
n[193] = 97.32;
n[194] = 97.381;
n[195] = 97.441;
n[196] = 97.5;
n[197] = 97.558;
n[198] = 97.615;
n[199] = 97.67;
n[200] = 97.725;
n[201] = 97.778;
n[202] = 97.831;
n[203] = 97.882;
n[204] = 97.932;
n[205] = 97.982;
n[206] = 98.03;
n[207] = 98.077;
n[208] = 98.124;
n[209] = 98.169;
n[210] = 98.214;
n[211] = 98.257;
n[212] = 98.3;
n[213] = 98.341;
n[214] = 98.382;
n[215] = 98.422;
n[216] = 98.461;
n[217] = 98.5;
n[218] = 98.537;
n[219] = 98.574;
n[220] = 98.61;
n[221] = 98.645;
n[222] = 98.679;
n[223] = 98.713;
n[224] = 98.745;
n[225] = 98.778;
n[226] = 98.809;
n[227] = 98.84;
n[228] = 98.87;
n[229] = 98.899;
n[230] = 98.928;
n[231] = 98.956;
n[232] = 98.983;
n[233] = 99.01;
n[234] = 99.036;
n[235] = 99.061;
n[236] = 99.086;
n[237] = 99.111;
n[238] = 99.134;
n[239] = 99.158;
n[240] = 99.18;
n[241] = 99.202;
n[242] = 99.224;
n[243] = 99.245;
n[244] = 99.266;
n[245] = 99.286;
n[246] = 99.305;
n[247] = 99.324;
n[248] = 99.343;
n[249] = 99.361;
n[250] = 99.379;
n[251] = 99.396;
n[252] = 99.413;
n[253] = 99.43;
n[254] = 99.446;
n[255] = 99.461;
n[256] = 99.477;
n[257] = 99.492;
n[258] = 99.506;
n[259] = 99.52;
n[260] = 99.534;
n[261] = 99.547;
n[262] = 99.56;
n[263] = 99.573;
n[264] = 99.585;
n[265] = 99.598;
n[266] = 99.609;
n[267] = 99.621;
n[268] = 99.632;
n[269] = 99.643;
n[270] = 99.653;
n[271] = 99.664;
n[272] = 99.674;
n[273] = 99.683;
n[274] = 99.693;
n[275] = 99.702;
n[276] = 99.711;
n[277] = 99.72;
n[278] = 99.728;
n[279] = 99.736;
n[280] = 99.744;
n[281] = 99.752;
n[282] = 99.76;
n[283] = 99.767;
n[284] = 99.774;
n[285] = 99.781;
n[286] = 99.788;
n[287] = 99.795;
n[288] = 99.801;
n[289] = 99.807;
n[290] = 99.813;
n[291] = 99.819;
n[292] = 99.825;
n[293] = 99.831;
n[294] = 99.836;
n[295] = 99.841;
n[296] = 99.846;
n[297] = 99.851;
n[298] = 99.856;
n[299] = 99.861;
n[300] = 99.865;
n[301] = 99.869;
n[302] = 99.874;
n[303] = 99.878;
n[304] = 99.882;
n[305] = 99.886;
n[306] = 99.889;
n[307] = 99.893;
n[308] = 99.896;
n[309] = 99.9;

function ZtoPercentile(z){
let az = Math.abs(z);

if (z < -3.09) return 0.1;
if (z > 3.09) return 99.9;
 let thispercentile = n[Math.round(az * 100)];
if (z >= 0) { return thispercentile }
else { return 100 - thispercentile }
}
function fixDP(r, dps) {
    if (isNaN(r)) return "NaN";
    var msign = '';
    if (r < 0) msign = '-';
    let x = Math.abs(r);
    if (x > Math.pow(10, 21)) return msign + x.toString();
    let m = Math.round(x * Math.pow(10, dps)).toString();
    if (dps == 0) return msign + m;
    while (m.length <= dps) m = "0" + m;
    return msign + m.substring(0, m.length - dps) + "." + m.substring(m.length - dps);
    }
function get_Height_Z(height){
    return (power((height / M), L) - 1) / (L * S);

}    
function define_L_M_S(sex,age){
    if(sex=='male'){
        if(age < 25){L = 1.00720807; M = 86.86160934; S = 0.040395626;}
        else if(age < 26){L = 0.837251351; M = 87.65247282; S = 0.040577525;}
        else if(age < 27){L = 0.681492975; M = 88.42326434; S = 0.040723122;}
        else if(age < 28){L = 0.538779654; M = 89.17549228; S = 0.040833194;}
        else if(age < 29){L = 0.407697153; M = 89.91040853; S = 0.040909059;}
        else if(age < 30){L = 0.286762453; M = 90.62907762; S = 0.040952433;}
        else if(age < 31){L = 0.174489485; M = 91.33242379; S = 0.04096533;}
        else if(age < 32){L = 0.069444521; M = 92.02127167; S = 0.040949976;}
        else if(age < 33){L = -0.029720564; M = 92.69637946; S = 0.040908737;}
        else if(age < 34){L = -0.124251789; M = 93.35846546; S = 0.040844062;}
        else if(age < 35){L = -0.215288396; M = 94.00822923; S = 0.040758431;}
        else if(age < 36){L = -0.30385434; M = 94.64636981; S = 0.040654312;}
        else if(age < 37){L = -0.390918369; M = 95.27359106; S = 0.04053412;}
        else if(age < 38){L = -0.254801167; M = 95.91474929; S = 0.040572876;}
        else if(age < 39){L = -0.125654535; M = 96.54734328; S = 0.04061691;}
        else if(age < 40){L = -0.00316735; M = 97.17191309; S = 0.040666414;}
        else if(age < 41){L = 0.11291221; M = 97.78897727; S = 0.040721467;}
        else if(age < 42){L = 0.222754969; M = 98.3990283; S = 0.040782045;}
        else if(age < 43){L = 0.326530126; M = 99.00254338; S = 0.040848042;}
        else if(age < 44){L = 0.42436156; M = 99.599977; S = 0.040919281;}
        else if(age < 45){L = 0.516353108; M = 100.191764; S = 0.040995524;}
        else if(age < 46){L = 0.602595306; M = 100.7783198; S = 0.041076485;}
        else if(age < 47){L = 0.683170764; M = 101.3600411; S = 0.041161838;}
        else if(age < 48){L = 0.758158406; M = 101.9373058; S = 0.041251224;}
        else if(age < 49){L = 0.827636736; M = 102.5104735; S = 0.041344257;}
        else if(age < 50){L = 0.891686306; M = 103.0798852; S = 0.041440534;}
        else if(age < 51){L = 0.95039153; M = 103.645864; S = 0.041539635;}
        else if(age < 52){L = 1.003830006; M = 104.208713; S = 0.041641136;}
        else if(age < 53){L = 1.05213569; M = 104.7687256; S = 0.041744602;}
        else if(age < 54){L = 1.0953669; M = 105.3261638; S = 0.041849607;}
        else if(age < 55){L = 1.133652119; M = 105.8812823; S = 0.041955723;}
        else if(age < 56){L = 1.167104213; M = 106.4343146; S = 0.042062532;}
        else if(age < 57){L = 1.195845353; M = 106.9854769; S = 0.042169628;}
        else if(age < 58){L = 1.220004233; M = 107.534968; S = 0.042276619;}
        else if(age < 59){L = 1.239715856; M = 108.0829695; S = 0.042383129;}
        else if(age < 60){L = 1.255121285; M = 108.6296457; S = 0.042488804;}
        else if(age < 61){L = 1.266367398; M = 109.1751441; S = 0.042593311;}
        else if(age < 62){L = 1.273606657; M = 109.7195954; S = 0.042696342;}
        else if(age < 63){L = 1.276996893; M = 110.2631136; S = 0.042797615;}
        else if(age < 64){L = 1.276701119; M = 110.8057967; S = 0.042896877;}
        else if(age < 65){L = 1.272887366; M = 111.3477265; S = 0.042993904;}
        else if(age < 66){L = 1.265728536; M = 111.8889694; S = 0.043088503;}
        else if(age < 67){L = 1.255402281; M = 112.4295761; S = 0.043180513;}
        else if(age < 68){L = 1.242090871; M = 112.9695827; S = 0.043269806;}
        else if(age < 69){L = 1.225981067; M = 113.5090108; S = 0.043356287;}
        else if(age < 70){L = 1.207263978; M = 114.0478678; S = 0.043439893;}
        else if(age < 71){L = 1.186140222; M = 114.5861486; S = 0.043520597;}
        else if(age < 72){L = 1.162796198; M = 115.1238315; S = 0.043598407;}
        else if(age < 73){L = 1.137442868; M = 115.6608862; S = 0.043673359;}
        else if(age < 74){L = 1.110286487; M = 116.1972691; S = 0.043745523;}
        else if(age < 75){L = 1.081536236; M = 116.732925; S = 0.043815003;}
        else if(age < 76){L = 1.05140374; M = 117.2677879; S = 0.043881929;}
        else if(age < 77){L = 1.020102497; M = 117.8017819; S = 0.043946461;}
        else if(age < 78){L = 0.987847213; M = 118.3348215; S = 0.044008785;}
        else if(age < 79){L = 0.954853043; M = 118.8668123; S = 0.044069112;}
        else if(age < 80){L = 0.921334742; M = 119.397652; S = 0.044127675;}
        else if(age < 81){L = 0.887505723; M = 119.9272309; S = 0.044184725;}
        else if(age < 82){L = 0.85357703; M = 120.455433; S = 0.044240532;}
        else if(age < 83){L = 0.819756239; M = 120.9821362; S = 0.044295379;}
        else if(age < 84){L = 0.786246296; M = 121.5072136; S = 0.044349559;}
        else if(age < 85){L = 0.753244292; M = 122.0305342; S = 0.044403374;}
        else if(age < 86){L = 0.720940222; M = 122.5519634; S = 0.04445713;}
        else if(age < 87){L = 0.689515708; M = 123.0713645; S = 0.044511135;}
        else if(age < 88){L = 0.659142731; M = 123.588599; S = 0.044565693;}
        else if(age < 89){L = 0.629997853; M = 124.1035312; S = 0.044621104;}
        else if(age < 90){L = 0.602203984; M = 124.6160161; S = 0.044677662;}
        else if(age < 91){L = 0.575908038; M = 125.1259182; S = 0.044735646;}
        else if(age < 92){L = 0.55123134; M = 125.6331012; S = 0.044795322;}
        else if(age < 93){L = 0.528279901; M = 126.1374319; S = 0.044856941;}
        else if(age < 94){L = 0.507143576; M = 126.6387804; S = 0.04492073;}
        else if(age < 95){L = 0.487895344; M = 127.1370217; S = 0.044986899;}
        else if(age < 96){L = 0.470590753; M = 127.6320362; S = 0.045055632;}
        else if(age < 97){L = 0.455267507; M = 128.1237104; S = 0.045127088;}
        else if(age < 98){L = 0.441945241; M = 128.6119383; S = 0.045201399;}
        else if(age < 99){L = 0.430625458; M = 129.096622; S = 0.045278671;}
        else if(age < 100){L = 0.421291648; M = 129.5776723; S = 0.045358979;}
        else if(age < 101){L = 0.413909588; M = 130.0550101; S = 0.045442372;}
        else if(age < 102){L = 0.408427813; M = 130.5285669; S = 0.045528869;}
        else if(age < 103){L = 0.404778262; M = 130.9982857; S = 0.045618459;}
        else if(age < 104){L = 0.402877077; M = 131.4641218; S = 0.045711105;}
        else if(age < 105){L = 0.402625561; M = 131.9260439; S = 0.045806742;}
        else if(age < 106){L = 0.40391127; M = 132.3840348; S = 0.045905281;}
        else if(age < 107){L = 0.406609232; M = 132.838092; S = 0.046006604;}
        else if(age < 108){L = 0.410583274; M = 133.2882291; S = 0.046110573;}
        else if(age < 109){L = 0.415687443; M = 133.7344759; S = 0.046217028;}
        else if(age < 110){L = 0.421767514; M = 134.1768801; S = 0.04632579;}
        else if(age < 111){L = 0.428662551; M = 134.6155076; S = 0.046436662;}
        else if(age < 112){L = 0.436206531; M = 135.0504433; S = 0.04654943;}
        else if(age < 113){L = 0.44423; M = 135.4817925; S = 0.046663871;}
        else if(age < 114){L = 0.45256176; M = 135.9096813; S = 0.046779748;}
        else if(age < 115){L = 0.461030578; M = 136.3342577; S = 0.046896817;}
        else if(age < 116){L = 0.469466904; M = 136.7556923; S = 0.047014827;}
        else if(age < 117){L = 0.477704608; M = 137.1741794; S = 0.047133525;}
        else if(age < 118){L = 0.48558272; M = 137.5899378; S = 0.047252654;}
        else if(age < 119){L = 0.492947182; M = 138.0032114; S = 0.047371961;}
        else if(age < 120){L = 0.499652617; M = 138.4142703; S = 0.047491194;}
        else if(age < 121){L = 0.505564115; M = 138.8234114; S = 0.047610108;}
        else if(age < 122){L = 0.510559047; M = 139.2309592; S = 0.047728463;}
        else if(age < 123){L = 0.514528903; M = 139.6372663; S = 0.04784603;}
        else if(age < 124){L = 0.517381177; M = 140.042714; S = 0.047962592;}
        else if(age < 125){L = 0.519041285; M = 140.4477127; S = 0.048077942;}
        else if(age < 126){L = 0.519454524; M = 140.8527022; S = 0.048191889;}
        else if(age < 127){L = 0.518588072; M = 141.2581515; S = 0.048304259;}
        else if(age < 128){L = 0.516433004; M = 141.6645592; S = 0.048414893;}
        else if(age < 129){L = 0.513006312; M = 142.072452; S = 0.048523648;}
        else if(age < 130){L = 0.508352901; M = 142.4823852; S = 0.048630402;}
        else if(age < 131){L = 0.502547502; M = 142.8949403; S = 0.04873505;}
        else if(age < 132){L = 0.495696454; M = 143.3107241; S = 0.048837504;}
        else if(age < 133){L = 0.487939275; M = 143.7303663; S = 0.048937694;}
        else if(age < 134){L = 0.479449924; M = 144.1545167; S = 0.049035564;}
        else if(age < 135){L = 0.470437652; M = 144.5838414; S = 0.049131073;}
        else if(age < 136){L = 0.461147305; M = 145.0190192; S = 0.049224189;}
        else if(age < 137){L = 0.451858946; M = 145.4607359; S = 0.049314887;}
        else if(age < 138){L = 0.442886661; M = 145.9096784; S = 0.049403145;}
        else if(age < 139){L = 0.434576385; M = 146.3665278; S = 0.049488934;}
        else if(age < 140){L = 0.427302633; M = 146.8319513; S = 0.049572216;}
        else if(age < 141){L = 0.421464027; M = 147.3065929; S = 0.049652935;}
        else if(age < 142){L = 0.417477538; M = 147.7910635; S = 0.049731004;}
        else if(age < 143){L = 0.415771438; M = 148.2859294; S = 0.0498063;}
        else if(age < 144){L = 0.416777012; M = 148.7917006; S = 0.04987865;}
        else if(age < 145){L = 0.420919142; M = 149.3088178; S = 0.049947823;}
        else if(age < 146){L = 0.428606007; M = 149.8376391; S = 0.050013518;}
        else if(age < 147){L = 0.440218167; M = 150.3784267; S = 0.050075353;}
        else if(age < 148){L = 0.456097443; M = 150.9313331; S = 0.050132858;}
        else if(age < 149){L = 0.476536014; M = 151.4963887; S = 0.050185471;}
        else if(age < 150){L = 0.501766234; M = 152.0734897; S = 0.050232532;}
        else if(age < 151){L = 0.531951655; M = 152.6623878; S = 0.050273285;}
        else if(age < 152){L = 0.567179725; M = 153.2626819; S = 0.050306885;}
        else if(age < 153){L = 0.607456565; M = 153.8738124; S = 0.050332406;}
        else if(age < 154){L = 0.652704121; M = 154.495058; S = 0.05034886;}
        else if(age < 155){L = 0.702759868; M = 155.1255365; S = 0.050355216;}
        else if(age < 156){L = 0.757379106; M = 155.7642086; S = 0.050350423;}
        else if(age < 157){L = 0.816239713; M = 156.4098858; S = 0.050333444;}
        else if(age < 158){L = 0.878947416; M = 157.0612415; S = 0.050303283;}
        else if(age < 159){L = 0.945053486; M = 157.7168289; S = 0.050259018;}
        else if(age < 160){L = 1.014046108; M = 158.3750929; S = 0.050199837;}
        else if(age < 161){L = 1.085383319; M = 159.034399; S = 0.050125062;}
        else if(age < 162){L = 1.158487278; M = 159.6930501; S = 0.05003418;}
        else if(age < 163){L = 1.232768816; M = 160.3493168; S = 0.049926861;}
        else if(age < 164){L = 1.307628899; M = 161.0014586; S = 0.049802977;}
        else if(age < 165){L = 1.382473225; M = 161.6477515; S = 0.04966261;}
        else if(age < 166){L = 1.456720479; M = 162.2865119; S = 0.049506051;}
        else if(age < 167){L = 1.529810247; M = 162.9161202; S = 0.049333801;}
        else if(age < 168){L = 1.601219573; M = 163.535045; S = 0.049146553;}
        else if(age < 169){L = 1.670433444; M = 164.1418486; S = 0.04894519;}
        else if(age < 170){L = 1.736995571; M = 164.7352199; S = 0.048730749;}
        else if(age < 171){L = 1.800483802; M = 165.3139755; S = 0.048504404;}
        else if(age < 172){L = 1.860518777; M = 165.8770715; S = 0.048267442;}
        else if(age < 173){L = 1.916765525; M = 166.4236087; S = 0.04802123;}
        else if(age < 174){L = 1.968934444; M = 166.9528354; S = 0.047767192;}
        else if(age < 175){L = 2.016781776; M = 167.4641466; S = 0.047506783;}
        else if(age < 176){L = 2.060109658; M = 167.9570814; S = 0.047241456;}
        else if(age < 177){L = 2.098765817; M = 168.4313175; S = 0.04697265;}
        else if(age < 178){L = 2.132642948; M = 168.8866644; S = 0.046701759;}
        else if(age < 179){L = 2.16167779; M = 169.3230548; S = 0.046430122;}
        else if(age < 180){L = 2.185849904; M = 169.7405351; S = 0.046159004;}
        else if(age < 181){L = 2.205180153; M = 170.139255; S = 0.045889585;}
        else if(age < 182){L = 2.219728869; M = 170.5194567; S = 0.045622955;}
        else if(age < 183){L = 2.2295937; M = 170.881464; S = 0.045360101;}
        else if(age < 184){L = 2.234907144; M = 171.2256717; S = 0.045101913;}
        else if(age < 185){L = 2.235833767; M = 171.5525345; S = 0.044849174;}
        else if(age < 186){L = 2.232567138; M = 171.8625576; S = 0.044602566;}
        else if(age < 187){L = 2.2253265; M = 172.1562865; S = 0.044362674;}
        else if(age < 188){L = 2.214353232; M = 172.4342983; S = 0.044129985;}
        else if(age < 189){L = 2.199905902; M = 172.6971935; S = 0.043904897;}
        else if(age < 190){L = 2.182262864; M = 172.9455898; S = 0.043687723;}
        else if(age < 191){L = 2.161704969; M = 173.180112; S = 0.043478698;}
        else if(age < 192){L = 2.138524662; M = 173.4013896; S = 0.043277987;}
        else if(age < 193){L = 2.113023423; M = 173.6100518; S = 0.043085685;}
        else if(age < 194){L = 2.085490286; M = 173.8067179; S = 0.042901835;}
        else if(age < 195){L = 2.0562195; M = 173.9919998; S = 0.042726424;}
        else if(age < 196){L = 2.025496648; M = 174.1664951; S = 0.042559396;}
        else if(age < 197){L = 1.993598182; M = 174.3307855; S = 0.042400652;}
        else if(age < 198){L = 1.960789092; M = 174.4854344; S = 0.042250063;}
        else if(age < 199){L = 1.927320937; M = 174.6309856; S = 0.042107465;}
        else if(age < 200){L = 1.89343024; M = 174.7679617; S = 0.041972676;}
        else if(age < 201){L = 1.859337259; M = 174.8968634; S = 0.041845488;}
        else if(age < 202){L = 1.825245107; M = 175.0181691; S = 0.041725679;}
        else if(age < 203){L = 1.791339209; M = 175.1323345; S = 0.041613015;}
        else if(age < 204){L = 1.757787065; M = 175.2397926; S = 0.041507249;}
        else if(age < 205){L = 1.724738292; M = 175.340954; S = 0.041408129;}
        else if(age < 206){L = 1.692324905; M = 175.4362071; S = 0.041315398;}
        else if(age < 207){L = 1.660661815; M = 175.5259191; S = 0.041228796;}
        else if(age < 208){L = 1.629847495; M = 175.6104358; S = 0.04114806;}
        else if(age < 209){L = 1.599964788; M = 175.690083; S = 0.041072931;}
        else if(age < 210){L = 1.571081817; M = 175.7651671; S = 0.04100315;}
        else if(age < 211){L = 1.543252982; M = 175.8359757; S = 0.040938463;}
        else if(age < 212){L = 1.516519998; M = 175.9027788; S = 0.040878617;}
        else if(age < 213){L = 1.490912963; M = 175.9658293; S = 0.040823368;}
        else if(age < 214){L = 1.466451429; M = 176.0253641; S = 0.040772475;}
        else if(age < 215){L = 1.44314546; M = 176.081605; S = 0.040725706;}
        else if(age < 216){L = 1.420996665; M = 176.1347593; S = 0.040682834;}
        else if(age < 217){L = 1.399999187; M = 176.1850208; S = 0.04064364;}
        else if(age < 218){L = 1.380140651; M = 176.2325707; S = 0.040607913;}
        else if(age < 219){L = 1.361403047; M = 176.2775781; S = 0.040575448;}
        else if(age < 220){L = 1.343763564; M = 176.3202008; S = 0.040546051;}
        else if(age < 221){L = 1.327195355; M = 176.3605864; S = 0.040519532;}
        else if(age < 222){L = 1.311668242; M = 176.3988725; S = 0.040495713;}
        else if(age < 223){L = 1.297149359; M = 176.4351874; S = 0.040474421;}
        else if(age < 224){L = 1.283603728; M = 176.469651; S = 0.040455493;}
        else if(age < 225){L = 1.270994782; M = 176.5023751; S = 0.040438773;}
        else if(age < 226){L = 1.25928483; M = 176.533464; S = 0.040424111;}
        else if(age < 227){L = 1.248435461; M = 176.5630153; S = 0.040411366;}
        else if(age < 228){L = 1.23840791; M = 176.5911197; S = 0.040400405;}
        else if(age < 229){L = 1.229163362; M = 176.6178621; S = 0.040391101;}
        else if(age < 230){L = 1.220663228; M = 176.6433219; S = 0.040383334;}
        else if(age < 231){L = 1.212869374; M = 176.6675729; S = 0.04037699;}
        else if(age < 232){L = 1.20574431; M = 176.6906844; S = 0.040371962;}
        else if(age < 233){L = 1.199251356; M = 176.712721; S = 0.040368149;}
        else if(age < 234){L = 1.19335477; M = 176.733743; S = 0.040365456;}
        else if(age < 235){L = 1.188019859; M = 176.753807; S = 0.040363795;}
        else if(age < 236){L = 1.183213059; M = 176.7729657; S = 0.04036308;}
        else if(age < 237){L = 1.178901998; M = 176.7912687; S = 0.040363233;}
        else if(age < 238){L = 1.175055543; M = 176.8087622; S = 0.040364179;}
        else if(age < 239){L = 1.171643828; M = 176.8254895; S = 0.04036585;}
        else if(age < 240){L = 1.16863827; M = 176.8414914; S = 0.04036818;}
    } else {
        if(age < 25){L = 1.051272912; M = 85.3973169; S = 0.040859727;}
        else if(age < 26){L = 1.041951175; M = 86.29026318; S = 0.041142161;}
        else if(age < 27){L = 1.012592236; M = 87.15714182; S = 0.041349399;}
        else if(age < 28){L = 0.970541909; M = 87.9960184; S = 0.041500428;}
        else if(age < 29){L = 0.921129988; M = 88.8055115; S = 0.041610508;}
        else if(age < 30){L = 0.868221392; M = 89.58476689; S = 0.041691761;}
        else if(age < 31){L = 0.81454413; M = 90.33341722; S = 0.04175368;}
        else if(age < 32){L = 0.761957977; M = 91.0515436; S = 0.041803562;}
        else if(age < 33){L = 0.711660228; M = 91.7396352; S = 0.041846882;}
        else if(age < 34){L = 0.664323379; M = 92.39854429; S = 0.041887626;}
        else if(age < 35){L = 0.620285102; M = 93.02945392; S = 0.041928568;}
        else if(age < 36){L = 0.57955631; M = 93.63382278; S = 0.041971514;}
        else if(age < 37){L = 0.54198094; M = 94.21335709; S = 0.042017509;}
        else if(age < 38){L = 0.511429832; M = 94.79643239; S = 0.042104522;}
        else if(age < 39){L = 0.482799937; M = 95.37391918; S = 0.042199507;}
        else if(age < 40){L = 0.455521041; M = 95.94692677; S = 0.042300333;}
        else if(age < 41){L = 0.429150288; M = 96.51644912; S = 0.042405225;}
        else if(age < 42){L = 0.403351725; M = 97.08337211; S = 0.042512706;}
        else if(age < 43){L = 0.377878239; M = 97.6484807; S = 0.042621565;}
        else if(age < 44){L = 0.352555862; M = 98.21246579; S = 0.042730809;}
        else if(age < 45){L = 0.327270297; M = 98.77593069; S = 0.042839638;}
        else if(age < 46){L = 0.301955463; M = 99.33939735; S = 0.042947412;}
        else if(age < 47){L = 0.276583851; M = 99.9033122; S = 0.043053626;}
        else if(age < 48){L = 0.251158446; M = 100.4680516; S = 0.043157889;}
        else if(age < 49){L = 0.225705996; M = 101.033927; S = 0.043259907;}
        else if(age < 50){L = 0.20027145; M = 101.6011898; S = 0.043359463;}
        else if(age < 51){L = 0.174913356; M = 102.1700358; S = 0.043456406;}
        else if(age < 52){L = 0.149700081; M = 102.7406094; S = 0.043550638;}
        else if(age < 53){L = 0.12470671; M = 103.3130077; S = 0.043642107;}
        else if(age < 54){L = 0.100012514; M = 103.8872839; S = 0.043730791;}
        else if(age < 55){L = 0.075698881; M = 104.4634511; S = 0.043816701;}
        else if(age < 56){L = 0.051847635; M = 105.0414853; S = 0.043899867;}
        else if(age < 57){L = 0.02853967; M = 105.6213287; S = 0.043980337;}
        else if(age < 58){L = 0.005853853; M = 106.2028921; S = 0.044058171;}
        else if(age < 59){L = -0.016133871; M = 106.7860583; S = 0.04413344;}
        else if(age < 60){L = -0.037351181; M = 107.3706841; S = 0.044206218;}
        else if(age < 61){L = -0.057729947; M = 107.9566031; S = 0.044276588;}
        else if(age < 62){L = -0.077206672; M = 108.5436278; S = 0.044344632;}
        else if(age < 63){L = -0.09572283; M = 109.1315521; S = 0.044410436;}
        else if(age < 64){L = -0.113225128; M = 109.7201531; S = 0.044474084;}
        else if(age < 65){L = -0.129665689; M = 110.3091934; S = 0.044535662;}
        else if(age < 66){L = -0.145002179; M = 110.8984228; S = 0.044595254;}
        else if(age < 67){L = -0.159197885; M = 111.4875806; S = 0.044652942;}
        else if(age < 68){L = -0.172221748; M = 112.0763967; S = 0.044708809;}
        else if(age < 69){L = -0.184048358; M = 112.6645943; S = 0.044762936;}
        else if(age < 70){L = -0.194660215; M = 113.2518902; S = 0.044815402;}
        else if(age < 71){L = -0.204030559; M = 113.8380006; S = 0.044866288;}
        else if(age < 72){L = -0.212174408; M = 114.4226317; S = 0.044915672;}
        else if(age < 73){L = -0.219069129; M = 115.0054978; S = 0.044963636;}
        else if(age < 74){L = -0.224722166; M = 115.5863089; S = 0.045010259;}
        else if(age < 75){L = -0.229140412; M = 116.1647782; S = 0.045055624;}
        else if(age < 76){L = -0.232335686; M = 116.7406221; S = 0.045099817;}
        else if(age < 77){L = -0.234324563; M = 117.3135622; S = 0.045142924;}
        else if(age < 78){L = -0.235128195; M = 117.8833259; S = 0.045185036;}
        else if(age < 79){L = -0.234772114; M = 118.4496481; S = 0.045226249;}
        else if(age < 80){L = -0.233286033; M = 119.0122722; S = 0.045266662;}
        else if(age < 81){L = -0.230703633; M = 119.5709513; S = 0.045306383;}
        else if(age < 82){L = -0.227062344; M = 120.1254495; S = 0.045345524;}
        else if(age < 83){L = -0.222403111; M = 120.6755427; S = 0.045384203;}
        else if(age < 84){L = -0.216770161; M = 121.22102; S = 0.045422551;}
        else if(age < 85){L = -0.210210748; M = 121.7616844; S = 0.045460702;}
        else if(age < 86){L = -0.202774891; M = 122.2973542; S = 0.045498803;}
        else if(age < 87){L = -0.194515104; M = 122.827864; S = 0.045537012;}
        else if(age < 88){L = -0.185486099; M = 123.3530652; S = 0.045575495;}
        else if(age < 89){L = -0.175744476; M = 123.8728276; S = 0.045614432;}
        else if(age < 90){L = -0.165348396; M = 124.38704; S = 0.045654016;}
        else if(age < 91){L = -0.15435722; M = 124.8956114; S = 0.04569445;}
        else if(age < 92){L = -0.142831123; M = 125.398472; S = 0.045735953;}
        else if(age < 93){L = -0.130830669; M = 125.895574; S = 0.045778759;}
        else if(age < 94){L = -0.118416354; M = 126.3868929; S = 0.045823114;}
        else if(age < 95){L = -0.105648092; M = 126.8724284; S = 0.04586928;}
        else if(age < 96){L = -0.092584657; M = 127.3522056; S = 0.045917535;}
        else if(age < 97){L = -0.079283065; M = 127.8262759; S = 0.045968169;}
        else if(age < 98){L = -0.065797888; M = 128.2947187; S = 0.04602149;}
        else if(age < 99){L = -0.0521805; M = 128.757642; S = 0.046077818;}
        else if(age < 100){L = -0.03847825; M = 129.2151839; S = 0.046137487;}
        else if(age < 101){L = -0.024733545; M = 129.6675143; S = 0.046200842;}
        else if(age < 102){L = -0.010982868; M = 130.1148354; S = 0.04626824;}
        else if(age < 103){L = 0.002744306; M = 130.5573839; S = 0.046340046;}
        else if(age < 104){L = 0.016426655; M = 130.995432; S = 0.046416629;}
        else if(age < 105){L = 0.030052231; M = 131.4292887; S = 0.046498361;}
        else if(age < 106){L = 0.043619747; M = 131.8593015; S = 0.046585611;}
        else if(age < 107){L = 0.05713988; M = 132.2858574; S = 0.046678741;}
        else if(age < 108){L = 0.070636605; M = 132.7093845; S = 0.046778099;}
        else if(age < 109){L = 0.08414848; M = 133.1303527; S = 0.04688401;}
        else if(age < 110){L = 0.097729873; M = 133.5492749; S = 0.046996769;}
        else if(age < 111){L = 0.111452039; M = 133.9667073; S = 0.047116633;}
        else if(age < 112){L = 0.125404005; M = 134.3832499; S = 0.047243801;}
        else if(age < 113){L = 0.13969316; M = 134.7995463; S = 0.047378413;}
        else if(age < 114){L = 0.154445482; M = 135.2162826; S = 0.047520521;}
        else if(age < 115){L = 0.169805275; M = 135.634186; S = 0.047670085;}
        else if(age < 116){L = 0.185934346; M = 136.0540223; S = 0.047826946;}
        else if(age < 117){L = 0.203010488; M = 136.4765925; S = 0.04799081;}
        else if(age < 118){L = 0.2212252; M = 136.9027281; S = 0.048161228;}
        else if(age < 119){L = 0.240780542; M = 137.3332846; S = 0.04833757;}
        else if(age < 120){L = 0.261885086; M = 137.7691339; S = 0.048519011;}
        else if(age < 121){L = 0.284748919; M = 138.2111552; S = 0.048704503;}
        else if(age < 122){L = 0.309577733; M = 138.6602228; S = 0.048892759;}
        else if(age < 123){L = 0.336566048; M = 139.1171933; S = 0.049082239;}
        else if(age < 124){L = 0.365889711; M = 139.5828898; S = 0.049271137;}
        else if(age < 125){L = 0.397699038; M = 140.0580848; S = 0.049457371;}
        else if(age < 126){L = 0.432104409; M = 140.5434787; S = 0.049638596;}
        else if(age < 127){L = 0.46917993; M = 141.0396832; S = 0.049812203;}
        else if(age < 128){L = 0.508943272; M = 141.5471945; S = 0.049975355;}
        else if(age < 129){L = 0.551354277; M = 142.0663731; S = 0.050125012;}
        else if(age < 130){L = 0.596307363; M = 142.59742; S = 0.050257992;}
        else if(age < 131){L = 0.643626542; M = 143.1403553; S = 0.050371024;}
        else if(age < 132){L = 0.693062173; M = 143.6949981; S = 0.050460835;}
        else if(age < 133){L = 0.744289752; M = 144.2609497; S = 0.050524236;}
        else if(age < 134){L = 0.79691098; M = 144.8375809; S = 0.050558224;}
        else if(age < 135){L = 0.85045728; M = 145.4240246; S = 0.050560083;}
        else if(age < 136){L = 0.904395871; M = 146.0191748; S = 0.050527494;}
        else if(age < 137){L = 0.958138449; M = 146.621692; S = 0.050458634;}
        else if(age < 138){L = 1.011054559; M = 147.2300177; S = 0.050352269;}
        else if(age < 139){L = 1.062474568; M = 147.8423918; S = 0.050207825;}
        else if(age < 140){L = 1.111727029; M = 148.4568879; S = 0.050025434;}
        else if(age < 141){L = 1.158135105; M = 149.0714413; S = 0.049805967;}
        else if(age < 142){L = 1.201050821; M = 149.6838943; S = 0.049551023;}
        else if(age < 143){L = 1.239852328; M = 150.2920328; S = 0.049262895;}
        else if(age < 144){L = 1.274006058; M = 150.8936469; S = 0.048944504;}
        else if(age < 145){L = 1.303044695; M = 151.4865636; S = 0.048599314;}
        else if(age < 146){L = 1.326605954; M = 152.0686985; S = 0.048231224;}
        else if(age < 147){L = 1.344443447; M = 152.6380955; S = 0.047844442;}
        else if(age < 148){L = 1.356437773; M = 153.1929631; S = 0.047443362;}
        else if(age < 149){L = 1.362602695; M = 153.7317031; S = 0.04703243;}
        else if(age < 150){L = 1.363085725; M = 154.2529332; S = 0.046616026;}
        else if(age < 151){L = 1.358162799; M = 154.755501; S = 0.046198356;}
        else if(age < 152){L = 1.348227142; M = 155.2384904; S = 0.04578335;}
        else if(age < 153){L = 1.333772923; M = 155.7012216; S = 0.045374597;}
        else if(age < 154){L = 1.315374704; M = 156.1432438; S = 0.044975281;}
        else if(age < 155){L = 1.293664024; M = 156.564323; S = 0.044588148;}
        else if(age < 156){L = 1.269304678; M = 156.9644258; S = 0.044215488;}
        else if(age < 157){L = 1.242968236; M = 157.3436995; S = 0.043859135;}
        else if(age < 158){L = 1.21531127; M = 157.7024507; S = 0.04352048;}
        else if(age < 159){L = 1.186955477; M = 158.0411233; S = 0.043200497;}
        else if(age < 160){L = 1.158471522; M = 158.3602756; S = 0.042899776;}
        else if(age < 161){L = 1.130367088; M = 158.6605588; S = 0.042618565;}
        else if(age < 162){L = 1.103079209; M = 158.9426964; S = 0.042356812;}
        else if(age < 163){L = 1.076970655; M = 159.2074654; S = 0.042114211;}
        else if(age < 164){L = 1.052329922; M = 159.455679; S = 0.041890247;}
        else if(age < 165){L = 1.029374161; M = 159.688172; S = 0.04168424;}
        else if(age < 166){L = 1.008254396; M = 159.9057871; S = 0.041495379;}
        else if(age < 167){L = 0.989062282; M = 160.1093647; S = 0.041322765;}
        else if(age < 168){L = 0.971837799; M = 160.299733; S = 0.041165437;}
        else if(age < 169){L = 0.95657215; M = 160.4776996; S = 0.041022401;}
        else if(age < 170){L = 0.94324228; M = 160.6440526; S = 0.040892651;}
        else if(age < 171){L = 0.931767062; M = 160.7995428; S = 0.040775193;}
        else if(age < 172){L = 0.922058291; M = 160.9448916; S = 0.040669052;}
        else if(age < 173){L = 0.914012643; M = 161.0807857; S = 0.040573288;}
        else if(age < 174){L = 0.907516917; M = 161.2078755; S = 0.040487005;}
        else if(age < 175){L = 0.902452436; M = 161.3267744; S = 0.040409354;}
        else if(age < 176){L = 0.898698641; M = 161.4380593; S = 0.040339537;}
        else if(age < 177){L = 0.896143482; M = 161.5422726; S = 0.040276811;}
        else if(age < 178){L = 0.894659668; M = 161.639917; S = 0.040220488;}
        else if(age < 179){L = 0.89413892; M = 161.7314645; S = 0.040169932;}
        else if(age < 180){L = 0.894475371; M = 161.8173534; S = 0.040124562;}
        else if(age < 181){L = 0.895569834; M = 161.8979913; S = 0.040083845;}
        else if(age < 182){L = 0.897330209; M = 161.9737558; S = 0.040047295;}
        else if(age < 183){L = 0.899671635; M = 162.0449969; S = 0.040014473;}
        else if(age < 184){L = 0.902516442; M = 162.1120386; S = 0.03998498;}
        else if(age < 185){L = 0.905793969; M = 162.17518; S = 0.039958458;}
        else if(age < 186){L = 0.909440266; M = 162.2346979; S = 0.039934584;}
        else if(age < 187){L = 0.913397733; M = 162.2908474; S = 0.039913066;}
        else if(age < 188){L = 0.91761471; M = 162.343864; S = 0.039893644;}
        else if(age < 189){L = 0.922045055; M = 162.3939652; S = 0.039876087;}
        else if(age < 190){L = 0.926647697; M = 162.4413513; S = 0.039860185;}
        else if(age < 191){L = 0.931386217; M = 162.4862071; S = 0.039845754;}
        else if(age < 192){L = 0.93622842; M = 162.5287029; S = 0.039832629;}
        else if(age < 193){L = 0.941145943; M = 162.5689958; S = 0.039820663;}
        else if(age < 194){L = 0.94611388; M = 162.6072309; S = 0.039809725;}
        else if(age < 195){L = 0.95111043; M = 162.6435418; S = 0.0397997;}
        else if(age < 196){L = 0.956116576; M = 162.6780519; S = 0.039790485;}
        else if(age < 197){L = 0.961115792; M = 162.7108751; S = 0.039781991;}
        else if(age < 198){L = 0.966093766; M = 162.7421168; S = 0.039774136;}
        else if(age < 199){L = 0.971038162; M = 162.7718741; S = 0.03976685;}
        else if(age < 200){L = 0.975938391; M = 162.8002371; S = 0.03976007;}
        else if(age < 201){L = 0.980785418; M = 162.8272889; S = 0.039753741;}
        else if(age < 202){L = 0.985571579; M = 162.8531067; S = 0.039747815;}
        else if(age < 203){L = 0.99029042; M = 162.8777619; S = 0.039742249;}
        else if(age < 204){L = 0.994936555; M = 162.9013208; S = 0.039737004;}
        else if(age < 205){L = 0.999505539; M = 162.9238449; S = 0.039732048;}
        else if(age < 206){L = 1.003993753; M = 162.9453912; S = 0.039727352;}
        else if(age < 207){L = 1.0083983; M = 162.9660131; S = 0.03972289;}
        else if(age < 208){L = 1.012716921; M = 162.9857599; S = 0.03971864;}
        else if(age < 209){L = 1.016947912; M = 163.0046776; S = 0.039714581;}
        else if(age < 210){L = 1.021090055; M = 163.0228094; S = 0.039710697;}
        else if(age < 211){L = 1.025142554; M = 163.0401953; S = 0.039706971;}
        else if(age < 212){L = 1.029104983; M = 163.0568727; S = 0.039703391;}
        else if(age < 213){L = 1.032977233; M = 163.0728768; S = 0.039699945;}
        else if(age < 214){L = 1.036759475; M = 163.0882404; S = 0.039696623;}
        else if(age < 215){L = 1.040452117; M = 163.1029943; S = 0.039693415;}
        else if(age < 216){L = 1.044055774; M = 163.1171673; S = 0.039690313;}
        else if(age < 217){L = 1.047571238; M = 163.1307866; S = 0.039687311;}
        else if(age < 218){L = 1.050999451; M = 163.1438776; S = 0.039684402;}
        else if(age < 219){L = 1.054341482; M = 163.1564644; S = 0.039681581;}
        else if(age < 220){L = 1.057598512; M = 163.1685697; S = 0.039678842;}
        else if(age < 221){L = 1.060771808; M = 163.1802146; S = 0.039676182;}
        else if(age < 222){L = 1.063862715; M = 163.1914194; S = 0.039673596;}
        else if(age < 223){L = 1.066872639; M = 163.202203; S = 0.039671082;}
        else if(age < 224){L = 1.069803036; M = 163.2125835; S = 0.039668635;}
        else if(age < 225){L = 1.072655401; M = 163.2225779; S = 0.039666254;}
        else if(age < 226){L = 1.075431258; M = 163.2322024; S = 0.039663936;}
        else if(age < 227){L = 1.078132156; M = 163.2414722; S = 0.039661679;}
        else if(age < 228){L = 1.080759655; M = 163.2504019; S = 0.039659481;}
        else if(age < 229){L = 1.083315329; M = 163.2590052; S = 0.039657339;}
        else if(age < 230){L = 1.085800751; M = 163.2672954; S = 0.039655252;}
        else if(age < 231){L = 1.088217496; M = 163.2752848; S = 0.039653218;}
        else if(age < 232){L = 1.090567133; M = 163.2829854; S = 0.039651237;}
        else if(age < 233){L = 1.092851222; M = 163.2904086; S = 0.039649306;}
        else if(age < 234){L = 1.095071313; M = 163.297565; S = 0.039647424;}
        else if(age < 235){L = 1.097228939; M = 163.304465; S = 0.039645591;}
        else if(age < 236){L = 1.099325619; M = 163.3111185; S = 0.039643804;}
        else if(age < 237){L = 1.101362852; M = 163.3175349; S = 0.039642063;}
        else if(age < 238){L = 1.103342119; M = 163.3237231; S = 0.039640367;}
        else if(age < 239){L = 1.105264876; M = 163.3296918; S = 0.039638715;}
        else if(age < 240){L = 1.107132561; M = 163.3354491; S = 0.039637105;}

}

}
function get_Systolic_BP_Avg(sex,Height_Z,Age_Factor){
    if(sex=='male'){
        return 102.19768 + (1.82416 * Age_Factor) + (0.12776 * power(Age_Factor,2)) + 
        (0.00249 * power(Age_Factor,3)) - (0.00135 * power(Age_Factor,4))  +
         (2.73157 * Height_Z) - (0.19618 * power(Height_Z,2)) - (0.04659 * power(Height_Z,3)) + 
         (0.00947 * power(Height_Z,4));
    }else{
        return 102.01027 + (1.94397 * Age_Factor) + (0.00598 * power(Age_Factor,2)) -
         (0.00789 * power(Age_Factor,3)) - (0.00059 * power(Age_Factor,4))  + 
         (2.03526 * Height_Z) + (0.02534 * power(Height_Z,2)) - (0.01884 * power(Height_Z,3)) + 
         (0.00121 * power(Height_Z,4));
    }
}
function get_Diastolic_BP_Avg(sex,Height_Z,Age_Factor){
    if(sex=='male'){
        return 61.01217 + (0.68314 * Age_Factor) - (0.09835 * power(Age_Factor,2)) +
         (0.01711 * power(Age_Factor,3)) + (0.00045 * power(Age_Factor,4))  +
          (1.46993 * Height_Z) - (0.07849 * power(Height_Z,2)) - (0.03144 * power(Height_Z,3)) + 
          (0.00967 * power(Height_Z,4));
    }else{
        return  60.50510 + (1.01301 * Age_Factor) + (0.01157 * power(Age_Factor,2)) + 
        (0.00424 * power(Age_Factor,3)) - (0.00137 * power(Age_Factor,4))  + 
        (1.16641 * Height_Z) + (0.12795 * power(Height_Z,2)) - (0.03869 * power(Height_Z,3)) - 
        (0.00079 * power(Height_Z,4));

    }
}
function get_Systolic_BP_Z(sex,Systolic_BP,Systolic_BP_Avg){
    if(sex=='male'){
        return  (Systolic_BP - Systolic_BP_Avg) / 10.7128;
    }else{
        return (Systolic_BP - Systolic_BP_Avg) / 10.4855;
    }
}
function get_Diastolic_BP_Z(sex,Diastolic_BP, Diastolic_BP_Avg){
    if(sex=='male'){
        return  (Diastolic_BP - Diastolic_BP_Avg) / 11.6032;
    }else{
        return (Diastolic_BP - Diastolic_BP_Avg) / 10.9573;
    }
}
function get_Systolic_Cut_Points(sex,Systolic_BP_Avg){
    let intermediate=0
    let poor=0
    if(sex=='male'){
    intermediate =  Systolic_BP_Avg + ( 1.28 * 10.7128)
    poor =  Systolic_BP_Avg + ( 1.64 * 10.7128)
}else{
     intermediate =  Systolic_BP_Avg + ( 1.28 * 10.4855)
     poor =  Systolic_BP_Avg + ( 1.64 * 10.4855)
}
intermediate=Math.round(intermediate)
poor=Math.round(poor)
return {intermediate,poor}
}
function get_Diastolic_Cut_Points(sex,Diastolic_BP_Avg){
    let intermediate=0
    let poor=0
    if(sex=='male'){
   intermediate =  Diastolic_BP_Avg + ( 1.28 * 11.6032)
   poor =  Diastolic_BP_Avg + ( 1.64 * 11.6032)
}else{
    intermediate =  Diastolic_BP_Avg + ( 1.28 * 10.9573)
    poor =  Diastolic_BP_Avg + ( 1.64 * 10.9573)
}
intermediate=Math.round(intermediate)
poor=Math.round(poor)
return {intermediate,poor}
}

function calculateBloodPressure(sex,age,Height,Systolic_BP,Diastolic_BP) {

   let height=Height*2.54
let Age_Factor =  (age/12) - 10;
let Systolic_BP_Z=0
let Diastolic_BP_Z=0

define_L_M_S(sex,age)

let Height_Z = get_Height_Z(height)

let Systolic_BP_Avg =  get_Systolic_BP_Avg(sex,Height_Z,Age_Factor)

let Diastolic_BP_Avg =  get_Diastolic_BP_Avg(sex,Height_Z,Age_Factor)

Systolic_BP_Z =  get_Systolic_BP_Z(sex,Systolic_BP,Systolic_BP_Avg)

Diastolic_BP_Z = get_Diastolic_BP_Z(sex,Diastolic_BP,Diastolic_BP_Avg)

let Systolic_BP_Percentile =  ZtoPercentile(Systolic_BP_Z); //dar un az de 1.28 para 90th
Systolic_BP_Percentile=fixDP(Systolic_BP_Percentile, 0)
Systolic_BP_Percentile=Number(Systolic_BP_Percentile)

let Diastolic_BP_Percentile =  ZtoPercentile(Diastolic_BP_Z);
Diastolic_BP_Percentile=fixDP(Diastolic_BP_Percentile, 0)
Diastolic_BP_Percentile=Number(Diastolic_BP_Percentile)


return {sex,systolic: Systolic_BP_Percentile, diastolic: Diastolic_BP_Percentile}

}

function bloodPressureCutPoints(sex,age,Height){
    let height=Height*2.54
    let Age_Factor =  (age/12) - 10
    define_L_M_S(sex,age)
let Height_Z = get_Height_Z(height) 
let Systolic_BP_Avg =  get_Systolic_BP_Avg(sex,Height_Z,Age_Factor)  
let Diastolic_BP_Avg =  get_Diastolic_BP_Avg(sex,Height_Z,Age_Factor)

let systolic_cut_points =  get_Systolic_Cut_Points(sex,Systolic_BP_Avg)
let diastolic_cut_points = get_Diastolic_Cut_Points(sex,Diastolic_BP_Avg)
return{systolic_cut_points,diastolic_cut_points}

}

export {calculateBloodPressure,bloodPressureCutPoints}