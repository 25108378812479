<template id="previewToPrint">
<div>
   
      <center  class="wrapper sudobody" data-link-color="#FFFFFF" data-body-style="font-size:16px; font-family:verdana,geneva,sans-serif; color:#516775; background-color:#ffffff;">

        <div class="webkit">
          <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#ffffff">
            <tr>
              <td valign="top" bgcolor="#ffffff" width="100%">
                <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
                  <tr>
                    <td width="100%">
                      <table width="100%" cellpadding="0" cellspacing="0" border="0">
                        <tr>
                          <td>
                            <!--[if mso]>
    <center>
    <table><tr><td width="600">
  <![endif]-->
                                    <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width:100%; max-width:600px;" align="center">
                                      <tr>
                                        <td role="modules-container" style="padding:0px 0px 0px 0px; color:#516775; text-align:left;" bgcolor="#F9F5F2" width="100%" align="left"><table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%" style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
    <tr>
      <td role="module-content">
        <p>Patient´s name Plan</p>
        
      </td>
    </tr>
  </table>

  <table class="previewSection" border="0" cellpadding="0" cellspacing="0" align="center" width="100%" role="module" data-type="columns" style="padding:0px 0px 0px 0px;" bgcolor="#10b1a9">
    <tbody>
      <tr role="module-content">
        <td height="100%" valign="top"><table width="598" style="width:598px; border-spacing:0; border-collapse:collapse; margin:0px 1px 0px 1px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-0">
      <tbody>
        <tr>
          <td style="padding:0px;margin:0px;border-spacing:0;"><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="8b9kRzxkUibr6UkDZF3UYu">
    <tbody>
      <tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center"><img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px;" width="50" alt="" data-proportionally-constrained="true" data-responsive="false" src="../static/prescriptionImages/icono1-hombre-con-manzana.jpg" height="50"></td>
     
      </tr>
    </tbody>
  </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="a76e6519-c6e6-4598-a15d-daf77dc0b474.2" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="color: #ffffff; font-size: 24px">{{ actualPatient.name }}'s Plan</span></div><div></div></div></td>
      </tr>
    </tbody>
  </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="a76e6519-c6e6-4598-a15d-daf77dc0b474.2.1" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="color: #ffffff; font-size: 18px">to be more active and make healthy food choices</span></div><div></div></div></td>
      </tr>
    </tbody>
  </table></td>
        </tr>
      </tbody>
    </table></td>
      </tr>
    </tbody>
  </table>
  <!-- removed option
  <table class="previewSection module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="2d876c9d-73bf-482a-8c2b-fdf3537287d6" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:center; background-color:#ffffff;" height="100%" valign="top" bgcolor="#ffffff" role="module-content"><div><div style="font-family: inherit; text-align: inherit"><span style="font-size: 16px; color: #10b1a9"><strong>Your next scheduled appointment is on: {{focusedPrescription.nextAppointment}}</strong></span></div><div></div></div></td>
      </tr>
    </tbody>
  </table>
  -->
  <template >
  <table class="previewSection module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="2af60530-c6df-4a98-a6bc-d4da0ba6776a" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td  style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div>
          <div v-if="!focusedPrescription.physicalRecomInact || !alldietRecomInact" style="font-family: inherit; text-align: inherit">Below is your plan to help you improve your heart health. We’ve made these goals based on what you told us about your physical activity and eating habits. We hope these goals are realistic for you. If you are struggling to meet your goals, please reach out to your provider sooner.</div>
          <div v-else-if="ageInMonth<240" style="font-family: inherit; text-align: inherit">We did not identify any specific physical activity or food intake goals during your appointment today. To keep your heart healthy, we encourage you to be active for at least 60 minutes every day. It is helpful to include fruits, vegetables and whole grains in your diet, while limiting sugar sweetened beverages and sugary, low-nutrient snacks. Please reach out if you would like work with us to identify more specific goals.</div>
          <div v-else style="font-family: inherit; text-align: inherit">We did not identify any specific physical activity or food intake goals during your appointment today. To keep your heart healthy, we encourage you to be active for at least 150 minutes every week. It is helpful to include fruits, vegetables and whole grains in your diet, while limiting sugar sweetened beverages and sugary, low-nutrient snacks. Please reach out if you would like work with us to identify more specific goals.</div>
          </div></td>
      </tr>
    </tbody>
  </table>
  </template>
  <template v-if="!focusedPrescription.physicalRecomInact">
    <div class="previewSection">
  <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="77367841-c0b2-4938-abc9-854d7527c42a" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#ffffff;" height="100%" valign="top" bgcolor="#ffffff" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="color: #fbaf4d; font-size: 24px"><strong>YOUR GOALS FOR PHYSICAL ACTIVITY</strong></span></div><div></div></div></td>
      </tr>
    </tbody>
  </table>
  </div>
            <div class="previewSection" v-if="!lerisk.bmi.active">
  <table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="ad9443e8-cf12-40f5-acb6-7e41512f6827.4.1.2.1">
    <tbody>
      <tr>
        <td   height="100%" valign="top" role="module-content">
<div style="font-family: inherit; text-align: center"><strong>There is not BMI information on your profile, or it is disabled</strong></div>
</td>
      </tr>
    </tbody>
  </table>
  </div>
            <div class="previewSection" v-else-if=" info.physical">
            <table border="0" cellpadding="0" cellspacing="0" align="center" width="100%" role="module" data-type="columns" style="padding:0px 0px 0px 0px;" bgcolor="#FFFFFF">
<tbody>
<tr role="module-content">
  <td height="100%" valign="top"><table width="290" style="width:290px; border-spacing:0; border-collapse:collapse; margin:0px 10px 0px 0px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-0">
<tbody >
  <tr>
    <td style="padding:0px;margin:0px;border-spacing:0;"><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1ed0486a-065f-4ec5-bf70-6206b0d3bcbc.1">
<tbody>
<tr>
  <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit"><strong>{{physicatActivitiesRecommnedation.week1_4.title}}:</strong> </div><div></div></div></td>
</tr>
</tbody>
</table></td>
  </tr>
</tbody>
</table><table width="290" style="width:290px; border-spacing:0; border-collapse:collapse; margin:0px 0px 0px 10px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-1">
<tbody>
  <tr>
    <td style="padding:0px;margin:0px;border-spacing:0;"><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1174e986-0050-46bd-898e-6e614fd83dd8.1">
<tbody>
<tr>
  <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div>
  <div style="font-family: inherit">
  {{focusedPrescription.physicalRecomByClinician?focusedPrescription.physicalRecomByClinician.week1_4:physicatActivitiesRecommnedation.week1_4.str}}
  </div><div></div></div></td>
</tr>
</tbody>
</table></td>
  </tr>
</tbody>
</table></td>
</tr>
</tbody>
</table>
<!-- other physical weeks recommendations

 <table border="0" cellpadding="0" cellspacing="0" align="center" width="100%" role="module" data-type="columns" style="padding:0px 0px 0px 0px;" bgcolor="#FFFFFF">
<tbody>
<tr role="module-content">
  <td height="100%" valign="top"><table width="290" style="width:290px; border-spacing:0; border-collapse:collapse; margin:0px 10px 0px 0px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-0">
<tbody >
  <tr>
    <td style="padding:0px;margin:0px;border-spacing:0;"><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1ed0486a-065f-4ec5-bf70-6206b0d3bcbc.1">
<tbody>
<tr>
  <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit"><strong>{{physicatActivitiesRecommnedation.week5_8.title}}:</strong> </div><div></div></div></td>
</tr>
</tbody>
</table></td>
  </tr>
</tbody>
</table><table width="290" style="width:290px; border-spacing:0; border-collapse:collapse; margin:0px 0px 0px 10px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-1">
<tbody>
  <tr>
    <td style="padding:0px;margin:0px;border-spacing:0;"><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1174e986-0050-46bd-898e-6e614fd83dd8.1">
<tbody>
<tr>
  <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit">{{physicatActivitiesRecommnedation.week5_8.str}}</div><div></div></div></td>
</tr>
</tbody>
</table></td>
  </tr>
</tbody>
</table></td>
</tr>
</tbody>
</table>
<table border="0" cellpadding="0" cellspacing="0" align="center" width="100%" role="module" data-type="columns" style="padding:0px 0px 0px 0px;" bgcolor="#FFFFFF">
<tbody>
<tr role="module-content">
  <td height="100%" valign="top"><table width="290" style="width:290px; border-spacing:0; border-collapse:collapse; margin:0px 10px 0px 0px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-0">
<tbody >
  <tr>
    <td style="padding:0px;margin:0px;border-spacing:0;"><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1ed0486a-065f-4ec5-bf70-6206b0d3bcbc.1">
<tbody>
<tr>
  <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit"><strong>{{physicatActivitiesRecommnedation.week9_12.title}}:</strong> </div><div></div></div></td>
</tr>
</tbody>
</table></td>
  </tr>
</tbody>
</table><table width="290" style="width:290px; border-spacing:0; border-collapse:collapse; margin:0px 0px 0px 10px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-1">
<tbody>
  <tr>
    <td style="padding:0px;margin:0px;border-spacing:0;"><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1174e986-0050-46bd-898e-6e614fd83dd8.1">
<tbody>
<tr>
  <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit">{{physicatActivitiesRecommnedation.week9_12.str}}</div><div></div></div></td>
</tr>
</tbody>
</table></td>
  </tr>
</tbody>
</table></td>
</tr>
</tbody>
</table> -->
  </div>
  
  <table class="previewSection module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="53631e90-3440-4a1a-8cc2-54cd293b0227" data-mc-module-version="2019-10-22">
    <tbody>
      <tr >
        <td  style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: inherit"><span style="font-size: 18px; color: #fbaf4d"><strong>Remember physical activity should be fun! Reach your goals by doing activities you enjoy!:</strong></span></div><div></div></div></td>
      </tr>
    </tbody>
  </table><table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="e5edcd36-d39c-485c-8685-0e0585279fc0">
    <tbody>
      <tr style=";background-color:#FFFFFF">
        <td  height="100%" valign="top" role="module-content">
<div  v-if="activities" 
style="font-family: inherit; text-align: inherit">
<ul>

<li class="previewSection"
v-for="activity in selectedActivities" :key="activity.id"
>{{activity.name}}</li>

</ul>
</div>

<div style=";background-color:#FFFFFF" v-else >You have not selected activities</div>
    </td>
      </tr>
    </tbody>
  </table>
  
 
  <table  class="module previewSection" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="0903c5a9-50d5-4bdc-9ed9-9326cc619f2f" data-mc-module-version="2019-10-22">
    <tbody>
      <tr >
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: inherit"><span style="color: #fbaf4d; font-size: 18px"><strong>Resources to help you be more active:</strong></span></div><div></div></div></td>
      </tr>
    </tbody>
  </table>
   <div class="no-padding-top" style="backgroundColor:white;padding:20px"><f7-button external target="_blank" :href="`https://map.preventtool.com/?c=${onClinic}&p=noPatient`" outline  >Open Community Resources map</f7-button></div>
 
  <table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="562bcdda-f06e-4f88-92de-1fcac7fce90f">
    <tbody>
      <tr style=";background-color:#FFFFFF">
        <td height="100%" valign="top" role="module-content">
<div 
v-if="selectedResourcesByType('physical').length>0"
 style="font-family: inherit; text-align: inherit">
  <div class="previewSection" v-for="resource in selectedResourcesByType('physical')" :key="resource.id">
 <div style="font-family: inherit; text-align: inherit"><strong>{{resource.ginfo.name}}:</strong></div> 
 <div v-if="resource.type"><div style="font-family: inherit; text-align: inherit">Type of resource: {{typeOfResources[resource.type].name}}</div></div>
 <div v-if="resource.amenities" ><div style="font-family: inherit; text-align: inherit">Amenities: {{resource.amenities}}</div></div>
<div v-if="resource.ginfo.formatted_address"><div style="font-family: inherit; text-align: inherit">Address: <f7-link  :href="resource.ginfo.url" target="_blank" :external="true">{{resource.ginfo.formatted_address}}</f7-link></div></div>
<div v-if="resource.ginfo.international_phone_number"><div style="font-family: inherit; text-align: inherit">Phone number: {{resource.ginfo.international_phone_number}}</div></div>
<div v-if="resource.opening_hours && resource.opening_hours.length>0"><div style="font-family: inherit; text-align: inherit">Opening hours:<br/>
<div style="margin-left:50px" v-for="day in resource.opening_hours" :key="day"

>{{day}} <br/></div></div></div>
<div v-if="resource.ginfo.website"><div style="font-family: inherit; text-align: inherit">Website:<f7-link  :href="resource.ginfo.website" target="_blank" :external="true"> Link to website</f7-link></div></div>
<br/>
 </div>
 
</div>
<div class="previewSection" v-else>You have not selected any physical activity resources</div>
</td>
      </tr>
    </tbody>
  </table>

  
  <div class="previewSection">
  <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="b6607d28-71dd-4641-9af0-8550b0be09e2" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: inherit"><span style="color: #10b1a9; font-size: 24px"><strong>Additional Information:</strong></span></div><div></div></div></td>
      </tr>
    </tbody>
  </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="dd95c60c-0c40-4901-8b7c-9c6c52e944c6" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: inherit"><span style="color: #0b0b0b"><strong>Being active helps:</strong></span></div><div></div></div></td>
      </tr>
    </tbody>
  </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="bKZJcGfRPJb7R2nzyp6ZB6">
      <tbody><tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center"><img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:100% !important; width:100%; height:auto !important;" src="../static/prescriptionImages/icono-2--5-recomendaciones.jpg" alt="" width="600" data-responsive="true" data-proportionally-constrained="false"></td>
      </tr>
    </tbody></table>
    <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="ef180293-b462-409a-aaa3-7e2353d13e82" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#0fb1a9;" height="100%" valign="top" bgcolor="#0fb1a9" role="module-content"><div><div style="font-family: inherit; text-align: inherit"><span style="color: #ffffff; font-size: 14px"><strong>&nbsp;&nbsp;&nbsp;Don`t forget your muscles!</strong></span></div><div></div></div></td>
      </tr>
    </tbody>
  </table>
    <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="6291635f-b909-439d-8d62-3f4f01e17947">
    <tbody>
      <tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center"><img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:100% !important; width:100%; height:auto !important;" width="600" alt="" data-proportionally-constrained="true" data-responsive="true" src="../static/prescriptionImages/icono-3-musculos-mas-grande.jpg"></td>
      </tr>
    </tbody>
  </table>

  <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="79c393b8-8290-4cd0-948d-a0d4daedec2c" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: inherit"><strong>Tips to help you achieve your physical activity goals:</strong></div>
<div style="font-family: inherit; text-align: inherit"><br></div>
<div style="font-family: inherit; text-align: inherit">• Do what you enjoy!</div>
<div style="font-family: inherit; text-align: inherit">• Families that play together, stay healthy together. Find time when your family could fit in physical activity together.</div>
<div style="font-family: inherit; text-align: inherit">• It is okay to break up the time and not be active all at once.</div>
<div style="font-family: inherit; text-align: inherit">• It is important to warm up and cool down/stretch before and after exercise.</div>
<div style="font-family: inherit; text-align: inherit">• Be sure to listen to your body. Stop exercising if any discomfort is felt in a muscle or joint. It is normal to feel a gentle burning sensation, but if the pain is worse, you should stop.</div><div></div></div></td>
      </tr>
    </tbody>
  </table>
  </div>
  </template>
  <template v-if="!alldietRecomInact">
  <div class="previewSection" >
  <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="77367841-c0b2-4938-abc9-854d7527c42a.1" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
       <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="font-size: 24px; color: #7fbf44"><strong>YOUR GOALS FOR HEALTHY EATING</strong></span></div><div></div></div></td>
      </tr>
    </tbody>
  </table>
  </div>
    <div class="previewSection"   v-if="lerisk.diet.active && ((lerisk.diet.dietQ1=='Rarely/Never' && lerisk.diet.dietQ2=='Rarely/Never' && lerisk.diet.dietQ3=='Rarely/Never' && lerisk.diet.dietQ4=='Rarely/Never' && lerisk.diet.dietQ5=='Rarely/Never'))"
        >Good job. There are no behaviour change recommendations for you.</div>

  <div class="previewSection" v-if="!focusedPrescription.dietQ1RecomInact&&(( focusedPrescription.risk.diet.active && focusedPrescription.risk.diet.dietQ1!=='Rarely/Never') || !focusedPrescription.risk.diet.active)">
  <table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="cee9a49a-875a-4353-b25f-b46fa2c35ea1">
    <tbody>
      <tr>
        <td height="100%" valign="top" role="module-content">
<div  style="font-family: inherit;background-color:#FFFFFF"><strong>Fruits:</strong> Your goal is to eat 2 or more fruits per day including fruits in the green and yellow foods. Rarely include fruit listed in the red box.</div></td>
      </tr>
    </tbody>
  </table>
  <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1e529f69-9e24-4646-b137-258c0e0c96f4">
    <tbody>
      <tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center"><img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:100% !important; width:100%; height:auto !important;" width="600" alt="" data-proportionally-constrained="true" data-responsive="true" src="../static/prescriptionImages/q1-otro-color-img.jpg"></td>
      </tr>
    </tbody>
  </table><table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="cee9a49a-875a-4353-b25f-b46fa2c35ea1.4">
    <tbody>
      <tr>
        <td height="100%" valign="top" role="module-content"></td>
      </tr>
    </tbody>
  </table>
  </div>

  <div class="previewSection"  v-if="!focusedPrescription.dietQ2RecomInact&&(( focusedPrescription.risk.diet.active && focusedPrescription.risk.diet.dietQ2!=='Rarely/Never') || !focusedPrescription.risk.diet.active)">
  <table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="cee9a49a-875a-4353-b25f-b46fa2c35ea1.5">
    <tbody>
      <tr>
        <td height="100%" valign="top" role="module-content">
<div  class="white" style="font-family: inherit;background-color:#FFFFFF"><strong>Vegetables:</strong> Your goal is to eat 2 or more vegetables per day. Eat vegetables in the green and yellow boxes. Rarely include vegetable listed in red box.</div></td>
      </tr>
    </tbody>
  </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1e529f69-9e24-4646-b137-258c0e0c96f4.4">
    <tbody>
      <tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center">
          <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:100% !important; width:100%; height:auto !important;" width="600" alt="" data-proportionally-constrained="true" data-responsive="true" src="../static/prescriptionImages/q2-otro-color-img.jpg">
        </td>
      </tr>
    </tbody>
  </table><table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="cee9a49a-875a-4353-b25f-b46fa2c35ea1.4.4">
    <tbody>
      <tr>
        <td height="100%" valign="top" role="module-content"></td>
      </tr>
    </tbody>
  </table>
  </div>

  <div class="previewSection"  v-if="!focusedPrescription.dietQ3RecomInact&&(( focusedPrescription.risk.diet.active && focusedPrescription.risk.diet.dietQ3!=='Rarely/Never') || !focusedPrescription.risk.diet.active)">
  <table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="cee9a49a-875a-4353-b25f-b46fa2c35ea1.3">
    <tbody>
      <tr>
        <td height="100%" valign="top" role="module-content">
<div   style="font-family: inherit;background-color:#FFFFFF"><strong>Grains:</strong> Eat whole grains listed in the green or yellow box. Rarely eat grains listed in the red box.</div></td>
      </tr>
    </tbody>
  </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1e529f69-9e24-4646-b137-258c0e0c96f4.3">
    <tbody>
      <tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center">
          <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:100% !important; width:100%; height:auto !important;" width="600" alt="" data-proportionally-constrained="true" data-responsive="true" src="../static/prescriptionImages/q3-otro-color-img.jpg">
        </td>
      </tr>
    </tbody>
  </table><table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="cee9a49a-875a-4353-b25f-b46fa2c35ea1.4.3">
    <tbody>
      <tr>
        <td height="100%" valign="top" role="module-content"></td>
      </tr>
    </tbody>
  </table>
  </div >

  <div class="previewSection"  v-if="!focusedPrescription.dietQ4RecomInact&&(( focusedPrescription.risk.diet.active && focusedPrescription.risk.diet.dietQ4!=='Rarely/Never') || !focusedPrescription.risk.diet.active)">
  <table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="cee9a49a-875a-4353-b25f-b46fa2c35ea1.1">
    <tbody>
      <tr style=";background-color:#FFFFFF">
        <td height="100%" valign="top" role="module-content">
<div  style="font-family: inherit"><strong>Sweetened beverages:</strong> Drink more liquids in the green and yellow boxes. Rarely include drinks listed in the red box.</div></td>
      </tr>
    </tbody>
  </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1e529f69-9e24-4646-b137-258c0e0c96f4.1">
    <tbody>
      <tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center">
          <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:100% !important; width:100%; height:auto !important;" width="600" alt="" data-proportionally-constrained="true" data-responsive="true" src="../static/prescriptionImages/q4otro-colorimg.jpg">
        </td>
      </tr>
    </tbody>
  </table><table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="cee9a49a-875a-4353-b25f-b46fa2c35ea1.4.1">
    <tbody>
      <tr>
        <td height="100%" valign="top" role="module-content"></td>
      </tr>
    </tbody>
  </table>
  </div>
  <div class="previewSection"  v-if="!focusedPrescription.dietQ5RecomInact&&(( focusedPrescription.risk.diet.active && focusedPrescription.risk.diet.dietQ5!=='Rarely/Never') || !focusedPrescription.risk.diet.active)">
  <table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="cee9a49a-875a-4353-b25f-b46fa2c35ea1.2">
    <tbody >
      <tr>
        <td height="100%" valign="top" role="module-content">
<div  style="font-family: inherit;background-color:#FFFFFF"><strong>Snacking between meals:</strong> Start eating foods in the green and yellow boxes when you feel hungry between meals. Rarely choose to eat foods in the red box.</div></td>
      </tr>
    </tbody>
  </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1e529f69-9e24-4646-b137-258c0e0c96f4.2">
    <tbody>
      <tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center">
          <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:100% !important; width:100%; height:auto !important;" width="600" alt="" data-proportionally-constrained="true" data-responsive="true" src="../static/prescriptionImages/q5-otro-colorimg.jpg">
        </td>
      </tr>
    </tbody>
  </table><table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="cee9a49a-875a-4353-b25f-b46fa2c35ea1.4.2">
    <tbody>
      <tr>
        <td height="100%" valign="top" role="module-content"></td>
      </tr>
    </tbody> 
  </table>
  </div>

  <table class="previewSection module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="0903c5a9-50d5-4bdc-9ed9-9326cc619f2f.1" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: inherit"><span style="font-size: 18px; color: #7fbf44"><strong>Resources to help you make healthy food choices:</strong></span></div><div></div></div></td>
      </tr>
    </tbody>
  </table>
     <div class="no-padding-top" style="backgroundColor:white;padding:20px"><f7-button external target="_blank" :href="`https://map.preventtool.com/?c=${onClinic}&p=noPatient`"  outline  >Open Community Resources map</f7-button></div>
 
  <table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="ead58b88-eca2-4817-90ec-cef57c317147">
    <tbody>
      <tr>
        <td style="background-color:#FFFFFF" height="100%" valign="top" role="module-content">
<div v-if="selectedResourcesByType('diet').length>0" style="font-family: inherit; text-align: inherit;background-color:#FFFFFF">
<div class="previewSection" v-for="resource in selectedResourcesByType('diet')" :key="resource.id">
 <div style="font-family: inherit; text-align: inherit"><strong>{{resource.ginfo.name}}:</strong></div> 
 <div v-if="resource.type"><div style="font-family: inherit; text-align: inherit">Type of resource: {{typeOfResources[resource.type].name}}</div></div>
 <div v-if="resource.amenities" ><div style="font-family: inherit; text-align: inherit">Amenities: {{resource.amenities}}</div></div>
<div v-if="resource.ginfo.formatted_address"><div style="font-family: inherit; text-align: inherit">Address: <f7-link  :href="resource.ginfo.url" target="_blank" :external="true">{{resource.ginfo.formatted_address}}</f7-link></div></div>
<div v-if="resource.ginfo.international_phone_number"><div style="font-family: inherit; text-align: inherit">Phone number: {{resource.ginfo.international_phone_number}}</div></div>
<div v-if="resource.opening_hours && resource.opening_hours.length>0"><div style="font-family: inherit; text-align: inherit">Opening hours:<br/>
<div style="margin-left:50px" v-for="day in resource.opening_hours" :key="day"
>{{day}} <br/></div></div></div>
<div v-if="resource.ginfo.website"><div style="font-family: inherit; text-align: inherit">Website:<f7-link  :href="resource.ginfo.website" target="_blank" :external="true"> Link to website</f7-link></div></div>
<br/>
 </div>
</div>
<div class="previewSection" v-else>You have not selected any diet resources</div>
</td>
      </tr>
    </tbody>
  </table>
  </template>
  <div  v-if="!focusedPrescription.physicalRecomInact || !alldietRecomInact"
   style="backgroundColor:white;padding-top:20px">
                 <div style="backgroundColor:white;"  class="previewSection text-align-center">
                    <span  style="font-size: 24px;"><strong>Digital Resources</strong></span>
                    <div style="backgroundColor:white;padding:20px"><f7-button outline external target="_blank"
                     :href="`http://rx.preventtool.com/?p=${actualPatient.id}&r=${focusedPrescription.id}&c=${onClinic}&v2=${true}&d=${true}&only=${true}`">Open Digital Resources</f7-button></div>
                 </div>
                 <div  style="backgroundColor:white;padding:20px" class="previewSection"
                  v-for="res in focusedPrescription.selectedDigitalResources" :key="res.id">
                     <p><strong>{{res.name}}</strong></p>
                     <a class="external" target="_blank" :href="res.link" >{{res.link}}</a>
                 </div>
                 </div>
  
  <template v-if="!alldietRecomInact">
  <div class="previewSection" >
  <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="b6607d28-71dd-4641-9af0-8550b0be09e2.1" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: inherit"><span style="color: #10b1a9; font-size: 24px"><strong>Additional Information:</strong></span></div><div></div></div></td>
      </tr>
    </tbody>
  </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="bf7646bc-3a00-481d-bf76-306eace508e5" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: inherit">Our recommendations are based on the Stoplight Diet that is shown to help children and adolescents reach a healthy weight. Foods are grouped into the colors of a stoplight (red, yellow and green) to guide you and your child’s choices in areas that are common challenges among children and adolescents: fruit &amp; vegetable intake, whole grains, sugary beverages and snacking. Your goals above focus on areas that may be a particular challenge for you.</div><div></div></div></td>
      </tr>
    </tbody>
  </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="adcfb08f-6aea-45e1-a75a-76321e877d90">
    <tbody>
      <tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center">
          <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:100% !important; width:100%; height:auto !important;" width="600" alt="" data-proportionally-constrained="true" data-responsive="true" src="../static/prescriptionImages/icono-6-go-stop.jpg">
        </td>
      </tr>
    </tbody>
  </table>
  </div>
  <div class="previewSection">
  <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="6a9ddcf4-3532-4b94-a2d4-db2f9dbcd575">
    <tbody>
      <tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center">
          <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:100% !important; width:100%; height:auto !important;" width="600" alt="" data-proportionally-constrained="true" data-responsive="true" src="../static/prescriptionImages/icono-4-comida.jpg">
        </td>
      </tr>
    </tbody>
  </table>
  <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="1e5b645b-259d-4d68-af8e-b6c8ffd3ba84">
    <tbody>
      <tr>
        <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center">
          <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:100% !important; width:100%; height:auto !important;" width="600" alt="" data-proportionally-constrained="true" data-responsive="true" src="../static/prescriptionImages/icono-5-costos-editado-con-visit.jpg">
        </td>
      </tr>
    </tbody>
  </table>
  </div>
  </template>
  <div  class="previewSection"
   style="font-family: inherit; text-align: center; background:#ffffff"><strong>{{this.focusedPrescription.clinicianName?'Doctor '+this.focusedPrescription.clinicianName:''}}, {{clinics[onClinic].name}} clinic</strong></div>
  <table class="module" role="module" data-type="spacer" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;background:#ffffff" data-muid="f5F8P1n4pQyU8o7DNMMEyW">
      <tbody><tr>
        <td style="padding:0px 0px 30px 0px;" role="module-content" bgcolor="">
        </td>
      </tr>
    </tbody></table></td>
                                      </tr>
                                    </table>
                                    <!--[if mso]>
                                  </td>
                                </tr>
                              </table>
                            </center>
                            <![endif]-->
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        
      </center> 
  </div>
  
</template>

<script>
import {mapState, mapGetters} from 'vuex'
export default {
  data(){
    return {

    }
  },
  computed:{
  ...mapState(['actualPatient','activities','focusedPrescription','resources','typeOfResources','focusedAccount','clinics']),
  ...mapGetters(['ageInMonth','obesityStatus','obesityRecomendationsProperties','physicatActivitiesRecommnedation','selectedResourcesByType','onClinic','info']),
     selectedActivities(){ // returns array with the selected activities for this prescription. including the activities that were writen by the clinician on the selectActivities page 
      if(Object.keys(this.activities).length>0){
        let preactivities = this.focusedPrescription.selectedActivities || {};
        let arrayofactivities=Object.keys(preactivities) || [];
        return arrayofactivities.map(activityid=>{
          if(!this.activities[activityid]){
            let activity={
              id:activityid,
              name:activityid
            }
            return activity
          }else{
         return this.activities[activityid]
           }
          });
         
      }
      else{
        return [];
      }
      
    },
  lerisk(){ // returns the risk object of the prescription
      return this.focusedPrescription.risk;
    },
    alldietRecomInact(){
      return this.focusedPrescription.dietQ1RecomInact&&this.focusedPrescription.dietQ2RecomInact&&
      this.focusedPrescription.dietQ3RecomInact&&this.focusedPrescription.dietQ4RecomInact&&
      this.focusedPrescription.dietQ5RecomInact?true:false
    }
  },
  methods:{
    async print(){
        try{
          const imgs = [...document.querySelectorAll('.previewSection')]
            let allCanvas=[]
      const doc = new this.$jsPDF('p','px')
      let x=50
      let y=10
      let remaningSpace=631.4175
    imgs.forEach(a => {
      if(!a)return;
      allCanvas.push(this.$html2canvas(a))
    })
    let resCanvas=await Promise.all(allCanvas)
          resCanvas.forEach((canvas,i)=>{
            const img =  canvas.toDataURL('image/png')
            let canvasHeight=canvas.height* 72 / 96
            if (remaningSpace<canvasHeight) {
              doc.addPage()
              y=10  
             remaningSpace=631.4175-canvasHeight  
             
                   }else{
                     y=631.4175-remaningSpace+10
                    remaningSpace=remaningSpace-canvasHeight  
            }
                doc.addImage(img,'PNG',x,y)
            
            })

            let pdf = doc.output('blob')
            let url = window.URL.createObjectURL(new Blob([pdf], {type: 'application/pdf'}));
              this.$store.commit('setWhatTo',{what:'focusedPDF',to:url})
              this.$f7.views.main.router.navigate('/pdf')
           // let today=this.$moment().format('YYYY-MM-DD')
            //    doc.save(`${this.actualPatient.name} ${this.actualPatient.lastName} ${today}`)



   //  var blob = doc.output();
    // window.open(URL.createObjectURL(blob));
     //window.open(doc.output("bloburl"), "_blank");

    // doc.save('filename.pdf');
     

            this.$f7.dialog.close()
      }catch(error){
        this.$f7.dialog.close()
        console.log(error)
        this.$f7.dialog.alert(error.message)
      }
    }
  }
  
}
</script>
     <style scoped>
    .sudobody, p, div {
      font-family: verdana,geneva,sans-serif;
      font-size: 16px;
    }
    .sudobody {
      padding-bottom: 100px;
      color: #516775;
    }
    .sudobody a {
      color:#0089ffcc !important;
      text-decoration: none;
    }
    p { margin: 0; padding: 0; }
    table.wrapper {
      width:100% !important;
      table-layout: fixed;
      -webkit-font-smoothing: antialiased;
      -webkit-text-size-adjust: 100%;
      -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }
    img.max-width {
      max-width: 100% !important;
    }
    .column.of-2 {
      width: 50%;
    }
    .column.of-3 {
      width: 33.333%;
    }
    .column.of-4 {
      width: 25%;
    }
    @media screen and (max-width:480px) {
      .preheader .rightColumnContent,
      .footer .rightColumnContent {
        text-align: left !important;
      }
      .preheader .rightColumnContent div,
      .preheader .rightColumnContent span,
      .footer .rightColumnContent div,
      .footer .rightColumnContent span {
        text-align: left !important;
      }
      .preheader .rightColumnContent,
      .preheader .leftColumnContent {
        font-size: 80% !important;
        padding: 5px 0;
      }
      table.wrapper-mobile {
        width: 100% !important;
        table-layout: fixed;
      }
      img.max-width {
        height: auto !important;
        max-width: 100% !important;
      }
      a.bulletproof-button {
        display: block !important;
        width: auto !important;
        font-size: 80%;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .columns {
        width: 100% !important;
      }
      .column {
        display: block !important;
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      .social-icon-column {
        display: inline-block !important;
      }
    }
.previewSection{
  margin: 0 !important;
  padding: 0 !important;
}
  </style>