<template>
<f7-page bg-color="white"  @page:beforein="loadData()" >
<f7-navbar>
  <f7-nav-left>
    <f7-link panel-open="left" icon-material="menu"></f7-link>
  </f7-nav-left>
    <f7-nav-title
    title="Global Privileges"
    subtitle="On Prevent tool users"
    ></f7-nav-title>
    <f7-nav-right>
      <f7-link @click="loadData(true)" icon-material="refresh"></f7-link>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchAllUsers"
          icon-material="search"
        ></f7-link>
      </f7-nav-right>
        <f7-searchbar
        class="searchAllUsers"
        expandable
        search-container=".search-list"
        search-in=".item-title, item-header"
        :disable-button="!$theme.aurora"
      ></f7-searchbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found"></f7-list-item>
    </f7-list>

<f7-list   class="search-list searchbar-found no-margin-top fixiconresorlist">
    <f7-list-item 
    v-for="user in sortedAllUsers" :key="user.id"
    v-show="!user.god"
    link="#"
    @click="userGlobalPrivx(user.id)"
         :title="user.email"
    :header="globalPrivxArray(user.id)"
        >
        <img class="userPhoto " slot="media" :src="user.photoURL ||'../static/icons/person.png'"/>
        <f7-icon slot="after" material="edit" ></f7-icon>
    </f7-list-item>
</f7-list>

</f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
    data(){
        return{
        }
    },
  
    computed:{
      ...mapState(['allUsers']),
      sortedAllUsers(){
          return Object.values(this.allUsers).sort((a,b)=>{
            var x = a.email;
            var y = b.email;
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
        
      }
    },
    methods:{
        globalPrivxArray(id){
         let globalPrivx= this.allUsers[id].globalPrivx
         if(!globalPrivx) return 'No global privileges'
        let array= Object.keys(globalPrivx).filter(priv=>{return globalPrivx[priv]})
        return array.join(', ')
     },
        async loadData(force=false){ // gets the users of the app
        if(Object.values(this.allUsers).length>0 && !force)return ;
        this.$f7.dialog.preloader('Loading users...')
        try{
        const call= this.$firebase.functions().httpsCallable('getAllUsers') 
        const res=await call()
        if(res.data.error) throw new Error(res.data.error)
        
        this.$store.commit('setObjectTo',{what:'allUsers',to:res.data.allUsers})
          this.$f7.dialog.close()
          }catch(error){
              this.$f7.dialog.close()
              console.log(error)
              this.$f7.dialog.alert(error.message)

          }
        },
        userGlobalPrivx(uid){
            this.$f7.views.main.router.navigate(`/editGlobalPrivx/${uid}`)
        }
    }
}
</script>
<style>
.fixiconresorlist .item-media .icon{
  margin: 0 auto;
}
.inactivated{
  color: #b1b1b1;
}
.userPhoto{
    height: 60px;
    width: 60px;
    border-radius: 50%;
}
</style>