<template>
<f7-page bg-color="white"  @page:beforein="loadData()" >
<f7-navbar>
  <f7-nav-left>
    <f7-link panel-open="left" icon-material="menu"></f7-link>
  </f7-nav-left>
    <f7-nav-title
    title="Activities"
    subtitle="Are avialable on every clinics"
    ></f7-nav-title>
    <f7-nav-right>
      <f7-link @click="loadData(true)" icon-material="refresh"></f7-link>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchActivities"
          icon-material="search"
        ></f7-link>
      </f7-nav-right>
        <f7-searchbar
        class="searchActivities"
        expandable
        search-container=".liconboabajo"
        search-in=".item-title, .item-header, .item-after"
        :disable-button="!$theme.aurora"
      ></f7-searchbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found"></f7-list-item>
    </f7-list>

<f7-list  media-list class="search-list searchbar-found liconboabajo no-margin-top">
  <f7-list-item v-if="sortedActivities.length==0" title="There are no activities created"></f7-list-item>
    <f7-list-item 
    v-for="activity in sortedActivities" :key="activity.id"
    v-show="!activity.inactive"
       :title="activity.name"
       link="#"
       @click="editActivity(activity)"
        >
        <span  slot="header"
        v-for="(level, i) in Object.keys(activity.actLevels)" :key="i"
        v-show="activity.actLevels[level]"
        >{{Object.keys(activity.actLevels).length-1==i?level:level+', ' }} </span>
        <span  slot="after"
        v-for="(group, i) in activity.groups" :key="i"
        v-show="group"
        :class="'seudobadge clp_'+i"
        >{{i}} </span>
    </f7-list-item>
</f7-list>
<f7-fab @click="createActivity()" position="right-bottom" slot="fixed" color="light-blue">
    <f7-icon material="add"></f7-icon>

  </f7-fab>

</f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
    data(){
        return{
        }
    },
    computed:{
      ...mapState(['activities','activeTab','focusedAccount']),
      ...mapGetters(['sortedActivities','onClinic'])
    },
    mounted(){

    },
    methods:{
        createActivity(){ // sets to null the focusedActivity on the state, and navigates to createActivity
           this.$store.commit('setObjectTo',{what:'focusedActivity',to:null})
            this.$f7.views.main.router.navigate("/createActivity/");
        },
        editActivity(activity){ // sets the focusedActivity on the state and navigates to createActivity
          let clonada = JSON.parse(JSON.stringify(activity));
          this.$store.commit('setObjectTo',{what:'focusedActivity',to:clonada})
           this.$f7.views.main.router.navigate("/createActivity/");
        },
        loadData(force=false){ // gets from database all the activities
         if(Object.keys(this.activities).length==0 || force){
          this.$f7.dialog.preloader('Loading activities...');
       let call= this.$firebase.functions().httpsCallable('getActivities')
       call({clinicid:this.onClinic,accountid:this.focusedAccount.id}).then(ledata=>{
         if(ledata.data.error){
           throw new Error(ledata.data.error)
         }
          this.$store.commit('setObjectTo',{what:'activities',to:ledata.data.activities});
           this.$f7.dialog.close();
        }).catch(error=>{
           this.$f7.dialog.close();
           console.log(error)
         this.$f7.dialog.alert((error.message,error.code)||error);
          
        });
        }
        }
    },
}
</script>

<style>
.seudobadge{
  padding: 3px 7px;
  font-weight: bold;
  margin-right: 3px;
  border-radius: 15px;
}
.seudobadge.clp_green{
  color: green;
  background: #dfffe8;
}
.seudobadge.clp_yellow{
  color: #736600;
  background: #fff8be;
}
.seudobadge.clp_red{
  color: #ff0a0a;
  background: #ffdfdf;
}
.liconboabajo .item-content{
  border-bottom: 1px solid #dedede;
}
</style>