<template>
  <f7-page @page:beforein="loadGlobalPrivx" class="bggris"  >
    <f7-navbar title="Editing Global privileges" :subtitle="allUsers[uid].email"  back-link="Back">

    </f7-navbar>
<f7-list class="margin-bottom no-margin-top"  no-hairlines-md>
  <f7-list-item divider title="Select this user privileges"></f7-list-item>
  <f7-list-item 
    title="Create activities"
    >
    <f7-toggle
     :checked="globalPrivx.createActivities"
    @toggle:change="privileges('createActivities',$event)"
    ></f7-toggle>
    </f7-list-item>
  
    <f7-list-item
    title="Create Digital Resources"
    >
    <f7-toggle
     :checked="globalPrivx.createDigitalResources"
    @toggle:change="privileges('createDigitalResources',$event)"
    ></f7-toggle>
    </f7-list-item>
    
    <f7-list-item 
    title="Create Clinics"
    >
    <f7-toggle
     :checked="globalPrivx.createClinics"
    @toggle:change="privileges('createClinics',$event)"
    ></f7-toggle>
    </f7-list-item>
</f7-list>
<f7-block>
  <f7-row>
    <f7-col><f7-button @click="$f7.views.main.router.back()" outline color="red"><f7-icon material="chevron_left"></f7-icon> Cancel</f7-button></f7-col>
    <f7-col><f7-button @click="savePrivx()" fill raised color="green"><f7-icon material="save"></f7-icon> Save</f7-button></f7-col>
  </f7-row>
</f7-block>
  </f7-page>
</template>

<script>
import {mapState} from 'vuex';
export default {
    props:{uid:String},
  data(){
    return{
        globalPrivx:{
        createActivities:null,
        createDigitalResources:null,
        createClinics:null
        }
    }
  },
  computed:{
   ...mapState(['allUsers','AYAVersion']),

  },
  methods:{
      loadGlobalPrivx(){
          if(this.allUsers[this.uid].globalPrivx)
          this.globalPrivx=Object.assign({},this.allUsers[this.uid].globalPrivx)
      },
      privileges(priv,checked){ // adds global privileges to the user
                                // payload:priv: the privilege to edit(string) , checked:boolean
          if(checked){
            this.globalPrivx[priv]=true;
          }else{
             this.globalPrivx[priv]=null;
          }
      },
      async savePrivx(){
        let call=  this.$firebase.functions().httpsCallable('saveGlobalPrivx')
        try{
            this.$f7.dialog.preloader('Saving global privileges...')
        let res= await call({userUid:this.uid,globalPrivx:this.globalPrivx})
        if(res.data.error) throw new Error (res.data.error)
        this.allUsers[this.uid].globalPrivx=Object.assign({},this.globalPrivx)

        this.$f7.dialog.close()
        this.$f7.views.main.router.back()

        }catch(error){
            this.$f7.dialog.close()
            console.log(error)
            this.$f7.dialog.alert(error.message)

        }
      }
     
  }
}
</script>