<template>
  <f7-page id="pagina" name="home"   :page-content="false" @page:beforeremove="updatePrescriontios()"  >
    <f7-navbar  :title="actualPatient.name +' '+actualPatient.lastName" :subtitle="actualPatient.email || actualPatient.caregiverEmail">
      
       <f7-nav-right>
 <f7-block>{{actualPatient.gender||actualPatient.sex}}, <span class="anobig">{{ageInYears}}</span> <span class="yearsmall">years</span></f7-block> 
        <f7-link @click="$f7.views.main.router.back()"><f7-icon  size="30" material="cancel"></f7-icon></f7-link>
       </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar labels bottom>
      <f7-link 
        tab-link="#tabeventos" 
        tab-link-active  
        text="Risk" 
        icon-material="slow_motion_video">
      </f7-link>
      <f7-link 
        tab-link="#tabcalendario" 
        text="Prescription" 
        icon-material="format_list_bulleted"
      ></f7-link>
      <f7-link 
        tab-link="#tabmenu" 
        text="Preview" 
        icon-material="mail_outline"
      ></f7-link>
      <f7-link 
        tab-link="#tabprogress" 
        text="Progress" 
        icon-material="playlist_add_check"
      ></f7-link>
    </f7-toolbar>

    <f7-tabs class="sofgratsx" >
      <f7-tab id="tabeventos" class="page-content" tab-active>
        <risk :ageInMonth="ageInMonth" :patrepdata="patrepdata"></risk>
      </f7-tab>

      <f7-tab id="tabcalendario" class="page-content">
        <rx :patrepdata="patrepdata"></rx>
      </f7-tab>
      <f7-tab id="tabmenu" class="page-content" @tab:show="fabvisible=true" @tab:hide="fabvisible=false" >
        <template v-if="focusedPrescription.sent"> 
        <f7-block v-for="one in focusedPrescription.sent" :key="one.when"
         ><f7-icon material="done"></f7-icon> 
        Prescription sent on {{$moment(one.when).format('LLL')}}</f7-block>
     </template>
     <f7-block class="containerCreatedon">
  <span class="createdOn" >Created on {{$moment(focusedPrescription.createdAt).format('LLL')}}</span>
  </f7-block>

    <preview v-if="AYAVersion" ref="preview" @setHeartHealth="setHeartHealth" ></preview>
    <send v-else ref="preview" ></send> 

      </f7-tab>
      <f7-tab id="tabprogress" class="page-content">
        <progresstab ></progresstab>
      </f7-tab>
    </f7-tabs>
    <f7-fab v-if=" showPrint && fabvisible && this.focusedAccount.privx.clinician && this.focusedPrescription.clinicid==this.focusedAccount.clinicid" class="fabbuttonx"  @click="print()" position="left-bottom" slot="fixed" text="Print">
        <f7-icon  material="print"></f7-icon>
      </f7-fab>
   
<f7-fab v-if="fabvisible && this.focusedAccount.privx.clinician && this.focusedPrescription.clinicid==this.focusedAccount.clinicid" class="fabbuttonx" @click="signasandsend()"  position="center-bottom" slot="fixed" :text="focusedPrescription.sent?'Send Again':'Sign and send'" color="orange">
        <f7-icon  material="send"></f7-icon>
      </f7-fab>
  </f7-page>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import risk from '../components/risk.vue';
import rx from '../components/rx.vue';
import send from '../components/send.vue';
import preview from '../components/preview.vue';
import progresstab from '../components/progresstab.vue';

export default {
  components:{
    risk,
    rx,
    send,
    preview,
    progresstab
  },
  data(){
    return {
      fabvisible: false,
      obserablePatRep: null,
      prescriptionObservable:null,
      patrepdata:null,
      heartHealth:{
          bigRiskItems:{
                overall:{
                    title:'Overall Heart Helth',
                    value:null,
                    symbol:'%',
                    improved:null,
                    color:null,
                    order:0
                },
                physical:{
                    title:`Physical Activity (${this.ageAtCollectionDate(this.focusedPrescription?.risk.bmi.weightDate )<240?'min/day':'min/week'})`,
                    value:null,
                    improved:null,
                    color:null,
                    order:1
                },
                diet:{
                    title:'Number of food intake behaviors met',
                    value:null,
                    improved:null,
                    color:null,
                    order:2
                },
                bmi:{
                    title:`Body Mass Index ${this.ageAtCollectionDate(this.focusedPrescription?.risk.bmi.weightDate )<240?'(percentile)':''}`,
                    value:null,
                    improved:null,
                    color:null,
                    order:3
                }
            },
        smallRiskItems:{
                fbg:{
                    title:'Fating Blood Glucose',
                    value:null,
                    improved:null,
                    color:null,
                    order:0
                },
                cholesterol:{
                    title:'Total Cholesterol',
                    value:null,
                    improved:null,
                    color:null,
                    order:1
                },
                bloodPressure:{
                    title:'Blood Pressure',
                    value:null,
                    improved:null,
                    color:null,
                    order:2
                },
                smoking:{
                    title:'Smoking Status',
                    value:null,
                    improved:null,
                    color:null,
                    order:3
                }
        },
      },
    }
  },
   computed:{
    ...mapState(['focusedPrescription','user','actualPatient','typeOfResources','resources','activities','focusedAccount','AYAVersion','preResourcesFilter']),
    ...mapGetters(['onClinic','ageInMonth']),
    ageInYears(){ // returns the patient age in years
      return this.$moment().diff(this.$moment(this.actualPatient?.birthday, "MM-DD-YYYY"),'years');
    },
      modifiable(){ // returns true if the the first prescription has not been sent yet and if the user is the clinician that created the prescription. used to enable the sliders and other parameters in the prescription
      return !this.focusedPrescription.sent && this.focusedAccount.privx.clinician && this.focusedPrescription.clinicid==this.focusedAccount.clinicid;
    },
    showPrint(){
      return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)           
    }
  },
  mounted(){
   if(this.preResourcesFilter.length) this.$store.commit('setWhatTo',{what:'preResourcesFilter',to:[]})
    if(this.modifiable){
        this.loadObserversPatientData();  
    }
  },
  methods:{ 
    loadObserversPatientData(){ // sets an observable to the answers of the health survey (meant to be answered before the visit). if it changes it gets the new answers
      this.obserablePatRep = this.$firebase.database().ref('requests_by_clinic/'+this.onClinic+'/'+this.actualPatient.id).orderByChild('answer/wh').limitToLast(1);
      this.obserablePatRep.on('child_added', (data)=> {
        this.processAvailable(data.val());
      });
      this.obserablePatRep.on('child_changed', (data)=> {
         this.processAvailable(data.val());
      });
      this.prescriptionObservable=this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id)
     this.prescriptionObservable.on('value',snap=>{
       let focusedGuy=Object.assign({},this.actualPatient);
          focusedGuy.prescriptions[this.focusedPrescription.id]=Object.assign({},snap.val() || {});
          this.$store.commit('setObjectTo',{what:'actualPatient',to:focusedGuy});
          this.$store.commit('updateObjectPropValue',{what:'patients',prop: this.actualPatient.id,neovalue:focusedGuy});
       this.$store.commit('setObjectTo',{what:'focusedPrescription',to:snap.val()})

         //   return this.visteme(snap.val() || {},'fromObs')
      });
    },
    processAvailable(requestobj){ //  sets 'patrepdata' to the new answers obtained by the observable 
      this.patrepdata=requestobj;
    },
    updatePrescriontios(){ // turns off the observable when this component is removed
      if(this.obserablePatRep){
        this.obserablePatRep.off();
        this.prescriptionObservable.off();
      }
    },
    async signasandsend(){ // calls a backend functions to send the first prescription to the patient. by email and sms
      this.$f7.dialog.preloader('Signing and sending...');
        try{
         if(this.AYAVersion &&!this.focusedPrescription.heartHealth) {
           await this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/heartHealth').set(this.heartHealth)
          this.$store.commit('setChildOnFocusedPrescription',{wich:'heartHealth',neovalue:this.heartHealth})
         }
        let signandsend = this.$firebase.functions().httpsCallable('signandsend');
      let ladata =await signandsend({
          prescriptionid:this.focusedPrescription.id,
          patientid: this.focusedPrescription.patientid,
          accountid : this.focusedAccount.id,
          clinicid : this.onClinic,
          AYAVersion:this.AYAVersion
          })
          this.$f7.dialog.close();
          if(ladata.data.fail){
            throw new Error(ladata.data.datum);
          }
          let res=ladata.data.datum
          this.$store.commit('setSent',res.saveOjet);
          this.$store.commit('setChildOnFocusedPrescription',
          {wich:'clinician',neovalue:this.user.id})
          this.$store.commit('setChildOnFocusedPrescription',
          {wich:'clinicianName',neovalue:this.focusedAccount.name})
          this.$store.commit('setChildOnFocusedPrescription',
          {wich:'clinicianemail',neovalue:this.user.email})

            this.$f7.toast.show({
            text: 'Prescription sent succesfully',
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
        }catch(error){
          this.$f7.dialog.close();
         this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
        }
      },
      setHeartHealth(e){
        if(this.heartHealth.bigRiskItems[e.item]){
        this.heartHealth.bigRiskItems[e.item][e.param]=e.value
        }else{
          this.heartHealth.smallRiskItems[e.item][e.param]=e.value
        }
      },
      print(){
        this.$f7.dialog.preloader('Loading...')
        setTimeout(()=>{
          this.$refs.preview.print()
        },100)
         
      },
      ageAtCollectionDate(collectionDate){ 
            if(!collectionDate) this.ageInMonth
        return this.$moment(collectionDate,"MM/DD/YYYY").diff(this.$moment(this.actualPatient?.birthday, "MM-DD-YYYY"),'months');
      }
  },
  watch:{
    onClinic(){ // obsers if the getter onClinic changes. if its true navigates back. used to get out of the edit pages if the user selects another clinic on the left pannel
        if(this.$f7.views.main.router.url=='/patient'){
            if(!this.focusedAccount.privx.clinician){
           this.$f7.views.main.router.navigate('/settings')
         }else{
         this.$f7.views.main.router.navigate('/patients')
       }
        } 
    }
  },
}
</script>
<style>
.yearsmall{
  font-size:13px;
}
.anobig{
  font-size:30px;
}

.titulobold.title{
font-weight: bold;
}
.sofgratsx{
      background: #efefef;
}

.fabbuttonx{
  bottom: 79px!important;
}
.createdOn{
  background-color: whitesmoke;
  border-radius: 10%;
  position: sticky;
    bottom: 580px;
    margin: 20px;
    z-index: 999999;
}
</style>