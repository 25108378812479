  <template>
    <f7-page @page:beforeout="sheetOpened = false" >
  <f7-navbar title="Resources" :subtitle="Object.keys(selectedResources).length +' resource'+(Object.keys(selectedResources).length==1?'':'s')+' selected'"  back-link="Back">
      <f7-nav-right v-if="!callingYelp"  >
        <f7-link @click="setResoucesTypesToFilter()" icon-material="refresh"></f7-link>
          <f7-link @click="backHome()" icon-material="home"></f7-link>
         <f7-link class="searchbar-enable" data-searchbar=".searchbar-resources" icon-material="search"></f7-link>
         <f7-block>
      <f7-button raised popover-open=".popover-filterx" >Filter
          <f7-badge class="badgeTop" :color="activeFilters.length>0?'red':''">{{activeFilters.length}}</f7-badge>
      </f7-button>
</f7-block>


    </f7-nav-right>
      <f7-searchbar
      @searchbar:enable="searchbarOpened=true"
      @searchbar:disable="cancelAddResource(true)"
      class="searchbar-resources"
      expandable
      search-container=".resourcesList"
      search-in=".item-title,.item-subtitle"
      :disable-button="true"
      ref="searchForResource"
    ></f7-searchbar>
    </f7-navbar>
<div class="comboholder">
 
<f7-block class="maperholder">
   <div class="mapresourcedos"></div>
   
</f7-block>
<f7-block class="searchbar-not-found">
  <div v-show="!addResourcePopupOpened">
<f7-list >
    <f7-list-item title="Nothing found"></f7-list-item>
  </f7-list>
  <f7-button @click="focusResourceNotFound" raised fill >Add this Resource</f7-button>
  </div>
<div   v-show="addResourcePopupOpened">
  <f7-block-title>Adding new resource...</f7-block-title>
    <f7-list class="no-margin" no-hairlines-md>
<f7-list-item v-if="Object.keys(typeOfResources).length==1"
    :title="typeOfResources[resourceToAdd.type].name"
    label="Type of resource"
  >
  </f7-list-item>
   <f7-list-input v-else
    @input  ="resourceToAddType($event.target.value)"
    :value="resourceToAdd.type"
    label="Type of resource"
    type="select"
    placeholder="Please choose..."
  >
   <option :value="null">Select Type of Resource</option>
    <option
    v-for="type in typeOfResources" :key="type.id"
     :value="type.id"
     >{{type.name}}</option>
  </f7-list-input>
  <f7-list-input :disabled="!resourceToAdd.type"
  id="addResourceInput"
    label="Address/Place"
    type="text"
    input-id="autocomplete_addResource"
    placeholder="Search address or place name" 
    :value="resourceToAdd.ginfo.formatted_address?resourceToAdd.ginfo.formatted_address:null"
    clear-button
  >
  </f7-list-input>
  <f7-list-input :disabled="!resourceToAdd.ginfo.name"
    label="Name"
    type="text"
    :value="resourceToAdd.ginfo.name"
    @input  ="resourceToAdd.ginfo.name=$event.target.value"
  >
  </f7-list-input>
</f7-list>
<f7-row >
  <f7-col><f7-button @click="cancelAddResource" outline color="red">Cancel</f7-button></f7-col>
  <f7-col><f7-button  @click="addResourceNotFound" :disabled="!resourceToAdd.ginfo.name" fill>Save and Select</f7-button></f7-col>
</f7-row>
</div>
  </f7-block>

    <!--virtual list-->
  <f7-list
      ref="myreslist"
      class="resourcesList searchbar-found" 
      media-list
      virtual-list
      :virtual-list-params="{ items, searchAll, renderExternal, height:100}"
    >
      <ul>
        <f7-list-item
          v-for="resource in vlData.items"
          :key="resource.placeId"
          name="resourcex-checkbox"
        :subtitle="typeOfResources[resource.type].name"
        :title="resource.ginfo.name"
        :text="resource.ginfo.formatted_address"
          :style="`top: ${vlData.topPosition}px`"
        >
         <div class="iconBackground" :style="{backgroundColor:typeOfResources[resource.type].color}" slot="before-title">
  <f7-icon class="iconRx" >{{typeOfResources[resource.type].ico.uni}}</f7-icon>
    </div>
    <label slot="media" class="checkbox">
  <input @change="checkResource(resource,$event)" :checked="!!selectedResources[resource.id]" type="checkbox">
  <i class="icon-checkbox"></i>
  
</label>
  <f7-button @click="focusResource(resource)"  raised slot="after" >
    Details
  </f7-button>
  </f7-list-item>
      </ul>
    </f7-list>
</div>
<f7-popover  class="popover-filterx inerliimtheight">
    <f7-list >
      <f7-list-item
      
       v-for="type in sortedResourcesTypes" 
      :key="type.id" 
      popover-close 
      :title="type.name"
      checkbox 
      :value="type.id"
      :checked="activeFilters.includes(type.id)"
      @change="checkActiveFilters({value:$event.target.value,checked:$event.target.checked})"
      >
      <f7-icon
      :style="{backgroundColor:type.color,borderRadius:'20%',padding:'5px'}"
       :icon="'fa fa-lg fa-'+type.ico.ico" slot="after"></f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-popover>



 <f7-sheet class="resourceInfo-sheet" :closeByBackdropClick="false" :backdrop="false" :opened="sheetOpened" @sheet:close="resetSheetmodalContent()" @sheet:closed="sheetOpened = false">
      <f7-toolbar>
        <div class="left">
          <f7-block>
            <f7-button v-if="!selectedResources[detailedResource.id]" @click="addOrRemoveReousre(detailedResource.id,true,true)" fill raised >Add to selection</f7-button>
            <f7-button v-else fill color="red" raised @click="addOrRemoveReousre(detailedResource.id,false,true)" >Remove from selection</f7-button>
            </f7-block>
        </div>
        <div class="right">
          <f7-block><f7-button raised sheet-close>Close details</f7-button></f7-block>
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content rating -->
      <f7-page-content class="bggris">
       
<f7-card>
   <f7-card-header><b>Information</b></f7-card-header>
   <f7-card-content>
      <f7-list >
          <f7-list-item :header="typeOfResources[detailedResource.type]?typeOfResources[detailedResource.type].name:''" :title="detailedResource.ginfo.name" ></f7-list-item>
          <f7-list-item v-if="detailedResource.ginfo.formatted_address" header="Address" :title="detailedResource.ginfo.formatted_address"></f7-list-item>
          <f7-list-item v-if="detailedResource.ginfo.international_phone_number" title="Phone" :after="detailedResource.ginfo.international_phone_number"></f7-list-item>
          <f7-list-item v-if="detailedResource.ginfo.rating" title="Rating" :after="detailedResource.ginfo.rating"></f7-list-item>
          <f7-list-item v-if="detailedResource.ginfo.website" header="Website" :title="detailedResource.ginfo.website"></f7-list-item>
          <f7-list-item divider title="Opening hours"></f7-list-item>
          <f7-list-item v-for="(hourd,idx) in openingHoursReceived" :key="idx" :title="hourd"></f7-list-item>
          <f7-list-item v-if="openingHoursReceived.length==0" title="No information on opening hours"></f7-list-item>
        </f7-list>
   </f7-card-content>
</f7-card>

<f7-card>
  <f7-card-header><b>Directions</b><f7-button @click="requestDirections()" v-if="!directionsReceived" raised>Get directions</f7-button>
  <f7-segmented v-else strong tag="p">
    <f7-button icon-material="directions_walk" @click="requestDirections('WALKING')" :active="travelMode=='WALKING'"></f7-button>
    <f7-button icon-material="directions_transit" @click="requestDirections('TRANSIT')" :active="travelMode=='TRANSIT'"></f7-button>
    <f7-button icon-material="directions_car" @click="requestDirections('DRIVING')" :active="travelMode=='DRIVING'"></f7-button>
  </f7-segmented>
  </f7-card-header>
  <f7-card-content v-show="directionsReceived || loadingDirections">
    <p v-if="loadingDirections"><f7-preloader color="multi"></f7-preloader>  Loading directions...</p>
    <f7-block v-show="directionsReceived" id='howtodirections'></f7-block>
  </f7-card-content>
</f7-card>

<f7-card>
  <f7-card-header><b>Photos</b><f7-button @click="requestPhotos()" v-if="!photosReceived" raised>Get photos</f7-button></f7-card-header>
  <f7-card-content v-if="photosReceived || loadingPhotos">
    <p v-if="loadingPhotos"><f7-preloader color="multi"></f7-preloader>  Loading photos...</p>
    <f7-row v-if="photosReceived">
       <f7-photo-browser
            :photos="photosReceived"
            theme="dark"
            ref="darkphotoviewer"
          ></f7-photo-browser>
      <f7-col v-for="(onephoto,idx) in photosReceived" :key="idx" width="25" @click="$refs.darkphotoviewer.open(idx)" class="fotosalto" 
      :style="'background: url('+onephoto+'); background-repeat:no-repeat; background-size:cover; background-position:center;'"></f7-col>
    </f7-row>
  </f7-card-content>
</f7-card>

      </f7-page-content>
    </f7-sheet>
    <f7-button popup-open=".newCenter-popup" style="display:none" id="hiddenButton" ></f7-button>
    <f7-popup  class="newCenter-popup">
      <f7-page>
         <f7-navbar title="Changing map center...">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list class="no-margin">
          <f7-list-input 
          id="newCenterInput"
          label="Address/Place"
          type="text"
          input-id="autocomplete_newCenter"
          placeholder="Search address or place name" 
          clear-button
  >
  </f7-list-input>
        </f7-list>
      </f7-page>
    </f7-popup>


    </f7-page>
</template>
    <script>
  import { mapState,mapGetters } from 'vuex';
  import loadGoogleMapsApi from 'load-google-maps-api';

export default {
   data () {
   
    return {
      items:[],
      vlData:{
        items:[]
      },
      localResources:{},
      lemapa:null,
      activeFilters:[],
      callingYelp:false,
      markers:{},
      mapHome:{},
      homeMarker:null,
      selectedMarkers:{},
      googleMaps:null,
      sheetOpened: false,
      detailedResourceID:null,
      loadingDirections:false,
      directionsRenderer:null,
      directionsReceived: false,
      mapLoading:false,
      loadingPhotos: false,
      oldCenter:null,
      photosReceived: null,
      openingHoursReceived:[],
      directionsHolder:{},
      enFocoVisible:[],
      travelMode:'DRIVING',
      searchbarOpened:false,
      resourceToAdd:{
        id:'',
      ginfo:{},
      type:'',
      },
      addResourcePopupOpened:false
    }
   },
   computed:{
...mapState(['actualPatient','typeOfResources','resources','focusedPrescription','focusedAccount','preResourcesFilter']),
 ...mapGetters(['sortedResources','sortedResourcesTypes','onClinic']),
    filteredResources(){ // returns array with resources that are not inactive and are inside the map screen
      if(this.searchbarOpened){
      return this.local_sortedResources
      }
      let filteredByFrame = this.local_sortedResources.filter(unrecurso=>{
        if(!unrecurso.inactive){
        return this.enFocoVisible.includes(unrecurso.id);
        }
      });
      if(this.activeFilters.length>0){
        
         return filteredByFrame.filter(unresroucer=>{
          return this.activeFilters.includes(unresroucer.type)
        });
      }
      else{
         return filteredByFrame;
      }
    },
    selectedResources(){ // returns the prescription selected resources
      return this.focusedPrescription.selectedResources || {};
    },
    detailedResource(){ // returns the resource selected to see its detailed info (object)
     return this.localResources[this.detailedResourceID] || {ginfo:{}};
    },
    local_sortedResources(){ // returns array of the resources ordered by name
        return Object.values(this.localResources).sort((a,b)=>{
            var x = a.ginfo.name.toLowerCase();
            var y = b.ginfo.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
   }
   },
   watch:{
     filteredResources(newvalue){ // obsers if the computed function filteredResources changes. if it is true it reload the markers that are visible and replace the items on the framework7 component 'virtual list'
       this.computeMarkersVisibility();
       this.$refs.myreslist.f7VirtualList.replaceAllItems(this.filteredResources);
      // this.vlData.items=this.filteredResources
     },
     onClinic(){ //  obsers if the getter onClinic changes. if its true and the user doesn't have the privilege 'clinician' navigates to setting. if the user does have the privilege 'clinician' navigates to patients. used to get out of the edit pages if the user selects another clinic on the left pannel
       if(this.$f7.views.main.router.url==='/map/'){
         if(!this.focusedAccount.privx.clinician){
           this.$f7.views.main.router.navigate('/settings')
         }else{
         this.$f7.views.main.router.navigate('/patients')
       }
       }
     }
   },
  mounted(){
    this.$f7.dialog.preloader('Loading...')
    
    this.oldCenter=this.mapHome=Object.assign({},{lat:this.actualPatient.geo.coord.lat,lng:this.actualPatient.geo.coord.lng})
    this.callYelp(this.oldCenter).then(()=>{
      this.loadMapWithPin()
      this.setResoucesTypesToFilter()
       this.$f7.dialog.close()
    }).catch(error=>{
      this.$f7.dialog.close()
        console.log(error)
        this.$f7.dialog.alert(error.message||error)
    })
    
    
  },
  methods:{
    addResourceNotFound(){
          if(this.resourceToAdd.ginfo.name=='')return this.$f7.dialog.alert('A name is required!');
           if(this.resourceToAdd.ginfo.formatted_address=='') return this.$f7.dialog.alert('An adress is required!');
           if ( this.resourceToAdd.type=='' )return this.$f7.dialog.alert('A type is required!');
            if(!this.mapLoading) this.$f7.preloader.showIn('.comboholder');
             this.mapLoading=true
             if(!this.resourceToAdd.id)this.resourceToAdd.id= this.$firebase.database().ref('resources_by_clinic/'+this.onClinic).push().key
            this.$firebase.database().ref('resources_by_clinic/'+this.onClinic+'/'+this.resourceToAdd.id).set(this.resourceToAdd).then(()=>{
             
             let local_resources=Object.assign({},this.resources);
              local_resources[this.resourceToAdd.id]=this.resourceToAdd;
              this.$store.commit('setObjectTo',{what:'resources',to:local_resources});
              this.localResources[this.resourceToAdd.id]=this.resourceToAdd
      this.addOrRemoveReousre(this.resourceToAdd.id,true)
            this.createOneMarker(this.localResources[this.resourceToAdd.id])
            this.$refs.searchForResource.f7Searchbar.disable()
            }).catch((error)=>{
              this.$f7.dialog.close();
              console.log(error)
              this.$f7.dialog.alert(error.message,error.code);
            })

    },
    focusResourceNotFound(){
     this.resourceToAdd.ginfo.formatted_address=this.$refs.searchForResource.f7Searchbar.value
     this.addResourcePopupOpened=true

    },
    cancelAddResource(){
     this.$refs.searchForResource.f7Searchbar.disable()
     if(this.resourceToAdd.id){
        this.markers[this.resourceToAdd.id].setMap(null)
     delete this.markers[this.resourceToAdd.id]
      this.detailedResourceID=null
      this.computeMarkersVisibility()
      }
      this.resourceToAdd=Object.assign({},{id:'',ginfo:{},type:''})
           this.addResourcePopupOpened=false
           this.searchbarOpened=false
    },
    resourceToAddType(value){
      this.resourceToAdd.type=value
      document.getElementById("autocomplete_addResource").focus()
    },
    searchAll(query, items) { // search engine for the framework7 component 'virtual list'. search by name and address
        let found = [];
        for (let i = 0; i < items.length; i += 1) {
          if (items[i].ginfo.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
          items[i].ginfo.formatted_address.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
           query.trim() === '') found.push(i)
            
           }
        return found; 
      },
      renderExternal(vl, vlData) { // render engine for the framework7 component 'virtual list'.
        this.vlData = vlData;
      },
    computeMarkersVisibility(){ //  turns on the visivility on the google map markers. depending if there is a resource to see its detail(it only show that resource), or if the resources are filtered by type of resources( just shows that ones), or if there are no filter (shows every one)
      let markersid=Object.keys(this.markers);
      markersid.forEach((unmarkerid,i) => {
        if(!this.detailedResourceID && ((this.activeFilters.length>=0 && this.activeFilters.includes(this.localResources[unmarkerid].type)) || (this.activeFilters.length==0) )){
          this.markers[unmarkerid].setVisible(true);
          if(this.selectedResources[unmarkerid]){
              this.enciendeOapagaCheckedResource(unmarkerid,true);
          }
        }
        else{
          this.markers[unmarkerid].setVisible(false);
           this.enciendeOapagaCheckedResource(unmarkerid,false);
        }
        
      });
      if(this.detailedResourceID){
        if(!this.markers[this.detailedResourceID]){
          this.createOneMarker(this.localResources[this.detailedResourceID])
            }
        this.markers[this.detailedResourceID].setVisible(true);
      }
    },
    enciendeOapagaCheckedResource(onemarekrid,enciende){ // if the resource is a selected resource for the prescription, it turns on the visibility the checked marker over the resource (✓ symbol)
      if(enciende){
        if(this.selectedMarkers[onemarekrid]){
            this.selectedMarkers[onemarekrid].setVisible(true);
          }
        else{
            let resource=this.localResources[onemarekrid];
            this.selectedMarkers[resource.id]=new this.googleMaps.Marker({
            position: {lat: resource.ginfo.lat, lng: resource.ginfo.lng},
              map: this.lemapa,
            icon: {
                url: '../static/selected.png'
              },
          });
          this.selectedMarkers[resource.id].addListener('click', ()=> {
                  this.focusResource(resource);
              });
        }
      }
      else{
        if(this.selectedMarkers[onemarekrid]){
            this.selectedMarkers[onemarekrid].setVisible(false);
          }
      }
    },
    backHome(){ // centers the map on the patient home again
      let homecenter = new this.googleMaps.LatLng(
        this.mapHome.lat,
        this.mapHome.lng
      );
      this.lemapa.panTo(homecenter);
    },
    checkResource(resource,event){ // calls the function to add or remove a selected resource from the prescription
                                    // payload: resource: the resource to add or remove (object), event: event, if checked means to add the resource
      this.addOrRemoveReousre(resource.id,event.target.checked);
    },
    addOrRemoveReousre(resourceid,add,closeSheet=false){ // adds or removes the resource fron the prescription
                                                          // payload: resourceid: the resource id, add: boolean if true it mean to add the resource, closeSheet: boolean if true its closes the detalied resource view  
      let resource=this.localResources[resourceid];
      let toStore={id: resource.id, where:'selectedResources'};
      let strng='addedd to';
      let tofirebase=resource;
      if(this.selectedMarkers[resource.id]){
          this.selectedMarkers[resource.id].setVisible(add);
          }
      if (!add){
        strng='removed from';
        toStore.value=null;
        tofirebase=null;
        if(this.selectedMarkers[resource.id]){
          this.selectedMarkers[resource.id].setMap(null);
          }
          this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/selectedResources/'+resource.id).set(tofirebase).then(()=>{
      this.$store.commit('setSelection',toStore);
      this.$f7.toast.show({
        text: resource.ginfo.name+' '+strng+' selection',
        position: 'center',
        closeTimeout: 2000,
        destroyOnClose: true
      });
          }).catch(error=>{
      this.$f7.dialog.alert(error.message,error.code)
      console.log(error)
    })
      }
      else{
        toStore.value=resource
          this.selectedMarkers[resource.id]=new this.googleMaps.Marker({
                position: {lat: resource.ginfo.lat, lng: resource.ginfo.lng},
                 map: this.lemapa,
                icon: {
                    url: '../static/selected.png'
                  },
              });
              this.selectedMarkers[resource.id].addListener("click", () => {
              this.focusResource(resource);
          });
      this.$store.commit('setSelection',toStore);
       this.$f7.toast.show({
        text: resource.ginfo.name+' '+strng+' selection',
        position: 'center',
        closeTimeout: 2000,
        destroyOnClose: true
      });
      if(closeSheet){
        this.sheetOpened=false;
      }
        if(!resource.ginfo.place_id){
             let geocoder=new this.googleMaps.Geocoder
                geocoder.geocode({
                 address:resource.ginfo.name+' '+resource.ginfo.formatted_address,
                 location: {lat: Number(resource.ginfo.lat), lng: Number(resource.ginfo.lng)}
              },response=>{
              resource.ginfo.place_id=response[0].place_id
              this.requestOpeningHours(resource,add);
              
          })
            }else{
            this.requestOpeningHours(resource,add);
              };
      }
  
      
    },
    checkActiveFilters({value,checked}){ // add or remove a type of resources from the filter
                                // payload: value: type of resource id
        if (checked) {
          this.activeFilters.push(value);
        } else {
          this.activeFilters.splice(this.activeFilters.indexOf(value), 1);
        }
    },
    async newFraming(refresh=false){ // its calls when there is a pinning on the map.  if the center on the new frame is further away than the maXdistance, then it calls the backend functions to get new yelp resources according to the new center of the map 
      if(!this.mapLoading){
        this.$f7.preloader.showIn('.comboholder');
        this.mapLoading=true
      }
      try{
        let yelpError=false
      let newCenter={lat:this.lemapa.getCenter().lat(),lng:this.lemapa.getCenter().lng()}
      if(!this.detailedResourceID&&(this.haversineFormula(newCenter,this.oldCenter)>=5000||refresh)){
        yelpError = await this.callYelp(newCenter)
      }
      if(yelpError){
       this.$f7.preloader.hideIn('.comboholder');
      this.mapLoading=false
       return 
      }
       this.enFocoVisible=[];
      this.local_sortedResources.forEach(unrecruso => {
         if( this.lemapa.getBounds().contains({lat: unrecruso.ginfo.lat, lng: unrecruso.ginfo.lng}) ) {
          this.enFocoVisible.push(unrecruso.id);
        }
      });
      this.createMarkers()
      this.paintExistingSelectedMarkers()
      this.callingYelp=false
      this.$f7.preloader.hideIn('.comboholder');
      this.mapLoading=false
      }catch(error){
        this.$f7.preloader.hideIn('.comboholder');
        this.mapLoading=false
        console.log(error)
        this.$f7.dialog.alert((error.message,error.code)||error)
      }
    },
    async callYelp(center){ // calls a backend functions that returns new resources from yelp near by the new center
      try{
        let yelpError=null
        this.callingYelp=true
      let call= this.$firebase.functions().httpsCallable('callYelp')
      let ladata=await call({
        center:center
      })
      if(ladata.data.error){
        yelpError=true
        let code=ladata.data.error.statusCode
        
        if(code==429 ||code==500 ||code==504 ){
          this.$f7.dialog.close()
          console.log('statusCode',ladata.data.error.statusCode)
          this.$f7.toast.show({
            text:'Please wait a few seconds more...',
           position: 'center',
            closeTimeout: 3000,
            destroyOnClose: true
          });
          
          setTimeout(()=>{
            if(!code==429 ){
            this.newFraming(true)
            }else{
              console.log('llamando en settieme out 429')
              this.oldCenter=center
            this.newFraming()
          }
          },4000)
          
        }else{
        throw new Error (ladata.data.error.message)
        }
      
      }
      if (yelpError) return yelpError;
      Object.keys(this.markers).forEach(markerid=>{
          this.markers[markerid].setMap(null)
      })
      Object.keys(this.selectedMarkers).forEach(markerid=>{
          this.selectedMarkers[markerid].setMap(null)
      })
      
      this.markers={}
      this.selectedMarkers={}
      this.oldCenter=center
      this.localResources=Object.assign({},this.resources,this.selectedResources)
      let yelpFiltered={}
        Object.values(ladata.data.resources).forEach(res=>{
         if(!Object.values(this.localResources).some(local=>res.ginfo.lat.toFixed(4)==local.ginfo.lat.toFixed(4)&&res.ginfo.lng.toFixed(4)==local.ginfo.lng.toFixed(4)&&local.ginfo.name==res.ginfo.name)){
           yelpFiltered[res.id]=res
         }
        })
        this.localResources=Object.assign(this.localResources,yelpFiltered)
      return false
      }catch(error){
        throw new Error (error)
       
      }
    },
    haversineFormula(p1,p2){ // returns the maximum distance between to point on the map
  let rad = function(x) {
  return x * Math.PI / 180;
};

let getDistance = function(p1, p2) {
  let R = 6378137; // Earth’s mean radius in meter
  let dLat = rad(p2.lat - p1.lat);
  let dLong = rad(p2.lng - p1.lng);
  let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d; // returns the distance in meter
};
let distance=getDistance(p1,p2)
return distance
    },
    loadMapWithPin(){ // it loads the google map on the screen and sets the patient home on the center 
      loadGoogleMapsApi({key:this.$store.state.gmapsAPIkey,libraries:['places'],language:'en'}).then((googleMaps)=> {
           this.googleMaps=googleMaps;
          this.lemapa= new this.googleMaps.Map(document.querySelector('.mapresourcedos'), {
             center:this.mapHome,
              zoom: 15,
              streetViewControl: false,
              mapTypeControl: false
            });
             this.lemapa.setOptions({minZoom:12});
             this.googleMaps.event.addListener(this.lemapa, 'idle', ()=>{this.newFraming();});// newFraming is triggered now
              this.createHomeMarker()
              this.directionsRenderer = new this.googleMaps.DirectionsRenderer({suppressMarkers: true});
              this.directionsRenderer.setPanel(document.getElementById('howtodirections'));
              this.autocompleteChangeCenter()
              this.autocompleteSearchResouce()
          }).catch((error)=> {
            this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
          })
     },
     autocompleteChangeCenter(){
      let autocomplete = new this.googleMaps.places.Autocomplete(
            /** @type {!HTMLInputElement} */ (
                document.getElementById('autocomplete_newCenter')), {
              componentRestrictions: {'country': 'us'},
            //  bounds: defaultBounds,
            });
            autocomplete.addListener('place_changed', ()=> {
              
            let place = autocomplete.getPlace();
            if (place.geometry) {

            let newCenter={lat:place.geometry.location.lat(),
            lng:place.geometry.location.lng()
            }
            this.mapHome=Object.assign({},newCenter)
            this.homeMarker.setMap(null)
            this.createHomeMarker()
            this.lemapa.panTo(this.mapHome)
            this.$f7.popup.close('.newCenter-popup')
            }
            
            })
    },
     autocompleteSearchResouce(){
       let autocomplete = new this.googleMaps.places.Autocomplete(
            /** @type {!HTMLInputElement} */ (
                document.getElementById('autocomplete_addResource')), {
              componentRestrictions: {'country': 'us'},
            //  bounds: defaultBounds,
            });
            autocomplete.addListener('place_changed', ()=> {
              
            let place = autocomplete.getPlace();
            if (place.geometry) {
              let resourcesKeys=Object.keys(this.resources)
         let alReadyExists= resourcesKeys.find(oneResource=>
           (this.resources[oneResource].ginfo.lat==place.geometry.location.lat() 
           &&this.resources[oneResource].ginfo.lng==place.geometry.location.lng())
           ||this.resources[oneResource].ginfo.formatted_address==place.formatted_address 
           ||this.resources[oneResource].ginfo.place_id?this.resources[oneResource].ginfo.place_id==place.place_id:false
           )
            if(alReadyExists){
              let exist=false
              if(this.resources[alReadyExists].inactive){
                exist=true
              return this.$f7.dialog.confirm('Already exists a resource created on this location but it is disactivated, do you want to activate it?','Activate activity',()=>{
                this.$firebase.database().ref('resources_by_clinic/'+this.onClinic+'/'+alReadyExists+'/inactive').set(null).then(()=>{
                
               this.resources[alReadyExists].inactive=null
              this.localResources[alReadyExists]=this.resources[alReadyExists]
                this.resourceToAdd=Object.assign({},this.resourceToAdd,this.resources[alReadyExists]);
                  this.paintOnMap(place)
              
              })
              })
             
              }else{
              let name=this.resources[alReadyExists].ginfo.name
                return this.$f7.dialog.alert('Already exists a resource created on this location called '+name)
              }
            }else{
              this.resourceToAdd.id= this.$firebase.database().ref('resources_by_clinic/'+this.onClinic).push().key
              this.paintOnMap(place)
              }
            } else {
              document.getElementById('autocomplete_addResource').placeholder = 'Enter a valid place';
            }
          });
     },
         createMarkers(){
        this.filteredResources.forEach(unrecruso => {
          if(!unrecruso.inactive&&!this.markers[unrecruso.id]&&this.lemapa.getBounds().contains({lat: unrecruso.ginfo.lat, lng: unrecruso.ginfo.lng})){
           this.createOneMarker(unrecruso) 
        }
        });
         
        },
        createOneMarker(unrecruso){
          let unicode=this.typeOfResources[unrecruso.type].ico.uni;
           let markerColor=this.typeOfResources[unrecruso.type].color.slice(1)
            let lobje={
              position: {lat: unrecruso.ginfo.lat, lng: unrecruso.ginfo.lng},
              map: this.lemapa,
              icon:{
                
                url:"https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|" + markerColor,
                
                labelOrigin:{
                  x:15,
                  y:12
                },
                scaledSize: new google.maps.Size(30, 40)
              },
              label: {
                fontFamily: 'Fontawesome',
                text: unicode,
                color: 'black',
                fontSize: '18px',
                
              },
              
            };

          this.markers[unrecruso.id] = new this.googleMaps.Marker(lobje);
            this.markers[unrecruso.id].addListener('click', ()=> {
                  this.focusResource(unrecruso);
              });
        },
          createHomeMarker(){
      this.homeMarker = new this.googleMaps.Marker({
            position: this.mapHome,
            map: this.lemapa,
            zIndex: 99,
            icon: {
              url: "https://maps.google.com/mapfiles/kml/paddle/red-blank.png",
              scaledSize: {
                height: 50,
                width: 50,
              },
              labelOrigin: {
                x: 26,
                y: 15,
              },
            },
            label: {
              fontFamily: "Fontawesome",
              text: "\uf015",
              color: "#FFF",
              fontSize: "18px",
            },
          });
           const contentString =`<a onclick="document.getElementById('hiddenButton').click();">Center map in other place</a>`
          const infowindow = new this.googleMaps.InfoWindow({
            content:contentString
          });
          this.homeMarker.addListener("click", () => {
            infowindow.open(this.lemapa, this.homeMarker);
            this.lemapa.panTo(this.mapHome);
          });
          infowindow.open(this.lemapa, this.homeMarker);
    },
        paintExistingSelectedMarkers(){ // it draws the selectedResources markers on the map
            let selectedMarketsIds=Object.keys(this.selectedResources) || [];
            selectedMarketsIds.forEach(onemarekrid => {
              this.enciendeOapagaCheckedResource(onemarekrid,true);
            });
        },
        paintOnMap(place){
              let tostore={
                formatted_address: place.formatted_address || '',
                international_phone_number: place.international_phone_number || '',
                icon: place.icon || '',
                lat: place.geometry.location.lat() || null,
                lng: place.geometry.location.lng() || null,
                name:place.name || '',
                place_id:place.place_id ,
                rating:place.rating || '',
                website:place.website || '',
                url:place.url || ''
              };
              this.resourceToAdd.ginfo=Object.assign({},this.resourceToAdd.ginfo,tostore);
              this.detailedResourceID=this.resourceToAdd.id;
              //crear marker
              let unicode=this.typeOfResources[this.resourceToAdd.type].ico.uni
                let markerColor=this.typeOfResources[this.resourceToAdd.type].color.slice(1)
               let lobje={
              position: {lat: this.resourceToAdd.ginfo.lat, lng: this.resourceToAdd.ginfo.lng},
              animation: this.googleMaps.Animation.DROP,
              map: this.lemapa,
              icon:{
                url:"https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|" + markerColor,
                
                labelOrigin:{
                  x:15,
                  y:12
                },
                scaledSize: new google.maps.Size(30, 40)
              }
              
              
            };
            
              this.markers[this.resourceToAdd.id] = new this.googleMaps.Marker(lobje);
              this.lemapa.panTo(place.geometry.location);
              this.lemapa.setZoom(16);
              setTimeout(()=>{
                this.markers[this.resourceToAdd.id].setLabel({
                fontFamily: 'Fontawesome',
                text: unicode,
                color: 'black',
                fontSize: '18px',
                
              })
              },1000) 
              
          
        },
        resetFilter(){ // makes the activeFilters (array of type of resources selected to filter the resources on the map) an empty array
          this.activeFilters=[];
        },
         focusResource(resource){ // is called when the user select a resource for details. set the  detailedResourceID to this resource id, sets this resource on the center of the map and ask google for its openning hours
         this.$refs.searchForResource.f7Searchbar.disable()
         this.detailedResourceID=resource.id;
          if(!this.directionsHolder[resource.id]){
            this.directionsHolder[resource.id]={};
          }
          this.computeMarkersVisibility();
           let resourceCenter = new this.googleMaps.LatLng(resource.ginfo.lat, resource.ginfo.lng);
            this.lemapa.panTo(resourceCenter);
            this.lemapa.setZoom(16);
            this.sheetOpened=true;
            if(!resource.ginfo.place_id){
             let geocoder=new this.googleMaps.Geocoder
                geocoder.geocode({
                 address:resource.ginfo.name+' '+resource.ginfo.formatted_address,
                 location: {lat: Number(resource.ginfo.lat), lng: Number(resource.ginfo.lng)}
              },response=>{
              resource.ginfo.place_id=response[0].place_id
              this.requestOpeningHours(resource);
          })
            }else{
            this.requestOpeningHours(resource);
              }
        },
        requestDirections(wich='DRIVING'){ // calls google maps api for direction from the patient home to the resource
                                          // payload: wich: the google maps travel mode (string). driving by default
          this.travelMode=wich;
          if(!this.directionsHolder[this.detailedResourceID][wich]){
              this.loadingDirections=true;
              let request ={
                origin: new this.googleMaps.LatLng(this.mapHome.lat,this.mapHome.lng),
                destination: this.detailedResource.ginfo.formatted_address,
                travelMode: wich
              }
              let directionsService = new this.googleMaps.DirectionsService();
              directionsService.route(request, (result, status)=> {
                this.loadingDirections=false;
                if(status!=='OK'){
                  this.loadingDirections=false;
                return this.$f7.dialog.alert(status);
                }
                else{
                  this.directionsHolder[this.detailedResourceID][wich]=result;
                  this.drawdirecitons(result)
                }
                this.loadingDirections=false;
              });
          }
          else{
            this.drawdirecitons(this.directionsHolder[this.detailedResourceID][wich]);
          }
        },
        drawdirecitons(result){ // draws the google maps directions on the map
           this.directionsReceived=true;
          this.directionsRenderer.setMap(this.lemapa);
          this.directionsRenderer.setDirections(result);
          
        },
        requestPhotos(){ // calls google maps api for the resources photos
           this.loadingPhotos=true;
          let request2 = {
            placeId: this.detailedResource.ginfo.place_id,
            fields: ['photo']
          };
          let service = new this.googleMaps.places.PlacesService(this.lemapa);
          service.getDetails(request2,(place, status)=> {
             this.loadingPhotos=false;
             if(status!=='OK'){
               return this.$f7.dialog.alert(status);
            }
            else{
               let locphots=place.photos || [];
               this.photosReceived= locphots.map(x => x.getUrl());

            }
          });
        },
         requestOpeningHours(resource,add=null){ // calls google maps api for the  resource openning hours, if 'add' is true it stores the resource on database as a selected resource for the prescription
                                                  // payloads: resource: the resource (object) : add: true or null
           this.openingHoursReceived=[];
           let resourceid=resource.id
          let request2 = {
            placeId: resource.ginfo.place_id,
            fields: ['opening_hours']
          };
          let service = new this.googleMaps.places.PlacesService(this.lemapa);
          service.getDetails(request2,(place, status)=> {
             if(status!=='OK'){
               console.error('error loading opening hours',place);
            }
            else{
              if(place.opening_hours){
             this.openingHoursReceived=(place.opening_hours && place.opening_hours.weekday_text)?place.opening_hours.weekday_text:[];
              this.localResources[resourceid].opening_hours=place.opening_hours.weekday_text
              }
              if(add){
              this.$firebase.database().ref('prescriptions_by_clinic/'+this.onClinic+'/'+this.focusedPrescription.patientid+'/'+this.focusedPrescription.id+'/selectedResources/'+resource.id).set(this.localResources[resourceid]).then(()=>{
              let toStore={id:resource.id,value: resource, where:'selectedResources'};
              this.$store.commit('setSelection',toStore);
              }).catch(error=>{
                console.log(error)
                this.$f7.dialog.alert(error.message,error.code)
              })
            }
            
            }
          });
        },

      resetSheetmodalContent(){ // sets to default the nodes related to the resouce. used when the user goes out from the detailed sheet
        this.detailedResourceID=null;
        this.directionsRenderer.setMap(null);
        document.getElementById("howtodirections").innerHTML = "";
        this.directionsReceived=false;
        this.photosReceived=null;
        this.computeMarkersVisibility();
      },
      setResoucesTypesToFilter(){
         this.activeFilters=[]
         if(this.preResourcesFilter.length>0)
        this.preResourcesFilter.forEach(res=>{
          this.checkActiveFilters({value:res.id,checked:true})
        })
        
      }

  },
}
</script>
<style >
.comboholder{
  height: 100%;
}
.maperholder{
  height: 50%;
   max-height:50%;
  margin-top:0px!important;
  margin-bottom:0px!important;
}
.resourcesList{
  height:50%;
  max-height:50%;
  overflow:auto;
  margin-top:0px!important;
  margin-bottom:0px!important;
}
.resourcesList .item-media label{
  margin: 0 auto;
  margin-top: 15px;
}

.resourcesList li{
      border-bottom: 1px solid #cecece;
}

.mapresourcedos{
  height:100%;
}
.iconBackground{
  position: static;
margin-right:10px;
border: black solid 1px;
border-radius:50%;
width:26px;
height:26px;
}
.inerliimtheight .popover-inner{
  max-height: 50vh;
  overflow-y: auto;
}
.inerliimtheight {
  width:400px
}

.resourceInfo-sheet.sheet-modal{
  border-top: 2px solid #559cfd;
  height: 58%;
}
.fotosalto{
  height: 100px;
  background-color: #cecece;
  /* -webkit-background-size:cover; -moz-background-size:cover; -o-background-size:cover; */
margin-bottom: 16px;
}

.popup.photo-browser-popup.photo-browser-standalone{
  z-index: 99999!important;
}
.badgeTop{
  top:-7px !important
}
.gm-style .gm-style-iw-c a span{
  display: none !important;
}
.newCenter-popup{
  height: 160px !important;
}
</style>