<template>
  <f7-page bg-color="white" @page:beforein="loadData()">
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left" icon-material="menu"></f7-link>
      </f7-nav-left>
      <f7-nav-title title="Digital Resources"
      ></f7-nav-title>
      <f7-nav-right>
        <f7-link @click="loadData(true)" icon-material="refresh"></f7-link>
        <f7-link class="searchbar-enable" data-searchbar=".searchDigitalResource" icon-material="search"></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchDigitalResource"
        expandable
        search-container=".search-list"
        search-in=".item-title, .item-subtitle, .item-text, .item-header"
        :disable-button="!$theme.aurora"
      ></f7-searchbar>
    </f7-navbar>
     <template v-if="Object.keys(pendingDigitalResources).length>0">
        <f7-block  class="recomInfo text-align-center" >
            <div>
               <f7-icon color="gray" material="info" class="margin-right" />
               There are digital resources created by patient pending to accept
            </div>
              <div>
                 <f7-link href="/pendingDigitalResources" >
                   <f7-icon class="margin-right" material="play_circle_outline"/>go to pending resources</f7-link>
              </div>
               </f7-block>
          </template>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found"></f7-list-item>
    </f7-list>

    <f7-list media-list
      class="search-list searchbar-found no-margin-top fixiconresorlist"
    >
    <f7-list-item
    v-if="Object.keys(digitalResources).length==0"
    >
    There are no digital resources created yet
    </f7-list-item>
      <f7-list-item
        v-for="resource in sortedDigitalResources"
        :key="resource.id"
        :header="'Types: '+Object.values(resource.types).join(', ')"
        :title="resource.name"
        :subtitle="'Platforms: '+Object.values(resource.platforms).join(', ')"
        :text="'Features: '+Object.values(resource.features).join(', ')"
         @click="createDigitalResource(resource.id)"
         link="#"
         >
        <img slot="media" class="userPhoto" :src="resource.logo||'../static/icons/no-pictures.png'" />
        <f7-link slot="after" ><f7-icon material="edit"/></f7-link>
      </f7-list-item>
    </f7-list>
    <f7-fab 
    @click="createDigitalResource()"
    position="right-bottom" slot="fixed" color="light-blue">
      <f7-icon material="add"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(['digitalResources','pendingDigitalResources']),
    sortedDigitalResources(){
      let array =Object.values(this.digitalResources).filter(res=>{
        return !res.inactive
      })
      return array.sort((a,b)=>{
        var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
    }
  },
  methods: {
    createDigitalResource(id) { 
    this.$f7.views.main.router.navigate(`/createDigitalResource/${id}`)
    },
     
        async loadData(force=false){
          if(Object.values(this.digitalResources).length==0 || force){
            try{
              this.$f7.dialog.preloader('Loading...')
           let preResources= await this.$firebase.database().ref('digitalResources').once('value')
            let localDigitalResources=preResources.val()||{}
            this.$store.commit('setObjectTo',{what:'digitalResources',to:localDigitalResources})
             this.$f7.dialog.close()
          }catch(error){
            this.$f7.dialog.close()
            console.log(error)
            this.$f7.dialog.alert(error.message)
            
          }
          }
         
        }
  }
};
</script>