<template>
  <f7-page>
  <f7-navbar  title="Clinics" subtitle="Select a clinic from the list" back-link="Back">
   <f7-subnavbar :inner="false">
      <f7-searchbar
        search-container=".alltheclinics"
        search-in=".item-title"
        :disable-button="true"
        :backdrop="false"
      ></f7-searchbar>
    </f7-subnavbar>
    </f7-navbar>

<f7-list class="searchbar-not-found">
    <f7-list-item title="Nothing found"></f7-list-item>
  </f7-list>
<f7-list class="alltheclinics search-list no-margin-top">
  <f7-list-item v-if="Object.keys(clinics).length==0">There are no clinics created</f7-list-item>
    <f7-list-item 
    v-for="clinic in sortedClinics" 
    :key="clinic.id"
    :title="clinic.name"
    radio
     :value="clinic.id"
    :checked="clinicToredeemCode==clinic.id"
      @change="selectedClinic(clinic.id)"
    ></f7-list-item>
</f7-list>

  </f7-page>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
export default {
  data(){
    
    return{
    }
  },
  computed:{
    ...mapState(['clinics','clinicToredeemCode']),
    ...mapGetters(['sortedClinics'])
  },
  methods:{
      selectedClinic(id){ // sets the state node clinicToredeemCode to the selected clinic id
          this.$store.commit('setWhatTo',{what:'clinicToredeemCode',to:id})
          this.$f7.views.main.router.back()


      }
  }
}
</script>
<style >

</style>