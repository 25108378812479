<template>
  <f7-page class="bggris"  >
    <f7-navbar :title="accountToEdit?accountToEdit.name:'Create New Account'" :subtitle="accountToEdit?accountToEdit.email:'On '+clinics[onClinic].name"  back-link="Back">
      <f7-nav-right
       v-if="accountToEdit">
                <f7-link v-if="accountToEdit.inactive && accountToEdit.user!=user.id" @click="activateAccount()"  text="Activate" style="color:grey" icon-material="power_settings_new"></f7-link>
               <f7-link  
               v-else-if="!accountToEdit.god && accountToEdit.user!=user.id"
                 @click="attemptInactivate()" icon-material="delete"></f7-link>
      </f7-nav-right>
    </f7-navbar>
<f7-list class="margin-bottom"  no-hairlines-md>
  <f7-list-item v-if="accountToEdit && account.code"
  header="Code"
  :title="account.code"
  >
    <div  slot="after" ><span>Copy</span>
        <f7-link @click="copyCode(account.code)" style="top:6px"  ><f7-icon material="system_update_alt"></f7-icon></f7-link>
        </div>
  </f7-list-item>
  <f7-list-item v-if="accountToEdit && !account.code"
  header="Name"
  :title="account.name"
  :footer="accountToEdit.user==user.id?'You can edit your name on Settings':''"
  >
  </f7-list-item>
    <f7-list-input v-else
    @input  ="account.name=$event.target.value"
    :value="account.name"
    label="Name"
    type="text"
    placeholder="Account`s name"
    clear-button
  >
  </f7-list-input>
  <f7-list-input v-if="!accountToEdit"
    @input  ="account.email=$event.target.value"
    @change="emailIsValid($event.target.value)"
    :value="account.email"
    label="Email"
    type="email"
    placeholder="Send code to email (optional)"
    clear-button
    validate
  >
  </f7-list-input>
  <f7-list-item v-else-if="account.email"
    @input  ="account.email=$event.target.value"
    :title="account.email"
    :header="account.code?'Sent to':'Redeemed by'"
    type="email"
    clear-button
    validate
  >
  </f7-list-item>
  <f7-list-item divider title="Select this account´s privileges"></f7-list-item>
  <f7-list-item 
    title="Is clinician"
    >
    <f7-toggle
     :checked="account.privx.clinician"
    @toggle:change="privileges('clinician',$event)"
    ></f7-toggle>
    </f7-list-item>
    <f7-list-item 
    title="Create Resources"
    >
    <f7-toggle
     :checked="account.privx.createResources"
    @toggle:change="privileges('createResources',$event)"
    ></f7-toggle>
    </f7-list-item>
    <f7-list-item 
      :class="accountToEdit?accountToEdit.god||accountToEdit.user==user.id?'disabled':'':''"
    title="Create Accounts"
    >
    <f7-toggle
     :checked="account.privx.createAccounts"
    @toggle:change="privileges('createAccounts',$event)"
    ></f7-toggle>
    </f7-list-item>
    <f7-list-item 
    title="Export Reports"
    >
    <f7-toggle
     :checked="account.privx.exportReports"
    @toggle:change="privileges('exportReports',$event)"
    ></f7-toggle>
    </f7-list-item>
</f7-list>
<f7-block>
  <f7-row>
    <f7-col><f7-button @click="$f7.views.main.router.back()" outline color="red"><f7-icon material="chevron_left"></f7-icon> Cancel</f7-button></f7-col>
    <f7-col><f7-button @click="saveAccount()" fill raised color="green"><f7-icon material="save"></f7-icon> Save</f7-button></f7-col>
  </f7-row>
</f7-block>
  </f7-page>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
export default {
  data(){
    return{
    account:{
       id:'',
       clinicid:'',
       name:'',
       email:'',
       code:'',
      privx:{
        clinician: null,
        createResources: null,
        createAccounts: null,
        exportReports:null
      },
      
    }
    }
  },
  created(){
    if(this.accountToEdit){
       this.account=JSON.parse(JSON.stringify(this.accountToEdit))
       if(!this.account.privx)this.account.privx={}
    }
  },
  watch:{
    onClinic(){ // obsers if the getter onClinic changes. if its true navigates back. used to get out of the edit pages if the user selects another clinic on the left pannel
        if(this.$f7.views.main.router.url=='/createAccount/'){
            this.$f7.views.main.router.back()
        } 
    }
  },
  computed:{
    ...mapState(['accountToEdit','accountsAll','clinics','user','focusedAccount']),
    ...mapGetters(['onClinic']),
  },
  methods:{
    activateAccount(){   // activates an account. sets the account node 'inactive' to null.
      this.$f7.dialog.preloader('Activating account...');
            this.$firebase.database().ref('accounts_by_clinic/'+this.onClinic+'/'+this.accountToEdit.id+'/inactive').set(null).then(()=>{
              let local_account=Object.assign(this.accountsAll[this.accountToEdit.id])
            delete local_account.inactive
            delete this.account.inactive
            this.$store.commit('updateObjectPropValue',{what:'accountsAll',prop: this.accountToEdit.id,neovalue:local_account});
            this.$store.commit('setObjectTo',{what:'accountToEdit',to:local_account});
            this.$f7.dialog.close();
            }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,error.code)
              console.log(error)
            })
            

    },
    attemptInactivate(){ // show a framewor7 prompt to be sure to inactive a account
      this.$f7.dialog.confirm('Please confirm','Are you sure you want to inactivate this account?',()=> {
        this.inactivateAccount();
      });
    },
    copyCode(code){ // copies the account code to clipboard
      let el = document.createElement('textarea');
  el.value = code;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  let toastCenter = this.$f7.toast.show({
          text:'Code copied!',
          position: 'center',
          closeTimeout: 2000,
          destroyOnClose: true
        });

    },
    async createCode(){ // calls a backend functions that creates a new code to redeem
      try {
     let call=  this.$firebase.functions().httpsCallable('createCode')
      let ledata= await call({clinic:this.onClinic})
      if(ledata.data.error){
        throw new Error(ledata.data.error)
       
      }
        this.account.code=ledata.data.result
     } catch(error){
       this.$f7.dialog.close()
       console.log(error)
        this.$f7.dialog.alert(error.message,error.code||error)
        
     }
      
    },
    emailIsValid (email) { // checks if the email has the correct format
        if(this.account.email.length>0){
      let valid= /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      if(!valid)  {
      this.$f7.dialog.alert('You must provide an email that looks like USER@DOMAIN.XYZ','email format invalid')
      return true
      }
        }
      },
    inactivateAccount(){ // inactivates an account on this clinic. in the account sets the node 'inactive' to true
         this.$f7.dialog.preloader('Inactivating account...'); 
            this.$firebase.database().ref('accounts_by_clinic/'+this.onClinic+'/'+this.accountToEdit.id+'/inactive').set(true).then(()=>{

            let local_account=Object.assign(this.accountsAll[this.accountToEdit.id])
            local_account.inactive=true
            this.$store.commit('updateObjectPropValue',{what:'accountsAll',prop: this.accountToEdit.id,neovalue:local_account});
            this.$f7.views.main.router.back();
            this.$f7.dialog.close();
            }).catch(error=>{
              this.$f7.dialog.close();
              console.log(error)
              this.$f7.dialog.alert(error.message,error.code)
            })
    },
      async saveAccount(){ // creates or edits a account for this clinic. if is on creation and there is an email on form the code will be sent to it
        try{
          if(this.account.name=='') return this.$f7.dialog.alert('A name is required!');
          let sent=false
            if(!this.accountToEdit){
              this.account.id= this.$firebase.database().ref('accounts_by_clinic/'+this.onClinic).push().key;
              this.$f7.dialog.preloader('Creating new account...');
                await this.createCode()
              if(this.account.email){
               if(this.emailIsValid(this.account.email)){
               this.$f7.dialog.close()
               return false 
               }
                let sendCode=this.$firebase.functions().httpsCallable('sendCode')
                let response= await sendCode({
                  email:this.account.email,
                  accountid:this.focusedAccount.id,
                  code:this.account.code,
                  clinicid:this.onClinic
                  })
                  if(response.data.error){
                    throw new Error(response.data.error)
                    }
                    sent=response.data.sent
              }

            }
            else{
              this.$f7.dialog.preloader('Updating account...');
            }
            this.account.clinicid=this.onClinic
            console.log('aca')
           let setAccount= await this.$firebase.database().ref('accounts_by_clinic/'+this.onClinic+'/'+this.account.id).set(this.account)
           if(this.accountToEdit && this.accountToEdit.user){
            let response= await this.$firebase.functions().httpsCallable('setLastUpdate')({
              accountid:this.focusedAccount.id,
              userid:this.accountToEdit.user,
              clinicid:this.onClinic
            })
            if(response.error){
              throw new Error(respose.error)
            }
            }
            let local_accounts=Object.assign({},this.accountsAll);
              local_accounts[this.account.id]=this.account;
              this.$store.commit('setObjectTo',{what:'accountsAll',to:local_accounts});
              this.$store.commit('setObjectTo',{what:'accountToEdit',to:this.account});
              if(this.accountToEdit.user==this.user.id){
              this.$store.commit('setObjectTo',{what:'focusedAccount',to:this.account});
              }
               this.$f7.toast.show({
                text:sent?'Code sent to'+this.account.email:'Account saved',
                position: 'center',
                closeTimeout: 2000,
                destroyOnClose: true
              });
              this.$f7.dialog.close();
              this.$f7.views.main.router.back();
            
            } catch(error){
              this.$f7.dialog.close();
              console.log(error)
             this.$f7.dialog.alert(error.message,error.code||error);
          
              }
      },
      privileges(priv,checked){ // adds the privileges to the account
                                // payload:priv: the privilege to edit(string) , checked:boolean
          if(checked){
            this.account.privx[priv]=true;
          }else{
             this.account.privx[priv]=null;
          }
      }
     
  }
}
</script>
<style >
.bggris{
  background:#e6e6e6;
}
.seudotitle .item-title{
  padding: 3px 7px;
  font-weight: bold;
  margin-right: 3px;
  border-radius: 15px;
}
.seudotitle.clp_green .item-title{
  color: green;
  background: #dfffe8;
}
.seudotitle.clp_yellow .item-title{
  color: #736600;
  background: #fff8be;
}
.seudotitle.clp_red .item-title{
  color: #ff0a0a;
  background: #ffdfdf;
}
</style> 

