<template>
<f7-page bg-color="white" >
  <f7-navbar>
  <f7-nav-left>
    <f7-link panel-open="left" icon-material="menu"></f7-link>
  </f7-nav-left>
    <f7-nav-title
    title="Settings"
    ></f7-nav-title>
  </f7-navbar>
  <f7-block class="text-align-center" v-if="Object.keys(myAccounts).length==0">Welcome to Prevent Tool, you don't have any active accounts yet</f7-block>
  <f7-list class="no-margin-top" >
              <f7-list-item divider title="Redeem your code">
              </f7-list-item>
  </f7-list>
  <f7-block-title v-if="Object.keys(clinics)==0">There are no clinics created to redeem a code</f7-block-title>
  <template v-else>
  <f7-block-title>Select a clinic to redeem your code</f7-block-title>
    <f7-card>
        <f7-card-header class="no-flex" >
            <f7-list >
                <f7-list-item
                :title="clinicToredeemCode?clinics[clinicToredeemCode].name:'Select a Clinic'"
                link="/selectClinic/"
                >
                </f7-list-item>
            </f7-list>
        </f7-card-header>
        <template  v-if="clinicToredeemCode">
        <f7-card-content>
            <f7-list>
                <f7-list-input
                label="Enter your code"
                :value="code"
                @change="code = $event.target.value"
                class="touppercase"
                outline
                autofocus
                autocomplete="off"
                >
                </f7-list-input>

            </f7-list>
        </f7-card-content>
        <f7-card-footer >
            <f7-button class="width-100" @click="redeem()" fill>Redeem</f7-button>
        </f7-card-footer>
        </template>
    </f7-card>
    </template>
    <f7-list>
      <f7-list-item divider>Your Profile</f7-list-item>
      <f7-list-item
       @click="editName=true"
      header="Your name"
      v-if="!editName"
      :title="name"
      link="#"
      >
      <f7-icon slot="after" material="edit"></f7-icon>
    </f7-list-item>
      <f7-list-input
      label="Enter your name"
      v-else
      :value="name"
      @input="name=$event.target.value"
      clear-button
      >
      </f7-list-input>
      <f7-list-item title="Edit avatar" @click="tomarFoto('#myimg-input')" link="#" >
        <img :src="user.photoURL ||'../static/icons/person.png'" class="myavatar" slot="media" />
        <f7-icon material="edit" slot="after"></f7-icon>
      </f7-list-item>
    </f7-list>
     <f7-block v-if="editName">
   <f7-row>
      <f7-col><f7-button  @click="cancel()"  popup-close outline color="red">Cancel</f7-button></f7-col>
     <f7-col><f7-button @click="save()" fill raised color="green">Save</f7-button></f7-col>
   </f7-row>
 </f7-block>
 <input type="file" id="myimg-input" @change="filesChange($event)" accept="image/*" />

</f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { photomixin } from '../mixins/photomixin'
export default {
  mixins: [photomixin],
    data(){
        return{
            code:'',
            editName:false,
            name:''
        }
    },
    mounted(){
      if(this.firstPreloader){
             this.$store.state.firstPreloader=false
             this.$f7.dialog.close()
           }
     this.name=this.user.displayName?this.user.displayName:this.focusedAccount.name
    },
    computed:{
      ...mapState(['clinics','clinicToredeemCode','user','myAccounts','focusedAccount','accountsAll','firstPreloader']),
      ...mapGetters(['myClinics','myClinicsProfiles'])
     
    },
    methods:{

      cancel(){ // cancels the changes made on the user name
        this.name=this.user.displayName?this.user.displayName:this.focusedAccount.name
        this.editName=false
      },
      save(){ // changes the name of the user on the firebase app and all his accounts
        if(!this.name) return this.$f7.dialog.alert('A name is required')
          this.$f7.dialog.preloader('Saving...')
          let call=this.$firebase.functions().httpsCallable('changeUserName')
          call({name:this.name,myClinicsProfiles:this.myClinicsProfiles}).then(ladata=>{
            if(ladata.data.error) throw new Error(ladata.data.error)
          this.$store.commit('updateObjectPropValue',{what:'user',prop:'displayName',neovalue:this.name})
          if(Object.keys(this.myAccounts).length>0){
          let local_myAccounts=Object.assign({},this.myAccounts)
          Object.keys(local_myAccounts).forEach(accountid => {
            local_myAccounts[accountid].name=this.name
          });
          this.$store.commit('setObjectTo',{what:'myAccounts',to:local_myAccounts})
          }
          if(Object.keys(this.accountsAll).length>0){
            let local_accountsAll=Object.assign({},this.accountsAll)
            Object.keys(local_accountsAll).forEach(accountid=>{
              if(local_accountsAll[accountid].user==this.user.id){
                local_accountsAll[accountid].name=this.name
              }
            })
              this.$store.commit('setObjectTo',{what:'accountsAll',to:local_accountsAll})
          }
          this.editName=false
          this.$f7.dialog.close()

        }).catch(error=>{
          this.$f7.dialog.close()
          console.log(error)
          this.$f7.dialog.alert(error.message,error.code||error)
        })
        
      },
        redeem(){ // calls a backend function to cheeck if the code is correct. if it is correct, it calls all information from the clinic according to the account privileges
          if(!this.code){
            return this.$f7.dialog.alert('Code can not be empty')
          }
          this.code=this.code.toUpperCase()
          let found=this.myClinics.includes(this.clinicToredeemCode)
            if(found){
              this.$f7.dialog.close()
              return this.$f7.dialog.alert('You already have an account on this Clinic')
            }
          this.$f7.dialog.preloader('Loading...')
          
         let redeem = this.$firebase.functions().httpsCallable('redeemCode');
        redeem({code:this.code,clinicid:this.clinicToredeemCode}).then((ladata)=> {
          let results=ladata.data ||{}
          if(results.error){
           throw new Error(results.error)
          }
            let account=results.account
         let local_myAccounts= Object.assign({},this.myAccounts)
        local_myAccounts[account.id]=account
        this.$store.commit('setObjectTo',{what:'myAccounts',to:local_myAccounts})
          this.$store.commit('setObjectTo',{what:'focusedAccount',to:account })
          this.code=''
          this.name=this.user.displayName?this.user.displayName:this.focusedAccount.name
        this.$f7.dialog.close()
         this.$f7.toast.show({
          text:'Code redeemed!',
          position: 'center',
          closeTimeout: 2000,
          destroyOnClose: true
        });

        if(this.focusedAccount.privx){
        if (this.focusedAccount.privx.clinician) {
         this.$store.commit('setWhatTo',{what:'activeTab',to:'/patients'}) 
        return this.$f7.views.main.router.navigate('/patients')
        }
        if (this.focusedAccount.privx.createResources) {
          this.$store.commit('setWhatTo',{what:'activeTab',to:'/resources'}) 
        return this.$f7.views.main.router.navigate('/resources')
        }
        if (this.focusedAccount.privx.createAccounts){
          this.$store.commit('setWhatTo',{what:'activeTab',to:'/accounts'}) 
          return this.$f7.views.main.router.navigate('/accounts')
        } 
        }else{
          this.$store.state.focusedAccount.privx={}
          return this.$f7.dialog.alert('This account does not have any privileges yet')
        }
        }).catch(error=>{
          this.$f7.dialog.close();
          console.log(error)
          this.$f7.dialog.alert((error.message,error.code )|| error);
        })
        }
    }
}
</script>
<style>
.touppercase input{
  text-transform: uppercase;
}
.width-100{
  width: 100%;
}
.no-flex{
  display:block
}
.fixiconresoruce .item-media .icon{
  margin: 0 auto;
}
.myavatar{
  width: 40px;
}
#myimg-input{
    display: none;
}
</style>

