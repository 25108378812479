import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'

Vue.use(Vuex)
export const store =new Vuex.Store({
state:{
    AYAVersion:true, // for using aya version modules
    version: '4.1.6',//app version
    allUsers:{}, // only a user with 'god' property can get all user of the app
    accountToEdit:{}, 
    activeTab:'/patients', // active tab from the left pannel (patients,resources, accounts,etc...)
    activities:{},//list of all available activities
    digitalResources:{},
    pendingDigitalResources:{},
    digitalResourcesTags:{
            types:{
            PhysicalActivity:'Physical Activity',
            FoodNutrition:'Food + Nutrition',
        },
        platforms:{
        Video:'Video',
        App:'App',
        Challenge:'Challenge',
        Tracker:'Tracker',
        Website:'Website'
        },
        features:{
        YogaPilates:' Yoga/Pilates',
        Cardio:'Cardio',
        StrengthBuilding:'Strength Building',
        HIIT:'HIIT',
        Cooking:'Cooking',
        HealthyEating:' Healthy Eating',
        Education:'Education'
        }
       
    },
    focusedAccount:{
        privx:{}
    }, // the account you are using on that moment
    focusedActivity:null, // activity to edit
    accountsAll:{}, // all the account of the clinic you are on
    myAccounts:{}, 
    loginout:false,
    firstPreloader:false,
    clinics:{}, // all the existing clinics
    user:{}, // logged in user info
    focusedclinic:{}, // clinic to edit
    clinicToredeemCode:'', // selected clinic on the settings tab to redeem a code
    typeOfResources:{},
    resourceTypeEnFoco: null,// focused type of resource, not available now
    gmapsAPIkey: 'AIzaSyAXB_J_9zZEnNzptUPwM2u9LK2APMwMuog', // google maps api key
    resources:{},//list of all available resources
    focusedResource:null,//resource to edit
    focusedPDF:null, // prescription preview on pdf string
    resourceTypesIcons:[   // icon to use on map resources
        {ico:'american-sign-language-interpreting',uni:'\uf2a3'},
        {ico:'apple',uni:'\uf179'},
        {ico:'bicycle',uni:'\uf206 '},
        {ico:'binoculars',uni:'\uf1e5'},
        {ico:'birthday-cake',uni:'\uf1fd'},
        {ico:'building',uni:'\uf1ad'},
        {ico:'child',uni:'\uf1ae'},
        {ico:'cutlery',uni:'\uf0f5'},
        {ico:'dribbble',uni:'\uf17d'},
        {ico:'flag-checkered',uni:'\uf11e'},
        {ico:'futbol-o',uni:'\uf1e3'},
        {ico:'gamepad',uni:'\uf11b'},
        {ico:'gift',uni:'\uf06b'},
        {ico:'graduation-cap',uni:'\uf19d'},
        {ico:'heart',uni:'\uf004'},
        {ico:'heartbeat',uni:'\uf21e'},
        {ico:'leaf',uni:'\uf06c'},
        {ico:'lemon-o',uni:'\uf094'},
        {ico:'medkit',uni:'\uf0fa'},
        {ico:'music',uni:'\uf001'},
        {ico:'paw',uni:'\uf1b0'},
        {ico:'shopping-bag',uni:'\uf290'},
        {ico:'shopping-basket',uni:'\uf291'},
        {ico:'shopping-cart',uni:'\uf07a'},
        {ico:'tree', uni: '\uf1bb' },
        {ico:'trophy',uni:'\uf091'},
        {ico:'universal-access',uni:'\uf29a'},
        {ico:'university',uni:'\uf19c'},
        {ico:'users',uni:'\uf0c0'},
        {ico:'wikipedia-w',uni:'\uf266'},
        {ico:'product-hunt',uni:'\uf288'},
        {ico:'user-md',uni:'\uf0f0'},
        {ico:'archive',uni:'\uf187'},
        {ico:'handshake-o',uni:'\uf2b5'},
        {ico:'lightbulb-o',uni:'\uf0eb'}
     ],
     activityTypes:['strength','light','moderate','vigorous'],
     actualPatient:null, // patient you are working on
     patients:{}, // all the patients from the clinic you are on
     focusedPrescription:{}, // prescription you are working on
     dietDiction:{ // to use on the titles of the diet recommendation 
        dietQ1:'Fruits',
        dietQ2:'Vegetables',
        dietQ3:'Grains',
        dietQ4:'Sweetened beverages',
        dietQ5:'Snacking between meals',
      },
    loginScreen:true, // to open the login screen
    info:{}, //  engine information needed for physical and diet recommendation
    preResourcesFilter:[]
},
getters:{
    ageInMonth:state=>{ // returns the patient age in months
        return moment().diff(moment(state.actualPatient.birthday, "MM-DD-YYYY"),'months');
      },
      info:(state,getters)=>{ // returns the state info filtered by the age of patient
      let target= getters.ageInMonth<240?'adolescents':'adults'
        let info={
            diet:state.info.diet,
            physical:state.info.physical[target]
        }
        return info
      },
      
    myClinics: state=>{ // returns array of ids of the clinic you have accounts on
            let array=[]
            Object.keys(state.myAccounts).forEach(accountid=>{
                array.push(state.myAccounts[accountid].clinicid)
            })
            return array || [];
    },
    onClinic: state=>{ // returns the id of the clinic you are working on
        let onClinic = state.focusedAccount.clinicid || '';
         return onClinic
        
    },
    myClinicsProfiles: state=>{ // returns array of objects (with the account id and the clinic id) for each account that the user have 
        let privx_by_clinic = state.user.privx_by_clinic || {};
        let clinicids= Object.keys(privx_by_clinic) || [];
        let todsned=[];
        clinicids.forEach(clinicid => {
            let clincs=privx_by_clinic[clinicid];
            todsned.push(clincs);
        });
        return todsned;
    },
    selectedResourcesByType: state => { // payload: 'physical' or 'diet'
                                        //returns array with the selected resources of the prescription filtered by the payload.
    return (cualtipo) => {
        let toreturn=[];
       if(Object.keys(state.focusedPrescription.selectedResources).length>0){
         let preresources = state.focusedPrescription.selectedResources || {};
         let arrareosurces=Object.keys(preresources) || [];
         let filtrado =  arrareosurces.filter(resourceid=>{
             return state.typeOfResources[state.focusedPrescription.selectedResources[resourceid].type].class==cualtipo;
           });
           toreturn =  filtrado.map(resourceid=>{
                 return state.focusedPrescription.selectedResources[resourceid];
           });
     }
     return toreturn;
    }
   },
   resourcesByType: state => { //returns array with the resources available on the clinic filtered by the payload.
                                // payload: 'physical' or 'diet'
        return (cualtipo) => {
            let arreglado=Object.values(state.typeOfResources || []);
            return arreglado.filter(unres=>{
                return unres.class==cualtipo;
            });
         }
    },
    availableIcons: state=>{ //returns array with the icons that are not used by any type of resource.
        let allresources=Object.values(state.typeOfResources) || [];
        return state.resourceTypesIcons.filter(unicon=>{
            return !allresources.some(unres=>{
                return unres.ico.ico==unicon.ico;
            });
        });
    },
    sortedAccounts:state=>{ //returns all the accounts of this clinic sorted by name.
        return Object.values(state.accountsAll).sort((a,b)=>{
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
    },
    sortedClinics:state=>{ //returns all the clinics sorted by name.
        return Object.values(state.clinics).sort((a,b)=>{
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
    },
   
    sortedActivities:state=>{ //returns all the activities sorted by name.
        return Object.values(state.activities).sort((a,b)=>{
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
    },
    sortedResources:state=>{ //returns all the resources of this clinic sorted by name.
        return Object.values(state.resources).sort((a,b)=>{
            var x = a.ginfo.name.toLowerCase();
            var y = b.ginfo.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
    },
    sortedResourcesTypes:state=>{ //returns all the type of resources sorted by name.
        return Object.values(state.typeOfResources).sort((a,b)=>{
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
    },
    sortedPatients:state=>{ //returns all the resources of this clinic sorted by last name.
        return Object.values(state.patients).sort((a,b)=>{
            var x = a.lastName.toLowerCase();
            var y = b.lastName.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            else {
                var m = a.name.toLowerCase();
                var n = b.name.toLowerCase();
                if (m < n) {return -1;}
                if (m > n) {return 1;}
                return 0;
            }
        });
    },
    obesityStatus:(state,getters)=>{ //returns object with the obesity risk (string) color and the activity level (string)
        let toreturn={
             obesity:null,
             activity:null
         };
         let risk=state.focusedPrescription.risk;
 
         if(getters.ageInMonth<240){      
         if(risk && risk.bmi && risk.bmi.active){
             if(risk.bmi.bmi>84 && risk.bmi.bmi<=95){
                 toreturn.obesity='green';
             }
             else if(risk.bmi.bmi>95 && risk.bmi.bmi<=98){
                 toreturn.obesity='yellow';
             }
             else if (risk.bmi.bmi>98 || risk.bmi.absoluteBmi > 35){
                 toreturn.obesity='red';
             }
             else if(risk.bmi.bmi<=84){
                 toreturn.obesity='normal';
             }
         }
         if(risk && risk.physical && risk.physical.active){
             let dayActivity = (risk.physical.moderateValue + (risk.physical.vigorousValue*2) )/7;
             if(dayActivity < 15) toreturn.activity='inactive';
             else if(dayActivity<30)toreturn.activity='SomewhatActive';
             else if(dayActivity<45)toreturn.activity='ModeratelyActive';
             else toreturn.activity='active';
         }
     }else{
         if(risk && risk.bmi.absoluteBmi && risk.bmi.active){
             if(risk.bmi.absoluteBmi>=25 && risk.bmi.absoluteBmi<30){
                 toreturn.obesity='green';
             }
             else if(risk.bmi.absoluteBmi>=30 && risk.bmi.absoluteBmi<40){
                 toreturn.obesity='yellow';
             }
             else if (risk.bmi.absoluteBmi >= 40){
                 toreturn.obesity='red';
             }
             else  toreturn.obesity='normal';
         }
         if(risk && risk.physical && risk.physical.active){
             let weekActivity = risk.physical.moderateValue + (risk.physical.vigorousValue*2) ;
             if(weekActivity < 38) toreturn.activity='inactive';
             else if(weekActivity<75)toreturn.activity='SomewhatActive';
             else if(weekActivity<113)toreturn.activity='ModeratelyActive';
             else toreturn.activity='active';
         }
     }
         return toreturn;
      },
     recommendedActivites:(state,getters)=>{ //returns object with level of intensity of the activities. each level has an array with its activities
        let obesityStatus=getters.obesityStatus.obesity || null;
        let bytypeholder={ };
        let types=state.activityTypes;
        let targetedActivities=[];
        if(obesityStatus){
            targetedActivities=Object.values(state.activities).filter(q=>{
                return q.groups[obesityStatus];
            });
            targetedActivities.forEach(unac => {
                state.activityTypes.forEach(onelevel => {
                    if(unac.actLevels[onelevel]){
                        if(!bytypeholder[onelevel]){
                            bytypeholder[onelevel]=[];
                        }
                        bytypeholder[onelevel].push(unac);
                    }
                });
            });
            types.forEach(ungroupid => {
                if(bytypeholder[ungroupid]){
                    bytypeholder[ungroupid].sort((a,b)=>{
                        var x = a.name.toLowerCase();
                        var y = b.name.toLowerCase();
                        if (x < y) {return -1;}
                        if (x > y) {return 1;}
                        return 0;
                    });
                }
            });
        }
        return bytypeholder;
    },
     obesityRecomendationsProperties:state=>{ //returns array with the diet questions that where wrongly answered (string)
        let parametrs=['dietQ1','dietQ2','dietQ3','dietQ4','dietQ5'];
        let risk=state.focusedPrescription.risk;
        if(risk && risk.diet && risk.diet.active){
            let dietvalues=risk.diet;
            let filtrado=parametrs.filter(q=>{
                return (dietvalues[q]!=='Rarely/Never' );
            });
            parametrs=filtrado;
        }
        return parametrs;
     },
     physicatActivitiesRecommnedation:(state, getters)=>{ //returns object with the physical activity recommendation divided by 3 groups of 4 weeks. each group is an object with title and a string recommendation (both strings)
        let recommend={
            week1_4:{
                title: 'Weeks 1 to 4',
                str: null
            },
            week5_8:{
                title: 'Weeks 5 to 8',
                str: null
            },
            week9_12:{
                title: 'Weeks 9 to 12',
                str: null
            },
            week12plus:{
                title: 'Weeks 12 onwards',
                str: null
            }
        };
        let lerisk=state.focusedPrescription.risk;
        if(lerisk && getters.obesityStatus.obesity && getters.info.physical.inactive[getters.obesityStatus.obesity] ){
            recommend.week1_4.str=lerisk.physical.active?getters.info.physical[getters.obesityStatus.activity][getters.obesityStatus.obesity].week1_4:getters.info.physical.inactive[getters.obesityStatus.obesity].week1_4;
            recommend.week5_8.str=lerisk.physical.active?getters.info.physical[getters.obesityStatus.activity][getters.obesityStatus.obesity].week5_8:getters.info.physical.inactive[getters.obesityStatus.obesity].week5_8;
            recommend.week9_12.str=lerisk.physical.active?getters.info.physical[getters.obesityStatus.activity][getters.obesityStatus.obesity].week9_12:getters.info.physical.inactive[getters.obesityStatus.obesity].week9_12;
            recommend.week12plus.str=lerisk.physical.active?getters.info.physical[getters.obesityStatus.activity][getters.obesityStatus.obesity].week12plus:getters.info.physical.inactive[getters.obesityStatus.obesity].week12plus;
        }
        return recommend;
     }
},
mutations:{
    inactiveDigitalResource:(state,payload)=>{
        let localDigitalResources=Object.assign({},state.digitalResources)
        let localresource=Object.assign({},localDigitalResources[payload.id])

       delete localresource.inactive
       
       localDigitalResources[localresource.id]=Object.assign({},localresource)
       state.digitalResources=Object.assign({},localDigitalResources)
    },
    setChildOnFocusedPrescription:(state,payload)=>{
        state.focusedPrescription=Object.assign({},state.focusedPrescription,{[payload.wich]:payload.neovalue});
        if(!payload.neovalue){
           delete state.actualPatient.prescriptions[state.focusedPrescription.id][payload.wich]
        }else{
             state.actualPatient.prescriptions[state.focusedPrescription.id]=Object.assign({},state.focusedPrescription,{[payload.wich]:payload.neovalue});
        }
        },
    setPhysicalRecomByClinician:(state,payload)=>{
        if(!payload.neovalue){
           delete state.focusedPrescription['physicalRecomByClinician']
           state.focusedPrescription=Object.assign({},state.focusedPrescription)
           delete state.actualPatient.prescriptions[state.focusedPrescription.id].physicalRecomByClinician
        }else{
            state.focusedPrescription['physicalRecomByClinician']=Object.assign({},payload.neovalue)
            state.actualPatient.prescriptions[state.focusedPrescription.id]=Object.assign({},state.focusedPrescription,{physicalRecomByClinician:payload.neovalue});
        }
        },
    updateAvatar:(state,photoURL)=>{
        state.user.photoURL = photoURL;
    },
    setSelection:(state,payload)=>{ // adds or deletes a selected resource or a selected activity from the prescription
                                    // payload: the item to edit (object), selected resources or selected activities(string), delete:boolean
         
         if(!payload.value){  
         delete  state.focusedPrescription[payload.where]?.[payload.id]
         state.focusedPrescription=Object.assign({},state.focusedPrescription);
        }else{
            if(!state.focusedPrescription[payload.where])state.focusedPrescription[payload.where]={}
        state.focusedPrescription[payload.where][payload.id]=Object.assign({},payload.value);
        state.focusedPrescription=Object.assign({},state.focusedPrescription);
                    }
    },
    setFupsDates:(state,payload)=>{ // sets the date to send one follow up survey
                                    // payload: wich follow up (string), the date to edit (string)
        if(state.focusedPrescription.fups){
            if(state.focusedPrescription.fups[payload.wich]){
                state.focusedPrescription.fups[payload.wich].date=payload.date;
              
            } else{
                state.focusedPrescription.fups[payload.wich]={}
                state.focusedPrescription.fups[payload.wich].date=payload.date;
              
            }
        } else{
            state.focusedPrescription.fups={}
            state.focusedPrescription.fups[payload.wich]={}
                state.focusedPrescription.fups[payload.wich].date=payload.date;
                
        }
        
    },
    setFupsSent:(state,payload)=>{ // sets true if the follow up survey was sent
                                    // payload: wich follow up (string), if it was sent (boolean)
        if(state.focusedPrescription.fups){
            if(state.focusedPrescription.fups[payload.wich]){
                state.focusedPrescription.fups[payload.wich].sent=true;
              
            } else{
                state.focusedPrescription.fups[payload.wich]={}
                state.focusedPrescription.fups[payload.wich].sent=true;
              
            }
        } else{
            state.focusedPrescription.fups={}
            state.focusedPrescription.fups[payload.wich]={}
                state.focusedPrescription.fups[payload.wich].sent=true;
                
        }
        
    },
    setNextApppointment:(state,payload)=>{ // sets the date for the next appointment with the clinician
                                            // payload: the date to edit (string)
        state.focusedPrescription['nextAppointment']=payload.date;
    },
    setRisk:(state,payload)=>{// sets one risk factor on the prescription
                                 // payload: riskid: the risk factor name (string), value: the risk factor object
        state.focusedPrescription.risk[payload.riskid]=Object.assign({},payload.value);
    },
    setSent:(state,payload)=>{// sets a time stamp for the moment the prescription was sent
                                // payload: time stamp
        let prevs=Object.assign({},state.focusedPrescription.sent || {});
        prevs[payload.when]=payload;
        if(!state.focusedPrescription.sent){
            state.focusedPrescription=Object.assign({},state.focusedPrescription,{sent:prevs});
            state.actualPatient.prescriptions[state.focusedPrescription.id]=Object.assign({},state.focusedPrescription,{sent:prevs});
        }
        else{
            state.focusedPrescription.sent=Object.assign({},prevs);
            state.actualPatient.prescriptions[state.focusedPrescription.id].sent=Object.assign({},prevs);
        }
    },
    setWhatTo:(state,payload)=>{ // overwrite a state item (array or string)
        state[payload.what]=payload.to;
    },
    setObjectTo:(state,payload)=>{ //overwrite a state item (object)
        if(payload.to==null){
            state[payload.what]=payload.to;
        }
        else{
            state[payload.what]=Object.assign({},payload.to);
        }
    },
    updateObjectPropValue:(state,payload)=>{ // overwrite a state item child
                                            // payload: what: item from state, prop: item to change, neovalue: new value
        if(payload.neovalue==null){
            let clonado=Object.assign({},state[payload.what]);
            delete clonado[payload.prop];
            state[payload.what]=Object.assign({},clonado);
        }
        else{
            if(!state[payload.what][payload.prop]){
                let clonado=Object.assign({},state[payload.what]);
                clonado[payload.prop]=payload.neovalue;
                state[payload.what]=Object.assign({},clonado);
            }
            else{
                if(typeof payload.neovalue === 'object'){
                    state[payload.what][payload.prop]=Object.assign({},payload.neovalue);
                }
                else{
                    state[payload.what][payload.prop]=payload.neovalue;
                }
            }
        }
    }

}
})