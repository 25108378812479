<template>
  <f7-page bg-color="white">
    <f7-navbar back-link="Back">
      <f7-nav-title title="Pending Digital Resources"
      ></f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchPendingDigitalResource" icon-material="search"></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchPendingDigitalResource"
        expandable
        search-container=".search-list"
        search-in=".item-title, .item-subtitle, .item-text, .item-header"
        :disable-button="!$theme.aurora"
      ></f7-searchbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found"></f7-list-item>
    </f7-list>

    <f7-list media-list
      class="search-list searchbar-found no-margin-top fixiconresorlist"
    >
    <f7-list-item
    v-if="Object.keys(pendingDigitalResources).length==0"
    >
    There are no pending digital resources
    </f7-list-item>
      <f7-list-item
        v-for="resource in sortedResources"
        :key="resource.id"
        :title="resource.link"
        :subtitle="resource.name"
        :text="resource.description"
         @click="editResource(resource.id)"
         link="#"
         >
         <f7-row slot="after" >
           <f7-col>
        <f7-link><f7-icon material="edit"/></f7-link>
        </f7-col>
        <f7-col class="margin-left">
        <f7-link @click="deletePendingResource($event,resource.id)"><f7-icon color="gray" material="delete"/></f7-link>
        </f7-col>
        </f7-row>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(['pendingDigitalResources']),
    sortedResources(){
      return Object.values(this.pendingDigitalResources).sort((a,b)=>{
        var x = a.link.toLowerCase();
            var y = b.link.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
    }
  },
  methods: {
    deletePendingResource(e,id){
      e.stopPropagation()
       this.$f7.dialog.confirm('Are you sure you want to delete this resource from the list?',  async ()=> {
         try{
        await this.$firebase.database().ref('pendingDigitalResources/'+id).set(null)
           let local=Object.assign({},this.pendingDigitalResources)
           delete local[id]
           this.$store.commit('setObjectTo',{what:'pendingDigitalResources',to:local});
           this.$f7.toast.show({
            text: `Deleted`,
            position: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
          });
        }catch(error){
          console.log(error)
          this.$f7.dialog.alert(error.message,error.code)
        }
      
    });
    },
      editResource(id){
           this.$f7.views.main.router.navigate(`/createDigitalResource/${id}`)
      }
     
  }
};
</script>