<template>
        <div class="previewWrapper" >
        <div  class="sudobody margin" >
            <div class="previewSection">
        <p>Dear {{actualPatient.name}},</p>
        <p v-if="!focusedPrescription.physicalRecomInact || !alldietRecomInact">
             Below is your plan to help you improve your heart health made during your visit to the {{clinics[onClinic].name}} clinic. We’ve made these goals based on what you told us about your physical activity and eating habits. We hope these goals are realistic for you. If you are struggling to meet your goals, please reach out to us sooner.  
        </p>
        <p v-else>
             We did not identify any specific physical activity or food intake goals during your appointment today. To keep your heart healthy, we encourage you to be active for at least {{isAdolescent?'60 minutes every day':'150 minutes every week'}}. It is helpful to include fruits, vegetables and whole grains in your diet, while limiting sugar sweetened beverages and sugary, low-nutrient snacks. Please reach out if you would like work with us to identify more specific goals.  
        </p>
            </div>
            <template v-if="!focusedPrescription.physicalRecomInact" >
            <div class="previewSection">
        <p class="sectionTitle green"><strong>YOUR GOAL FOR PHYSICAL ACTIVITY</strong></p>
        <p v-if="focusedPrescription.risk.bmi.active"><strong>Week 1 to 4: </strong>
        {{focusedPrescription.physicalRecomByClinician?focusedPrescription.physicalRecomByClinician.week1_4:physicatActivitiesRecommnedation.week1_4.str}}
            </p>
            <p v-else class="text-align-center">There is not BMI information on your profile, or it is disabled</p>
            </div>
            <div v-if="selectedActivities.length>0 && focusedPrescription.risk.bmi.active" class="previewSection">
                <p ><strong class="sectionSubtitle green">Remember physical activity should be fun! Reach your goals by doing activities you enjoy!:</strong>
                <i><strong>{{selectedActivities}}</strong></i>
                </p>
            </div>
            </template>
            <div v-if="!alldietRecomInact" class="previewSection">
            <p class="sectionTitle green"><strong>YOUR GOALS FOR HEALTHY EATING</strong></p>
            <p v-if="dietCounter(focusedPrescription.risk.diet)==5" class="text-align-center"
            >Good job. There are no behaviour change recommendations for you.</p>
            <center class="dietRecomendationImgs">
            <img v-if="dietRecommendations.includes('dietQ1')" src="../static/prescriptionImages/fruits.png" />
            <img v-if="dietRecommendations.includes('dietQ2')" src="../static/prescriptionImages/vegetables.png" />
            <img v-if="dietRecommendations.includes('dietQ3')" src="../static/prescriptionImages/whole-grains.png" />
            <img v-if="dietRecommendations.includes('dietQ4')" src="../static/prescriptionImages/beverages.png" />
            <img v-if="dietRecommendations.includes('dietQ5')" src="../static/prescriptionImages/snacking.png" />
            </center>
            </div>
            <div >
                <div class="previewSection">
                <p class="sectionTitle green"><strong>RESOURCES TO HELP YOU ACHIEVE YOUR GOALS</strong></p>
                 </div>
                 <div >
                     <div class="previewSection">
                     <p class="sectionTitle orange">Community Resources</p>
                     </div>
                     <div><f7-button  outline external target="_blank" 
                :href="`https://map.preventtool.com/?c=${onClinic}&p=noPatient`"
                 >Open Resources map</f7-button></div>
                 <div class="previewSection"
                  v-for="res in focusedPrescription.selectedResources" :key="res.id">
                     <p><strong>{{res.ginfo.name}}</strong> <i>({{typeOfResources[res.type].name}})</i></p>
                     <a class="external" target="_blank" :href="`https://www.google.com/maps/search/?api=1&query=${res.ginfo.formatted_address.replace(' ','+')}&${res.ginfo.lat},${res.ginfo.lng}`" >{{res.ginfo.formatted_address}}</a>
                     <p>{{res.ginfo.international_phone_number}}</p>
                     <a class="external" target="_blank" :href="res.ginfo.website ">{{res.ginfo.website}}</a>
                 </div>
                 </div>
                 <div v-if="!focusedPrescription.physicalRecomInact || !alldietRecomInact">
                 <div class="previewSection">
                    <p class="sectionTitle orange">Digital Resources</p>
                 </div>
                 <div><f7-button  outline external target="_blank" 
                :href="`http://rx.preventtool.com/?p=${actualPatient.id}&r=${focusedPrescription.id}&c=${onClinic}&v2=${true}&d=${true}&only=${true}`"
                 >Open digital reosources</f7-button></div>
                 <div class="previewSection"
                  v-for="res in focusedPrescription.selectedDigitalResources" :key="res.id">
                     <p><strong>{{res.name}}</strong></p>
                     <a class="external" target="_blank" :href="res.link" >{{res.link}}</a>
                 </div>
                 </div>
            </div>
                   <div class="previewSection">
            <p class="sectionTitle green"><strong>YOUR HEART HEALTH</strong></p>
            <table class="margin-auto">
            <tr class="margin-bottom">
                <td v-for="(item,index) in bigRiskItems" :key="index" width="25%" class="text-align-center vertical-align-baseline">
                    <p class="secondRowFont"><strong>{{item.title}}</strong></p>
                    <div class="indicatorContainer firstRow"
                    :style="{'backgroundColor':item.color}">
                        <div class="indicator" :class="index!='overall'&&!focusedPrescription.risk[index].active?'symbolFont':''">
                        {{index=='overall'?item.value:focusedPrescription.risk[index].active?item.value:'No data'}}
                        <span class="symbolFont">{{item.symbol}}</span>
                        </div>
                        </div>
                        <div v-if="item.improved" class="improvedFont display-inline-flex" >
                          <p> Improved from last visit!</p>
                          <f7-icon color="green" class="rotate" material="forward" />
                            </div>

                </td>
            </tr>
            <tr>
                <td v-for="(item,index) in smallRiskItems" :key="index" width="25%" class="text-align-center vertical-align-baseline">
                    <p class="secondRowFont"><strong>{{item.title}}</strong></p>
                    <div :style="{'backgroundColor':item.color}" class="indicatorContainer secondRow">
                        <div class="indicator"
                         :class="(index!='overall'&&!focusedPrescription.risk[index].active)||(index=='bloodPressure'||index=='smoking')?'symbolFont':''">
                        {{focusedPrescription.risk[index].active?item.value:'No data'}}</div>
                        </div>
                        <div v-if="item.improved" class="improvedFont display-inline-flex" >
                          <p> Improved from last visit!</p>
                          <f7-icon color="green" class="rotate" material="forward" />
                            </div>

                </td>
            </tr>
            </table>
        </div>
        <div class="previewSection">
            <p class="sectionSubtitle green"><strong>HELPFUL HINTS</strong></p>
            <p>Use <f7-link external target="_blank" :href="`http://rx.preventtool.com/?p=${actualPatient.id}&r=${focusedPrescription.id}&c=${onClinic}&v2=${true}&h=${true}&only=${true}`" >this link to view additional information</f7-link> that will help you to understand your heart health and improve your health behaviors.</p>
            <p>Sincerely yours,</p>
            <p>{{focusedPrescription.clinicianName}}, {{clinics[onClinic].name}}</p>
        </div>
        </div>
</div>
</template>
<script>
import {mapGetters, mapState} from 'vuex'
export default {
    computed:{
        ...mapState(['focusedPrescription','actualPatient','clinics','activities','typeOfResources']),
        ...mapGetters(['ageInMonth','onClinic','physicatActivitiesRecommnedation']),
         alldietRecomInact(){
      return this.focusedPrescription.dietQ1RecomInact&&this.focusedPrescription.dietQ2RecomInact&&
      this.focusedPrescription.dietQ3RecomInact&&this.focusedPrescription.dietQ4RecomInact&&
      this.focusedPrescription.dietQ5RecomInact?true:false
    },
    isAdolescent(){
       return this.ageInMonth<240
    },
        selectedActivities(){
           let selected= Object.keys(this.focusedPrescription.selectedActivities ||{})
           let array=[]
            selected.forEach(act=>{
                if(this.activities[act]?.name)array.push(this.activities[act].name)
                else array.push(act)
            }) 
            return array.join(', ')
        },
        dietRecommendations(){
        const diets=['dietQ1','dietQ2','dietQ3','dietQ4','dietQ5']
         if(!this.focusedPrescription.risk.diet.active) return diets
        return diets.filter(dietQ=>{ return !this.focusedPrescription[`${dietQ}RecomInact`] 
        && (((dietQ=='dietQ1'||dietQ=='dietQ2'||dietQ=='dietQ3')&&this.focusedPrescription.risk.diet[dietQ]!=='Usually/Often')||((dietQ=='dietQ4'||dietQ=='dietQ5')&&this.focusedPrescription.risk.diet[dietQ]!=='Rarely/Never'))})
            
        },
        bigRiskItems(){
            return {
                overall:{
                    title:'Overall Heart Helth',
                    value:this.setItemValue('overall',this.focusedPrescription.globalProgressBar),
                    symbol:'%',
                    improved:this.improvedFromLastVisit('overall'),
                    color:this.itemColor('overall')
                },
                physical:{
                    title:`Physical Activity (${this.ageAtCollectionDate(this.focusedPrescription.risk.weightDate)<240?'min/day':'min/week'})`,
                    value:this.setItemValue('physical',this.focusedPrescription.risk.physical.moderateValue
                    +this.focusedPrescription.risk.physical.vigorousValue),
                    improved:this.improvedFromLastVisit('physical'),
                    color:this.itemColor('physical')
                },
                diet:{
                    title:'Number of food intake behaviors met',
                    value:this.dietCounter(this.focusedPrescription.risk.diet),
                    improved:this.improvedFromLastVisit('diet'),
                    color:this.itemColor('diet')
                },
                bmi:{
                    title:`Body Mass Index ${this.ageAtCollectionDate(this.focusedPrescription.risk.bmi.weightDate )<240?'(percentile)':''}`,
                    value:this.setItemValue('bmi',this.ageAtCollectionDate(this.focusedPrescription.risk.bmi.weightDate )<240?this.focusedPrescription.risk.bmi.bmi:this.focusedPrescription.risk.bmi.absoluteBmi),
                    improved:this.improvedFromLastVisit('bmi'),
                    color:this.itemColor('bmi')
                }
            }
        },
        smallRiskItems(){
            return {
                fbg:{
                    title:'Fasting Blood Glucose',
                    value:this.setItemValue('fbg',this.focusedPrescription.risk.fbg.value),
                    improved:this.improvedFromLastVisit('fbg'),
                    color:this.itemColor('fbg')
                },
                cholesterol:{
                    title:'Total Cholesterol',
                    value:this.setItemValue('cholesterol',this.focusedPrescription.risk.cholesterol.value),
                    improved:this.improvedFromLastVisit('cholesterol'),
                    color:this.itemColor('cholesterol')
                },
                bloodPressure:{
                    title:'Blood Pressure',
                    value:this.setItemValue('bloodPressure',`${this.focusedPrescription.risk.bloodPressure.bloodSystolicValue}/${this.focusedPrescription.risk.bloodPressure.bloodDiastolicValue}`),
                    improved:this.improvedFromLastVisit('bloodPressure'),
                    color:this.itemColor('bloodPressure')
                },
                smoking:{
                    title:'Smoking Status',
                    value:this.setItemValue('smoking',this.focusedPrescription.risk.smoking.value),
                    improved:this.improvedFromLastVisit('smoking'),
                    color:this.itemColor('smoking')
                },
            }
        },
        lastPrescriptionSent(){
            if(!this.actualPatient.prescriptions) return 
            let lastSend=0
            let id=''
            Object.values(this.actualPatient.prescriptions).forEach(prescr=>{
             if(prescr.id!=this.focusedPrescription.id && prescr.lastSent && prescr.lastSent >lastSend ) {
                 lastSend=prescr.lastSent
                 id=prescr.id
                 }
            })
            let last=this.actualPatient.prescriptions[id]
               return last
        }
    },
    methods:{
        dietCounter(riskItem){
            let pts = 0;
          let options = ["dietQ1", "dietQ2", "dietQ3", "dietQ4", , "dietQ5"];
          options.forEach((one) => {
            if ((one=='dietQ1'||one=='dietQ2'||one=='dietQ3')&&(riskItem[one] === true || riskItem[one] === "Usually/Often")) pts++;
            else if ((one=='dietQ4'||one=='dietQ5')&&(riskItem[one] === true || riskItem[one] === "Rarely/Never")) pts++;
            })
            this.$emit('setHeartHealth',{item:'diet',param:'value',value:pts})
            return pts
        },
        getLastGlobalProgressbar(){
                let local_points = 0;
                let local_totalItems = 0;
      Object.keys(this.lastPrescriptionSent.risk).forEach((unriskid) => {
        if (this.lastPrescriptionSent.risk[unriskid].active) {
          local_points += Number(this.lastPrescriptionSent.risk[unriskid].points);
          local_totalItems++;
        }
      });
      let value = Math.round((local_points / (local_totalItems * 2)) * 100);
      if (isNaN(value)) {
        value = 0;
      }
      return value
        },
        improvedFromLastVisit(item){
            let improved=null
            if(this.focusedPrescription.heartHealth?.bigRiskItems[item]?.improved
            ||this.focusedPrescription.heartHealth?.smallRiskItems[item]?.improved){
              improved=  true
            }else if (this.lastPrescriptionSent){
            let lastValue=0
            let newValue=0
        if(item=='overall'){
            lastValue=this.lastPrescriptionSent.globalProgressBar
            newValue=this.focusedPrescription.globalProgressBar
            if(!this.lastPrescriptionSent.globalProgressBar||lastValue==0){
                  lastValue= this.getLastGlobalProgressbar()
                }
         
            }else{
                if(!this.lastPrescriptionSent.risk[item].active 
             ||!this.focusedPrescription.risk[item].active){
                  return false
             }
           else{ 
               lastValue=this.getValue('lastPrescriptionSent',item)
            newValue=this.getValue('focusedPrescription',item)
            }
            } 
            if(item=='overall' || item=='physical' || item=='diet' || item=='smoking'){
                improved = newValue>lastValue
            }else {
                improved = newValue<lastValue
                }
                if(improved)this.$emit('setHeartHealth',{item,param:'improved',value:true})
           }
            
           return improved
        },
        itemColor(item){
            let color=null
            if(item=='overall'){
               color=  this.focusedPrescription.globalProgressBar<50
                ? '#ff1b32'
                :this.focusedPrescription.globalProgressBar<75
                ? '#ffba00'
                :'#7fbf44'
            }else{
            let risk=this.focusedPrescription.risk
                 if(!risk[item].active){
                  color="#9e9e9e"
                  } else {
                   color= risk[item].points == 2
                   ? '#7fbf44'
                   : risk[item].points == 1
                   ? '#ffba00'
                   : '#ff1b32'
             }
                }
             this.$emit('setHeartHealth',{item,param:'color',value:color})
             return color
        },
        getValue(wich,item){
            let riskItem=this[wich].risk[item]
            if(item=='bmi') return  this.ageAtCollectionDate(riskItem.weightDate )<240?riskItem.bmi:riskItem.absoluteBmi
            if(item=='smoking' || item=='bloodPressure') return  riskItem.points
            if(item=='cholesterol' || item=='fbg') return  riskItem.value
            if(item=='diet') return this.dietCounter(riskItem)
            if(item=='physical'){
             let total = riskItem.moderateValue + riskItem.vigorousValue*2
             return this.ageAtCollectionDate(riskItem.weightDate )<240?total/7:total
            }
    },
    setItemValue(item,value){
      if(item=='overall'||this.focusedPrescription.risk[item].active){
          this.$emit('setHeartHealth',{item,param:'value',value})
      }else{
          this.$emit('setHeartHealth',{item,param:'value',value:'No data'})
      }
        return value
    },
     async print(){
        try{
          const imgs = [...document.querySelectorAll('.previewSection')]
            let allCanvas=[]
      const doc = new this.$jsPDF('p','px')
      let x=50
      let y=10
      let remaningSpace=631.4175
    imgs.forEach(a => {
      if(!a)return;
      allCanvas.push(this.$html2canvas(a))
    })
    let resCanvas=await Promise.all(allCanvas)
          resCanvas.forEach((canvas,i)=>{
            const img =  canvas.toDataURL('image/png')
            let canvasHeight=canvas.height* 72 / 96
            if (remaningSpace<canvasHeight) {
              doc.addPage()
              y=10  
             remaningSpace=631.4175-canvasHeight  
             
                   }else{
                     y=631.4175-remaningSpace+10
                    remaningSpace=remaningSpace-canvasHeight  
            }
                doc.addImage(img,'PNG',x,y)
            
            })
            let pdf = doc.output('blob')
            let url = window.URL.createObjectURL(new Blob([pdf], {type: 'application/pdf'}));
              this.$store.commit('setWhatTo',{what:'focusedPDF',to:url})
              this.$f7.views.main.router.navigate('/pdf')

            this.$f7.dialog.close()
      }catch(error){
        this.$f7.dialog.close()
        console.log(error)
        this.$f7.dialog.alert(error.message)
      }
    },
     ageAtCollectionDate(collectionDate){ 
            if(!collectionDate) this.ageInMonth
        return this.$moment(collectionDate,"MM/DD/YYYY").diff(this.$moment(this.actualPatient.birthday, "MM-DD-YYYY"),'months');
      },
    }
}
</script>

<style scoped>
.previewWrapper{
        padding: 10px;
    background: white;
}
.sudobody{
    background-color: white;
    margin: auto  !important;
    max-width: 600px;
    margin-bottom: 15vh !important;
}
.sectionTitle{
    text-align: center;
    font-size:24px;
}
.sectionSubtitle{
    font-size:18px;
}
.orange{
     color:#fbaf4d;
} 
.green{
    color:#7fbf44;
} 
.dietRecomendationImgs img{
    display:block;
    height:500px
}  
.margin-auto{
    margin: auto  !important;
}
.vertical-align-baseline{
    vertical-align: baseline !important;
}
.indicator{
    margin: auto;
    
    font-weight: bolder;
}
.indicatorContainer{
    display: flex;
    margin: auto;
    border-radius: 50%;
}
.firstRow{
    font-size: 5vh;
    height: 12.5vh;
    width: 12.5vh;
}
.firstRowFont{
    font-size: 1.5vh;
}
.secondRow{
    font-size: 4vh;
    height: 11.5vh;
    width: 11.5vh;
}
.secondRowFont{
    font-size: 1.3vh;
}
.rotate{
    transform: rotate(-90deg);
}
.improved{
    align-self: flex-end;
}
.improvedFont{
    font-size: 1.3vh;
}
.symbolFont{
    font-size: 2.5vh;
}

</style>